import type { Maybe } from 'types'
import { environment } from './index'

export const getTenantOverrideFromUrl = (): Maybe<string> => {
  const url = new URL(window.location.href)
  const params = new URLSearchParams(url.search)

  return environment.REACT_APP_TENANT_OVERRIDE && params.get('tenant-override')
}

export const getTenantOverrideHeader = (): Record<string, string> => {
  const tenant = getTenantOverrideFromUrl()

  if (environment.REACT_APP_TENANT_OVERRIDE && tenant) {
    return {
      'X-FINTASTIC-TENANT-OVERRIDE': tenant,
    }
  }

  return {}
}
