import type { EmitUserListRequest, LoadUserListFinish } from './types'

import type { State } from '../state'
import type { WritableDraft } from '../../../../../redux/types'

export function emitUserListRequest(
  state: WritableDraft<State>,
  action: EmitUserListRequest,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
): void {
  state.error = null
  state.userList = []
}

export function loadUserListEnd(
  state: WritableDraft<State>,
  action: LoadUserListFinish,
): void {
  const { error, userList } = action.payload
  state.error = error
  if (error !== null) {
    state.status = 'error'
    state.userList = []
  } else {
    state.status = 'success'
    state.userList = userList
  }
}
