import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import rowData from '../data/arrArea_1.json'
import rowData2 from '../data/arrArea_2.json'
import {
  AssumptionsStates,
  DashboardStates,
  HeadcountStates,
} from '../ducks/dashboard/types'
import {
  selectAssumptionsPageState,
  selectDashboardPageState,
  selectHeadcountPageState,
} from '../ducks/dashboard/selectors'
import DashboardChartHeader from './DashboardChartHeader'

interface WindowSize {
  width: number
  height: number
}

export default function DashboardArrAreaChart() {
  const [chartData, setChartData] = useState(rowData)
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
  })
  const currentAssumptionsState = useSelector(selectAssumptionsPageState)
  const currentHeadcountState = useSelector(selectHeadcountPageState)
  const currentDashboardState = useSelector(selectDashboardPageState)

  useEffect(() => {
    if (currentDashboardState === DashboardStates.finalMarchForecast) {
      setChartData(rowData2)
    } else if (currentHeadcountState === HeadcountStates.suggestionClicked)
      setChartData(rowData2)
    else if (currentAssumptionsState === AssumptionsStates.suggestionClicked)
      setChartData(rowData2)
  }, [currentAssumptionsState, currentHeadcountState, currentDashboardState])

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const tooltipFormatter = (value: number, name: string) => {
    let formattedValue

    if (value < 0) {
      formattedValue = `$(${-Math.round(value / 1000)}K)`.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ',',
      )
    } else {
      formattedValue = `$${Math.round(value / 1000)}K`.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ',',
      )
    }

    return [formattedValue || value, name]
  }

  const yTickFormatter = (value: number) => {
    const formattedValue = `$${
      //   isPositive
      Math.round(value / 1000) - (Math.round(value / 1000) % 10000)
      // : Math.round(value / 1000)
    }K`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    return formattedValue
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <DashboardChartHeader
        title="Target vs Capacity"
        legend={chartData.legend}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <ResponsiveContainer width="97%" height="100%">
          <AreaChart
            width={Math.floor(windowSize.width / 2.0)}
            height={Math.min(Math.floor(windowSize.height / 2.3), 350)}
            data={chartData.data}
            margin={{
              top: 5,
              bottom: 0,
              right: 10,
              left: 0,
            }}
            stackOffset="sign"
          >
            <XAxis dataKey="name" tick={{ fontSize: 10 }} interval={0} />
            <YAxis
              tick={{ fontSize: 10 }}
              domain={[35000000, 65000000]}
              tickFormatter={yTickFormatter}
              interval={0}
            />
            <CartesianGrid strokeDasharray="0.08 0.2" />
            <Tooltip formatter={tooltipFormatter} />
            <defs>
              <linearGradient id="ARR Plan" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#FF9B24" stopOpacity={0.25} />
                <stop offset="95%" stopColor="#FF9B24" stopOpacity={0.25} />
              </linearGradient>
              <linearGradient id="AE Capacity" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#548234" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#548234" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="SDR Capacity" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#B0C5A1" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#B0C5A1" stopOpacity={0} />
              </linearGradient>
              <linearGradient
                id="Marketing Capacity"
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop offset="5%" stopColor="#0163D7" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#0163D7" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="CSM Capacity" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#FEE9E8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#FEE9E8" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="PS Capacity" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#AFCEEE" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#AFCEEE" stopOpacity={0} />
              </linearGradient>
            </defs>
            <Area
              type="monotone"
              dataKey="ARR Plan"
              stroke="#FF9B24"
              fillOpacity={0.25}
              fill="#FF9B24"
            />
            <Area
              type="monotone"
              dataKey="AE Capacity"
              stroke="#548234"
              fillOpacity={0}
              fill="url(#colorUv)"
            />
            <Area
              type="monotone"
              dataKey="SDR Capacity"
              stroke="#B0C5A1"
              fillOpacity={0}
              fill="url(#colorUv)"
            />
            <Area
              type="monotone"
              dataKey="Marketing Capacity"
              stroke="#0163D7"
              fillOpacity={0}
              fill="url(#colorUv)"
            />
            <Area
              type="monotone"
              dataKey="CSM Capacity"
              stroke="#FEE9E8"
              fillOpacity={0}
              fill="url(#colorUv)"
            />
            <Area
              type="monotone"
              dataKey="PS Capacity"
              stroke="#AFCEEE"
              fillOpacity={0}
              fill="url(#colorUv)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  )
}

// missing legend on data for UI
// {
//   "id": 6,
//   "displayName": "PS Capacity",
//   "color": "#AFCEEE"
// },
// {
//   "id": 5,
//   "displayName": "CSM Capacity",
//   "color": "#FEE9E8"
// },
// {
//   "id": 4,
//   "displayName": "Marketing Capacity",
//   "color": "#0163D7"
// },
