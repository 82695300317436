import type {
  AccountProperties,
  ApiConfigResponse,
  ApiUsersInfoResponse,
} from '../api/types'

import React from 'react'

/**
 * ATTENTION! Do not use context for the config getting.
 * Use the hooks from "frontend/main-client/src/redux/ducks/config/hooks.ts" instead
 * This context will be removed in the future
 * @deprecated
 */
export type ConfigContext = {
  config?: ApiConfigResponse
  userInfo?: ApiUsersInfoResponse
  properties?: Record<string, AccountProperties>
}

export const ConfigContext = React.createContext<ConfigContext>({})
