import type { PayloadAction } from '@reduxjs/toolkit'

export enum StatePages {
  Settings,
  Dashboard,
  Assumptions,
  Headcount,
}

export type StateType = any

export enum SettingsStates {
  initial,
  productAdded,
}

export enum AssumptionsStates {
  initial,
  suggestionClicked,
}

export enum HeadcountStates {
  initial,
  suggestionClicked,
}

export enum DashboardStates {
  initial,
  newForecastAdded,
  finalMarchForecast,
}

export type MoveToStatePayload = {
  nextState: StateType
  page: StatePages
}

export type MoveToState = PayloadAction<MoveToStatePayload>

export type UpdateFilterPayload = {
  filterName: string
  filterValue: string[]
}

export type UpdateFilter = PayloadAction<UpdateFilterPayload>
