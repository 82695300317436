/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { DraggableCore, DraggableEventHandler } from 'react-draggable'
// @see https://github.com/MetalMichael/react-resize-panel-ts/
import React, { CSSProperties } from 'react'

import $ from 'cash-dom'
import classNames from 'classnames/bind'
import debounce from 'lodash/debounce'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import style from './ResizePanel.module.css'

const cx = classNames.bind(style)

export interface ResizePanelProps {
  disabled?: boolean
  direction: 'n' | 's' | 'e' | 'w'
  handleClass?: string
  borderClass?: string
  containerClass?: string
  style?: CSSProperties
  onResized?: (newSize: number) => unknown
  onDragStart?: (newSize: number) => unknown
}

interface State {
  size: number
}

class ResizePanel extends React.Component<ResizePanelProps, State> {
  contentRef = React.createRef<HTMLDivElement>()

  wrapperRef = React.createRef<HTMLDivElement>()

  private nodeRef: React.RefObject<HTMLDivElement> = React.createRef()

  constructor(props: ResizePanelProps) {
    super(props)
    this.state = { size: 0 }

    this.validateSize = debounce(this.validateSize, 100).bind(this)
  }

  componentDidMount() {
    const content = this.contentRef.current
    const actualContent = content?.children[0]
    const initialSize = this.isHorizontal()
      ? $(actualContent).outerWidth(true)
      : $(actualContent).outerHeight(true)

    // Initialize the size value based on the content's current size
    this.setState({ size: initialSize })
    this.validateSize()
  }

  isHorizontal = (): boolean =>
    this.props.direction === 'w' || this.props.direction === 'e'

  handleDrag = (e: React.MouseEvent<Element, MouseEvent>, ui: any): void => {
    const { direction } = this.props
    const factor = direction === 'e' || direction === 's' ? -1 : 1

    // modify the size based on the drag delta
    const delta = this.isHorizontal() ? ui.deltaX : ui.deltaY
    this.setState((s, p) => ({ size: Math.max(10, s.size - delta * factor) }))

    if (this.props.onDragStart) {
      this.props.onDragStart(this.state.size)
    }
  }

  handleDragEnd = (e: React.MouseEvent<Element, MouseEvent>): void => {
    this.validateSize()
    if (this.props.onResized) {
      this.props.onResized(this.state.size)
    }
  }

  validateSize(): void {
    const isHorizontal = this.isHorizontal()
    const content = this.contentRef.current
    const wrapper = this.wrapperRef.current
    const actualContent = content?.children[0]
    const containerParent = wrapper?.parentElement

    //
    // Or if our size doesn't equal the actual content size, then we
    // must have pushed past the min size of the content, so resize back
    // let minSize = isHorizontal ? $(actualContent).outerWidth(true) : $(actualContent).outerHeight(true);
    let minSize = isHorizontal
      ? actualContent?.scrollWidth
      : actualContent?.scrollHeight

    minSize = minSize || 0

    const margins = isHorizontal
      ? $(actualContent).outerWidth(true) - $(actualContent).outerWidth()
      : $(actualContent).outerHeight(true) - $(actualContent).outerHeight()
    minSize += margins

    if (this.state.size !== minSize) {
      this.setState((prev) => ({
        ...prev,
        size: minSize || 0,
      }))
    } else if (containerParent && actualContent) {
      // If our resizing has left the parent container's content overflowing
      // then we need to shrink back down to fit
      const overflow = isHorizontal
        ? containerParent.scrollWidth - containerParent.clientWidth
        : containerParent.scrollHeight - containerParent.clientHeight

      if (overflow) {
        this.setState((prev) => ({
          ...prev,
          size: isHorizontal
            ? actualContent.clientWidth - overflow
            : actualContent.clientHeight - overflow,
        }))
      }
    }
  }

  render(): JSX.Element {
    const dragHandlers = {
      onDrag: this.handleDrag as DraggableEventHandler,
      onStop: this.handleDragEnd as DraggableEventHandler,
    }
    const { direction } = this.props
    const isHorizontal = this.isHorizontal()

    let containerClass = cx({
      ContainerHorizontal: isHorizontal,
      ContainerVertical: !isHorizontal,
    })

    if (this.props.containerClass) {
      containerClass += ` ${this.props.containerClass}`
    }

    const containerStyle = { ...this.props.style } || {}
    if (this.state.size !== 0) {
      containerStyle.flexGrow = 0
      containerStyle[isHorizontal ? 'width' : 'height'] = 'auto'
    }

    const handleClasses =
      this.props.handleClass ||
      cx({
        ResizeHandleHorizontal: isHorizontal,
        ResizeHandleVertical: !isHorizontal,
      })

    const resizeBarClasses =
      this.props.borderClass ||
      cx({
        ResizeBarHorizontal: isHorizontal,
        ResizeBarVertical: !isHorizontal,
      })

    const contentStyle = isHorizontal
      ? { width: `${this.state.size}px` }
      : { height: `${this.state.size}px` }
    const contentClassName = cx('ResizeContent', {
      ResizeContentHorizontal: isHorizontal,
      ResizeContentVertical: !isHorizontal,
    })

    const content = [
      <div
        key="content"
        ref={this.contentRef}
        className={contentClassName}
        style={contentStyle}
      >
        {React.Children.only(this.props.children)}
      </div>,
    ]

    const handle = (
      <DraggableCore key="handle" {...dragHandlers} nodeRef={this.nodeRef}>
        {(!this.props.disabled && (
          <div className={resizeBarClasses} ref={this.nodeRef}>
            <div className={handleClasses}>
              <span />
            </div>
          </div>
        )) || <div />}
      </DraggableCore>
    )

    // Insert the handle at the beginning of the content if our directio is west or north
    if (direction === 'w' || direction === 'n') {
      content.unshift(handle)
    } else {
      content.push(handle)
    }

    return (
      <div
        ref={this.wrapperRef}
        className={containerClass}
        style={containerStyle}
      >
        {content}
      </div>
    )
  }
}

export default ResizePanel
