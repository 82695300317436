import {
  AgBridgeRecord,
  aggregateBridgeByTimeframe,
} from '../utils/bridge-aggregations'
import type {
  AggregatedDataComparator,
  AggregatedDataLoaderResult,
  AggregatedDataLoadParams,
  AggregationLoadingStatus,
  ArrItemBase,
  ArrItemWithAggregation,
  ArrItemWithMetric,
  Maybe,
  TimeSegmentationFrame,
} from 'types'
import {
  AggregatedTableContext,
  AggregatedTableFlags,
  AggregatedTableTypeKey,
} from './AggregatedTableContext'

import { AUTH0_SCOPE } from '../utils/const'
import type { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact'
import type { AgKPIRecord } from '../utils/kpi-aggregations'
import { aggregateKPIByTimeframe } from '../utils/kpi-aggregations'
import type { ApiConfigResponse } from '../api/types'
import type { Auth0ContextInterface } from '@auth0/auth0-react'
import { ConfigContext } from './ConfigContext'
import { LocalCacheContext } from './LocalCacheContext'
import React from 'react'
import { environment } from '../environment'
import { loadCachedAggregation } from '../api/cache/cache'
import { useAsync } from 'react-async'
import { useAuth0WithCypress } from '../hooks/useAuth0WithCypress'
import { useIsFeatureEnabled } from 'redux/ducks/config/hooks'
import { useMonthsCount } from 'components/AggregatedTableView/hooks/useMonthsCount'
import { useTimeSegmentationsList } from 'components/AggregatedTableView/hooks/useTimeSegmentationsList'
import { useUrlHashState } from '../hooks/useUrlHashState'

const fireLoaderFn = async ({
  getAccessTokenSilently,
  selectedArr,
  from,
  to,
  config,
  key,
  setLoadingStatus,
  comparator,
  resetDateRange,
}: FireLoaderFnProps): Promise<Maybe<AggregatedDataLoaderResult>> => {
  if (!config?.cohorts) {
    return null
  }

  const accessToken = await getAccessTokenSilently({
    audience: environment.REACT_APP_AUTH0_AUDIENCE,
    scope: AUTH0_SCOPE.join(','),
  })

  const selectedArrKey = selectedArr || config.arrs?.[0]?.field || ''

  const params: AggregatedDataLoadParams = {
    accessToken,
    range: {
      selectedArr,
      from,
      to,
    },
    key: `${key}_${selectedArrKey}`,
  }

  try {
    setLoadingStatus('init')

    const fallback = () => {
      // If we could not fetch any data - try to reset date range
      // It will lead to the second attempt
      console.log('Could not load cache for requested range. Reset to default')
      resetDateRange()
      return [[], config.arrs, config.cohorts] as const
    }

    setLoadingStatus('cache-validation')

    let cache: Maybe<Array<ArrItemWithMetric | ArrItemWithAggregation>>

    try {
      cache = await loadCachedAggregation<
        ArrItemWithMetric | ArrItemWithAggregation
      >(params as unknown as any)
    } catch (ex) {
      return fallback()
    }

    return Promise.resolve().then(
      () =>
        [
          cache?.sort(comparator),
          config.arrs,
          config.cohorts,
        ] as AggregatedDataLoaderResult,
    )
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.error(ex)
    throw ex
  }
}

/**
 * Creates Aggregated Table Context
 * Loads data and exposes setters to change data loading configuration (date range and aggregation formula)
 * @returns {AggregatedTableContext}
 */
export const useAggregatedTableContext = (
  params: UseAggregatedTableContextParams,
  flags: AggregatedTableFlags,
): AggregatedTableContext => {
  const { getLocalCache, writeLocalCache } = React.useContext(LocalCacheContext)
  const gridRef: React.RefObject<AgGridReactType> = React.useRef(null)

  const { config } = React.useContext(ConfigContext)
  const { getAccessTokenSilently } = useAuth0WithCypress()
  const [selectedArr, setSelectedArr] = React.useState('')
  const [timeSegmentationFrame, setTimeSegmentationFrame] =
    useUrlHashState<TimeSegmentationFrame>('time-segmentation', {}, 'month')

  const [dateRangeFrom, setDateRangeFrom] = useUrlHashState<string>(
    'grid-date-from',
    React.useMemo(() => ({ keepInitialValue: true }), []),
    params.defaultRange.start,
  )
  const [dateRangeTo, setDateRangeTo] = useUrlHashState<string>(
    'grid-date-to',
    React.useMemo(() => ({ keepInitialValue: true }), []),
    params.defaultRange.end,
  )

  const cacheKey = React.useMemo(
    () => [params.key, selectedArr, dateRangeFrom, dateRangeTo].join('_'),
    [params, selectedArr, dateRangeFrom, dateRangeTo],
  )

  const [loadingStatus, setLoadingStatus] =
    React.useState<AggregationLoadingStatus>('unknown')

  const resetDateRange = React.useCallback((): void => {
    if (
      dateRangeTo !== params.defaultRange.end ||
      dateRangeFrom !== params.defaultRange.start
    ) {
      setDateRangeFrom(params.defaultRange.start)
      setDateRangeTo(params.defaultRange.end)
    }
  }, [
    dateRangeFrom,
    dateRangeTo,
    setDateRangeFrom,
    setDateRangeTo,
    params.defaultRange.start,
    params.defaultRange.end,
  ])

  const runFetch = React.useCallback(
    async (): ReturnType<typeof fireLoaderFn> => {
      const cached = getLocalCache<AggregatedDataLoaderResult>(cacheKey)
      if (cached) {
        return cached
      }

      return fireLoaderFn({
        getAccessTokenSilently,
        selectedArr,
        from: dateRangeFrom,
        to: dateRangeTo,
        config,
        key: params.key,
        setLoadingStatus,
        comparator: params.comparator,
        resetDateRange,
      })
    },
    // Data is fetched once `deps` are changed
    [
      getAccessTokenSilently,
      selectedArr,
      dateRangeFrom,
      dateRangeTo,
      params.comparator,
      config,
      params.key,
      setLoadingStatus,
      getLocalCache,
      cacheKey,
      resetDateRange,
    ],
  )

  const { isLoading, data } =
    useAsync<Maybe<AggregatedDataLoaderResult>>(runFetch)

  React.useEffect(() => {
    if (data) {
      writeLocalCache(cacheKey, data)
    }
  }, [writeLocalCache, cacheKey, data])

  const setDateRange = React.useCallback(
    (nextFrom: string, nextTo: string): void => {
      setDateRangeFrom(nextFrom)
      setDateRangeTo(nextTo)
    },
    [setDateRangeFrom, setDateRangeTo],
  )

  const [rowData, arrs, cohorts] = data || []

  const kpiDefs = config?.kpis

  const isTimeSegmentationEnabled = useIsFeatureEnabled('time_segmentations')

  const timeframeAggregatedData = React.useMemo(() => {
    if (!isTimeSegmentationEnabled || timeSegmentationFrame === 'month') {
      return rowData
    }
    const t = performance.now()
    const ret =
      params.key === 'bridge'
        ? aggregateBridgeByTimeframe(
            rowData as ArrItemBase<AgBridgeRecord>[],
            timeSegmentationFrame,
          )
        : aggregateKPIByTimeframe(
            kpiDefs,
            rowData as ArrItemBase<AgKPIRecord>[],
            timeSegmentationFrame,
          )

    console.log('calulated in', performance.now() - t)

    return ret
  }, [
    kpiDefs,
    params.key,
    rowData,
    timeSegmentationFrame,
    isTimeSegmentationEnabled,
  ])

  // Reset timesegmentation to months if no enouph months selected
  const monthCount = useMonthsCount(dateRangeFrom, dateRangeTo)
  const timeSegments = useTimeSegmentationsList(monthCount)
  React.useEffect(() => {
    if (
      timeSegments.length &&
      !timeSegments.map(([key]) => key).includes(timeSegmentationFrame)
    ) {
      setTimeSegmentationFrame('month')
      console.log('Reset time segmentation due to lack of months')
      document.location.reload()
    }
  }, [timeSegmentationFrame, timeSegments, setTimeSegmentationFrame])

  return {
    rowData: timeframeAggregatedData as ArrItemBase<
      AgBridgeRecord | AgKPIRecord
    >[],
    arrs: arrs || [],
    cohorts: cohorts || [],
    selectedArr,
    isLoading,
    setSelectedArr,
    setDateRange,
    resetDateRange,
    dateRangeFrom: dateRangeFrom || '',
    dateRangeTo: dateRangeTo || '',
    loadingStatus,
    flags,
    defaultCellRangeSelection: params.defaultCellRangeSelection,
    key: params.key,
    gridRef,
    timeSegmentationFrame,
    setTimeSegmentationFrame,
  }
}

export type UseAggregatedTableContextParams = {
  key: AggregatedTableTypeKey
  defaultRange: {
    start: string
    end: string
  }
  comparator?: AggregatedDataComparator<
    ArrItemWithAggregation | ArrItemWithMetric
  >
  defaultCellRangeSelection: AggregatedTableContext['defaultCellRangeSelection']
}

type FireLoaderFnProps = Pick<
  Auth0ContextInterface,
  'getAccessTokenSilently'
> & {
  selectedArr: string
  from: string
  to: string
  config?: ApiConfigResponse
  key: string
  setLoadingStatus: React.Dispatch<
    React.SetStateAction<AggregationLoadingStatus>
  >
  comparator?: AggregatedDataComparator<
    ArrItemWithAggregation | ArrItemWithMetric
  >
  resetDateRange: () => void
}
