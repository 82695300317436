import { AssumptionsStates, DashboardStates } from '../../ducks/dashboard/types'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  selectAssumptionsPageState,
  selectDashboardPageState,
} from '../../ducks/dashboard/selectors'
import TableViewIcon from '@mui/icons-material/TableView'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import DashboardComboBarLineChart from '../DashboardComboBarLineChart'
import pnl1 from '../../data/pnl_1.json'
import pnl2 from '../../data/pnl_2.json'
import pnl3 from '../../data/pnl_3.json'
import pnlMetadata from '../../data/pnlMetadata.json'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { IconButton } from '@mui/material'
import { SmallPnlWidget } from '../SmallPnlWidget'
import otherPnl1 from '../../data/planningSmallPnl_1.json'
import otherPnl2 from '../../data/planningSmallPnl_2.json'
import otherPnl3 from '../../data/planningSmallPnl_3.json'

const IconsContainer = styled.div`
  margin-left: 15px;
  display: flex;
  gap: 5px;
`

export const PnlChart: React.FC = () => {
  const history = useHistory()
  const assumptionsState = useSelector(selectAssumptionsPageState)
  const dashboardState = useSelector(selectDashboardPageState)
  const [chartData, setChartData] = useState(pnl1)
  const [rowData, setRowData] = useState(otherPnl1)
  const [pnlView, setPnlView] = useState('chart')

  useEffect(() => {
    if (dashboardState === DashboardStates.finalMarchForecast) {
      setChartData(pnl2)
      setRowData(otherPnl2)
    } else if (assumptionsState === AssumptionsStates.suggestionClicked) {
      setChartData(pnl3)
      setRowData(otherPnl3)
    }
  }, [assumptionsState, dashboardState])

  const handleChangeView = useCallback((view: string) => {
    setPnlView(view)
  }, [])

  const handleFullScreenView = useCallback(() => {
    history.push('/pnl')
  }, [history])

  const FooterPanel = () => (
    <IconsContainer>
      <IconButton
        onClick={() =>
          handleChangeView(pnlView === 'table' ? 'chart' : 'table')
        }
        size="small"
      >
        <TableViewIcon />
      </IconButton>
      <IconButton onClick={handleFullScreenView} size="small">
        <FullscreenIcon />
      </IconButton>
    </IconsContainer>
  )

  return (
    <>
      {pnlView === 'table' ? (
        <SmallPnlWidget FooterPanel={FooterPanel} rowData={rowData} />
      ) : (
        <DashboardComboBarLineChart
          data={chartData}
          legend={pnlMetadata.legend}
          title={pnlMetadata.title}
          tooltipNameMapper={pnlMetadata.tooltipNameMapper}
          heightRatio={2.3}
          widthRatio={2.4}
          stroke={pnlMetadata.stroke}
          dollars={[true, true]}
          FooterPanel={FooterPanel}
        />
      )}
    </>
  )
}
