import { AssumptionsStates, DashboardStates } from '../ducks/dashboard/types'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { Box, IconButton, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import {
  selectAssumptionsPageState,
  selectDashboardPageState,
} from '../ducks/dashboard/selectors'
import styled from 'styled-components'
import TableViewIcon from '@mui/icons-material/TableView'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import DashboardChartHeader from './DashboardChartHeader'
import chartData1 from '../data/cashMovement_1.json'
import chartData2 from '../data/cashMovement_2.json'
import chartData3 from '../data/cashMovement_3.json'
import chartData4 from '../data/cashMovement_4.json'
import chartData5 from '../data/cashMovement_1_analytics.json'
import chartData6 from '../data/cashMovement_1_analytics_after_product.json'
import metadata0 from '../data/cashMovementMetadata0.json'
import metadata1 from '../data/cashMovementMetadata1.json'
import metadata2 from '../data/cashMovementMetadata2.json'
import { useSelector } from 'react-redux'
import DashboardCashFlowTable from './DashboardCashFlowTable'
import { WidgetHeader } from './WidgetHeader'
import tableData1 from '../data/cashflowTable_1.json'
import tableData2 from '../data/cashflowTable_2.json'

type color = {
  key: string
  color: string
}

type CashflowChartUnit = {
  period: string
  in1?: number
  out1?: number
  in2?: number
  out2?: number
  in3?: number
  out3?: number
  total1?: number
  total2?: number
  total3?: number
}

interface CashflowProps {
  data?: CashflowChartUnit[]
  heightRatio?: number
  widthRatio?: number
  tooltipNameMapper: Record<string, string>
  colors: color[]
}

interface WindowSize {
  width: number
  height: number
}

const CustomTooltip = (props: any) => {
  const { active, payload, label } = props

  const getColor = (tag: string) => {
    if (tag === 'inc') {
      return '#95CABE'
    }
    if (tag === 'dec') {
      return '#FF9B25'
    }
    return '#303030'
  }

  const getLabel = (tag: string) => {
    if (tag === 'inc') {
      return 'Increase'
    }
    if (tag === 'dec') {
      return 'Decrease'
    }
    return 'Total'
  }

  if (active && payload && payload.length) {
    return (
      <Box p={1} style={{ backgroundColor: 'white' }}>
        <Typography> {label} </Typography>
        <Typography style={{ color: getColor(payload[0].payload.tag) }}>
          {getLabel(payload[0].payload.tag)}: {payload[0].payload.cash}
        </Typography>
      </Box>
    )
  }

  return <div />
}

const CashflowChart: React.FC<CashflowProps> = ({
  data,
  colors,
  tooltipNameMapper,
  heightRatio = 2.7,
  widthRatio = 3,
}) => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const tooltipFormatter = (value: number, name: string) => {
    let formattedValue

    if (value < 0) {
      formattedValue = `$(${-Math.round(value / 1000)}K)`.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ',',
      )
    } else {
      formattedValue = `$${Math.round(value / 1000)}K`.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ',',
      )
    }

    return [formattedValue, `${tooltipNameMapper[name]}`]
  }

  const yTickFormatter = (value: number) => {
    let tick = `$(${
      -Math.round(value / 100) + (Math.round(value / 100) % 1000)
    }K)`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    if (value < -1000000) {
      tick = `$(${
        -Math.round(value / 1000) + (Math.round(value / 1000) % 10000)
      }K)`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    } else if (value > 0) {
      tick = `$${
        Math.round(value / 1000) - (Math.round(value / 1000) % 10000)
      }K`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    return tick
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={Math.floor(windowSize.width / widthRatio)}
        height={Math.min(Math.floor(windowSize.height / heightRatio), 350)}
        data={data}
        stackOffset="sign"
      >
        <defs>
          <linearGradient id="bargrad" gradientTransform="rotate(90)">
            <stop offset="0%" stopColor="white" />
            <stop offset="60%" stopColor="#AAAAAA" stopOpacity="1" />
          </linearGradient>
          <linearGradient id="bargradup" gradientTransform="rotate(90)">
            <stop offset="40%" stopColor="#AAAAAA" />
            <stop offset="100%" stopColor="white" stopOpacity="1" />
          </linearGradient>
          <linearGradient id="bargrad1" gradientTransform="rotate(90)">
            <stop offset="0%" stopColor="white" />
            <stop offset="60%" stopColor="#FF9B25" stopOpacity="1" />
          </linearGradient>
          <linearGradient id="bargradup1" gradientTransform="rotate(90)">
            <stop offset="40%" stopColor="#FF9B25" />
            <stop offset="100%" stopColor="white" stopOpacity="1" />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="0.08 0.2" />
        <XAxis
          interval={0}
          dataKey="period"
          tick={{ fontSize: 10 }}
          scale="point"
          height={10}
        />
        <YAxis
          width={52}
          tick={{ fontSize: 10 }}
          tickFormatter={yTickFormatter}
        />
        {/* <Legend /> */}
        <Tooltip formatter={tooltipFormatter} />

        <ReferenceLine y={0} stroke="#000" />

        <Line
          type="monotone"
          dataKey="total1"
          stroke={colors.find((l) => l.key === 'total1')?.color || '#AAAAAA'}
        />
        <Line
          type="monotone"
          dataKey="total2"
          stroke={colors.find((l) => l.key === 'total2')?.color || '#FF9B25'}
        />
        <Line
          type="monotone"
          dataKey="total3"
          stroke={colors.find((l) => l.key === 'total3')?.color || '#FF9B25'}
        />
        <Bar
          dataKey="out3"
          barSize={8}
          radius={[4, 4, 0, 0]}
          // fill="url(#bargrad1)"
          fill={colors.find((l) => l.key === 'out3')?.color || '#ffdead'}
          stackId="stack2"
        />
        <Bar
          dataKey="in3"
          barSize={8}
          radius={[4, 4, 0, 0]}
          // fill="url(#bargradup1)"
          fill={colors.find((l) => l.key === 'in3')?.color || '#FF9B25'}
          stackId="stack2"
        />
        <Bar
          dataKey="out1"
          barSize={8}
          radius={[4, 4, 0, 0]}
          // fill="url(#bargrad)"
          fill={colors.find((l) => l.key === 'out1')?.color || '#AAAAAA'}
          stackId="stack"
        />
        <Bar
          dataKey="in1"
          barSize={8}
          radius={[4, 4, 0, 0]}
          // fill="url(#bargradup)"
          fill={colors.find((l) => l.key === 'in1')?.color || '#AAAAAA'}
          stackId="stack"
        />
        <Bar
          dataKey="out2"
          barSize={8}
          radius={[4, 4, 0, 0]}
          // fill="url(#bargrad1)"
          fill={colors.find((l) => l.key === 'out2')?.color || '#FF9B25'}
          stackId="stack1"
        />
        <Bar
          dataKey="in2"
          barSize={8}
          radius={[4, 4, 0, 0]}
          // fill="url(#bargradup1)"
          fill={colors.find((l) => l.key === 'in2')?.color || '#FF9B25'}
          stackId="stack1"
        />
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export interface DashboardCashflowData {
  heightRatio?: number
  widthRatio?: number
  planning?: boolean
}

const formatData = (oldData: any) => ({
  data: oldData.data.map((d: any) => {
    const { in3, out3, total3, ...noAct } = d
    return noAct
  }),
  colors: oldData.colors,
})

const IconsContainer = styled.div`
  margin-left: 15px;
  display: flex;
  gap: 5px;
`

const DashboardCashFlow: React.FC<DashboardCashflowData> = ({
  heightRatio = 2.9,
  widthRatio = 3.5,
  planning = false,
}) => {
  const currentAssumptionsState = useSelector(selectAssumptionsPageState)
  const currentDashboardState = useSelector(selectDashboardPageState)
  const [chartData, setChartData] = useState(
    planning ? formatData(chartData1) : chartData5,
  )
  const [chartMetadata, setChartMetadata] = useState(metadata0)
  const [rowData, setRowData] = useState(tableData1)
  const [cashFlowView, setCashFlowView] = useState('chart')

  useEffect(() => {
    console.log(planning, DashboardStates)
    if (currentDashboardState === DashboardStates.finalMarchForecast) {
      setChartData(planning ? formatData(chartData4) : chartData4)
      setChartMetadata(metadata2)
      setRowData(tableData2)
    } else if (
      currentAssumptionsState === AssumptionsStates.suggestionClicked
    ) {
      setChartData(planning ? formatData(chartData2) : chartData6)
      setRowData(tableData2)
    } else if (currentDashboardState === DashboardStates.newForecastAdded) {
      setChartMetadata(metadata1)
      setChartData(planning ? formatData(chartData3) : chartData3)
      setRowData(tableData2)
    }
  }, [currentAssumptionsState, currentDashboardState, planning])

  const handleChangeView = useCallback(() => {
    setCashFlowView(cashFlowView === 'table' ? 'chart' : 'table')
  }, [cashFlowView])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      {cashFlowView === 'chart' ? (
        <DashboardChartHeader
          title={chartMetadata.title}
          legend={
            planning
              ? chartMetadata.legend.filter((x) => x.displayName !== 'Actual')
              : chartMetadata.legend
          }
        />
      ) : (
        <WidgetHeader title="Cash Flow" />
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          padding: '0 10px 0px',
        }}
      >
        {cashFlowView === 'table' ? (
          <DashboardCashFlowTable rowData={rowData} />
        ) : (
          <CashflowChart
            data={chartData.data}
            heightRatio={heightRatio}
            widthRatio={widthRatio}
            tooltipNameMapper={chartMetadata.tooltipNameMapper}
            colors={chartData.colors}
          />
        )}
      </Box>
      <IconsContainer>
        <IconButton size="small" onClick={handleChangeView}>
          <TableViewIcon />
        </IconButton>
      </IconsContainer>
    </Box>
  )
}

export default DashboardCashFlow
