import { ColDef } from 'ag-grid-community'
import dayjs from 'dayjs'

export const getPnlHeaderName = (colDef?: ColDef): string => {
  if (!colDef) return ''
  const { headerName, colId } = colDef
  const data = headerName ?? ''
  if (!colId) return data
  if (colId.includes('Total')) return `${data} Total`

  return `${data} ${dayjs(colId.slice(0, 7)).format('MMM YYYY')}`
}
