import {
  getKpiDisplayNameByAggregation,
  parseAggregation,
} from '../../../utils/primitives'

import type { Aggregation } from '../../../workers/kpi/types'
import type { ApiConfigResponse } from '../../../api/types'
import type { Maybe } from '../../../types'
import type { RowNode } from 'ag-grid-community'
import { generateDateRangeTextFromAggregation } from './generate-date-range-text-from-aggregation'
import { generateFormulaText } from './generate-formula-text'
import { generateSecondaryTableHeader } from './generate-secondary-table-header'

export const generateSecondaryTableHeaderParts = (
  row: RowNode,
  aggregation: Maybe<Aggregation>,
  config: ApiConfigResponse,
  pivotKeys?: string[],
  headerName?: string,
): { text: string; explanation: string; cellPath: string } => {
  if (!pivotKeys || !pivotKeys.length) {
    return { text: '', explanation: '', cellPath: '' }
  }

  let text = ''
  let explanation = ''
  if (aggregation) {
    const [formula] = parseAggregation(aggregation)
    const formulaText = generateFormulaText(formula)

    const displayName = getKpiDisplayNameByAggregation(
      aggregation,
      config?.kpis || [],
    )

    text = generateSecondaryTableHeader(row, displayName)
    const textRange = generateDateRangeTextFromAggregation(
      pivotKeys[0],
      aggregation,
    )

    explanation = `${formulaText} (${textRange})`
  } else {
    const defaultText = generateSecondaryTableHeader(row)
    text = `${defaultText} (${headerName})`
  }

  const cellPath = `${text} ${explanation}`

  return { text, explanation, cellPath }
}
