import type { Cohort } from '../../../api/types'
import React from 'react'
import { useGetCohortRenderer } from './useGetCohortRenderer'

const accountCohort: Cohort = { field: 'account' }

/**
 * Maps array of Cohorts to an array of <AgGridColumn />
 * `account` field is always add to the beginning of the list
 */
export const useBasicCohortColumns = (
  cohorts: readonly Cohort[],
): React.ReactElement[] => {
  const renderCohort = useGetCohortRenderer()

  // Move `account` the start and make it always present
  const cohortsWithAccount = React.useMemo(() => {
    const localCohorts = cohorts.filter((i) => i.field !== 'account')
    localCohorts.unshift(accountCohort)
    return localCohorts
  }, [cohorts])

  return React.useMemo(
    () => cohortsWithAccount.map((cohort) => renderCohort(cohort)),
    [cohortsWithAccount, renderCohort],
  )
}

export type CellRendererBasicParam = { value: string }
