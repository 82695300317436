import { AccessDeninedAlert } from '../components/atoms/AccessDeninedAlert'
import { ConfigContext } from '../context/ConfigContext'
import { DashboardWrapper } from '../components/DashboardWrapper/DashboardWrapper'
import { LinearProgress } from '@mui/material'
import { PnlContainer } from '../containers/PnlContainer'
import React from 'react'
import { useLoadThreads } from 'features/threads/ducks/threads/hooks'

const PnlPageWrapper: React.FC = ({ children }) => <>{children}</>

export const PnlLayout: React.FC = () => {
  const { config } = React.useContext(ConfigContext)

  const threadsPageKey = 'pnl'
  const { loadThreads } = useLoadThreads()

  React.useEffect(() => {
    loadThreads(threadsPageKey, null)
  }, [loadThreads])

  if (typeof config?.features_dict.show_pnl_page?.enabled === 'undefined') {
    return <LinearProgress />
  }

  if (!config?.features_dict.show_pnl_page?.enabled) {
    return <AccessDeninedAlert />
  }

  return (
    <DashboardWrapper enableThreads>
      <PnlPageWrapper>
        <PnlContainer />
      </PnlPageWrapper>
    </DashboardWrapper>
  )
}
