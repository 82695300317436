import type {
  ApiFileLink,
  ExternalApiLoadParams,
  s3FileCustomMetadata,
  s3FileDescription,
} from './types'
import { AsyncState, useAsync } from 'react-async'

import type { Maybe } from '../types'
import React from 'react'
import { csvToJson } from '../utils/csv-utils'
import { endpoints } from './endpoints'
import { runFetchWithToken } from './useApi'

export async function getFileFromS3<T>(
  s3url: string,
  options?: RequestInit,
  loadParams?: ExternalApiLoadParams,
): Promise<Maybe<T>> {
  if (!s3url) return null

  const { headers, ...otherOptions } = options || {}

  const response = await fetch(s3url, {
    ...otherOptions,
    headers: {
      ...headers,
    },
  })

  const responseText = await response.text()
  if (!response.ok) {
    throw new Error(
      `Response is not ok. ${response.status}: ${response.statusText} \n ${responseText}`,
    )
  } else if (loadParams?.getRawResponse) {
    return Promise.resolve(responseText) as unknown as Promise<T>
  } else {
    const contentType = response.headers.get('Content-Type')

    if (contentType?.includes('text/csv')) {
      return csvToJson(responseText, loadParams?.csvNumberColumns) as Promise<T>
    }

    if (contentType?.includes('application/json')) {
      try {
        return JSON.parse(responseText) as Promise<T>
      } catch (e) {
        throw new Error(
          `${(e as Error)?.message} with parsing text ${responseText}`,
        )
      }
    } else {
      throw new Error(
        `Unimplemented response content type ${contentType} for response text ${responseText}`,
      )
    }
  }
}

export function useS3Api<T>(
  url: string,
  options?: RequestInit,
  loadParams?: ExternalApiLoadParams,
): AsyncState<Maybe<T>> {
  const runFetch = React.useCallback(async () => {
    const res = await getFileFromS3(url, options, loadParams)
    return res as Maybe<T>
  }, [options, url, loadParams])

  return useAsync({
    promiseFn: runFetch,
  })
}

export function getLinkFromS3UrlLoader(
  urlLoader: AsyncState<Maybe<ApiFileLink>>,
): string {
  if (
    urlLoader.isLoading ||
    urlLoader.error ||
    !urlLoader.data ||
    !urlLoader.data.link
  )
    return ''
  return urlLoader.data.link
}

export function isIncorrectS3UrlLoader(
  urlLoader: AsyncState<Maybe<ApiFileLink>>,
): boolean {
  return !!urlLoader.error || !urlLoader.data || !urlLoader.data.link
}

export async function uploadFileToS3(
  s3folder: string,
  file: File,
  metadata: s3FileCustomMetadata,
  getAccessTokenSilently: () => Promise<string>,
): Promise<boolean> {
  const fileBinaryContents = await file.arrayBuffer()
  const headers = {
    'content-type': file.type,
    'Metadata': JSON.stringify(metadata),
  }
  const uploadUrlLoader = await runFetchWithToken<ApiFileLink>(
    { getAccessTokenSilently },
    endpoints.fileEndpoint(s3folder, file.name),
    { method: 'POST', headers },
  )
  const uploadLink = uploadUrlLoader?.link
  if (!uploadLink) return false
  let res = true
  const uploadRes = await fetch(uploadLink, {
    method: 'PUT',
    body: fileBinaryContents,
    headers,
  }).catch((e: Error) => {
    // eslint-disable-next-line no-console
    console.error('error while uploading file', e)
    res = false
  })
  return res && !!uploadRes && uploadRes.ok
}

export async function getFileListFromS3(
  s3folder: string,
  getAccessTokenSilently: () => Promise<string>,
): Promise<Maybe<s3FileDescription[]>> {
  const response = await runFetchWithToken(
    { getAccessTokenSilently },
    endpoints.filesList(s3folder),
  )
  return response as Maybe<s3FileDescription[]>
}

export async function deleteFileFromS3(
  s3folder: string,
  fileName: string,
  getAccessTokenSilently: () => Promise<string>,
): Promise<void> {
  await runFetchWithToken(
    { getAccessTokenSilently },
    endpoints.fileEndpoint(s3folder, fileName),
    { method: 'DELETE' },
  )
}
