import * as React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'

import { useDispatch, useSelector } from 'react-redux'
import { selectFilterState } from 'features/dashboard/ducks/dashboard/selectors'
import { dashboardSlice } from 'features/dashboard/ducks/dashboard/slice'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const filterSet = [
  {
    filterName: 'Products',
    filterOptions: ['Product 1', 'Product 2'],
  },
  {
    filterName: 'Segments',
    filterOptions: ['Segment 1', 'Segment 2'],
  },
  {
    filterName: 'Regions',
    filterOptions: ['Region 1', 'Region 2'],
  },
]

export default function FilterSelect() {
  const dispatch = useDispatch()

  const handleChange = React.useCallback(
    (filterName: string) => (event: SelectChangeEvent<string[]>) => {
      const {
        target: { value },
      } = event
      dispatch(
        dashboardSlice.actions.updateFilter({
          filterName,
          filterValue: typeof value === 'string' ? value.split(',') : value,
        }),
      )
    },
    [dispatch],
  )

  const currentFilterState = useSelector(selectFilterState)

  return (
    <div>
      {filterSet.map((filter) => (
        <FormControl key={filter.filterName} sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-checkbox-label">
            {filter.filterName}
          </InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={currentFilterState[filter.filterName] || []}
            onChange={handleChange(filter.filterName)}
            input={<OutlinedInput label="Tag" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {filter.filterOptions.map((option) => (
              <MenuItem key={option} value={option}>
                <Checkbox
                  checked={
                    currentFilterState[filter.filterName]
                      ? currentFilterState[filter.filterName].indexOf(option) >
                        -1
                      : false
                  }
                />
                <ListItemText primary={option} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ))}
    </div>
  )
}
