import { getNow, isNowOverridden } from '../../utils/now'

import { API_RECORD_DATE_FORMAT } from '../../utils/const'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'
import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import dayjs from 'dayjs'
import { useAuth0WithCypress } from '../../hooks/useAuth0WithCypress'

export const NowIsOverridenSnack: React.FC = () => {
  const { user } = useAuth0WithCypress()
  const overriden = React.useMemo(() => user && isNowOverridden(), [user])
  const newNow = React.useMemo(
    () => (overriden ? dayjs(getNow()).format(API_RECORD_DATE_FORMAT) : ''),
    [overriden],
  )
  const [open, setOpen] = React.useState(true)

  const handleClose = React.useCallback(() => {
    setOpen(false)
  }, [setOpen])

  return (
    <Snackbar
      message={`Now is overriden to ${newNow}`}
      autoHideDuration={7000}
      open={overriden && open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      onClose={handleClose}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  )
}
