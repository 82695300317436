import type { ICellRendererParams } from 'ag-grid-community'
import { generateColumnPath } from 'components/AggregatedTableView/utils/generate-column-path'
import { ThreadRenderer } from 'components/cell-renderer/ThreadRenderer'
import { usePageThreads } from 'features/threads/hooks/usePageThreads'
import {
  generatePnlThreadId,
  hasCellThread,
} from 'features/threads/utils/prepare-thread-id'
import React from 'react'
import { getPnlHeaderName } from './get-pnl-header-name'

export const PnlNumericCellRenderer: React.FC<ICellRendererParams> = ({
  valueFormatted,
  colDef,
  node,
}) => {
  const threads = usePageThreads()
  const cellPath = generateColumnPath(node)
  const headerName = getPnlHeaderName(colDef)

  const threadId = generatePnlThreadId(headerName, cellPath)
  const isWithThread = hasCellThread(threads, threadId)

  if (isWithThread) {
    return <ThreadRenderer>{valueFormatted}</ThreadRenderer>
  }

  return valueFormatted
}
