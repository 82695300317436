import type {
  ApiCreateThreadEventResponse,
  ApiCreateThreadResponse,
  ApiThreadsEventsResponse,
  ApiThreadsListResponse,
  ApiUserListResponse,
  RawApiThreadEventsResponse,
  RawApiThreadsListResponse,
  RawApiUserListResponse,
} from './types'

import { endpoints } from '../../../api/endpoints'
import { runFetch } from '../../../api/useApi'

export function getThreadsList(
  token: string,
  urlParams: Record<string, string>,
): Promise<ApiThreadsListResponse> {
  return runFetch(
    token,
    endpoints.collaboration.threads,
    undefined,
    urlParams,
    {
      transformResponse: (data) => (data as RawApiThreadsListResponse).result,
    },
  )
}

export function postThread(
  token: string,
  params: Record<string, string>,
): Promise<ApiCreateThreadResponse> {
  return runFetch(token, endpoints.collaboration.threads, {
    method: 'POST',
    body: JSON.stringify(params),
  })
}

export function postThreadEvent(
  token: string,
  threadId: number,
  params: Record<string, string | number>,
): Promise<ApiCreateThreadEventResponse> {
  return runFetch(token, endpoints.collaboration.threadEvents(threadId), {
    method: 'POST',
    body: JSON.stringify(params),
  })
}

export function getUserList(token: string): Promise<ApiUserListResponse> {
  return runFetch(
    token,
    endpoints.collaboration.userList,
    undefined,
    undefined,
    {
      transformResponse: (data) => (data as RawApiUserListResponse).result,
    },
  )
}

export function getThreadEvents(
  token: string,
  threadId: number,
): Promise<ApiThreadsEventsResponse> {
  return runFetch(
    token,
    endpoints.collaboration.threadEvents(threadId),
    undefined,
    undefined,
    {
      transformResponse: (data) => (data as RawApiThreadEventsResponse).result,
    },
  )
}
