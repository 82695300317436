/**
 Reducers functions, that used by @reduxjs/toolkit in createSlice function
 @see https://redux-toolkit.js.org/api/createSlice
 Notice! Each reducer used by @reduxjs/toolkit as a recipe of immer.produce function
 @see https://immerjs.github.io/immer/produce
 */

import type {
  AccountProperties,
  ApiConfigResponse,
  ApiUsersInfoResponse,
} from '../../../api/types'

import type { Maybe } from '../../../types'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { EmitRequestPayload as RequestPayload } from '../../shared/request/reducers'
import type { State } from './state'
import type { WritableDraft } from '../../types'

export type EmitRequestPayload = RequestPayload
export type EmitRequest = PayloadAction<EmitRequestPayload>

function emitRequest(
  state: WritableDraft<State>,
  action: EmitRequest,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
): void {}

export type LoadStart = PayloadAction

function loadStart(state: WritableDraft<State>, action: LoadStart): void {
  state.status = 'loading'
  state.error = null
  state.config = undefined
  state.userInfo = undefined
  state.properties = undefined
}

export type LoadFinishPayload = {
  error: Maybe<string>
  config?: ApiConfigResponse
  userInfo?: ApiUsersInfoResponse
  properties?: Record<string, AccountProperties>
}
export type LoadFinish = PayloadAction<LoadFinishPayload>

function loadEnd(state: WritableDraft<State>, action: LoadFinish): void {
  const { error, config, userInfo, properties } = action.payload
  state.error = error
  if (error !== null) {
    state.status = 'error'
    state.config = undefined
    state.userInfo = undefined
    state.properties = undefined
  } else {
    state.status = 'success'
    state.config = config
    state.userInfo = userInfo
    state.properties = properties
  }
}

export default {
  emitRequest,
  loadStart,
  loadEnd,
}
