import React from 'react'
import { routes } from './routes'
import { useHistory } from 'react-router-dom'

type VersionStatus = 'published' | 'draft'
type Navigate = (route?: string) => void
export type VersionActionType = 'publish' | 'delete' | 'copy'

export const scenarioTypeName = 'pnl-forecast'

export const getVersionStatus = (
  version: Record<string, unknown>,
): VersionStatus => (version.published_at ? 'published' : 'draft')

export const useNavigate = (): Navigate => {
  const history = useHistory()

  return React.useCallback(
    (route?: string) => history.push(route ?? routes.scenario_versions),
    [history],
  )
}
