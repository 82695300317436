const requiredVariables = [
  'REACT_APP_AUTH0_DOMAIN',
  'REACT_APP_AUTH0_CLIENTID',
  'REACT_APP_AUTH0_ORGID',
  'REACT_APP_AUTH0_AUDIENCE',
  'REACT_APP_API_URL',
] as const

const optionalVariables = [
  'REACT_APP_AGGRID_LICENSE',
  'REACT_APP_LOGROCKET_APP_ID',
  'REACT_APP_TENANT_OVERRIDE',
  'REACT_APP_TENANT_LOGIN',
  'REACT_APP_MIXPANEL_TOKEN',
] as const

export const environment = Object.fromEntries(
  [...optionalVariables, ...requiredVariables].map((variableName) => {
    if (
      requiredVariables.includes(
        variableName as typeof requiredVariables[number],
      ) &&
      !process.env[variableName]
    ) {
      throw new Error(`${variableName} is required but not defined`)
    }

    return [variableName, process.env[variableName]]
  }),
) as Record<
  typeof requiredVariables[number] | typeof optionalVariables[number],
  string
>
