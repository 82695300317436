import type { ICellRendererFunc, ICellRendererParams } from 'ag-grid-community'

const COUNTRY_CODES = {
  IL: 'il',
  USA: 'us',
  UK: 'gb',
  Americas: 'us',
}

export const regionCellRenderer: ICellRendererFunc = (
  params: ICellRendererParams,
) => {
  if (
    (params.value !== 0 && !params.value) ||
    (typeof params.value === 'object' && !params.value?.length)
  ) {
    return 'No value'
  }

  if (params.value === '' || params.value === '(Select All)') {
    return params.value
  }

  const code = COUNTRY_CODES[params.value as keyof typeof COUNTRY_CODES]

  if (!code) {
    return params.value as string
  }

  const src = `https://flags.fmcdn.net/data/flags/mini/${
    COUNTRY_CODES[params.value as keyof typeof COUNTRY_CODES]
  }.png`

  const flag = `
    <img
      border="0"
      width="15"
      height="10"
      src="${src}"
      alt="${params.value}"
    />`

  return `${flag} ${params.value}`
}
