/**
 Reducers functions, that used by @reduxjs/toolkit in createSlice function
 @see https://redux-toolkit.js.org/api/createSlice
 Notice! Each reducer used by @reduxjs/toolkit as a recipe of immer.produce function
 @see https://immerjs.github.io/immer/produce
 */

import type { PayloadAction } from '@reduxjs/toolkit'
import { EmitRequestPayload } from '../../../../redux/shared/request/reducers'
import { Maybe } from '../../../../types'
import {
  HeadcountConfig,
  HeadcountDataSourceKey,
  HeadcountDataSourceVersion,
} from '../../types'
import type { State } from './state'
import type { WritableDraft } from '../../../../redux/types'

export type EmitLoadDataSourcePayload = EmitRequestPayload<{
  sourceKey: HeadcountDataSourceKey
  version: HeadcountDataSourceVersion
}>
export type EmitLoadDataSource = PayloadAction<EmitLoadDataSourcePayload>
export const emitLoadDataSource = (
  state: WritableDraft<State>,
  action: EmitLoadDataSource,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
): void => {}

export type LoadDataSourceStartPayload = {
  sourceKey: HeadcountDataSourceKey
}
export type LoadDataSourceStart = PayloadAction<LoadDataSourceStartPayload>
export const loadDataSourceStart = (
  state: WritableDraft<State>,
  action: LoadDataSourceStart,
): void => {
  const { sourceKey } = action.payload
  state[sourceKey].status = 'loading'
}

export type LoadDataSourceEndPayload = {
  sourceKey: HeadcountDataSourceKey
  version: HeadcountDataSourceVersion
  error: Maybe<string>
  data?: never
}
export type LoadDataSourceEnd = PayloadAction<LoadDataSourceEndPayload>
export const loadDataSourceEnd = (
  state: WritableDraft<State>,
  action: LoadDataSourceEnd,
): void => {
  const { sourceKey, version, error, data } = action.payload

  state[sourceKey].version = version
  state[sourceKey].status = error === null ? 'success' : 'error'
  state[sourceKey].error = error
  state[sourceKey].data = data
}

export type EmitLoadConfigPayload = EmitRequestPayload
export type EmitLoadConfig = PayloadAction<EmitRequestPayload>
export const emitLoadConfig = (
  state: WritableDraft<State>,
  action: EmitLoadConfig,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
): void => {}

export type LoadConfigStart = PayloadAction<unknown>
export const loadConfigStart = (
  state: WritableDraft<State>,
  action: LoadConfigStart,
): void => {
  state.config.status = 'loading'
}

export type LoadConfigEndPayload = {
  error: Maybe<string>
  data?: HeadcountConfig
}
export type LoadConfigEnd = PayloadAction<LoadConfigEndPayload>
export const loadConfigEnd = (
  state: WritableDraft<State>,
  action: LoadConfigEnd,
): void => {
  const { error, data } = action.payload
  state.config.status = error === null ? 'success' : 'error'
  state.config.error = error
  state.config.data = data
}

// export reducers below in default export to avoid names overlapping with action creators
export default {
  emitLoadDataSource,
  loadDataSourceStart,
  loadDataSourceEnd,
  emitLoadConfig,
  loadConfigStart,
  loadConfigEnd,
}
