import { Box, FormGroup, Typography } from '@mui/material'
import type { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact'
import React from 'react'

export const PnlSettingsColumns: React.FC<{
  grid: AgGridReactType
  columns: { key: string; title: string }[]
}> = ({ grid, columns }) => {
  const [hiddenColumns, setHiddenColumns] = React.useState<string[]>([])

  const toggleHiddenColumn = React.useCallback(
    (col) => {
      const isVisible = hiddenColumns.includes(col.key)
      const allColumns = grid.columnApi?.getAllColumns()
      if (allColumns) {
        allColumns
          ?.filter((c) =>
            c
              ?.getUserProvidedColDef()
              ?.field?.startsWith(`sum_accounting_${col.key}_`),
          )
          .forEach((c) => {
            grid.columnApi.setColumnVisible(c.getColId(), isVisible)
          })
      }
      if (isVisible) {
        setHiddenColumns((prevHiddenColumns) =>
          prevHiddenColumns.filter((i) => i !== col.key),
        )
      } else {
        setHiddenColumns((prevHiddenColumns) => [col.key, ...prevHiddenColumns])
      }
    },
    [grid, hiddenColumns],
  )

  return (
    <Box py={1} px={2}>
      <Typography variant="subtitle2" color="textSecondary" pt={1} pb={3}>
        Select columns
      </Typography>
      <FormGroup>
        {columns.map((col, ind) => (
          <div
            key={col.key}
            className="ag-virtual-list-item enlarged-height"
            style={{ position: 'relative' }}
          >
            <div
              role="presentation"
              className="ag-checkbox ag-input-field ag-label-align-right"
            >
              <div
                className={`ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper ${
                  !hiddenColumns.includes(col.key) ? 'ag-checked' : ''
                }`}
                role="presentation"
              >
                <input
                  type="checkbox"
                  className="ag-input-field-input ag-checkbox-input"
                  defaultChecked={!hiddenColumns.includes(col.key)}
                  onChange={() => toggleHiddenColumn(col)}
                />
              </div>
              <div className="ag-input-field-label ag-label ag-checkbox-label">
                {col.title}
              </div>
            </div>
          </div>
        ))}
      </FormGroup>
    </Box>
  )
}
