import {
  AssumptionsStates,
  DashboardStates,
  HeadcountStates,
  SettingsStates,
  StatePages,
} from './types'

export const namespace = 'dashboard'

export type State = {
  currentState: Record<string, any>
  currentFilter: Record<string, string[]>
}

export const initialState: State = {
  currentState: {
    [StatePages.Settings]: SettingsStates.initial,
    [StatePages.Dashboard]: DashboardStates.initial,
    [StatePages.Assumptions]: AssumptionsStates.initial,
    [StatePages.Headcount]: HeadcountStates.initial,
  },
  currentFilter: {},
}
