import DashboardComboBarLineChart, {
  DashboardComboBarLineChartData,
} from '../DashboardComboBarLineChart'
import React, { useState } from 'react'
import businessOptimizationData from '../../data/businessOptimizationData.json'
import businessOptimizationData2 from '../../data/businessOptimizationData2.json'
import { Box, Typography } from '@mui/material'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts'

type Props = {
  hideInsight?: boolean
  graphState: boolean
  setGraphState: React.Dispatch<React.SetStateAction<boolean>>
}

export const BusinessOptimizationChart: React.FC<Props> = (props) => {
  const [chartData, setChartData] = useState<DashboardComboBarLineChartData>(
    businessOptimizationData,
  )

  React.useEffect(() => {
    if (!props.graphState) {
      setChartData(businessOptimizationData)
    }
    if (props.graphState) {
      setChartData(businessOptimizationData2)
    }
  }, [props])

  const CustomTooltip = (p: any) => {
    if (p.active && p.payload && p.payload.length && p.label === '$90M') {
      return (
        <Box
          sx={{
            background: 'white',
            padding: 1,
            border: '0.1px solid gray',
            borderRadius: '4px',
          }}
        >
          <div className="label">{`${p.label}`}</div>
          <div>Your current goal</div>
        </Box>
      )
    }
    return (
      <Box
        sx={{
          background: 'white',
          padding: 1,
          border: '0.1px solid gray',
          borderRadius: '4px',
        }}
      >
        <div className="label">{`${p.label}`}</div>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        height: '320px',
        ml: '5px',
        mr: '150px',
        mb: '25px',
        width: '400%',
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={chartData.data}
          margin={{
            top: 5,
            right: 30,
            left: 10,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="4 4" />
          <XAxis dataKey="name" />
          <YAxis domain={[0, 1000]} />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            dataKey="ARR (M$)"
            fill="#FF9B25"
            radius={[4, 4, 0, 0]}
            barSize={5}
          />
          <Bar
            dataKey="Goal"
            fill="#AAAAAA"
            radius={[4, 4, 0, 0]}
            barSize={6}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  )
}
