import dayjs from 'dayjs'

export const datesComparator = (
  filterValue: string,
  cellValue: string,
): 0 | 1 | -1 => {
  const cellDate = dayjs(cellValue)
  const filterDate = dayjs(filterValue)

  if (cellDate == null) {
    return 0
  }

  if (cellDate < filterDate) {
    return -1
  }
  if (cellDate > filterDate) {
    return 1
  }
  return 0
}
