import mixpanel, { Mixpanel } from 'mixpanel-browser'

import React from 'react'
import { environment } from '../environment'
import { useAuth0WithCypress } from '../hooks/useAuth0WithCypress'

const hasMixpanelToken = !!environment.REACT_APP_MIXPANEL_TOKEN
// user key has custom keys from auth0 rules with namespace
const namespace = 'https://fintastic.ai/'

export const useMixpanel = (): UseMixpanel => {
  const { user } = useAuth0WithCypress()

  const isTestAccount = user?.[`${namespace}isTester`]
  const isMixpanelActive = React.useMemo(
    () => hasMixpanelToken && !isTestAccount,
    [isTestAccount],
  )

  const track: Mixpanel['track'] = React.useCallback(
    (...options) => {
      if (isMixpanelActive) {
        mixpanel.track(...options)
      }
    },
    [isMixpanelActive],
  )

  const identify: Mixpanel['identify'] = React.useCallback(
    // eslint-disable-next-line camelcase
    (unique_id) => {
      if (isMixpanelActive) {
        mixpanel.identify(unique_id)
      }
    },
    [isMixpanelActive],
  )

  const reset: Mixpanel['reset'] = React.useCallback(() => {
    if (isMixpanelActive) {
      mixpanel.reset()
    }
  }, [isMixpanelActive])

  const isIdentified =
    process.env.NODE_ENV === 'test'
      ? false
      : isMixpanelActive && mixpanel.get_distinct_id() === user?.sub

  return {
    track,
    identify,
    reset,
    isIdentified,
  }
}

export const mixpanelInit = (): void => {
  if (hasMixpanelToken) {
    mixpanel.init(environment.REACT_APP_MIXPANEL_TOKEN, { debug: true })
  }
}

type UseMixpanel = {
  track: Mixpanel['track']
  identify: Mixpanel['identify']
  reset: Mixpanel['reset']
  isIdentified: boolean
}
