import { AggregationRange } from '../../../workers/kpi/types'
import { Dayjs } from 'dayjs'
import { Maybe } from '../../../types'
import { startingDateFromRange } from '../../../utils/date-utils'

export const getStartDateFromAggregationRange = (
  date: Dayjs,
  range: AggregationRange,
): Maybe<Dayjs> => {
  const getPeriodStartFn = startingDateFromRange[range]

  if (!getPeriodStartFn) {
    return null
  }

  return getPeriodStartFn(date).endOf('month')
}
