import { Box, Typography } from '@mui/material'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'
import React from 'react'
import { ThreadButton } from './ThreadButton'

export type ThreadHeaderProps = {
  title: string
  showComments: boolean
  showResolve?: boolean
  onResolveThread: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void
  onCloseThread: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void
}

export const ThreadHeader: React.FC<ThreadHeaderProps> = ({
  title,
  showComments,
  onResolveThread,
  onCloseThread,
  showResolve,
}) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      mb: 2,
    }}
  >
    <Box display="flex" flexGrow={1} alignItems="center">
      <Typography variant="subtitle1">{title}</Typography>
    </Box>
    <Box display="flex" alignItems="center">
      {showComments && showResolve && (
        <ThreadButton
          title="Resolve thread"
          onClick={onResolveThread}
          icon={<CheckCircleIcon />}
        />
      )}
      <ThreadButton
        title="Close thread"
        onClick={onCloseThread}
        icon={<CloseIcon />}
      />
    </Box>
  </Box>
)
