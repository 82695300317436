import { endpoints } from '../../../api/endpoints'
import { runFetch } from '../../../api/useApi'
import { Maybe } from '../../../types'
import { capitalize } from 'lodash'
import {
  HeadcountConfig,
  HeadcountEmployee,
  HeadcountAssumptionsRecord,
} from '../types'

/* eslint-disable camelcase */

type GetHeadcountConfigResponse = {
  result: {
    dimensions: {
      name: string
      display_name: Maybe<string>
    }[]
  }
}

export function getHeadcountConfig(token: string): Promise<HeadcountConfig> {
  return runFetch(token, endpoints.headcount.config, undefined, undefined, {
    transformResponse: (jsonData) => {
      const rawData = jsonData as GetHeadcountConfigResponse
      return {
        dimensions: [
          {
            name: 'role',
            display_name: null,
          },
          ...rawData.result.dimensions,
        ].map((dimConf) => ({
          field: dimConf.name,
          display_name: dimConf.display_name || capitalize(dimConf.name),
        })),
      }
    },
  })
}

type GetHeadcountKnownEmployeesResponse = {
  result: HeadcountEmployee[]
}

export function getHeadcountKnownEmployees(
  token: string,
): Promise<HeadcountEmployee[]> {
  return runFetch(token, endpoints.headcount.employees, undefined, undefined, {
    transformResponse: (raw) =>
      (raw as GetHeadcountKnownEmployeesResponse).result.map((employee) => ({
        ...employee,
        clientId: `knownEmployee_${employee.id}`, // need to be unique for each employee
      })),
  })
}

type GetHeadcountHiringPlanResponse = {
  result: HeadcountEmployee[]
}

export function getHeadcountHiringPlan(
  token: string,
): Promise<HeadcountEmployee[]> {
  return runFetch(token, endpoints.headcount.hiringPlan, undefined, undefined, {
    transformResponse: (raw) =>
      (raw as GetHeadcountHiringPlanResponse).result.map((employee) => ({
        ...employee,
        clientId: `hiringPlan_${employee.id}`, // need to be unique for each employee
      })),
  })
}

type GetHeadcountAssumptionsResponse = {
  result: HeadcountAssumptionsRecord[]
}

export function getHeadcountAssumptions(
  token: string,
): Promise<HeadcountAssumptionsRecord[]> {
  return runFetch(
    token,
    endpoints.headcount.assumptions,
    undefined,
    undefined,
    {
      transformResponse: (raw) =>
        (raw as GetHeadcountAssumptionsResponse).result,
    },
  )
}
