import { ADMISSIBLE_EMAIL_GROUP, AGGREGATION_NAME_DELIMITER } from './const'
import type {
  Aggregation,
  AggregationFormula,
  AggregationRange,
} from '../workers/kpi/types'

import { KPIDefinintion } from '../api/types'

export const percentage = (
  current: number,
  previous: number,
  factor = 1,
): number => {
  if (!previous || !current) {
    return 0
  }

  return 100 * (current / previous) ** factor
}

export const parseAggregation = (
  aggregation: Aggregation,
): [AggregationFormula, AggregationRange] =>
  (aggregation?.split(AGGREGATION_NAME_DELIMITER) || []) as [
    AggregationFormula,
    AggregationRange,
  ]

export const aggregationNameFromKPIDef = (
  kpiDef: KPIDefinintion,
): Aggregation =>
  `${kpiDef.formula}${AGGREGATION_NAME_DELIMITER}${kpiDef.range}`

export const getKpiDisplayNameByAggregation = (
  aggregation: Aggregation | string,
  kpiDefs: KPIDefinintion[],
): string => {
  const [formula, range] = parseAggregation(aggregation as Aggregation)
  if (!formula || !range) {
    return aggregation
  }

  return (
    kpiDefs.find((i) => i.formula === formula && i.range === range)
      ?.display_name || aggregation
  )
}

export const isEmailAdmissible = (email: string): boolean =>
  email.split('@')?.[1] === ADMISSIBLE_EMAIL_GROUP
