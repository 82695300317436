import pivoted from '../data/assumptionsNewProduct.json'
import React, { useCallback, useEffect, useMemo } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Box, Typography } from '@mui/material'
import { pivotTableColumns } from '../pivot'
import {
  CellValueChangedEvent,
  ColDef,
  ColGroupDef,
  ColumnEverythingChangedEvent,
  FirstDataRenderedEvent,
  GridReadyEvent,
} from 'ag-grid-community'
import { InsightModal } from 'components/atoms/InsightModal'
import { dashboardSlice } from '../ducks/dashboard/slice'
import {
  AssumptionsStates,
  DashboardStates,
  StatePages,
} from '../ducks/dashboard/types'
import { useDispatch, useSelector } from 'react-redux'
import { selectAssumptionsPageState } from '../ducks/dashboard/selectors'

const COLUMNS = {
  METRIC: 'Assumption',
  PRODUCT: 'Product',
}

const assumptionInsights = [
  {
    name: 'option 1',
    component: <div>Avg 3M (827)</div>,
  },
  {
    name: 'option 2',
    component: <div>Avg 6M (852)</div>,
  },
  {
    name: 'option 2',
    component: <div>Avg 9M (843)</div>,
  },
]

export const AssumptionsTableNewProduct = () => {
  const [openInsightDialog, setOpenInsight] = React.useState(false)
  const handleClose = () => setOpenInsight(false)

  const [dimensions, setDimensions] = React.useState([COLUMNS.METRIC])
  const [grid, setGridApi] = React.useState<GridReadyEvent | undefined>(
    undefined,
  )
  const openInsight = useCallback(() => {
    setDimensions([COLUMNS.METRIC])
    setOpenInsight(true)
    grid?.api.setFocusedCell(5, 'Mar-22Apr 22 Forecast')
  }, [grid?.api])
  const dispatch = useDispatch()

  const currentState = useSelector(selectAssumptionsPageState)

  const handleSuggestionClick = useCallback(() => {
    dispatch(
      dashboardSlice.actions.moveToState({
        page: StatePages.Assumptions,
        nextState: AssumptionsStates.suggestionClicked,
      }),
    )
  }, [dispatch])
  const getRowStyle = (params: any) => {
    const headers = ['Marketing', 'HR Costs', 'Retention', 'G&A', 'Sales', 'PS']
    if (
      // eslint-disable-next-line
      headers.includes(params.node.data['Assumption'])
    ) {
      return {
        fontWeight: 'bold',
        borderBottom: '1px solid black',
        backgroundColor: '#f8f8f8',
      }
    }
    return {
      fontWeight: 'unset',
      borderBottom: '1px solid lightgrey',
      backgroundColor: 'unset',
    }
  }
  const monthForecastMap = useMemo(
    () => ({
      'Jan-22': new Set(['Actual']),
      'Feb-22': new Set(['Actual']),
      'Mar-22': new Set(['Actual']),
      'Apr-22': new Set(['Actual']),
      'May-22': new Set(['Actual']),
      'Jun-22': new Set(['June 22 Forecast', 'Actual', 'Diff']),
      'Jul-22': new Set(['June 22 Forecast', 'July 22 Forecast', 'Diff']),
      'Aug-22': new Set(['June 22 Forecast', 'July 22 Forecast', 'Diff']),
      'Sep-22': new Set(['June 22 Forecast', 'July 22 Forecast', 'Diff']),
      'Oct-22': new Set(['June 22 Forecast', 'July 22 Forecast', 'Diff']),
      'Nov-22': new Set(['June 22 Forecast', 'July 22 Forecast', 'Diff']),
      'Dec-22': new Set(['June 22 Forecast', 'July 22 Forecast', 'Diff']),
    }),
    [],
  )

  const insightValue = useMemo(
    () => ({
      data: {
        [COLUMNS.PRODUCT]: 'Google',
      },
      colId: 'Mar-22Apr 22 Forecast',
      badgeField: COLUMNS.METRIC,
      badgeValue: 'DOESNTEXISTS',
      replaceData: currentState !== AssumptionsStates.initial,
    }),
    [currentState],
  )

  const columnDefs = useMemo(
    () =>
      pivotTableColumns(
        dimensions,
        monthForecastMap,
        insightValue,
        assumptionInsights,
        openInsight,
        handleSuggestionClick,
      ).map((def: ColDef | ColGroupDef) => {
        if ((def as ColDef).field) {
          const colDef = def as ColDef
          if (colDef.field === 'Assumption') colDef.minWidth = 200
          if (colDef.field === 'Region') colDef.width = 105
          if (colDef.field === 'Platform') colDef.width = 120
          return colDef
        }
        return def
      }),
    [
      dimensions,
      handleSuggestionClick,
      insightValue,
      monthForecastMap,
      openInsight,
    ],
  )
  const v = [1, 2, 3].filter(() => true)
  const filtered = (pivoted as Record<string, any>).filter((row: any) =>
    dimensions.some((d) => row[d] !== ''),
  )

  const onCellChanged = (params: CellValueChangedEvent) => {
    dispatch(
      dashboardSlice.actions.moveToState({
        page: StatePages.Dashboard,
        nextState: DashboardStates.finalMarchForecast,
      }),
    )
  }

  const onCellClicked = (params: any) => {
    if (params?.colDef?.field === 'Assumption')
      setDimensions([COLUMNS.METRIC, COLUMNS.PRODUCT])
  }

  const handleGridReady = useCallback((e: GridReadyEvent) => {
    setGridApi(e)
  }, [])

  return (
    <Box
      sx={{
        'p': '20px',
        'pt': '0',
        '& .ag-root-wrapper .ag-cell': {
          textAlign: 'right',
        },
        '& .ag-root-wrapper .ag-pinned-left-cols-container .ag-cell': {
          textAlign: 'left',
        },
      }}
    >
      <div
        className="ag-theme-alpine ag-theme-demo-short"
        style={{ height: '40vh', width: '100%' }}
      >
        <AgGridReact
          suppressAggFuncInHeader={true}
          columnDefs={columnDefs}
          rowData={filtered}
          onGridReady={handleGridReady}
          onCellValueChanged={onCellChanged}
          rowHeight={24}
          headerHeight={30}
          getRowStyle={getRowStyle}
          onCellClicked={onCellClicked}
        />
      </div>
    </Box>
  )
}
