import type { AgColumnsPivotKey, Maybe } from '../types'

import type { ColumnState } from 'ag-grid-community'
import React from 'react'

function defaultSerializer<T>(val: T) {
  return encodeURIComponent(JSON.stringify(val))
}

function decodeURIComponentSafe(s: string) {
  if (!s) {
    return s
  }
  return decodeURIComponent(s.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25'))
}

function defaultDeserializer(val: string) {
  try {
    return JSON.parse(decodeURIComponentSafe(val))
  } catch (err) {
    return decodeURIComponentSafe(val)
  }
}

export function useUrlHashState<T, R = T>(
  key: string,
  options?: {
    serializer?: (val: T) => string
    deserializer?: (val: string) => R
    // Imperatively writes initial value to deeplink
    keepInitialValue?: boolean
  },
  initialValue: Maybe<R> = null,
): readonly [R, (value: T) => void] {
  const initialValueWritten = React.useRef(false)

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [urlHashValue, setUrlHashValue] = React.useState<R>(() => {
    const urlHashParams = new URLSearchParams(window.location.hash.substring(1))
    const storedValue = urlHashParams.has(key) ? urlHashParams.get(key) : null

    if (storedValue === null) {
      return initialValue
    }
    return (options?.deserializer || defaultDeserializer)(storedValue)
  })

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to URL hash.
  const setValue = React.useCallback(
    (value: T) => {
      const valueToStore =
        typeof value !== 'string'
          ? (options?.serializer || defaultSerializer)(value)
          : (value as unknown as string)
      const urlHashParams = new URLSearchParams(
        window.location.hash.substring(1),
      )
      setUrlHashValue(
        (options?.deserializer || defaultDeserializer)(valueToStore),
      )
      urlHashParams.set(key, valueToStore)
      window.location.hash = urlHashParams.toString()
    },
    [key, options],
  )

  // Push initial state to actual deeplink (if `keepInitialValue`)
  React.useEffect(() => {
    if (initialValueWritten.current) {
      return
    }
    if (urlHashValue === initialValue && options?.keepInitialValue) {
      initialValueWritten.current = true
      setValue(initialValue as unknown as T)
    }
  }, [urlHashValue, options, setValue, initialValue, initialValueWritten])

  return [urlHashValue, setValue] as const
}

export function serializeStringArray(val: Maybe<string[]>): string {
  return (val || []).join('~')
}

export function deserializeStringArray(val: string): string[] {
  return val.split('~')
}

export function serializeGridSelectionCols(
  val: Maybe<AgColumnsPivotKey[]>,
): string {
  return (val || []).map((i) => i.field).join('~')
}

export function deserializeGridSelectionCols(val: string): string[] {
  return val.split('~')
}

export function serializeGridSelectionRows(val: Maybe<number[]>): string {
  return (val || []).join('~')
}

export function deserializeGridSelectionRows(val: string): number[] {
  return val.split('~').map((i) => parseInt(i, 10))
}

export function serializeGridColumns(val: Maybe<ColumnState[]>): string {
  return (val || [])
    .filter((i) => !i.hide)
    .map((i) => i.colId)
    .join('~')
}

export function deserializeGridColumns(val: string): string[] {
  return val.split('~')
}
