import { AgFilterModel, Maybe } from '../../types'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import { Box, Typography } from '@mui/material'
import type {
  CellClickedEvent,
  Column,
  ColumnGroup,
  ColumnMovedEvent,
  FirstDataRenderedEvent,
  GetContextMenuItemsParams,
  GroupCellRendererParams,
  IAggFuncParams,
  MenuItemDef,
  RowNode,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community'
import {
  cohortValueGetter,
  DATA_TYPE_ACTUAL,
  DATA_TYPE_BUDGET,
  DATA_TYPE_FORECAST,
  dataTypes,
} from './financial-reports-utils'
import type {
  PnLAccountingGroup,
  PnLActual,
  PnLBudget,
  PnLConfig,
  PnLGroup,
  s3FileDescription,
} from '../../api/types'
import {
  deserializeUrlFilter,
  serializeUrlFilter,
} from '../AggregatedTableView/utils/prepare-url-filters'
import {
  finCurrencyFormatter,
  percentFormatter,
  roundToDecimals,
} from '../../utils/num-utils'
import {
  generatePnlThreadId,
  hasCellThread,
} from 'features/threads/utils/prepare-thread-id'
import {
  getPeriodList,
  getPeriodName,
  periodicities,
} from '../../utils/periodicity-utils'

import type { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact'
import { CenteredCircularProgress } from '../atoms/CenteredCircularProgress'
import CloseIcon from '@mui/icons-material/Close'
import { ConfigContext } from '../../context/ConfigContext'
import type { DateRange } from '@mui/lab'
import { PnlNumericCellRenderer } from './PnlNumericCellRenderer'
import { PnlSettingsBudgetVersions } from './PnlSettingsBudgetVersions'
import { PnlSettingsColumns } from './PnlSettingsColumns'
import { PnlSettingsDateRange } from './PnlSettingsDateRange'
import { PnlTransactionsTable } from './PnlTransactionsTable'
import React from 'react'
import ResizePanel from '../../vendor/react-resize-panel-ts/ResizePanel'
import clsx from 'clsx'
import { compact } from 'lodash'
import { generalComparatorSavePeriodsOrder } from '../../utils/ag-grid'
import { generateColumnPath } from 'components/AggregatedTableView/utils/generate-column-path'
import { getAdditionalField } from '../../utils/csv-utils'
import { getNetsuiteTransactionUrl } from '../../utils/external-platform-url'
import { getPnlHeaderName } from './get-pnl-header-name'
import { styled } from '@mui/material/styles'
import styledComp from 'styled-components'
import { useIsFeatureEnabled } from 'redux/ducks/config/hooks'
import { usePageThreads } from 'features/threads/hooks/usePageThreads'
import { useSelectedThreadState } from 'features/threads/ducks/threads/hooks'
import { useUrlHashState } from '../../hooks/useUrlHashState'
import { pageHeadingStyles } from '../../features/dashboard/styles/page-heading-styles'

const showDecimalPlaces = 0
const showPercentDecimalPlaces = 1

const defaultExpandedGroups = 1

type ProcessedBackendDataItem = Partial<
  (PnLActual | PnLBudget) & {
    pnlGroup: PnLGroup
    datatype: string
  }
>

// data processors
export function processBackendData(
  values: (PnLActual | PnLBudget)[],
  accountsData: Record<string, string>,
  groupsDefinition: PnLGroup[],
  accountingGroupsDefinition: PnLAccountingGroup[],
) {
  const tooMuchGroups: Partial<
    (PnLActual | PnLBudget) & {
      pnlGroups: PnLGroup[]
      datatype: string
    }
  >[] = []
  const noGroup: Partial<
    (PnLActual | PnLBudget) & {
      pnlGroup: PnLGroup
      datatype: string
    }
  >[] = []
  const rowsAdded: Partial<
    (PnLActual | PnLBudget) & {
      pnlGroup: PnLGroup
      datatype: string
    }
  >[] = []

  const emptyPnlGroup = {
    budgetGroup: '-no group-',
    generalGroup: '-no group-',
    sign: 1,
    filters: {},
  }

  function calculateBudgetGroup(row: PnLActual | PnLBudget): PnLGroup {
    const rowAcctType = getAdditionalField(
      accountsData,
      row.acctnumber,
      'accttype',
    )
    let correctGroups = groupsDefinition.filter((group) => {
      const { filters } = group
      if (
        filters.acctnumbers &&
        filters.acctnumbers.length &&
        filters.acctnumbers.every((acctnumber) => acctnumber !== row.acctnumber)
      )
        return false

      const accttypeNotEqual =
        filters.accttype && filters.accttype.substr(0, 1) === '!'
      const accttype = accttypeNotEqual
        ? filters.accttype?.substr(1, filters.accttype?.length - 1)
        : filters.accttype
      if (
        filters.accttype &&
        ((accttypeNotEqual && rowAcctType === accttype) ||
          (!accttypeNotEqual && rowAcctType !== accttype))
      )
        return false

      if (filters.departments && filters.departments.length > 0)
        return filters.departments.some(
          (depname) => depname?.toLowerCase() === row?.depname?.toLowerCase(),
        )
      return true
    })
    if (correctGroups.length > 1) {
      correctGroups = correctGroups.filter((group, index) => {
        if (index === 0) return true
        // if (group.duplicate) rowsAdded.push({ ...row, budgetGroup: group })
        return false
      })
    }

    if (!correctGroups || correctGroups.length === 0) {
      noGroup.push(row)
      return emptyPnlGroup
    }
    if (correctGroups.length > 1) {
      tooMuchGroups.push({ ...row, pnlGroups: correctGroups })
      return correctGroups[0]
    }
    return correctGroups[0]
  }

  function calculateAccountingGroup(row: PnLActual | PnLBudget): string {
    const acctname = getAdditionalField(
      accountsData,
      row.acctnumber,
      'acctname',
    )?.toLowerCase()
    let res = `- no accounting name for ${acctname}`
    accountingGroupsDefinition.some((group) => {
      if (acctname?.includes(group.acctNamePart.toLowerCase())) {
        res = group.name
        return true
      }
      return false
    })
    return res
  }

  const res = values.map((row: PnLActual | PnLBudget) => {
    const pnlGroup = calculateBudgetGroup(row)
    return {
      pnlGroup,
      generalGroup: pnlGroup.generalGroup,
      budgetGroup: pnlGroup.budgetGroup,
      accountingGroup: calculateAccountingGroup(row),
      ...row,
    }
  })

  if (tooMuchGroups.length) {
    // eslint-disable-next-line no-console
    console.error('too much groups for rows', tooMuchGroups)
  }

  if (noGroup.length) {
    // eslint-disable-next-line no-console
    console.error('no budget groups', noGroup)
  }

  return [...res, ...rowsAdded]
}

export function getPeriodicIncome(
  processedData: ProcessedBackendDataItem[],
  accountsData: Record<string, string | number>,
  periodicity: string,
): Record<string, { actual: number; budget: number }> {
  const res = { Total: { actual: 0, budget: 0 } } as Record<
    string,
    { actual: number; budget: number }
  >
  processedData.forEach((row) => {
    const periodName = getPeriodName(row.periodstart || '', periodicity)
    if (!periodName) return
    const dataType = row.datatype as 'actual' | 'budget'

    if (!res[periodName]) res[periodName] = { actual: 0, budget: 0 }

    if (!res[periodName][dataType]) res[periodName][dataType] = 0

    if (
      getAdditionalField(accountsData, row.acctnumber || '', 'accttype') ===
      'Income'
    ) {
      res[periodName][dataType] -= row.sum_accounting || 0
      res.Total[dataType] -= row.sum_accounting || 0
    }
  })
  return res
}

// aggrid functions
export function valueToString(budget: number, actual: number): string {
  return `actual: ${roundToDecimals(
    actual,
    showDecimalPlaces,
  )}, budget: ${roundToDecimals(budget, showDecimalPlaces)}`
}

export function aggregate(params: IAggFuncParams): {
  actual: number
  budget: number
  toString?: () => string
} {
  const { values } = params
  const aggr = values.reduce(
    (res: Record<string, number>, value: number | Record<string, number>) => {
      if (typeof value === 'number') return res
      return {
        actual: res.actual + value.actual,
        budget: res.budget + value.budget,
      }
    },
    {
      actual: 0,
      budget: 0,
    },
  )

  return {
    actual: aggr.actual,
    budget: aggr.budget,
    toString: () => valueToString(aggr.budget, aggr.actual),
  }
}

export function valueGetter(
  params: ValueGetterParams,
  period: string,
  periodicity: string,
  datasrcs?: Maybe<s3FileDescription>[],
): {
  actual: number
  budget: number
  toString?: () => string
} {
  const title = params.data?.datasource?.metadata?.Metadata?.title
  const dataTitles = compact(
    datasrcs?.map((i) => i?.metadata?.Metadata?.title) || [],
  )
  let sign = 1
  let actual = 0
  let budget = 0

  if (params.data) {
    sign = params.data.pnlGroup ? params.data.pnlGroup.sign : 1
    const periodName = getPeriodName(params.data.periodstart, periodicity)
    const value =
      period && period !== 'Total' && periodName !== period
        ? 0
        : params.data.sum_accounting
    if (
      !dataTitles.includes(params.colDef.headerName || '') ||
      title === params.colDef.headerName
    ) {
      actual = params.data.datatype === 'actual' ? value : 0
      budget = params.data.datatype === 'budget' ? value : 0
    }
  }

  return {
    actual: sign * actual,
    budget: sign * budget,
    toString: () => valueToString(sign * budget, sign * actual),
  }
}

const PREFIX = 'PnlContainer'

const classes = {
  bottomTableCloseIcon: `${PREFIX}-bottomTableCloseIcon`,
}

const $Container = styledComp.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: nowrap column;
  overflow: hidden;
`

const $BottomTableWrap = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',

  [`& .${classes.bottomTableCloseIcon}`]: {
    position: 'absolute',
    top: '0.3rem',
    right: '0.3rem',
    cursor: 'pointer',
  },
}))

export const Pnl: React.FC<{
  pnlConfig: PnLConfig
  defaultFileKeys: string[]
  datasrcs: Maybe<s3FileDescription>[]
  datasets: PnLActual[][]
  dateRange: DateRange<Date>
  setDateRange: (
    callBack: (oldValue: DateRange<Date>) => DateRange<Date>,
  ) => void
  onSelectBudgets: (files: s3FileDescription[]) => void
  setRecalculating: React.Dispatch<React.SetStateAction<boolean>>
}> = ({
  pnlConfig,
  defaultFileKeys,
  datasrcs,
  datasets,
  dateRange,
  setDateRange,
  onSelectBudgets,
  setRecalculating,
}) => {
  const selfGridRef: React.RefObject<AgGridReactType> = React.useRef(null)
  const resizePanelRef: React.RefObject<ResizePanel> = React.useRef(null)
  const isGridLoaded = React.useRef(false)
  /*
  const [urlDateRangeFrom] = useUrlHashState<Maybe<string>>('grid-date-from')
  const [urlDateRangeTo] = useUrlHashState<Maybe<string>>('grid-date-to')
  */
  const [urlFilters, setUrlFilters] =
    useUrlHashState<Maybe<AgFilterModel>>('grid-filters')

  const [finRowData, setFinRowData] = React.useState(
    () => [] as (PnLActual | PnLBudget)[],
  )
  const [defaultToolPanel] = React.useState<string>()
  const [clickedCell, setClickedCell] = React.useState<
    Maybe<{
      node: RowNode
      header: string
    }>
  >(null)

  const { config } = React.useContext(ConfigContext)
  const demoMode = config?.features_dict.demo_mode?.enabled

  React.useEffect(() => {
    for (let i = 0; i < datasets.length; i++) {
      if (!Array.isArray(datasets[i])) return
    }
    const combinedBackendData = datasets
      .map((ds, ind) =>
        ds.map((row: PnLActual | PnLBudget) => ({
          ...row,
          datatype: Object.keys(dataTypes).includes(
            datasrcs[ind]?.metadata?.Metadata?.type as string,
          )
            ? datasrcs[ind]?.metadata?.Metadata?.type
            : (!datasrcs[ind] && ind === 0 && 'actual') || 'budget',
          datasource: datasrcs[ind],
        })),
      )
      .flat()
    let filteredBackendData = combinedBackendData.filter(
      (row: PnLActual | PnLBudget) =>
        new Date(row.periodstart) >= (dateRange[0] as Date) &&
        new Date(row.periodstart) <= (dateRange[1] as Date),
    )

    if (demoMode) {
      const currentMonth = new Date().getMonth() + 1
      const currentYear = new Date().getFullYear()

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filteredBackendData = filteredBackendData
        .filter((row: PnLActual | PnLBudget) => {
          const { periodstart } = row
          if (!periodstart) return false

          const dataMonth = new Date(periodstart.toString()).getMonth() + 1
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return !(row.datatype === 'actual' && dataMonth >= currentMonth)
        })
        .map((row: PnLActual | PnLBudget) => {
          if (!row.periodstart) return row
          const dataMonth = new Date(row.periodstart.toString()).getMonth() + 1
          return { ...row, periodstart: `${currentYear}-${dataMonth}-01` }
        })
    }

    setFinRowData(filteredBackendData)
  }, [datasets, datasrcs, dateRange, demoMode])

  const [periodicity, setPeriodicity] = React.useState(periodicities.month)
  const [periodList, setPeriodList] = React.useState(() => [] as string[])
  React.useEffect(() => {
    setPeriodList(getPeriodList(finRowData, 'periodstart', periodicity))
  }, [finRowData, periodicity])

  const [processedData, setProcessedData] = React.useState<
    ProcessedBackendDataItem[]
  >(() => null as unknown as [])

  const dataForJoins = pnlConfig.data_for_joins
  const accountsData = dataForJoins.pnl_account_view
  const groupsDefinition = pnlConfig.pnl_groups
  const accountingGroupsDefinition = pnlConfig.accounting_groups
  const basicValue = pnlConfig.basic_value
  const cohortsColumns = pnlConfig.cohorts

  const hasNonLeafNodes = (nodes: Maybe<RowNode[]>) =>
    nodes?.findIndex((n) => n.allChildrenCount) !== -1

  React.useEffect(() => {
    if (finRowData && accountsData) {
      setProcessedData(
        processBackendData(
          finRowData,
          accountsData,
          groupsDefinition,
          accountingGroupsDefinition,
        ),
      )
    }
  }, [
    finRowData,
    accountsData,
    accountingGroupsDefinition,
    groupsDefinition,
    demoMode,
  ])

  const [periodicIncome, setPeriodicIncome] =
    React.useState<Maybe<Record<string, { budget: number; actual: number }>>>(
      null,
    )

  React.useEffect(() => {
    if (processedData && processedData.length > 0) {
      setPeriodicIncome(
        getPeriodicIncome(
          processedData as Record<string, string>[],
          accountsData,
          periodicity,
        ),
      )
    }
  }, [accountsData, processedData, periodicity])

  React.useEffect(() => {
    if (clickedCell && clickedCell.node && clickedCell.header) {
      resizePanelRef?.current?.setState({
        size: Math.floor((document.documentElement.clientHeight / 100) * 70),
      })
    }
  }, [clickedCell])

  const handleGridLoaded = React.useCallback(
    (event: FirstDataRenderedEvent) => {
      if (urlFilters) {
        const filterModel = deserializeUrlFilter(urlFilters, selfGridRef)
        selfGridRef?.current?.api?.setFilterModel(filterModel)
      }
      // Put timeout so that URL-saved selection will be re-applied after the default one
      setTimeout(() => {
        isGridLoaded.current = true
      }, 1000)
    },
    [urlFilters],
  )

  function getHeaderFromColumn(column: Column): string {
    return column?.getOriginalParent()?.getColGroupDef()?.headerName || ''
  }

  const [selectedThreadId, setThreadId] = useSelectedThreadState()

  const onCellClicked = React.useCallback(
    (evt: CellClickedEvent) => {
      if (selectedThreadId) {
        const { colDef } = evt
        const rowNode = evt.node
        const headerName = getPnlHeaderName(colDef)

        const cellPath = generateColumnPath(rowNode)
        const threadId = generatePnlThreadId(headerName, cellPath)
        setThreadId(threadId)
      }
      const isLeafNode = !hasNonLeafNodes(evt.node.childrenAfterFilter)
      if (isLeafNode && evt.node && getHeaderFromColumn(evt?.column)) {
        setClickedCell({
          node: evt.node,
          header: getHeaderFromColumn(evt?.column),
        })
      }
    },
    [selectedThreadId, setThreadId],
  )

  const onColumnMoved = React.useCallback((evt: ColumnMovedEvent) => {
    const getFieldGenericName = (field: string | undefined) =>
      (field && field.replace(/(.+)_[0-9]+/, '$1')) || null
    if (
      evt.column &&
      evt.source === 'uiColumnDragged' &&
      evt.column.getColId() !== 'ag-Grid-AutoColumn'
    ) {
      const parentCol = evt.column.getParent()
      if (!parentCol) {
        return
      }
      const allColumns = selfGridRef?.current?.columnApi?.getAllColumns()
      const colOrder =
        parentCol.getChildren()?.map((i) =>
          getFieldGenericName(
            (
              i as unknown as {
                getUserProvidedColDef: () => { field: string }
              }
            ).getUserProvidedColDef().field,
          ),
        ) || []
      const colOffset = 1

      let prevParent: Maybe<ColumnGroup> = null
      let prevSubgroupIndex = 0

      allColumns?.forEach((c, ind) => {
        if (
          c.getParent() &&
          c.getParent() !== parentCol &&
          c.getColId() !== 'ag-Grid-AutoColumn'
        ) {
          if (prevParent !== c.getParent()) {
            prevSubgroupIndex = 0
            prevParent = c.getParent()
          }
          const colField = c?.getUserProvidedColDef()?.field
          if (colField) {
            const indexDiff =
              colOrder.findIndex((i) => i === getFieldGenericName(colField)) -
              prevSubgroupIndex
            selfGridRef?.current?.columnApi?.moveColumn(
              c,
              ind + colOffset + indexDiff,
            )
          }
          prevSubgroupIndex += 1
        }
      })
    }
  }, [])

  const handleFiltersChanged = React.useCallback(() => {
    const filterModel = selfGridRef?.current?.api?.getFilterModel()

    if (filterModel) {
      const newUrlFilters = serializeUrlFilter(filterModel, selfGridRef)
      if (isGridLoaded.current) {
        setUrlFilters(newUrlFilters)
      }
    }
  }, [selfGridRef, setUrlFilters])

  const threads = usePageThreads()
  const isDiscusssionsFeatureEnabled = useIsFeatureEnabled(
    'collaboration_threads',
  )

  const handleThreadsStart = React.useCallback(
    ({ node, column }: GetContextMenuItemsParams) => {
      const rowIndex = node?.rowIndex
      if (!node || !column || typeof rowIndex !== 'number') return

      const cellPath = generateColumnPath(node)
      const headerName = getPnlHeaderName(column.getColDef())
      const threadId = generatePnlThreadId(headerName, cellPath)
      setThreadId(threadId)
    },
    [setThreadId],
  )

  const getContextMenuItems = React.useCallback(
    (params: GetContextMenuItemsParams) => {
      const menuItems: (string | MenuItemDef)[] = [
        {
          name: 'Show transaction lines',
          action: () => {
            if (params?.node && params?.column)
              setClickedCell({
                node: params.node,
                header: getHeaderFromColumn(params.column),
              })
          },
        },
        'separator',
        'copy',
        'copyWithHeaders',
        'paste',
        'separator',
        'export',
      ]
      const cellPath = generateColumnPath(params.node as RowNode)
      const colDef = params.column?.getColDef()
      const headerName = getPnlHeaderName(colDef)

      const threadId = generatePnlThreadId(headerName, cellPath)
      const isWithThread = hasCellThread(threads, threadId)
      const isNumberValue = colDef?.type === 'numericColumn'

      if (isNumberValue && isDiscusssionsFeatureEnabled) {
        menuItems.unshift(
          {
            name: `${isWithThread ? 'Open' : 'Start'} discussion`,
            action: () => handleThreadsStart(params),
          },
          'separator',
        )
      }

      return menuItems
    },
    [handleThreadsStart, threads, isDiscusssionsFeatureEnabled],
  )

  const hideBudgetAccounts = pnlConfig.hide_budget_accounts

  const getBudgetValue = React.useCallback(
    (node: Maybe<RowNode>, value: { budget: number }) =>
      node?.key && hideBudgetAccounts && hideBudgetAccounts.includes(node.key)
        ? 0
        : value.budget,
    [hideBudgetAccounts],
  )

  // data definitions
  const detailedColumns = React.useMemo(
    () => [
      {
        key: 'diff',
        title: 'diff',
        style: { color: 'black', fontStyle: 'italic' },
        props: { minWidth: 120, headerTooltip: 'difference (actual - budget)' },
        formatFunc: (params: ValueFormatterParams) => {
          const budget = getBudgetValue(params.node, params.value)
          let value
          if (budget === 0) value = 0
          else value = params.value.actual - params.value.budget
          return finCurrencyFormatter(value, showDecimalPlaces)
        },
      },
      {
        key: 'attainment',
        title: 'attainment',
        style: { color: 'black', fontStyle: 'italic' },
        props: { minWidth: 100, headerTooltip: 'attainment (actual / budget)' },
        formatFunc: (params: ValueFormatterParams) => {
          const budget = getBudgetValue(params.node, params.value)
          const value = budget === 0 ? 0 : 100 * (params.value.actual / budget)
          return percentFormatter(value, showPercentDecimalPlaces)
        },
      },
      {
        key: 'planned_revenue',
        title: '% planned rev',
        style: {
          color: 'var(--color-text-pnl-budget)',
        },
        props: {
          minWidth: 90,
          maxWidth: 120,
          initialHide: false,
          headerTooltip: '% in planned income (budget sum / budget income)',
        },
        formatFunc: (params: ValueFormatterParams, periodName: string) => {
          const budget = getBudgetValue(params.node, params.value)
          let value
          if (!periodicIncome || !periodicIncome[periodName]) {
            value = 0
          } else {
            const income = periodicIncome[periodName]?.budget || 0
            value = income === 0 ? 0 : 100 * (budget / income)
          }
          return percentFormatter(value, showPercentDecimalPlaces)
        },
      },
      {
        key: 'actual_revenue',
        title: '% actual rev',
        style: {
          'color': 'var(--color-text-pnl-actual)',
          'border-right-color': '#e2e2e2',
        },
        props: {
          minWidth: 90,
          maxWidth: 120,
          initialHide: false,
          headerTooltip: '% in actual income (actual sum / actual income)',
        },
        formatFunc: (params: ValueFormatterParams, periodName: string) => {
          const budget = getBudgetValue(params.node, params.value)
          let value
          if (!periodicIncome || !periodicIncome[periodName] || budget === 0)
            value = 0
          else {
            const income = periodicIncome[periodName]?.actual || 0
            value = income === 0 ? 0 : 100 * (params.value.actual / income)
          }
          return percentFormatter(value, showPercentDecimalPlaces)
        },
      },
    ],
    [periodicIncome, getBudgetValue],
  )

  const getDetailedColumns = React.useCallback(
    () => [
      ...datasrcs.map((i, ind) => {
        const formatActual = (params: ValueFormatterParams) =>
          finCurrencyFormatter(params.value.actual, showDecimalPlaces)
        const formatBudget = (params: ValueFormatterParams) =>
          finCurrencyFormatter(
            getBudgetValue(params.node, params.value),
            showDecimalPlaces,
          )
        const isActual =
          i?.metadata?.Metadata?.type === DATA_TYPE_ACTUAL || (!i && ind === 0)
        const colorsMap: { [key: string]: string } = {
          [DATA_TYPE_ACTUAL]: 'var(--color-text-pnl-actual)',
          [DATA_TYPE_BUDGET]: 'var(--color-text-pnl-budget)',
          [DATA_TYPE_FORECAST]: 'var(--color-text-pnl-forecast)',
        }
        return {
          key: `data_${ind + 1}`,
          title:
            i?.metadata?.Metadata?.title ||
            (ind === 0 ? DATA_TYPE_ACTUAL : DATA_TYPE_BUDGET),
          style: {
            color: isActual
              ? colorsMap[DATA_TYPE_ACTUAL]
              : colorsMap[i?.metadata?.Metadata?.type || DATA_TYPE_BUDGET],
          },
          props: { minWidth: 130 },
          formatFunc: isActual ? formatActual : formatBudget,
        }
      }),
      ...detailedColumns.filter(
        (i) => datasets.length === 2 || ['budget', 'actual'].includes(i.key),
      ),
    ],
    [detailedColumns, getBudgetValue, datasets.length, datasrcs],
  )

  const periodColumns = React.useMemo(
    () => ['Total', ...periodList],
    [periodList],
  )

  const detailedColumnsList = React.useMemo(
    () => getDetailedColumns(),
    [getDetailedColumns],
  )

  return React.useMemo(() => {
    if (processedData === null) {
      return <CenteredCircularProgress message="calculating..." />
    }

    return (
      <$Container>
        <Typography
          variant="h1"
          style={{
            marginRight: '20px',
            ...pageHeadingStyles(),
          }}
        >
          P&L BVA
        </Typography>
        <ResizePanel
          ref={resizePanelRef}
          disabled={!(clickedCell && clickedCell.node && clickedCell.header)}
          direction="s"
          style={{
            height: !(clickedCell && clickedCell.node && clickedCell.header)
              ? '100%'
              : '70%',
          }}
        >
          <div
            className="ag-theme-alpine main-grid pnl-grid"
            style={{ height: '100vh', width: '100%' }}
          >
            <AgGridReact
              ref={selfGridRef}
              defaultColDef={{
                flex: 1,
                minWidth: 80,
                filter: true,
                sortable: true,
                resizable: true,
              }}
              // headerHeight={0}
              autoGroupColumnDef={{
                headerName: 'Groups',
                field: 'trannumber',
                minWidth: 250,
                cellRenderer: 'agGroupCellRenderer',
                pinned: 'left',
                sort: 'asc',
                cellRendererParams: {
                  footerValueGetter: (params: GroupCellRendererParams) => {
                    const isRootLevel = params?.node?.level === -1
                    if (isRootLevel) {
                      return 'Net'
                    }
                    return `Sub Total (${params?.value})`
                  },
                },
                valueGetter: (params) =>
                  cohortValueGetter(params, basicValue, dataForJoins),
                comparator: (
                  a: string,
                  b: string,
                  nodeA: RowNode,
                  nodeB: RowNode,
                  isInverted: boolean,
                ) =>
                  generalComparatorSavePeriodsOrder(
                    a,
                    b,
                    nodeA,
                    nodeB,
                    isInverted,
                  ),
                onCellDoubleClicked: (event) => {
                  if (
                    !event.data ||
                    !event.data.tranidid ||
                    !event.data.trantype
                  )
                    return
                  const transactionUrl = getNetsuiteTransactionUrl(
                    config?.features_dict.netsuite_customer_id?.value,
                    event.data.trantype,
                    event.data.tranidid,
                  )
                  if (transactionUrl) window.open(transactionUrl, '_blank')
                },
              }}
              getContextMenuItems={getContextMenuItems}
              icons={{
                grouping:
                  '<span class="ag-icon ag-icon-grouping" unselectable="on" role="presentation"></span>',
                periods:
                  '<span class="ag-icon ag-icon-periods" unselectable="on" role="presentation"></span>',
                settings:
                  '<span class="ag-icon ag-icon-settings" unselectable="on" role="presentation"></span>',
              }}
              sideBar={{
                toolPanels: [
                  {
                    id: 'cohorts',
                    labelDefault: 'Cohorts',
                    labelKey: 'cohorts',
                    iconKey: 'grouping',
                    toolPanel: 'agColumnsToolPanel',
                    toolPanelParams: {
                      suppressPivotMode: true,
                      // suppressSideButtons: true,
                      suppressValues: true,
                      suppressPivots: true,
                      suppressColumnFilter: false,
                      suppressColumnExpandAll: false,
                      suppressColumnSelectAll: false,
                      suppressColumnMove: false,
                    },
                  },
                  {
                    id: 'subcolumns',
                    labelDefault: 'Columns',
                    labelKey: 'subcolumns',
                    iconKey: 'columns',
                    toolPanelFramework: () =>
                      (selfGridRef?.current && (
                        <PnlSettingsColumns
                          grid={selfGridRef.current}
                          columns={getDetailedColumns()}
                        />
                      )) ||
                      null,
                  },
                  {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                  },
                  {
                    id: 'dateRange',
                    labelDefault: 'Date Range',
                    labelKey: 'dateRange',
                    iconKey: 'periods',
                    toolPanelFramework: () => (
                      <PnlSettingsDateRange
                        pnlConfig={pnlConfig}
                        datasets={datasets}
                        currentPeriodicity={periodicity}
                        setCurrentPeriodicityCallBack={setPeriodicity}
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                        setRecalculating={setRecalculating}
                      />
                    ),
                  },
                  {
                    id: 'budgetVersions',
                    labelDefault: 'Budget versions',
                    labelKey: 'budgetVersions',
                    iconKey: 'settings',
                    toolPanelFramework: () => (
                      <PnlSettingsBudgetVersions
                        pnlConfig={pnlConfig}
                        defaultFileKeys={defaultFileKeys}
                        datasrcs={datasrcs}
                        onSelectBudgets={onSelectBudgets}
                      />
                    ),
                  },
                ],
                defaultToolPanel,
              }}
              rowData={processedData}
              suppressAggFuncInHeader={true}
              aggFuncs={{ sum: aggregate }}
              groupIncludeTotalFooter={true}
              tooltipShowDelay={500}
              // groupRemoveSingleChildren={true}
              // groupRemoveLowestSingleChildren={true}
              // groupIncludeFooter={true}
              // groupHideOpenParents={true}
              suppressMakeColumnVisibleAfterUnGroup={true}
              groupDefaultExpanded={defaultExpandedGroups}
              onFirstDataRendered={handleGridLoaded}
              onCellClicked={onCellClicked}
              onColumnMoved={onColumnMoved}
              onFilterChanged={handleFiltersChanged}
              reactUi={false}
            >
              {cohortsColumns
                .filter((cohort) => cohort.selected)
                .map((cohort) => (
                  <AgGridColumn
                    key={cohort.name}
                    field={cohort.joined?.join_on || cohort.column}
                    headerName={cohort.header}
                    valueGetter={(params: ValueGetterParams) =>
                      cohortValueGetter(params, cohort, dataForJoins)
                    }
                    rowGroup
                    hide
                    suppressColumnsToolPanel
                  />
                ))}
              {periodColumns.map((period, iPeriod) => (
                <AgGridColumn
                  field={`period${period}`}
                  key={period}
                  headerName={period}
                  marryChildren
                  suppressColumnsToolPanel
                >
                  {detailedColumnsList.map((column) => (
                    <AgGridColumn
                      field={`sum_accounting_${column.key}_${iPeriod}`}
                      headerName={column.title}
                      aggFunc="sum"
                      type="numericColumn"
                      enableValue={true}
                      allowedAggFuncs={['sum']}
                      valueFormatter={(params: ValueFormatterParams) =>
                        column.formatFunc(params, period)
                      }
                      key={period + column.title}
                      colId={period + column.title}
                      cellRendererFramework={PnlNumericCellRenderer}
                      valueGetter={(params: ValueGetterParams) =>
                        valueGetter(params, period, periodicity, datasrcs)
                      }
                      cellStyle={column.style as { color: string }}
                      suppressFiltersToolPanel={true}
                      {...column.props}
                    />
                  ))}
                </AgGridColumn>
              ))}
            </AgGridReact>
          </div>
        </ResizePanel>
        {clickedCell && clickedCell.node && clickedCell.header && (
          <$BottomTableWrap>
            <CloseIcon
              fontSize="small"
              className={clsx(classes.bottomTableCloseIcon)}
              style={{ zIndex: 2 }}
              onClick={() => {
                resizePanelRef?.current?.setState({ size: 0 })
                setClickedCell(null)
              }}
            />
            <PnlTransactionsTable
              clickedCell={clickedCell}
              pnlConfig={pnlConfig}
              dateRange={dateRange}
              periodicity={periodicity}
            />
          </$BottomTableWrap>
        )}
      </$Container>
    )
  }, [
    basicValue,
    clickedCell,
    cohortsColumns,
    config?.features_dict.netsuite_customer_id?.value,
    dataForJoins,
    datasets,
    datasrcs,
    dateRange,
    defaultToolPanel,
    detailedColumnsList,
    getContextMenuItems,
    getDetailedColumns,
    handleFiltersChanged,
    handleGridLoaded,
    onCellClicked,
    onColumnMoved,
    periodColumns,
    periodicity,
    pnlConfig,
    processedData,
    setDateRange,
    setRecalculating,
    onSelectBudgets,
    defaultFileKeys,
  ])
}
