/* eslint-disable no-param-reassign */
import type { Maybe } from '../types'
import csv from 'csvtojson'

/* eslint-disable @typescript-eslint/no-explicit-any */

export async function csvToJson(
  csvText: string,
  numberColumns?: string[],
): Promise<any> {
  let jsonObject = await csv().fromString(csvText)
  if (numberColumns) {
    jsonObject = jsonObject.map((row) => {
      const res = row
      numberColumns.forEach((col) => {
        if (res[col]) res[col] = parseFloat(res[col])
        else res[col] = 0
      })
      return res
    })
  }
  return jsonObject
}

export function convertToSingleJson(
  jsonArray: Record<string, any>,
  keyField: string,
  idAlwaysExist = true,
): Record<string, any> {
  return jsonArray.reduce(
    (res: any, value: any) => {
      // eslint-disable-next-line no-param-reassign
      if (value[keyField]) res[value[keyField]] = value
      return res
    },
    { idAlwaysExist },
  )
}

export function getAdditionalField(
  fieldsDict: Record<string, any>,
  id: string,
  fieldName: string,
  defaultValue: Maybe<string> = 'id',
): Maybe<string> {
  if (!id || !fieldsDict || !fieldName)
    return defaultValue === 'id' ? id : defaultValue

  const additionalFields = fieldsDict[id]
  if (additionalFields) return additionalFields[fieldName]
  if (fieldsDict.idAlwaysExist)
    // eslint-disable-next-line no-console
    console.error(`Can't find field ${fieldName} for id ${id}`)

  return defaultValue === 'id' ? id : defaultValue
}

export function getAdditionalFieldByOtherField(
  fieldsDict: Record<string, any>,
  searchFieldName: string,
  searchFieldValue: string | number,
  fieldName: string,
  defaultValue: any = 'id',
): any {
  if (!searchFieldValue || !searchFieldName || !fieldsDict || !fieldName)
    return defaultValue === 'id' ? searchFieldValue : defaultValue

  let res
  Object.values(fieldsDict).forEach((value) => {
    if (value && value[searchFieldName] === searchFieldValue)
      res = value[fieldName]
  })

  if (res) return res
  return defaultValue === 'id' ? searchFieldValue : defaultValue
}
