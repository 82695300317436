import type {
  ScenarioConfig,
  ScenarioVersion,
  ScenarioVersionResponse,
} from './types'
import {
  runFetch,
  RunFetchTokenPolymorphicParams,
  runFetchWithToken,
  useApi,
} from './useApi'

import { AsyncState } from 'react-async'
import type { Maybe } from '../types'
import { endpoints } from './endpoints'

export function useScenarioConfigApi(): AsyncState<Maybe<ScenarioConfig>> {
  return useApi<ScenarioConfig>(endpoints.scenarioConfig)
}

export async function getScenarioConfig({
  getAccessTokenSilently,
  token,
}: RunFetchTokenPolymorphicParams): Promise<ScenarioConfig> {
  return getAccessTokenSilently
    ? runFetchWithToken({ getAccessTokenSilently }, endpoints.scenarioConfig)
    : runFetch(token as string, endpoints.scenarioConfig)
}

export function useScenarioVersionApi(
  scenarioTypeName: string,
  versionUUID: string,
): AsyncState<Maybe<ScenarioVersionResponse>> {
  return useApi<ScenarioVersionResponse>(
    endpoints.scenarioVersions(scenarioTypeName, versionUUID),
  )
}

export async function getScenarioVersion(
  { getAccessTokenSilently, token }: RunFetchTokenPolymorphicParams,
  scenarioTypeName: string,
  versionUUID: string,
): Promise<ScenarioVersionResponse> {
  const url = endpoints.scenarioVersions(scenarioTypeName, versionUUID)
  return getAccessTokenSilently
    ? runFetchWithToken({ getAccessTokenSilently }, url)
    : runFetch(token as string, url)
}

export async function getScenarioVersions(
  { getAccessTokenSilently, token }: RunFetchTokenPolymorphicParams,
  scenarioTypeName: string,
  showDeleted = false,
): Promise<Maybe<ScenarioVersion[]>> {
  const url = endpoints.scenarioVersions(scenarioTypeName)
  const urlParams = { show_deleted: encodeURIComponent(showDeleted) }
  return getAccessTokenSilently
    ? runFetchWithToken({ getAccessTokenSilently }, url, {}, urlParams)
    : runFetch(token as string, url, {}, urlParams)
}

export async function saveScenarioVersion(
  { getAccessTokenSilently, token }: RunFetchTokenPolymorphicParams,
  scenarioTypeName: string,
  scenarioVersion: ScenarioVersion,
): Promise<ScenarioVersionResponse> {
  const url = endpoints.scenarioVersions(scenarioTypeName)
  const options = { method: 'PUT', body: JSON.stringify(scenarioVersion) }
  return getAccessTokenSilently
    ? runFetchWithToken({ getAccessTokenSilently }, url, options)
    : runFetch(token as string, url, options)
}

export async function publishScenarioVersion(
  { getAccessTokenSilently, token }: RunFetchTokenPolymorphicParams,
  scenarioTypeName: string,
  versionUUID: string,
): Promise<ScenarioVersion> {
  const url = endpoints.scenarioVersions(scenarioTypeName, versionUUID)
  const options = { method: 'POST', body: '' }
  return getAccessTokenSilently
    ? runFetchWithToken({ getAccessTokenSilently }, url, options)
    : runFetch(token as string, url, options)
}

export async function deleteScenarioVersion(
  { getAccessTokenSilently, token }: RunFetchTokenPolymorphicParams,
  scenarioTypeName: string,
  versionUUID: string,
): Promise<ScenarioVersion> {
  const url = endpoints.scenarioVersions(scenarioTypeName, versionUUID)
  const options = { method: 'DELETE' }
  return getAccessTokenSilently
    ? runFetchWithToken({ getAccessTokenSilently }, url, options)
    : runFetch(token as string, url, options)
}

export async function copyVersionWithDocs(
  { getAccessTokenSilently, token }: RunFetchTokenPolymorphicParams,
  versionUUID: string,
  scenarioTypeName: string,
  name: string,
): Promise<ScenarioVersion> {
  const url = `${endpoints.scenarioVersions(scenarioTypeName)}/copy`
  const options = { method: 'POST' }
  const urlParams = { version_uuid: versionUUID, name }
  return getAccessTokenSilently
    ? runFetchWithToken({ getAccessTokenSilently }, url, options, urlParams)
    : runFetch(token as string, url, options, urlParams)
}
