import {
  ApiAccountsPropertiesResponse,
  ApiConfigResponse,
  ApiUsersInfoResponse,
  RawApiConfigResponse,
} from './types'
import { endpoints } from './endpoints'
import { mapConfig } from './mappings'
import { runFetch } from './useApi'

export function getConfig(token: string): Promise<ApiConfigResponse> {
  return runFetch(token, endpoints.config, undefined, undefined, {
    transformResponse: (data) => mapConfig(data as RawApiConfigResponse),
  })
}

export function getUserInfo(token: string): Promise<ApiUsersInfoResponse> {
  return runFetch(token, endpoints.users.info)
}

export function getAccountProperties(
  token: string,
): Promise<ApiAccountsPropertiesResponse> {
  return runFetch(token, endpoints.accounts.properties)
}

export function getAllConfigs(
  token: string,
): Promise<
  [ApiConfigResponse, ApiUsersInfoResponse, ApiAccountsPropertiesResponse]
> {
  return Promise.all([
    getConfig(token),
    getUserInfo(token),
    getAccountProperties(token),
  ])
}
