import { Box, CircularProgress, Typography } from '@mui/material'

import type { CircularProgressProps } from '@mui/material/CircularProgress'
import React from 'react'

export const CenteredCircularProgress: React.FC<
  CircularProgressProps & { message?: string } & { onTop?: boolean }
> = ({ message, onTop, ...props }) => {
  const { style, ...otherProps } = props
  let resStyle = style || {}
  if (onTop)
    resStyle = {
      ...style,
      zIndex: 9999,
      position: 'absolute',
      left: '50%',
    }

  return (
    <Box
      display="flex"
      height="100vh"
      alignItems="center"
      justifyContent="center"
      flexDirection="row"
      style={resStyle}
      {...otherProps}
    >
      <Box textAlign="center">
        <CircularProgress />
        <br />
        {message ? <Typography variant="caption">{message}</Typography> : null}
      </Box>
    </Box>
  )
}
