import type { AggregationLoadingStatus, ArrItemBase } from '../workers/utils'
import type { ArrProcessingFormula, Cohort } from '../api/types'
import type { Maybe, TimeSegmentationFrame } from '../types'

import type { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact'
import React from 'react'

export type AggregatedTableContext = {
  gridRef: Maybe<React.RefObject<AgGridReactType>>
  rowData: readonly ArrItemBase[]
  arrs: readonly ArrProcessingFormula[]
  cohorts: readonly Cohort[]
  selectedArr?: string
  isLoading: boolean
  setSelectedArr: (nextSelectedArr: string) => void
  dateRangeFrom: string
  dateRangeTo: string
  setDateRange: (from: string, to: string) => void
  resetDateRange: () => void
  key: AggregatedTableTypeKey
  loadingStatus: AggregationLoadingStatus
  flags: AggregatedTableFlags
  defaultCellRangeSelection: Maybe<'firstRow' | 'lastFullMonthColumn'>
  timeSegmentationFrame: TimeSegmentationFrame
  setTimeSegmentationFrame: (next: TimeSegmentationFrame) => void
}

const stub = (): never => {
  throw new Error('Context is empty')
}

export const emptyAggregatedTableContext: AggregatedTableContext = {
  gridRef: null,
  rowData: [],
  arrs: [],
  cohorts: [],
  isLoading: false,
  setSelectedArr: stub,
  setDateRange: stub,
  resetDateRange: stub,
  dateRangeFrom: '',
  dateRangeTo: '',
  key: 'unknown',
  loadingStatus: 'unknown',
  flags: {
    bridgeWaterfall: false,
  },
  defaultCellRangeSelection: null,
  timeSegmentationFrame: 'month',
  setTimeSegmentationFrame: stub,
}

export const AggregatedTableContext =
  React.createContext<AggregatedTableContext>(emptyAggregatedTableContext)

export type AggregatedTableFlags = {
  bridgeWaterfall: boolean
}

export type AggregatedTableTypeKey = 'kpis' | 'bridge' | 'unknown'
