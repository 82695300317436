import { ValueGetterParams } from 'ag-grid-community'
import { PnLCohort, PnLDataForJoins } from '../../api/types'
import { getAdditionalField } from '../../utils/csv-utils'
import { capitalize } from 'lodash'
import type { Maybe } from '../../types'

export const DATA_TYPE_BUDGET = 'budget'
export const DATA_TYPE_FORECAST = 'forecast'
export const DATA_TYPE_ACTUAL = 'actual'

export type DataType = 'budget' | 'forecast' | 'actual'

export const dataTypes: Record<DataType, string> = {
  budget: 'Budget',
  forecast: 'Forecast',
  actual: 'Actual',
}

export function cohortValueGetter(
  params: ValueGetterParams,
  cohort: PnLCohort,
  dataForJoins: PnLDataForJoins,
): Maybe<string | undefined> {
  if (!params.data) return null
  let value
  if (!cohort.joined) {
    value = params.data[cohort.column]
  } else {
    const key = params.data[cohort.joined.join_on]

    const sourceTable = dataForJoins[cohort.joined.source]

    value = getAdditionalField(sourceTable, key, cohort.column, cohort.default)
  }
  if (!value) return cohort.default ?? ''
  return cohort.capitalize ? capitalize(value) : value
}
