import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import type {
  GetMainMenuItemsParams,
  RowNode,
  ValueGetterParams,
} from 'ag-grid-community'
import { ValueFormatterParams } from 'ag-grid-community'
import type { Maybe } from '../../types'
import { NetSuiteCellRenderer } from './NetSuiteCellRenderer'
import type { PnLActualDetailed, PnLConfig } from '../../api/types'
import React from 'react'
import { useAuth0WithCypress } from '../../hooks/useAuth0WithCypress'
import { DateRange } from '@mui/lab'
import { getPeriodName } from '../../utils/periodicity-utils'
import { runFetchWithToken } from '../../api/useApi'
import { endpoints } from '../../api/endpoints'
import { CenteredCircularProgress } from '../atoms/CenteredCircularProgress'
import { cohortValueGetter } from './financial-reports-utils'
import { finCurrencyFormatter } from '../../utils/num-utils'

const showDecimalPlaces = 0

export const PnlTransactionsTable: React.FC<{
  clickedCell: { node: RowNode; header: string }
  pnlConfig: PnLConfig
  dateRange: DateRange<Date>
  periodicity: string
}> = ({ clickedCell, pnlConfig, dateRange, periodicity }) => {
  const { getAccessTokenSilently } = useAuth0WithCypress()

  const [transactionData, setTransactionData] =
    React.useState<Maybe<PnLActualDetailed[]>>(null)
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    function getFetchParams() {
      const processColumns = [
        ...pnlConfig.columns.main,
        ...pnlConfig.columns.custom,
        'periodstart',
      ]
      const filter: Record<string, Set<unknown>> = {}
      const columnHeader = clickedCell.header
      const isTotalColumn = columnHeader === 'Total'
      let dateFrom = (isTotalColumn ? dateRange[0] : dateRange[1]) as Date
      let dateTo = (isTotalColumn ? dateRange[1] : dateRange[0]) as Date
      // collecting data for filters based on aggrid child rows
      const childrenToProcess = new Set([clickedCell.node])
      while (childrenToProcess.size) {
        const currentChildren = new Set(childrenToProcess)
        // eslint-disable-next-line no-loop-func
        currentChildren.forEach((childNode) => {
          childrenToProcess.delete(childNode)
          if (childNode.childrenAfterFilter)
            childNode.childrenAfterFilter.forEach((leaf) =>
              childrenToProcess.add(leaf),
            )
          if (childNode.data && childNode.data.sum_accounting)
            Object.entries(childNode.data)
              .filter(([key, value]) => processColumns.includes(key))
              .forEach(([key, value]) => {
                if (key === 'periodstart' && !isTotalColumn) {
                  if (
                    getPeriodName(value as string, periodicity) === columnHeader
                  ) {
                    const date = new Date(value as string)
                    dateFrom = dateFrom > date ? date : dateFrom
                    dateTo = dateTo < date ? date : dateTo
                  }
                } else if (key !== 'periodstart') {
                  if (!filter[key]) filter[key] = new Set()
                  filter[key].add(value)
                }
              })
        })
      }
      return {
        filter,
        dateFrom,
        dateTo,
      }
    }

    const { filter, dateFrom, dateTo } = getFetchParams()
    setLoading(true)
    runFetchWithToken<PnLActualDetailed[]>(
      { getAccessTokenSilently },
      endpoints.pnl.actuals_filtered,
      {
        method: 'POST',
        body: JSON.stringify(filter, (key, value) =>
          value instanceof Set ? [...value] : value,
        ),
      },
      {
        from: dateFrom?.toISOString().substr(0, 10) || '',
        to: dateTo?.toISOString().substr(0, 10) || '',
      },
    )
      .then((data) => setTransactionData(data))
      .finally(() => setLoading(false))
  }, [
    getAccessTokenSilently,
    dateRange,
    clickedCell,
    clickedCell.node,
    clickedCell.header,
    pnlConfig,
    periodicity,
  ])

  // Disable 'rowGroup' in header menu
  const getMainMenuItems = React.useCallback(
    (params: GetMainMenuItemsParams) =>
      params.defaultItems.filter((i) => i !== 'rowGroup'),
    [],
  )

  if (loading || !transactionData) return <CenteredCircularProgress /> // TODO doesn't work. why?

  return (
    <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
      <AgGridReact
        rowData={transactionData}
        showOpenedGroup
        defaultColDef={{
          sortable: true,
          resizable: true,
          hide: false,
          enableRowGroup: true,
          enableValue: true,
          enablePivot: false,
          menuTabs: ['generalMenuTab'],
        }}
        sideBar={{
          toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Fields',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
              toolPanelParams: {
                suppressPivotMode: true,
                suppressSideButtons: true,
                suppressValues: false,
                suppressPivots: true,
                suppressRowGroups: false,
              },
            },
            {
              id: 'filters',
              labelDefault: 'Filters',
              labelKey: 'filters',
              iconKey: 'filter',
              toolPanel: 'agFiltersToolPanel',
            },
          ],
          defaultToolPanel: 'columns',
        }}
        getMainMenuItems={getMainMenuItems}
        immutableData
        reactUi
      >
        {pnlConfig.detailed_panel.columns
          .filter((column) => column.selected)
          .map((column, ind) => (
            <AgGridColumn
              key={column.name}
              field={column.joined?.join_on || column.column}
              headerName={column.header}
              filter
              valueGetter={(params: ValueGetterParams) =>
                cohortValueGetter(params, column, pnlConfig.data_for_joins)
              }
              cellRendererFramework={ind > 0 ? undefined : NetSuiteCellRenderer}
            />
          ))}
        <AgGridColumn
          key="sum_accounting"
          field="sum_accounting"
          headerName="Sum in accounting"
          filter="agNumberColumnFilter"
          valueFormatter={(params: ValueFormatterParams) =>
            params.value
              ? finCurrencyFormatter(params.value, showDecimalPlaces)
              : ''
          }
        />
      </AgGridReact>
    </div>
  )
}
