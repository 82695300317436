import type { ApiFileLink, PnLConfig, s3FileDescription } from '../../api/types'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
  tableCellClasses,
} from '@mui/material'
import {
  deleteFileFromS3,
  getFileFromS3,
  getFileListFromS3,
  uploadFileToS3,
} from '../../api/useS3Api'

import CloseIcon from '@mui/icons-material/Close'
import type { DataType } from './financial-reports-utils'
import Dropzone from 'react-dropzone'
import ErrorIcon from '@mui/icons-material/Error'
import { Icon } from '../Icon/Icon'
import { Maybe } from '../../types'
import { ModalDialog } from '../atoms/ModalDialog'
import React from 'react'
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined'
import clsx from 'clsx'
import { dataTypes } from './financial-reports-utils'
import { endpoints } from '../../api/endpoints'
import { orderBy } from 'lodash'
import { runFetchWithToken } from '../../api/useApi'
import { styled } from '@mui/material/styles'
import styledComp from 'styled-components'
import { useAuth0WithCypress } from '../../hooks/useAuth0WithCypress'
import { useUserInfo } from '../../api'

const csvFileFilter = '.csv,text/csv,text/comma-separated-values'
const xlsFileFilter = '.xls,application/vnd.ms-excel'
const xlsxFileFilter =
  '.xlsx,.xlsm,.xlsb,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

const BUDGETS_AUTOREFRESH_TIMEOUT = 7500

const PROGRESS_NONE = 0
const PROGRESS_UPLOADING = 1
const PROGRESS_SUCCESS = 2
const PROGRESS_FAILURE = 3

const UPLOAD_STATUS_ERROR = 1
const UPLOAD_STATUS_PROCESSED = 2

const PREFIX = 'PnlSettings'

const classes = {
  closeIcon: `${PREFIX}-closeIcon`,
  isDragActive: `${PREFIX}-isDragActive`,
}

const $ProcessingError = styledComp.pre`
  margin: 0;
  white-space: pre-wrap;
`

const $ManageUploadsContainer = styled(Box)(({ theme }) => ({
  [`& .${classes.closeIcon}`]: {
    position: 'absolute',
    top: '0.3rem',
    right: '0.3rem',
    cursor: 'pointer',
  },

  '& .remove-icon': {
    'position': 'absolute',
    'top': '0',
    'bottom': '0',
    'right': '0.5rem',
    'margin': 'auto',
    'cursor': 'pointer',

    'path': {
      stroke: 'var(--color-icon-nav-selected)',
    },

    '&:hover': {
      path: {
        stroke: 'var(--color-primary)',
      },
    },
  },

  [`& .${classes.isDragActive}`]: {
    borderColor: 'red',
    background: '#fff7f3',
  },
}))

const $DropzoneWrapper = styled(Box)(() => ({
  'marginTop': '0.8rem',
  'boxShadow': '0 4px 4px 0 rgba(0, 0, 0, 0.2)',

  '&.activeArea > div': {
    border: '1px dashed #CCC',
  },

  [`&.activeArea > div.${classes.isDragActive}`]: {
    borderColor: 'var(--color-primary)',
  },
}))

const $LinkButton = styled(Button)(() => ({
  'fontWeight': 'normal',
  'textTransform': 'none',
  'color': '#0075ff',

  '&:hover': {
    textDecoration: 'underline',
    background: 'none',
  },
}))

const $TableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}))

const $TableCell = styled(TableCell)(({ theme }) => ({
  padding: '0.5rem 0.8rem',
  whiteSpace: 'normal',
  wordWrap: 'break-word',

  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const $TableCellSecondary = styled($TableCell)(({ theme }) => ({
  color: '#888888',
}))

const allTimeouts: Set<ReturnType<typeof setTimeout>> = new Set()

function clearAllTimeouts() {
  allTimeouts.forEach((id) => clearTimeout(id))
  allTimeouts.clear()
}

export const PnlSettingsBudgetVersions: React.FC<{
  pnlConfig: PnLConfig
  defaultFileKeys: string[]
  datasrcs: Maybe<s3FileDescription>[]
  onSelectBudgets: (files: s3FileDescription[]) => void
}> = ({ pnlConfig, defaultFileKeys, datasrcs, onSelectBudgets }) => {
  const { getAccessTokenSilently } = useAuth0WithCypress()
  const userEmail = useUserInfo().data?.user?.email

  const [searchTerm, setSearchTerm] = React.useState('')
  const [showUploadsManagerModal, setShowUploadsManagerModal] =
    React.useState(false)
  const [showProcessingErrorModal, setShowProcessingErrorModal] =
    React.useState(false)
  const [uploadProgress, setUploadProgress] = React.useState(PROGRESS_NONE)
  const [attachedFile, setAttachedFile] = React.useState<Maybe<File>>()
  const [selectedFiles, setSelectedFiles] = React.useState<s3FileDescription[]>(
    [],
  )
  const [budgetsList, setBudgetsList] =
    React.useState<Maybe<s3FileDescription[]>>()
  const [newUploadTitle, setNewUploadTitle] = React.useState('')
  const [newUploadType, setNewUploadType] = React.useState('budget')
  const [newUploadFormat, setNewUploadFormat] =
    React.useState('periodic-default')
  const [processingErrorText, setProcessingErrorText] = React.useState('')
  const [processingErrorTooltip, setProcessingErrorTooltip] = React.useState('')
  const [uploadsManagerSortBy, setUploadsManagerSortBy] = React.useState('name')
  const [uploadsManagerSortDir, setUploadsManagerSortDir] = React.useState<
    'asc' | 'desc'
  >('asc')

  React.useEffect(() => {
    setSelectedFiles((prevSelectedFiles) => {
      const newSelectedFiles = prevSelectedFiles
      datasrcs.forEach((i) => {
        if (i && !newSelectedFiles.find((f) => f.key === i.key)) {
          newSelectedFiles.push(i)
        }
      })
      return newSelectedFiles
    })
  }, [datasrcs, selectedFiles])

  const applySearchCriteria = React.useCallback(
    (item: s3FileDescription) =>
      !searchTerm ||
      !searchTerm.trim() ||
      (item.metadata.Metadata?.title || item.file_name)
        .toLowerCase()
        .indexOf(searchTerm.trim()) !== -1,
    [searchTerm],
  )

  const sortUploads = React.useCallback(
    (cell) => {
      if (cell.key === uploadsManagerSortBy) {
        setUploadsManagerSortDir(
          uploadsManagerSortDir === 'asc' ? 'desc' : 'asc',
        )
      } else {
        setUploadsManagerSortBy(cell.key)
        setUploadsManagerSortDir('asc')
      }
    },
    [
      uploadsManagerSortBy,
      setUploadsManagerSortBy,
      uploadsManagerSortDir,
      setUploadsManagerSortDir,
    ],
  )

  const onSearch = React.useCallback(
    (evt) => {
      setSearchTerm(evt.target.value)
    },
    [setSearchTerm],
  )

  const onAttachFiles = React.useCallback(
    (files) => {
      if (files && files.length && files[0]) {
        setAttachedFile(files[0])
        setNewUploadTitle(files[0].name || '')
      }
    },
    [setAttachedFile, setNewUploadTitle],
  )

  const onUploadFiles = React.useCallback(async () => {
    if (!attachedFile) return

    setUploadProgress(PROGRESS_UPLOADING)
    const metadata = {
      type: newUploadType,
      title: newUploadTitle,
      format: newUploadFormat,
      filename: attachedFile.name,
      sender: userEmail,
    }
    const res = await uploadFileToS3(
      pnlConfig.budget_folders.uploaded,
      attachedFile,
      metadata,
      getAccessTokenSilently,
    )
    if (res) {
      setUploadProgress(PROGRESS_SUCCESS)
    } else {
      setUploadProgress(PROGRESS_FAILURE)
    }
    setAttachedFile(null)
  }, [
    pnlConfig.budget_folders.uploaded,
    attachedFile,
    newUploadType,
    newUploadTitle,
    newUploadFormat,
    getAccessTokenSilently,
    userEmail,
  ])

  const onHoverProcessingError = React.useCallback(
    async (upload: s3FileDescription) => {
      setProcessingErrorText('')
      setProcessingErrorTooltip('')
      const loadS3UrlErrorResponse = await runFetchWithToken<ApiFileLink>(
        { getAccessTokenSilently },
        endpoints.fileEndpoint(
          pnlConfig.budget_folders.errors,
          `${upload.file_name}.txt`,
        ),
        {},
      )
      if (loadS3UrlErrorResponse?.link) {
        const data = await getFileFromS3<string>(
          loadS3UrlErrorResponse?.link,
          {},
          { getRawResponse: true },
        )
        if (data) {
          setProcessingErrorText(data)
          setProcessingErrorTooltip(data?.split('\n')[0] || '')
        }
      }
    },
    [
      pnlConfig.budget_folders.errors,
      getAccessTokenSilently,
      setProcessingErrorText,
      setProcessingErrorTooltip,
    ],
  )

  const onClickProcessingError = React.useCallback(() => {
    setShowProcessingErrorModal(true)
  }, [setShowProcessingErrorModal])

  const toggleSelectedFile = (file: s3FileDescription) => {
    if (selectedFiles.find((i) => i.key === file.key)) {
      setSelectedFiles(selectedFiles.filter((i) => i.key !== file.key))
    } else {
      setSelectedFiles([...selectedFiles, file])
    }
  }

  const loadBudgets: (setTimeoutToCheck?: boolean) => void = React.useCallback(
    async (setTimeoutToCheck = false) => {
      const res = await Promise.all([
        getFileListFromS3(
          pnlConfig.budget_folders.uploaded,
          getAccessTokenSilently,
        ),
        getFileListFromS3(
          pnlConfig.budget_folders.processed,
          getAccessTokenSilently,
        ),
        getFileListFromS3(
          pnlConfig.budget_folders.errors,
          getAccessTokenSilently,
        ),
      ]).catch((error) =>
        // eslint-disable-next-line no-console
        console.error('error updating budgets', error),
      )

      if (!Array.isArray(res)) return

      const uploadedBudgets = Array.isArray(res[0]) ? res[0] : []
      const processedBudgets = Array.isArray(res[1]) ? res[1] : []
      const errorsBudgets = Array.isArray(res[2]) ? res[2] : []

      processedBudgets.forEach((i) => {
        const upl = uploadedBudgets?.find((file) =>
          i.file_name.startsWith(file.file_name),
        )
        if (upl) {
          upl.status = UPLOAD_STATUS_PROCESSED
          upl.key = i.key
        }
      })

      errorsBudgets.forEach((i) => {
        const upl = uploadedBudgets?.find((file) =>
          i.file_name.startsWith(file.file_name),
        )
        if (upl && !upl.status) upl.status = UPLOAD_STATUS_ERROR
      })

      const budgetsSorters: {
        [key: string]: [
          ((o: s3FileDescription) => string | number | undefined)[],
          ('asc' | 'desc')[],
        ]
      } = {
        name:
          uploadsManagerSortDir === 'asc'
            ? [
                [
                  (o: s3FileDescription) =>
                    defaultFileKeys.includes(o.key) ? 1 : 2,
                  (o: s3FileDescription) =>
                    o.metadata.Metadata?.title || o.file_name,
                ],
                ['asc', uploadsManagerSortDir],
              ]
            : [
                [
                  (o: s3FileDescription) =>
                    o.metadata.Metadata?.title || o.file_name,
                ],
                [uploadsManagerSortDir],
              ],
        user: [
          [(o: s3FileDescription) => o.metadata.Metadata?.sender || 'N/A'],
          [uploadsManagerSortDir],
        ],
        date: [
          [
            ((o: s3FileDescription) =>
              o.metadata.LastModified &&
              new Date(o.metadata.LastModified).getTime()) || 'N/A',
          ],
          [uploadsManagerSortDir],
        ],
        type: [
          [(o: s3FileDescription) => o.metadata.Metadata?.type || 'budget'],
          [uploadsManagerSortDir],
        ],
      }

      setBudgetsList(
        orderBy(
          [
            {
              key: 'actuals.csv.gz',
              file_name: 'actuals.csv.gz',
              file_size: 0,
              status: UPLOAD_STATUS_PROCESSED,
              metadata: { Metadata: { type: 'actual', title: 'ACTUALS' } },
            },
            ...uploadedBudgets,
          ],
          (budgetsSorters[uploadsManagerSortBy] &&
            budgetsSorters[uploadsManagerSortBy][0]) ||
            budgetsSorters.name[0],
          (budgetsSorters[uploadsManagerSortBy] &&
            budgetsSorters[uploadsManagerSortBy][1]) ||
            budgetsSorters.name[1],
        ),
      )

      if (setTimeoutToCheck) {
        const id = setTimeout(
          () => loadBudgets(true),
          BUDGETS_AUTOREFRESH_TIMEOUT,
        )
        allTimeouts.add(id)
      }
    },
    [
      getAccessTokenSilently,
      pnlConfig.budget_folders,
      defaultFileKeys,
      uploadsManagerSortBy,
      uploadsManagerSortDir,
    ],
  )

  // @todo (mykola): loadBudgets is a regular function
  // so useEffect is called at every render!
  React.useEffect(() => {
    clearAllTimeouts()
    loadBudgets(true)
    return clearAllTimeouts
  }, [loadBudgets])

  const isSelectedVersion = React.useCallback(
    (fileKey: string) =>
      selectedFiles.findIndex((f) => f.key === fileKey) !== -1 ||
      (!selectedFiles.length && defaultFileKeys.includes(fileKey)),
    [defaultFileKeys, selectedFiles],
  )

  return (
    <Stack>
      <Box sx={{ display: 'flex', justifyContent: 'center' }} py={1} px={2}>
        {!Array.isArray(budgetsList) && <CircularProgress />}
        {Array.isArray(budgetsList) && (
          <Box sx={{ width: '100%' }}>
            <Typography variant="subtitle2" color="textSecondary" pt={1} pb={2}>
              Select from available versions
            </Typography>
            <Box pb={3}>
              <input
                type="text"
                className="w-100"
                placeholder="Search..."
                onChange={onSearch}
              />
            </Box>
            <FormGroup>
              {budgetsList.filter(applySearchCriteria).map((i) => (
                <Box key={i.key} position="relative">
                  <div
                    className="ag-virtual-list-item enlarged-height"
                    style={{ position: 'relative' }}
                  >
                    <div
                      role="presentation"
                      className="ag-checkbox ag-input-field ag-label-align-right"
                    >
                      <div
                        className={`ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper ${
                          isSelectedVersion(i.key) ? 'ag-checked' : ''
                        }`}
                        role="presentation"
                      >
                        <input
                          type="checkbox"
                          className="ag-input-field-input ag-checkbox-input"
                          disabled={i.status !== UPLOAD_STATUS_PROCESSED}
                          defaultChecked={isSelectedVersion(i.key)}
                          style={
                            i.status !== UPLOAD_STATUS_PROCESSED
                              ? { opacity: 0 }
                              : {}
                          }
                          onChange={() => toggleSelectedFile(i)}
                        />
                      </div>
                      <div className="ag-input-field-label ag-label ag-checkbox-label">
                        {i.metadata.Metadata?.title || i.file_name}
                      </div>
                    </div>
                  </div>
                  {![UPLOAD_STATUS_PROCESSED, UPLOAD_STATUS_ERROR].includes(
                    i.status as number,
                  ) && (
                    <Box
                      style={{
                        position: 'absolute',
                        top: '-0.1rem',
                        left: '-0.1rem',
                        width: '1rem',
                        height: '1rem',
                        padding: '0.6rem',
                        background: '#fff',
                        zIndex: 1,
                      }}
                    >
                      <CircularProgress
                        style={{
                          position: 'absolute',
                          top: '0.1rem',
                          left: '0.1rem',
                          width: '1rem',
                          height: '1rem',
                          color: '#555555',
                        }}
                      />
                    </Box>
                  )}
                  {i.status === UPLOAD_STATUS_ERROR && (
                    <Tooltip
                      title={
                        <Typography variant="body1">
                          {processingErrorTooltip || (
                            <CircularProgress
                              style={{
                                width: '1rem',
                                height: '1rem',
                                color: '#FFFFFF',
                              }}
                            />
                          )}
                        </Typography>
                      }
                      placement="right"
                      arrow
                    >
                      <ErrorIcon
                        style={{
                          position: 'absolute',
                          top: '-0.25rem',
                          left: '-0.25rem',
                          width: '1.5rem',
                          height: '1.5rem',
                          color: 'red',
                        }}
                        onMouseEnter={() => onHoverProcessingError(i)}
                        onClick={onClickProcessingError}
                      />
                    </Tooltip>
                  )}
                </Box>
              ))}
            </FormGroup>
            <Box sx={{ textAlign: 'center' }} py={1}>
              <Button
                variant="contained"
                disabled={!selectedFiles.length}
                sx={{ width: '100%' }}
                onClick={() => onSelectBudgets(selectedFiles)}
              >
                Compare Selection
              </Button>
            </Box>
          </Box>
        )}
      </Box>

      <Divider style={{ margin: 0 }} />

      <ModalDialog
        open={showProcessingErrorModal}
        maxWidth="60rem"
        onClose={() => setShowProcessingErrorModal(false)}
      >
        <Box style={{ width: '100%', maxWidth: '70rem' }}>
          <$ProcessingError>{processingErrorText}</$ProcessingError>
        </Box>
      </ModalDialog>

      <ModalDialog
        open={showUploadsManagerModal}
        maxWidth="55rem"
        onClose={() => setShowUploadsManagerModal(false)}
      >
        <$ManageUploadsContainer>
          <$DropzoneWrapper className={!attachedFile ? 'activeArea' : ''}>
            {uploadProgress === PROGRESS_UPLOADING && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }} py={2}>
                <CircularProgress />
              </Box>
            )}
            {(uploadProgress === PROGRESS_SUCCESS ||
              uploadProgress === PROGRESS_FAILURE) && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                }}
                py={2}
                px={3}
              >
                <CloseIcon
                  fontSize="small"
                  className={clsx(classes.closeIcon)}
                  onClick={() => setUploadProgress(PROGRESS_NONE)}
                />
                {uploadProgress === PROGRESS_SUCCESS ? (
                  <Typography className="color-text-positive">
                    File uploaded corrrectly
                  </Typography>
                ) : (
                  <Typography className="color-text-negative">
                    Error while uploading file
                  </Typography>
                )}
              </Box>
            )}
            {!uploadProgress && !attachedFile && (
              <Dropzone
                multiple={false}
                onDrop={onAttachFiles}
                accept={`${csvFileFilter},${xlsFileFilter},${xlsxFileFilter}`}
              >
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <Box
                    {...getRootProps()}
                    pt={3}
                    pb={2}
                    sx={{
                      display: 'flex',
                      flexFlow: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    className={clsx({
                      [classes.isDragActive]: isDragActive,
                    })}
                  >
                    <input {...getInputProps()} />
                    <UploadFileOutlinedIcon color="primary" fontSize="large" />
                    <Box pt={2} pb={1}>
                      Drag & Drop File here
                    </Box>
                    <$LinkButton>Select A New Version File</$LinkButton>
                  </Box>
                )}
              </Dropzone>
            )}
            {!uploadProgress && attachedFile && (
              <Box pt={2} px={2}>
                <Typography position="relative" align="center">
                  {attachedFile.name}
                </Typography>
                <FormControl fullWidth={true} sx={{ mt: 3 }}>
                  <TextField
                    id="new-upload-title"
                    label="Title"
                    variant="outlined"
                    value={newUploadTitle}
                    onChange={(evt) => setNewUploadTitle(evt.target.value)}
                  />
                </FormControl>
                <FormControl fullWidth={true} sx={{ mt: 3 }}>
                  <InputLabel id="new-upload-type">Type</InputLabel>
                  <Select
                    id="new-upload-type"
                    labelId="new-upload-type"
                    value={newUploadType}
                    label="Type"
                    onChange={(evt) => setNewUploadType(evt.target.value)}
                  >
                    {Object.keys(dataTypes).map((key) => (
                      <MenuItem key={key} value={key}>
                        {dataTypes[key as DataType]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth={true} sx={{ mt: 3 }}>
                  <InputLabel id="new-upload-format">Format</InputLabel>
                  <Select
                    id="new-upload-format"
                    labelId="new-upload-format"
                    value={newUploadFormat}
                    label="Format"
                    onChange={(evt) => setNewUploadFormat(evt.target.value)}
                  >
                    {Object.entries(pnlConfig.budget_templates).map(
                      ([name, template]) => (
                        <MenuItem key={template.title} value={name}>
                          {`${template.title}: dates by ${template.dates_by}, format ${template.dates_format}`}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </FormControl>
                <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                  <Button
                    variant="contained"
                    sx={{ my: 3, ml: 2 }}
                    onClick={async () => onUploadFiles()}
                  >
                    Upload
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ my: 3, ml: 2 }}
                    onClick={(evt) => setAttachedFile(null)}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            )}
          </$DropzoneWrapper>
          {budgetsList && budgetsList.length > 0 && (
            <Box pt={3}>
              <Typography
                variant="subtitle1"
                component="div"
                style={{
                  fontSize: '90%',
                  color: '#adadad',
                }}
              >
                Manage your previous versions
              </Typography>
              {budgetsList.length > 0 && (
                <TableContainer component={Box}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {[
                          {
                            key: 'name',
                            sort: true,
                            title: 'Name of Version',
                          },
                          {
                            key: 'user',
                            sort: true,
                            title: 'Sender',
                          },
                          {
                            key: 'date',
                            sort: true,
                            title: 'Date Sent',
                            align: 'center',
                          },
                          {
                            key: 'type',
                            sort: true,
                            title: 'Type',
                            align: 'center',
                          },
                          {
                            key: 'actions',
                            align: 'right',
                          },
                        ].map((cell) => (
                          <$TableCell
                            key={cell.key}
                            align={(cell.align as 'center' | 'right') || 'left'}
                          >
                            {cell.sort ? (
                              <TableSortLabel
                                active={cell.key === uploadsManagerSortBy}
                                direction={uploadsManagerSortDir}
                                onClick={() => sortUploads(cell)}
                              >
                                {cell.title}
                              </TableSortLabel>
                            ) : (
                              cell.title
                            )}
                          </$TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {budgetsList.map((upl, ind) => (
                        <$TableRow key={upl.key}>
                          <$TableCell>
                            {(!defaultFileKeys.includes(upl.key) && (
                              <a
                                href="/"
                                target="_blank"
                                style={{
                                  color: 'var(--color-text)',
                                  textDecoration: 'none',
                                }}
                                onClick={async (evt) => {
                                  const target = evt.target as HTMLAnchorElement
                                  if (target.getAttribute('href') === '/') {
                                    evt.preventDefault()
                                    const loadS3UrlResponse =
                                      await runFetchWithToken<ApiFileLink>(
                                        { getAccessTokenSilently },
                                        endpoints.fileEndpoint(
                                          pnlConfig.budget_folders.uploaded,
                                          upl.file_name,
                                        ),
                                        {},
                                      ).catch((error) => {
                                        // eslint-disable-next-line no-console
                                        console.error(error)
                                      })
                                    if (loadS3UrlResponse) {
                                      target.setAttribute(
                                        'href',
                                        loadS3UrlResponse.link,
                                      )
                                      target.click()
                                      setTimeout(() => {
                                        target.setAttribute('href', '/')
                                      }, 0)
                                    }
                                  }
                                }}
                              >
                                {upl.metadata.Metadata?.title || upl.file_name}
                              </a>
                            )) ||
                              upl.metadata.Metadata?.title ||
                              upl.file_name}
                          </$TableCell>
                          <$TableCellSecondary>
                            {upl.metadata.Metadata?.sender || 'N/A'}
                          </$TableCellSecondary>
                          <$TableCellSecondary align="center">
                            {upl.metadata.LastModified || 'N/A'}
                          </$TableCellSecondary>
                          <$TableCellSecondary align="center">
                            {upl.metadata.Metadata?.type || 'budget'}
                          </$TableCellSecondary>
                          <$TableCellSecondary
                            align="right"
                            sx={{ position: 'relative' }}
                          >
                            {!defaultFileKeys.includes(upl.key) && (
                              <Icon
                                icon="trashcan"
                                color={null}
                                stroke={true}
                                className="remove-icon"
                                onClick={async (evt) => {
                                  await deleteFileFromS3(
                                    upl.key,
                                    '',
                                    getAccessTokenSilently,
                                  ).catch((error) =>
                                    // eslint-disable-next-line no-console
                                    console.error('error deleting', error),
                                  )
                                  await loadBudgets()
                                }}
                              />
                            )}
                          </$TableCellSecondary>
                        </$TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          )}
        </$ManageUploadsContainer>
      </ModalDialog>

      <Box pt={1} pb={2} style={{ textAlign: 'left' }}>
        <$LinkButton
          onClick={async () => {
            setShowUploadsManagerModal(true)
            if (!budgetsList) await loadBudgets()
          }}
        >
          Manage uploads
        </$LinkButton>
      </Box>
    </Stack>
  )
}
