import * as React from 'react'

import Button from '@mui/material/Button'
import { Icon } from 'components/Icon/Icon'
import InsightBadge from 'components/atoms/InsightBadge'
import { InsightModal } from 'components/atoms/InsightModal'
import { Link } from 'react-router-dom'

export default function ArrInsightModal() {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <InsightBadge
        handleClick={handleOpen}
        count={5}
        sx={{
          left: '0',
          transform: 'translate(-57%, 0)',
        }}
      />
      <InsightModal
        open={open}
        title="ARR Churn | Insights"
        handleClose={handleClose}
        width={600}
        height={395}
      >
        <div>
          <Button>
            <Icon icon="notification" style={{ position: 'absolute' }} />
            <div style={{ position: 'absolute', color: 'white' }}>1</div>
          </Button>
          New Logos Sales in UK, segment Large are $243K below plan{' '}
          <Link to="/arr#grid-date-from=2021-04-01&grid-date-to=2022-04-30&grid-selection-cols=Metric%7ERegion%7ESegment%7EApr+2021%7EMay+2021%7EJun+2021%7EJul+2021%7EAug+2021%7ESep+2021%7EOct+2021%7ENov+2021%7EDec+2021%7EJan+2022%7EFeb+2022%7EMar+2022%7EApr+2022+to+date&grid-selection-rows=13&grid-cohorts=metric%7Eregion%7Esegment&grid-cohorts-expanded=metric__New+Logos__0%7Eregion__UK__1">
            see
          </Link>
        </div>
        <div>
          <Button>
            <Icon icon="notification" style={{ position: 'absolute' }} />
            <div style={{ position: 'absolute', color: 'white' }}>2</div>
          </Button>
          New Logos Sales in IL, segment Large are $115K below plan{' '}
          <Link to="/arr#grid-date-from=2021-04-01&grid-date-to=2022-04-30&grid-selection-cols=Jan+2022%7EFeb+2022%7EMar+2022&grid-selection-rows=6&grid-cohorts=metric%7Eregion&grid-cohorts-expanded=metric__New+Logos__0">
            see
          </Link>
        </div>
        <div>
          <Button>
            <Icon icon="notification" style={{ position: 'absolute' }} />
            <div style={{ position: 'absolute', color: 'white' }}>3</div>
          </Button>
          Expansion Sales in UK, segment Mid are $98K below plan{' '}
          <Link to="/arr#grid-date-from=2021-04-01&grid-date-to=2022-04-30&grid-selection-cols=Jan+2022%7EFeb+2022%7EMar+2022&grid-selection-rows=10&grid-cohorts=metric%7Eregion&grid-cohorts-expanded=metric__Expansion__0">
            see
          </Link>
        </div>
        <div>
          <Button>
            <Icon icon="notification" style={{ position: 'absolute' }} />
            <div style={{ position: 'absolute', color: 'white' }}>4</div>
          </Button>
          Lost Logos in IL, segment Large are $72K above plan{' '}
          <Link to="/arr#grid-date-from=2021-04-01&grid-date-to=2022-04-30&grid-selection-cols=Jan+2022%7EFeb+2022%7EMar+2022&grid-selection-rows=8&grid-cohorts=metric%7Eregion&grid-cohorts-expanded=metric__Lost+Logos__0">
            see
          </Link>
        </div>
        <div>
          <Button>
            <Icon icon="notification" style={{ position: 'absolute' }} />
            <div style={{ position: 'absolute', color: 'white' }}>5</div>
          </Button>
          Contraction in Americas, segment Mid are $57K above plan{' '}
          <a href="/arr#grid-date-from=2021-04-01&grid-date-to=2022-04-30&grid-selection-cols=Jan+2022%7EFeb+2022%7EMar+2022&grid-selection-rows=8&grid-cohorts=metric%7Eregion&grid-cohorts-expanded=metric__Contraction__0">
            see
          </a>
        </div>
      </InsightModal>
    </>
  )
}
