import type {
  ApiFileLink,
  PnLActual,
  PnLConfig,
  s3FileDescription,
} from '../api/types'
import { runFetchWithToken, useApi } from '../api/useApi'

import { Alert } from '@mui/material'
import { CenteredCircularProgress } from '../components/atoms/CenteredCircularProgress'
import { DateRange } from '@mui/lab'
import type { Maybe } from 'types'
import { Pnl } from '../components/Pnl/Pnl'
import React from 'react'
import { endpoints } from '../api/endpoints'
import { getFileFromS3 } from '../api/useS3Api'
import styledComp from 'styled-components'
import { useAuth0WithCypress } from '../hooks/useAuth0WithCypress'
import { ConfigContext } from '../context/ConfigContext'

const $OverlayProgressWrap = styledComp.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 10rem;
  height: 8rem;
  margin: auto;
  border-radius: 1rem;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
  background: var(--color-bg-table);
  z-index: 99999;

  > * {
    top: 0;
    bottom: 0;
    right: 50%;
    height: auto;
  }
`

const actualsFileName = 'actuals.csv.gz'

const finCsvParams = { csvNumberColumns: ['sum_accounting'] }

type DataSource = {
  s3file: Maybe<s3FileDescription>
  key: string
  filename: string
}

export const PnlContainer: React.FC = () => {
  const pnlConfigLoad = useApi<PnLConfig>(endpoints.pnl.config)

  const [dataSources, setDataSources] = React.useState<DataSource[]>([])
  const [dataSourcesDefaults, setDataSourcesDefaults] = React.useState<
    DataSource[]
  >([])

  React.useEffect(() => {
    if (pnlConfigLoad?.data?.defaults?.data_sources) {
      const defaultDataSources =
        pnlConfigLoad?.data?.defaults?.data_sources.map((ds) => ({
          s3file: null,
          ...ds,
        }))
      setDataSources(defaultDataSources)
      setDataSourcesDefaults(defaultDataSources)
    }
  }, [pnlConfigLoad?.data])

  const [dateRange, setDateRange] = React.useState<DateRange<Date>>([
    null,
    null,
  ])

  const { config } = React.useContext(ConfigContext)
  const demoMode = config?.features_dict.demo_mode?.enabled

  React.useEffect(() => {
    let startDate =
      pnlConfigLoad.data?.defaults?.start_date ||
      pnlConfigLoad.data?.actuals_min_date ||
      new Date()
    let endDate =
      pnlConfigLoad.data?.defaults?.end_date ||
      pnlConfigLoad.data?.actuals_max_date ||
      new Date()

    if (demoMode) {
      startDate = '2021-01-01'
      endDate = '2021-12-31'
    }
    if (pnlConfigLoad.data)
      setDateRange([new Date(startDate), new Date(endDate)])
  }, [demoMode, pnlConfigLoad.data])

  const { getAccessTokenSilently } = useAuth0WithCypress()
  const [dataLoad, setDataLoad] = React.useState(
    {} as Record<string, PnLActual[]>,
  )
  const [dataLoading, setDataLoading] = React.useState([] as string[]) // used to indicate what was loading

  const [recalculating, setRecalculating] = React.useState(false)

  React.useEffect(() => {
    dataSources.forEach(async (ds) => {
      if (dataLoading.includes(ds.key)) return
      setDataLoading((oldDataLoading) => [...oldDataLoading, ds.key])

      let data = null as Maybe<PnLActual[]>
      if (ds.key === actualsFileName) {
        // load from real backend
        const urlParams = {
          from: dateRange[0]?.toISOString().substr(0, 10) || '',
          to: dateRange[1]?.toISOString().substr(0, 10) || '',
        }
        data = await runFetchWithToken<PnLActual[]>(
          { getAccessTokenSilently },
          endpoints.pnl.actuals,
          {},
          urlParams,
        )
      } else {
        // load from s3
        const loadS3Url = endpoints.fileEndpoint(ds.key)
        const loadS3UrlResponse = await runFetchWithToken<ApiFileLink>(
          { getAccessTokenSilently },
          loadS3Url,
          {},
        )
        const s3url = loadS3UrlResponse?.link
        if (!s3url) return
        data = await getFileFromS3<PnLActual[]>(s3url, {}, finCsvParams)
      }

      // save to state
      if (data) {
        setDataLoad((oldDataLoad) => {
          const newDataLoad = { ...oldDataLoad }
          newDataLoad[ds.key] = data as PnLActual[]
          return newDataLoad
        })
      }
    })
  }, [dataSources, getAccessTokenSilently, dataLoading, dateRange])

  const onSelectBudgets = React.useCallback(
    (files: s3FileDescription[]) => {
      setDataSources(
        files.map((i) => ({
          s3file: i,
          key: i.key,
          filename: i.file_name,
        })),
      )
    },
    [setDataSources],
  )

  function hasLoadingData(): boolean {
    return dataSources.some((ds) => !(ds.key in dataLoad))
  }

  const defaultFileKeys = React.useMemo(
    () => dataSourcesDefaults.map((ds) => ds.key),
    [dataSourcesDefaults],
  )

  const datasrcs = React.useMemo(
    () => dataSources.map((ds) => ds.s3file),
    [dataSources],
  )
  const datasets = React.useMemo(
    () => dataSources.map((ds) => dataLoad[ds.key]),
    [dataSources, dataLoad],
  )

  if (
    pnlConfigLoad.isLoading ||
    !dateRange[0] ||
    !dateRange[1] ||
    hasLoadingData()
  ) {
    return <CenteredCircularProgress message="loading..." />
  }

  if (
    pnlConfigLoad.error ||
    !pnlConfigLoad?.data?.actuals_min_date ||
    !pnlConfigLoad?.data?.actuals_max_date
  ) {
    console.warn('Error while loading pnl config', pnlConfigLoad)
    return <Alert severity="warning">Error getting PnL config</Alert>
  }

  return (
    <>
      {recalculating ? (
        <$OverlayProgressWrap>
          <CenteredCircularProgress onTop={true} message="recalculating..." />
        </$OverlayProgressWrap>
      ) : null}
      <Pnl
        pnlConfig={pnlConfigLoad.data}
        defaultFileKeys={defaultFileKeys}
        datasrcs={datasrcs}
        datasets={datasets}
        dateRange={dateRange}
        setDateRange={setDateRange}
        onSelectBudgets={onSelectBudgets}
        setRecalculating={setRecalculating}
      />
    </>
  )
}
