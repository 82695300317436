import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'

import { AUTH0_SCOPE } from '../utils/const'
import { Auth0Provider } from '@auth0/auth0-react'
import { AuthentificatedContainer } from './AuthentificatedContainer'
import { LocalCacheContext } from '../context/LocalCacheContext'
import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { environment } from '../environment'
import { store } from '../redux/store'
import { theme } from '../utils/theme'
import { useLocalCacheContext } from '../context/useLocalCacheContext'

export const RootContainer: React.FC = ({ children }) => {
  const localCacheCtx = useLocalCacheContext()
  return (
    <StyledEngineProvider injectFirst>
      <ReduxProvider store={store}>
        <ThemeProvider theme={theme}>
          <Auth0Provider
            domain={environment.REACT_APP_AUTH0_DOMAIN}
            clientId={environment.REACT_APP_AUTH0_CLIENTID}
            scope={AUTH0_SCOPE.join(',')}
            audience={environment.REACT_APP_AUTH0_AUDIENCE}
            redirectUri={window?.location.origin}
            organization={environment.REACT_APP_AUTH0_ORGID}
          >
            <LocalCacheContext.Provider value={localCacheCtx}>
              <AuthentificatedContainer>{children}</AuthentificatedContainer>
            </LocalCacheContext.Provider>
          </Auth0Provider>
        </ThemeProvider>
      </ReduxProvider>
    </StyledEngineProvider>
  )
}
