import { Alert, AlertTitle, Box, Container } from '@mui/material'

import { AggregatedTableContext } from '../context/AggregatedTableContext'
import type { AggregationLoadingStatus } from '../workers/utils'
import { CenteredCircularProgress } from '../components/atoms/CenteredCircularProgress'
import React from 'react'

const messagePerStatus: Record<AggregationLoadingStatus, string> = {
  'unknown': '',
  'init': '',
  'cache-validation': 'Validating your data.',
  'loading': 'Loading your data.',
  'calculating': 'Recalculating your data. Do not refresh the page.',
  'done': 'Done!',
}

export const AggregatedTableContextDataLoaderGuard: React.FC = ({
  children,
}) => {
  const ctx = React.useContext(AggregatedTableContext)

  if (ctx.isLoading) {
    return (
      <CenteredCircularProgress message={messagePerStatus[ctx.loadingStatus]} />
    )
  }

  if (
    (!ctx.arrs.length || !ctx.rowData?.length) &&
    ctx.loadingStatus !== 'unknown'
  ) {
    return (
      <Container>
        <Box mt={4}>
          <Alert severity="error">
            <AlertTitle>
              Could not load the response or find the data.
            </AlertTitle>
          </Alert>
        </Box>
      </Container>
    )
  }

  return <>{children}</>
}
