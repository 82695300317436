import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  typography: {
    fontFamily: "'Ubuntu', sans-serif;",
    fontWeightBold: 600,
    h1: {
      margin: '1.5rem 0',
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    button: {
      textTransform: 'none',
    },
    subtitle2: {
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: '#f3622d',
    },
    secondary: {
      main: '#000',
    },
    text: {
      secondary: '#adadad',
    },
    success: {
      main: '#5EBA91',
    },
    error: {
      main: '#EA4A72',
    },
  },
})
