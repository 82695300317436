/**
 Selector is a function that gets some data from the redux store's RootState
 Each selector's name should start on "selector" word
 */

import { Maybe } from '../../../../types'
import { SvGlobalOperationKey } from '../../types'
import type { RootState, State } from './state'
import type { Selector } from 'reselect'
import { createSelector } from '@reduxjs/toolkit'

// unified access to all state of current duck
export const selectAll: Selector<RootState, State> = (s) => s.scenarioVersions

export const selectVersionsConfig: Selector<
  RootState,
  State['versionsConfig']
> = (s) => selectAll(s).versionsConfig

export const selectDocsConfig: Selector<RootState, State['docsConfig']> = (s) =>
  selectAll(s).docsConfig

export const selectVersionsList: Selector<RootState, State['versionsList']> = (
  s,
) => selectAll(s).versionsList

export const selectGlobalOperation: Selector<
  RootState,
  Maybe<State['globalOperations'][SvGlobalOperationKey]>
> = (s, key: SvGlobalOperationKey) => selectAll(s).globalOperations[key] || null

export const selectVersions: Selector<RootState, State['versions']> = (s) =>
  selectAll(s).versions

export const selectDocuments: Selector<RootState, State['documents']> = (s) =>
  selectAll(s).documents

export const selectVersion: Selector<
  RootState,
  Maybe<State['versions'][0]>
> = createSelector(
  [selectVersions, (_, versionId: State['versions'][0]['id']) => versionId],
  (versions, versionId) => versions.find((v) => v.id === versionId) || null,
)

export const selectDocument: Selector<
  RootState,
  Maybe<State['documents'][0]>
> = createSelector(
  [selectDocuments, (_, docId: State['documents'][0]['id']) => docId],
  (docs, docId) => docs.find((d) => d.id === docId) || null,
)
