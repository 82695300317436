import {
  generateColumnThreadId,
  hasCellThread,
} from 'features/threads/utils/prepare-thread-id'

import type { ICellRendererParams } from 'ag-grid-community'
import type { Maybe } from 'types'
import React from 'react'
import { SecondaryTableContext } from 'context/SecondaryTableContext'
import { ThreadRenderer } from '../cell-renderer/ThreadRenderer'
import findKey from 'lodash/findKey'
import { generateColumnPath } from './utils/generate-column-path'
import { usePageThreads } from 'features/threads/hooks/usePageThreads'

export const AmountWithThreadCellRenderer: React.FC<ICellRendererParams> = ({
  valueFormatted,
  colDef,
  node,
}) => {
  const threads = usePageThreads()
  const { aggregationType } = React.useContext(SecondaryTableContext)

  const cellPath = generateColumnPath(node)
  const threadId = generateColumnThreadId(
    colDef?.headerName ?? '',
    String(colDef?.pivotKeys || ''),
    cellPath,
    aggregationType,
  )
  const hasValue = findKey(
    node.aggData,
    (agg: Maybe<string | number>) => agg !== null,
  )

  const isWithThread = hasValue && hasCellThread(threads, threadId)

  if (isWithThread) {
    return <ThreadRenderer>{valueFormatted}</ThreadRenderer>
  }

  return valueFormatted
}
