import type { RootState, State } from './state'

import type { Selector } from 'reselect'
import { makeSelectors as makeRequestSelectors } from '../../../../redux/shared/request/selectors'

export const selectAll: Selector<RootState, State> = (s) => s.threads

export const {
  selectStatus,
  selectIsInitial,
  selectIsError,
  selectIsLoading,
  selectError,
} = makeRequestSelectors(selectAll)

export const selectThreadId: Selector<RootState, State['selectedThreadId']> = (
  s,
) => selectAll(s).selectedThreadId
