import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import React, { useEffect, useState } from 'react'

import { xAxisLabelFormatter } from './chart-utils'

type ComboBarLineChartUnit = {
  name: string
  line1?: number
  line2?: number
  line3?: number
  line4?: number
  bar1?: number
  bar2?: number
  bar3?: number
  bar1neg?: number
  bar2neg?: number
  bar3neg?: number
  range?: number[]
}

type Legend = {
  id: number
  displayName: string
  color: string
  key: string
}

type AreaType = {
  line1: string
  line2: string
  color?: string
}

// type ChartDomain = {
//   high?: number | string
//   low?: number | string
//   lineHigh?: number | string
//   lineLow?: number | string
//   barHigh?: number | string
//   barLow?: number | string
// }

type ChartDomain = {
  high?: number
  low?: number
  lineHigh?: number
  lineLow?: number
  barHigh?: number
  barLow?: number
}
export interface ComboBarLineChartData {
  data: ComboBarLineChartUnit[]
  legend: Legend[]
  // Mapping from ComboBarLineChartUnit keys to wanted tool tip name
  tooltipNameMapper: Record<string, string>
  heightRatio?: number
  widthRatio?: number
  stroke?: string[]
  area?: AreaType[]
  dollars?: boolean[]
  domain?: ChartDomain
}

interface WindowSize {
  width: number
  height: number
}

const ComboBarLineChart: React.FC<ComboBarLineChartData> = ({
  data,
  legend,
  tooltipNameMapper,
  heightRatio = 2.6,
  widthRatio = 2.2,
  stroke = [], // Keys of lines to be stroke
  area = [], // pairs of lines to color area between them
  dollars = [false, false],
  domain = { low: 0, high: 0, barHigh: 0, barLow: 0, lineHigh: 0, lineLow: 0 },
}) => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
  })

  const hasBars =
    data.filter((x) => Object.prototype.hasOwnProperty.call(x, 'bar1')).length >
    0

  const hasBars3 =
    data.filter((x) => Object.prototype.hasOwnProperty.call(x, 'bar3')).length >
    0

  const hasBars4 =
    data.filter((x) => Object.prototype.hasOwnProperty.call(x, 'bar4')).length >
    0
  const hasNeg =
    data.filter((x) => Object.prototype.hasOwnProperty.call(x, 'bar1neg'))
      .length > 0

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const tooltipFormatter = (value: number, name: string) => {
    let formattedValue

    if (value < 0 && (dollars[0] || dollars[1])) {
      formattedValue = `$(${-Math.round(value / 1000)}K)`.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ',',
      )
    } else if (dollars[0] || dollars[1]) {
      formattedValue = `$${Math.round(value / 1000)}K`.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ',',
      )
    }

    return [formattedValue || value, `${tooltipNameMapper[name]}`]
  }

  const yTickFormatter = (value: number) =>
    value >= 0
      ? `$${
          Math.round(value / 1000) - (Math.round(value / 1000) % 10000) > 0
            ? Math.round(value / 1000) - (Math.round(value / 1000) % 10000)
            : Math.round(value / 1000)
        }K`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      : `$(${
          Math.round(value / 1000) - (Math.round(value / 1000) % 10000) > 0
            ? -(Math.round(value / 1000) + (Math.round(value / 1000) % 10000))
            : -Math.round(value / 1000)
        })K`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return (
    // Currently fixed ratio for resizing - later can be overridden or provide different ratio
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        data={data}
        margin={{
          top: 0,
          bottom: -12,
          right: -15,
          left: 10,
        }}
        stackOffset="sign"
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis
          dataKey="name"
          tick={{ fontSize: 10 }}
          interval={0}
          // scale={hasBars ? 'band' : 'point'}
          scale="point"
          domain={['dataMin', 'dataMax + 1']}
        />
        {hasBars ? (
          <>
            <YAxis
              yAxisId="left"
              tick={{ fontSize: 10 }}
              width={55}
              domain={
                !domain.barLow && !domain.barHigh
                  ? undefined
                  : [
                      (dataMin: number) => dataMin - (domain.barLow || 0),
                      (dataMax: number) => dataMax + (domain.barHigh || 0),
                    ]
              }
              tickFormatter={dollars[0] ? yTickFormatter : undefined}
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              width={50}
              axisLine={false}
              tick={{ fontSize: 10 }}
              domain={
                !domain.lineLow || !domain.lineHigh
                  ? undefined
                  : [
                      (dataMin: number) => dataMin - (domain.lineLow || 0),
                      (dataMax: number) => dataMax + (domain.lineHigh || 0),
                    ]
              }
              tickFormatter={dollars[1] ? yTickFormatter : undefined}
            />
          </>
        ) : (
          <YAxis
            yAxisId="left"
            tick={{ fontSize: 10 }}
            domain={
              domain.low === 0 && domain.high === 0
                ? undefined
                : [
                    (dataMin: number) => dataMin - (domain.low || 0),
                    (dataMax: number) => dataMax + (domain.high || 0),
                  ]
            }
            tickFormatter={dollars[0] ? yTickFormatter : undefined}
          />
        )}
        <ReferenceLine yAxisId="left" y={0} stroke="#000" />
        <Tooltip formatter={tooltipFormatter} />
        <Bar
          yAxisId="left"
          stackId="b"
          dataKey="bar1"
          barSize={8}
          fill={legend.find((l) => l.key === 'bar1')?.color || '#FFB400'}
          radius={[4, 4, 0, 0]}
        />
        <Bar
          yAxisId="left"
          stackId="c"
          dataKey="bar2"
          barSize={8}
          fill={legend.find((l) => l.key === 'bar2')?.color || '#D0E7E1'}
          radius={[4, 4, 0, 0]}
        />
        {hasBars3 && (
          <Bar
            yAxisId="left"
            dataKey="bar3"
            stackId="d"
            barSize={8}
            fill={legend.find((l) => l.key === 'bar3')?.color || '#D0E7E1'}
            radius={[4, 4, 0, 0]}
          />
        )}
        {hasBars4 && (
          <Bar
            yAxisId="left"
            dataKey="bar4"
            barSize={8}
            fill={legend.find((l) => l.key === 'bar4')?.color || '#D0E7E1'}
            radius={[4, 4, 0, 0]}
          />
        )}
        {hasNeg && (
          <>
            <Bar
              yAxisId="left"
              stackId="b"
              dataKey="bar1neg"
              barSize={8}
              fill={legend.find((l) => l.key === 'bar1neg')?.color || '#ffdead'}
              radius={[4, 4, 0, 0]}
            />
            <Bar
              yAxisId="left"
              dataKey="bar2neg"
              stackId="c"
              barSize={8}
              fill={legend.find((l) => l.key === 'bar2neg')?.color || '#AFCEEE'}
              radius={[4, 4, 0, 0]}
            />
            <Bar
              yAxisId="left"
              dataKey="bar3neg"
              stackId="d"
              barSize={8}
              fill={legend.find((l) => l.key === 'bar3neg')?.color || '#D0D0D0'}
              radius={[4, 4, 0, 0]}
            />
          </>
        )}
        <Line
          yAxisId={hasBars ? 'right' : 'left'}
          type="monotone"
          dataKey="line1"
          strokeDasharray={stroke.includes('line1') ? '5 5' : ''}
          stroke={legend.find((l) => l.key === 'line1')?.color || '#FF9B25'}
        />
        <Line
          yAxisId={hasBars ? 'right' : 'left'}
          type="monotone"
          dataKey="line2"
          strokeDasharray={stroke.includes('line2') ? '5 5' : ''}
          stroke={legend.find((l) => l.key === 'line2')?.color || '#0063D8'}
        />
        <Line
          yAxisId={hasBars ? 'right' : 'left'}
          type="monotone"
          dataKey="line3"
          strokeDasharray={stroke.includes('line3') ? '5 5' : ''}
          stroke={legend.find((l) => l.key === 'line3')?.color || '#AAAAAA'}
        />
        <Line
          yAxisId={hasBars ? 'right' : 'left'}
          type="monotone"
          dataKey="line4"
          strokeDasharray={stroke.includes('line4') ? '5 5' : ''}
          stroke={
            legend.length > 0
              ? legend.find((l) => l.key === 'line4')?.color
              : '#AAAAAA'
          }
        />
        <defs>
          <linearGradient id="colorArea" gradientTransform="rotate(46)">
            <stop offset="74%" stopColor="#448CE2" stopOpacity={0.6} />
            <stop offset="80%" stopColor="#FF9B25" stopOpacity={0.6} />
          </linearGradient>
        </defs>
        {area.length > 0 && (
          <Area
            yAxisId={hasBars ? 'right' : 'left'}
            type="monotone"
            dataKey="range"
            fill="url(#colorArea)"
            fillOpacity={1}
            stroke="transparent"
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default ComboBarLineChart
