import { Alert, AlertTitle } from '@mui/material'

import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import React from 'react'

export const AccessDeninedAlert: React.FC = () => (
  <Alert
    role="dialog"
    aria-labelledby="access-denied-title"
    aria-describedby="access-denied-text"
    severity="error"
    icon={<HighlightOffIcon fontSize="inherit" />}
  >
    <AlertTitle id="access-denied-title">Access denied</AlertTitle>
    <span id="access-denied-text">
      You don't have access to this page. Please reach out administrator.
    </span>
  </Alert>
)
