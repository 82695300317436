import React from 'react'
import type { Thread } from '../types'
import { useThreads } from '../ducks/threads/hooks'

export function usePageThreads(): Thread[] {
  const { threadsList, selectedPage } = useThreads()

  return React.useMemo(
    () => threadsList.filter((thread) => thread.page_key === selectedPage),
    [threadsList, selectedPage],
  )
}
