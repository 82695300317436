import React, { useState } from 'react'
import {
  Box,
  Typography,
  Select,
  Fade,
  InputAdornment,
  TextField,
  MenuItem,
  Popper,
  Chip,
} from '@mui/material'
import { pageHeadingStyles } from '../styles/page-heading-styles'
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation'
import { DashboardWrapper } from 'components/DashboardWrapper/DashboardWrapper'
import Plot from 'react-plotly.js'
import { theme } from 'utils/theme'
import graph from '../../../assets/EXPANSIONTreemap.json'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { DateRangeSidebarPanel } from '../../headcount/components/HeadcountEmployeeWaterfall/DateRangeSidebarPanel'

const HeaderComponent = () => {
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const canBeOpen = open && Boolean(anchorEl)
  const id = canBeOpen ? 'transition-popper' : undefined
  const history = useHistory()
  const $Select = styled(Select)`
    width: 20px;
    font-size: 20px;
    font-weight: 500;
    margin-right: 80px;
    &:focus {
      background-color: transparent;
    }
  `
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen((previousOpen) => !previousOpen)
  }
  const moveToPage = () => {
    history.push('/new-logos-arr')
  }
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: 1,
        mb: '3px',
        width: '100%',
      }}
    >
      <Box
        display="flex"
        gap={theme.spacing(1.25)}
        sx={{
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h1"
          style={{
            marginRight: '20px',
            ...pageHeadingStyles(),
          }}
        >
          ARR Closing Balance
        </Typography>
        <$Select
          value="New Logos ARR"
          onChange={moveToPage}
          displayEmpty
          disableUnderline
          variant="standard"
        >
          <MenuItem value="ARR Closing Balance">New Logos ARR</MenuItem>
        </$Select>
        <TextField
          id="standard-basic"
          variant="standard"
          defaultValue="Aug 21 - Mar 22"
          onClick={handleClick}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <InsertInvitationIcon />
              </InputAdornment>
            ),
          }}
        />

        <Popper
          placement="bottom"
          open={open}
          id={id}
          anchorEl={anchorEl}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Box sx={{ p: 1, bgcolor: 'background.paper' }}>
                <DateRangeSidebarPanel
                  onChangeRange={() => console.log('lala')}
                  dateFrom="08/01/2021"
                  dateTo="03/01/2022"
                />
              </Box>
            </Fade>
          )}
        </Popper>
      </Box>
      <Box
        style={{ margin: '0 40px' }}
        display="flex"
        gap="10px"
        sx={{
          alignItems: 'center',
        }}
      >
        <Chip label="Expansion" style={{ background: '#a8caff' }} />
        <Chip label="Contraction" style={{ background: '#ff9b2440' }} />
        <Chip label="Stable" style={{ background: '#fff' }} />
      </Box>
    </Box>
  )
}

const TreeMapPage = () => {
  const TextWrapper = styled.text`
    // font-weight: 600 !important;
  `
  return (
    <DashboardWrapper enableThreads>
      <HeaderComponent />
      <TextWrapper>
        <Plot
          // eslint-disable-next-line
          // @ts-ignore
          data={graph.data}
          layout={{
            width: window.innerWidth - 150,
            height: window.innerHeight - 100,
            autosize: true,
            margin: {
              t: 10,
              b: 10,
              l: 10,
              r: 10,
            },
          }}
          config={{ displayModeBar: false }}
        />
      </TextWrapper>
    </DashboardWrapper>
  )
}

export default TreeMapPage
