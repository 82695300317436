import {
  Box,
  FormControlLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'

import { AggregatedTableContext } from '../../../context/AggregatedTableContext'
import { ArrProcessingFormula } from '../../../api/types'
import React from 'react'
import type { SelectProps } from '@mui/material/Select'

export const SelectedArrPanel: React.FC = () => {
  const { selectedArr, arrs, setSelectedArr } = React.useContext(
    AggregatedTableContext,
  )

  const handleChange: SelectProps['onChange'] = React.useCallback(
    (event) => {
      setSelectedArr(event.target.value)
    },
    [setSelectedArr],
  )

  const renderList = arrs.length
    ? arrs
    : ([
        {
          display_name: selectedArr,
          field: selectedArr,
        },
      ] as ArrProcessingFormula[])

  if (renderList.length === 1) {
    return (
      <Box pb={1} pt={1} height="100%" display="flex" alignItems="center">
        <Typography variant="caption">
          Selected ARR: <b>{renderList[0].display_name}</b>
        </Typography>
      </Box>
    )
  }

  return (
    <Box pb={1} pt={1}>
      <FormControlLabel
        labelPlacement="start"
        control={
          <Box ml={1}>
            <Select
              labelId="selected-arr"
              value={selectedArr || arrs?.[0]?.field || ''}
              onChange={handleChange}
              disabled={!arrs.length}
              variant="standard"
            >
              {renderList.map((item) => (
                <MenuItem dense value={item.field} key={item.field}>
                  {item.display_name || 'Loading...'}
                </MenuItem>
              ))}
            </Select>
          </Box>
        }
        label={<Typography variant="caption">Selected ARR:</Typography>}
      />
    </Box>
  )
}
