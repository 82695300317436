import type {
  CellClickedEvent,
  ColumnState,
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  GridColumnsChangedEvent,
  MenuItemDef,
} from 'ag-grid-community'
import {
  deserializeGridColumns,
  serializeGridColumns,
  useUrlHashState,
} from '../../hooks/useUrlHashState'
import {
  generateRowThreadId,
  serializeRowThreadId,
  hasCellThread,
} from 'features/threads/utils/prepare-thread-id'

import { AgGridReact } from 'ag-grid-react'
import { Alert } from '@mui/material'
import { ConfigContext } from '../../context/ConfigContext'
import type { Maybe } from '../../types'
import React from 'react'
import { SecondaryTableContext } from '../../context/SecondaryTableContext'
import { SecondaryTableRow } from '../../types'
import flattenChildren from 'react-keyed-flatten-children'
import { useIsFeatureEnabled } from 'redux/ducks/config/hooks'
import { usePageThreads } from 'features/threads/hooks/usePageThreads'
import { useSecondaryTableColumns } from './hooks/useSecondaryTableColumns'
import { useSelectedThreadState } from 'features/threads/ducks/threads/hooks'

export const SecondaryTable: React.FC = () => {
  const { rowData, secondaryGridRef, filter, aggregationType, headerText } =
    React.useContext(SecondaryTableContext)
  const { properties } = React.useContext(ConfigContext)
  const columns = useSecondaryTableColumns()

  const isGridLoaded = React.useRef(false)
  const [urlSecondaryColumns, setUrlSecondaryColumns] = useUrlHashState<
    Maybe<ColumnState[]>,
    string[]
  >('grid-secondary-columns', {
    serializer: serializeGridColumns,
    deserializer: deserializeGridColumns,
  })

  const [selectedThreadId, setThreadId] = useSelectedThreadState()
  const threads = usePageThreads()
  const { rowDataId, rowNodeId } = serializeRowThreadId(
    selectedThreadId as string,
  )

  const mergedData: SecondaryTableRow[] = React.useMemo(() => {
    if (!properties) {
      return []
    }
    return rowData.map((item) => ({
      ...item,
      ...(properties?.[item.id] || {}),
    }))
  }, [rowData, properties])

  // Sync filter with the React state `filter`
  React.useEffect(() => {
    const apply = () => {
      if (filter && secondaryGridRef?.current?.api?.setFilterModel) {
        secondaryGridRef?.current?.api?.setFilterModel(filter)
        return true
      }

      return false
    }

    apply()

    // Need to re-apply filter for the case when table was not really mounted
    const REAPPLY_TIMEOUT = 100
    const timer = setTimeout(apply, REAPPLY_TIMEOUT)

    return () => timer && clearTimeout(timer)
  }, [filter, secondaryGridRef, mergedData])

  const handleColumnsChanged = React.useCallback(
    (evt: GridColumnsChangedEvent) => {
      if (isGridLoaded.current) {
        const columnsState = evt.columnApi.getColumnState()
        setUrlSecondaryColumns(columnsState)
      }
    },
    [setUrlSecondaryColumns],
  )

  const handleGridLoaded = React.useCallback(() => {
    isGridLoaded.current = true
    if (urlSecondaryColumns && urlSecondaryColumns.length) {
      const state = secondaryGridRef?.current?.columnApi
        ?.getColumnState()
        .map((i) => ({
          ...i,
          hide: !!i.colId && !urlSecondaryColumns.includes(i.colId),
        }))
      secondaryGridRef?.current?.columnApi?.applyColumnState({
        state,
      })
      const row = secondaryGridRef?.current?.api.getRowNode(rowNodeId)
      secondaryGridRef?.current?.api.ensureIndexVisible(row?.rowIndex, 'middle')
    }
  }, [rowNodeId, secondaryGridRef, urlSecondaryColumns])

  // Disable `rowGroup` in header menu
  const getMainMenuItems = React.useCallback(
    (params: GetMainMenuItemsParams) =>
      params.defaultItems.filter((i) => i !== 'rowGroup'),
    [],
  )

  const hasAnyProperties = React.useMemo(
    () => Object.keys(properties || {}).length > 0,
    [properties],
  )

  const isDiscusssionsFeatureEnabled = useIsFeatureEnabled(
    'collaboration_threads',
  )

  const handleThreadsStart = React.useCallback(
    ({ node, column, api }: GetContextMenuItemsParams) => {
      const rowIndex = node?.rowIndex
      const isValid = node?.data?.id && node?.data?.account
      if (!node || !column || typeof rowIndex !== 'number' || !isValid) return

      const rowId = `${node.data.id}/${node.id}/${node.data.account}`
      const threadId = generateRowThreadId(
        rowId,
        headerText.default,
        aggregationType,
      )
      setThreadId(threadId)
      api.redrawRows()
    },
    [aggregationType, headerText.default, setThreadId],
  )

  const getContextMenuItems = React.useCallback(
    (params: GetContextMenuItemsParams) => {
      const menuItems: (string | MenuItemDef)[] = [
        'copy',
        'copyWithHeaders',
        'paste',
        'separator',
        'export',
      ]

      const isValid = params.node?.data?.id && params.node?.data?.account
      if (isValid && isDiscusssionsFeatureEnabled) {
        const rowId = `${params.node?.data?.id}/${params.node?.id}/${params.node?.data?.account}`
        const threadId = generateRowThreadId(
          rowId,
          headerText.default,
          aggregationType,
        )
        const isWithThread = hasCellThread(threads, threadId)

        menuItems.unshift(
          {
            name: `${isWithThread ? 'Open' : 'Start'} discussion`,
            action: () => handleThreadsStart(params),
          },
          'separator',
        )
      }

      return menuItems
    },
    [
      aggregationType,
      handleThreadsStart,
      headerText.default,
      threads,
      isDiscusssionsFeatureEnabled,
    ],
  )

  const handleCellClick = React.useCallback(
    (event: CellClickedEvent) => {
      const rowNode = event.node
      const isValid = rowNode.data?.id && rowNode.data?.account
      if (selectedThreadId && isValid) {
        const rowId = `${rowNode.data.id}/${rowNode.id}/${rowNode.data.account}`
        const threadId = generateRowThreadId(
          rowId,
          headerText.default,
          aggregationType,
        )
        setThreadId(threadId)
        event.api.redrawRows()
      }
    },
    [aggregationType, headerText.default, selectedThreadId, setThreadId],
  )

  if (!hasAnyProperties) {
    return <Alert severity="error">Could not find account properties</Alert>
  }

  if (!mergedData.length) {
    return null
  }

  return (
    <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
      <AgGridReact
        getContextMenuItems={getContextMenuItems}
        ref={secondaryGridRef}
        rowData={mergedData}
        showOpenedGroup
        masterDetail
        defaultColDef={{
          sortable: true,
          resizable: true,
          hide: false,
          suppressMovable: true,
          menuTabs: ['generalMenuTab'],
        }}
        rowClassRules={{
          'ag-row-selected': (props) => {
            const isRowIdSelected = rowDataId === props.data.id
            return isRowIdSelected
          },
        }}
        sideBar={{
          toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Fields',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
              toolPanelParams: {
                suppressPivotMode: true,
                suppressSideButtons: true,
                suppressValues: true,
                suppressPivots: true,
                suppressRowGroups: true,
              },
            },
          ],
          defaultToolPanel: 'columns',
        }}
        onCellClicked={handleCellClick}
        getMainMenuItems={getMainMenuItems}
        onFirstDataRendered={handleGridLoaded}
        onGridColumnsChanged={handleColumnsChanged}
        reactUi
      >
        {flattenChildren(columns)}
      </AgGridReact>
    </div>
  )
}
