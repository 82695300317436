import type { Metric } from '../../workers/aggregate-bridge.worker'

export const COUNT_LOGOS_VIEW_DISABLED_METRICS: Metric[] = [
  // 'Net Churn',
  // 'Growth',
]

export const ALL_METRICS_ORDERED: Metric[] = [
  'Opening Balance',
  'Booking',
  'New Logos',
  'Expansion',
  'Total Churn',
  'Lost Logos',
  'Contraction',
  'Net Churn',
  'Growth',
  'Closing Balance',
]
