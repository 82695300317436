import type {
  ApiAccountsArrResponse,
  ApiAccountsPropertiesResponse,
  ApiCollabCommentsResponse,
  ApiCollabReviewsResponse,
  ApiCustomersArrResponse,
  ApiCustomersUpdatedAt,
  ApiFileLink,
  ApiUsersInfoResponse,
} from './types'

import { useApi } from './useApi'
import type { AsyncState } from 'react-async'
import type { Maybe } from '../types'
import { endpoints } from './endpoints'

export const useUserInfo = (): AsyncState<Maybe<ApiUsersInfoResponse>> =>
  useApi<ApiUsersInfoResponse>(endpoints.users.info)

export const useAccountsArr = (): AsyncState<Maybe<ApiAccountsArrResponse>> =>
  useApi<ApiAccountsArrResponse>(endpoints.accounts.arrs())

export const useCustomersList = (): AsyncState<
  Maybe<ApiCustomersArrResponse>
> => useApi<ApiCustomersArrResponse>(endpoints.customers.list)

export const useLastUpdatedAt = (): AsyncState<Maybe<ApiCustomersUpdatedAt>> =>
  useApi<ApiCustomersUpdatedAt>(endpoints.customers.updated_at)

export const useAccountProperties = (): AsyncState<
  Maybe<ApiAccountsPropertiesResponse>
> => useApi<ApiAccountsPropertiesResponse>(endpoints.accounts.properties)

export const useFileUrl = (
  folder: string,
  fileName: string,
): AsyncState<Maybe<ApiFileLink>> =>
  useApi<ApiFileLink>(endpoints.fileEndpoint(folder, fileName))

export const useCollabReviews = (
  options?: RequestInit,
): AsyncState<Maybe<ApiCollabReviewsResponse>> =>
  useApi<ApiCollabReviewsResponse>(endpoints.collaboration.reviews, options)

export const useCollabComments = (
  options?: RequestInit,
): AsyncState<Maybe<ApiCollabCommentsResponse>> =>
  useApi<ApiCollabCommentsResponse>(endpoints.collaboration.comments, options)
