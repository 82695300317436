import {
  InsightGroupCellRenderer,
  getInsightfulCellRenderer,
} from './components/InsightfulCellRenderer'

import dayjs from 'dayjs'

export const pivotTableColumns = (
  dimensions: any[],
  monthForecastColumnMap: Record<string, Set<string>>,
  insightValue: Record<string, any>,
  insightContent: Record<string, any>[],
  handleInsightClick: () => void,
  handleInsightSuggestionClick: () => void,
) => {
  const months = Object.keys(monthForecastColumnMap)

  const monthColumn = (month: string) => {
    const forecasts = Array.from(monthForecastColumnMap[month])
    return {
      headerName: dayjs(month).set('year', 2022).format('MMM YYYY'), // todo remove ".set('year', 2022)"
      children: forecasts.map((f: string) => {
        const defaultWidth =
          f === 'September 22 Forecast' || f === 'October 22 Forecast'
            ? 145
            : 110
        return {
          key: f,
          headerName: f,
          field: month + f,
          width: f === 'Diff' ? 50 : defaultWidth,
          cellRendererFramework: getInsightfulCellRenderer(
            insightValue,
            insightContent,
            handleInsightSuggestionClick,
          ),
          editable: true,
        }
      }),
    }
  }

  const dimensionFields = dimensions.map((dim: string) => ({
    field: dim,
    pinned: true,
    width: 80,
    cellRendererFramework: InsightGroupCellRenderer(
      insightValue,
      handleInsightClick,
    ),
  }))
  console.log(dimensionFields)

  return [...dimensionFields, ...months.map(monthColumn)]
}

export const preparePivotData = (
  rowData: Record<string, any>[],
  dimensions: string[],
  monthColumnName: string,
  forecastColumnName: string,
  valueColumnName: string,
) => {
  const monthForecastColumnMap: Record<string, Set<string>> = {}
  const dimensionKey = (row: Record<string, any>) =>
    dimensions.reduce(
      (key: string, dim: string) =>
        dim in row && row[dim] ? key + row[dim] : key,
      '',
    )

  const dateForecastKey = (row: Record<string, any>) =>
    row[monthColumnName] + row[forecastColumnName]

  const pivoted = (() => {
    const dateForecastMap: Record<string, any[]> = {}
    rowData.forEach((row: any) => {
      const key = dimensionKey(row)
      if (!(row[monthColumnName] in monthForecastColumnMap))
        monthForecastColumnMap[row[monthColumnName]] = new Set()

      monthForecastColumnMap[row[monthColumnName]].add(row[forecastColumnName])

      if (!(key in dateForecastMap)) dateForecastMap[key] = []
      dateForecastMap[key].push(row)
    })
    const result: Record<string, any>[] = []
    Object.entries(dateForecastMap).forEach((value: [string, any[]]) => {
      const rows = value[1]
      const resultObj: Record<string, any> = rows[0]
      rows.forEach((row: any) => {
        resultObj[dateForecastKey(row)] = row[valueColumnName]
      })
      result.push(resultObj)
    })

    return result
  })()

  const months = Object.keys(monthForecastColumnMap)

  pivoted.forEach((row: Record<string, any>) => {
    months.forEach((month: string) => {
      const forecasts = Array.from(monthForecastColumnMap[month])

      if (forecasts.length === 2) {
        row[`${month}diff`] =
          row[month + forecasts[0]] - row[month + forecasts[1]]
        monthForecastColumnMap[month].add('diff')
      }
    })
  })

  return pivoted
}
