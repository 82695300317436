import type { AggregatedDataComparator } from '../workers/utils'
import { sortByMetric } from '../workers/utils'
import { AggregatedTableContext } from '../context/AggregatedTableContext'
import { AggregatedTableContextDataLoaderGuard } from './AggregatedTableContextDataLoaderGuard'
import type { ArrItemWithAggregation } from '../workers/kpi/types'
import type { ArrItemWithMetric } from '../workers/aggregate-bridge.worker'
import { BridgeTable } from '../components/BridgeTable/BridgeTable'
import type { EndpointLeaf } from '../api/endpoints'
import React from 'react'
import { getDefaultRange } from '../utils/date-utils'
import { useAggregatedTableContext } from '../context/useAggregatedTableContext'

const endpointLeaf: EndpointLeaf = ['accounts', 'arrs']
export const ArrBridgeContainer: React.FC = () => {
  const ctx = useAggregatedTableContext(
    {
      defaultRange: getDefaultRange('bridge'),
      key: 'bridge',
      comparator: sortByMetric as AggregatedDataComparator<
        ArrItemWithAggregation | ArrItemWithMetric
      >,
      defaultCellRangeSelection: 'lastFullMonthColumn',
    },
    {
      bridgeWaterfall: true,
    },
  )

  // console.log('useAggregatedTableContext', ctx)

  return (
    <AggregatedTableContext.Provider value={ctx}>
      <AggregatedTableContextDataLoaderGuard>
        <BridgeTable />
      </AggregatedTableContextDataLoaderGuard>
    </AggregatedTableContext.Provider>
  )
}
