import { Alert, Box, LinearProgress, List, Typography } from '@mui/material'
import { CommentForm, FormInput } from './CommentForm'
import { Mention, PAGE_TITLE, ThreadComment } from '../types'
import {
  useCreateThread,
  useSelectedThreadState,
  useThreadEvents,
  useThreads,
} from '../ducks/threads/hooks'

import { CommentBox } from './CommentBox'
import type { Maybe } from '../../../types'
import React from 'react'
import { ResolveThreadModal } from './ResolveThreadModal'
import { ThreadHeader } from './ThreadHeader'
import { getThreadDescription } from '../utils/prepare-thread-id'
import { useIsFeatureEnabled } from '../../../redux/ducks/config/hooks'
import { theme } from 'utils/theme'

export const ThreadsBar: React.FC = () => {
  const isEnabled = useIsFeatureEnabled('collaboration_threads')
  const [selectedThreadId, setSelectedThreadId] = useSelectedThreadState()
  const {
    threadsList,
    selectedThreadEvents,
    selectedPage,
    error,
    status,
    userList,
  } = useThreads()
  const { loadThreadEvents, postThreadEvent } = useThreadEvents()
  const { createThread } = useCreateThread()

  const [showResolveThreadModal, setShowResolveThreadModal] =
    React.useState(false)
  const [mentions, setMentions] = React.useState<Maybe<Mention[]>>(null)

  const existingThread = React.useMemo(
    () => threadsList.find((thread) => thread.object_key === selectedThreadId),
    [threadsList, selectedThreadId],
  )

  React.useEffect(() => {
    const threadId = existingThread?.id
    if (threadId) {
      loadThreadEvents(threadId)
    }
  }, [existingThread, loadThreadEvents])

  const comments = React.useMemo<ThreadComment[]>(
    () =>
      existingThread
        ? selectedThreadEvents
            ?.filter((event) => event.type === 'comment')
            ?.sort((a, b) => a.timestamp - b.timestamp)
            ?.map((event) => ({
              id: event.id,
              authorName:
                userList.find((user) => user.id === +event.author_id)?.name ??
                '',
              date: new Date(event.timestamp * 1000),
              body: event.body,
            })) ?? []
        : [],
    [selectedThreadEvents, userList, existingThread],
  )

  const handleCommentSubmit = React.useCallback(
    (data: FormInput) => {
      if (!selectedPage || !selectedThreadId) return

      const mentionData = mentions?.length
        ? mentions.filter((mention) => data.comment.includes(mention.display))
        : undefined

      const pageTitle = PAGE_TITLE[selectedPage]

      if (!existingThread) {
        createThread(
          selectedPage,
          selectedThreadId,
          data.comment,
          pageTitle,
          mentionData,
        )

        setMentions(null)

        return
      }

      postThreadEvent(existingThread.id, 'comment', data.comment, mentionData)
      setMentions(null)
    },
    [
      createThread,
      postThreadEvent,
      existingThread,
      selectedPage,
      selectedThreadId,
      mentions,
      setMentions,
    ],
  )

  const handleMention = React.useCallback(
    (id: string | number, display: string) => {
      setMentions([
        ...(mentions ?? []),
        {
          id: `${id}`,
          display,
        },
      ])
    },
    [setMentions, mentions],
  )

  const handleCloseModal = React.useCallback(
    () => setShowResolveThreadModal(false),
    [],
  )

  const handleResoleThread = React.useCallback(() => {
    if (!existingThread) return
    postThreadEvent(existingThread.id, 'resolve')
    handleCloseModal()
  }, [existingThread, postThreadEvent, handleCloseModal])

  if (!selectedThreadId || !isEnabled) return null

  const isLoading = status === 'loading'
  const isResolved = existingThread?.is_resolved
  const showComments = Boolean(comments.length)
  const title = getThreadDescription(selectedThreadId)

  return (
    <>
      <ResolveThreadModal
        open={showResolveThreadModal}
        onClose={handleCloseModal}
        onResolve={handleResoleThread}
      />
      <Box
        sx={{
          width: '300px',
          height: '100vh',
          overflowY: 'scroll',
        }}
      >
        {isLoading && !showComments && <LinearProgress />}
        {error && <Alert severity="error">{error}</Alert>}
        {!isLoading && (
          <Box
            sx={{
              m: 1,
              p: 1,
              border: '1px solid #D3D3D3',
              backgroundColor: '#FFF',
              position: 'relative',
            }}
          >
            <ThreadHeader
              title={title}
              showComments={showComments}
              onResolveThread={() => setShowResolveThreadModal(true)}
              onCloseThread={() => setSelectedThreadId(null)}
              showResolve={!isResolved}
            />
            {showComments && (
              <List>
                {comments.map((comment) => (
                  <CommentBox key={comment.id} comment={comment} />
                ))}
              </List>
            )}
            {isResolved && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                pb={2}
                pt={1}
              >
                <Typography
                  variant="caption"
                  color={theme.palette.grey[700]}
                  fontWeight={500}
                  fontStyle="italic"
                >
                  Marked as resolved
                </Typography>
              </Box>
            )}
            <CommentForm
              onSubmit={handleCommentSubmit}
              onAdd={handleMention}
              onCancel={setSelectedThreadId}
            />
          </Box>
        )}
      </Box>
    </>
  )
}
