import React, { useCallback } from 'react'

import { AgGridReact } from 'ag-grid-react'
import { Box } from '@mui/material'
import type { ColDef } from 'ag-grid-community'
import { currencyFormatter } from '../utils/currencyFormatter'
import { grey } from '@mui/material/colors'
import { makeStyles } from '@mui/styles'
import { theme } from 'utils/theme'

interface DashboardCashFlowTableProps {
  rowData: any[]
}

const useStyles = makeStyles(() => ({
  ...(() => {
    console.log('123', theme)
    return {}
  })(),
  actual: {
    color: 'inherit',
  },
  budget: {
    color: 'inherit',
  },
  diffPositive: {
    color: theme.palette.success.main,
  },
  diffNegative: {
    color: theme.palette.error.main,
  },
  nocolor: {},
  basicCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRightColor: `${grey[200]} !important`,
    fontSize: '11px',
  },
  textAlignLeft: {
    'justifyContent': 'flex-start',
    'paddingLeft': '5px !important',
    'textAlign': 'left',
    '& .ag-header-cell-label': {
      justifyContent: 'flex-start !important',
    },
  },
  basicHeader: {
    'borderLeft': `1px solid ${grey[200]} !important`,
    'marginLeft': '-1px',
    'width': '110px !important',
    '& .ag-header-cell-label': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}))

export default function DashboardCashFlowTable({
  rowData,
}: DashboardCashFlowTableProps) {
  const classes = useStyles()
  const columnDefs: ColDef[] = [
    {
      field: 'Groups',
      headerName: '',
      cellClass: [classes.basicCell, classes.textAlignLeft],
      headerClass: [classes.basicHeader, classes.textAlignLeft],
    },
    {
      field: 'Actual',
      headerName: 'Q3 QTD Actual',
      cellClass: [classes.actual, classes.basicCell],
      headerClass: classes.basicHeader,
      valueFormatter: currencyFormatter,
    },
    {
      field: 'Mar 22 forecast',
      headerName: 'Q3 QTD Plan',
      cellClass: [classes.budget, classes.basicCell],
      headerClass: classes.basicHeader,
      valueFormatter: currencyFormatter,
    },
    {
      field: 'Plan',
      headerName: 'Plan',
      cellClass: [classes.basicCell, classes.budget],
      headerClass: classes.basicHeader,
      valueFormatter: currencyFormatter,
    },
  ]

  const onGridReady = useCallback((params) => {
    params.api.sizeColumnsToFit()
    window.addEventListener('resize', function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit()
      })
    })
  }, [])

  const getRowStyle = () => ({
    borderBottom: '1px solid lightgrey',
    fontWeight: 'unset',
  })

  return (
    <Box
      className="ag-theme-alpine"
      sx={{
        height: '100%',
        width: '100%',
      }}
    >
      <AgGridReact
        processSecondaryColDef={(colGroupDef) => {
          if (!colGroupDef.pivotKeys) return
          colGroupDef.headerName = colGroupDef.pivotKeys.join(' ')
        }}
        columnDefs={columnDefs}
        onGridReady={onGridReady}
        rowData={rowData}
        rowHeight={34}
        headerHeight={40}
        getRowStyle={getRowStyle}
      />
    </Box>
  )
}
