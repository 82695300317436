import { Alert, AlertTitle } from '@mui/material'
import { useConfig, useLoadConfig } from '../redux/ducks/config/hooks'

import { ConfigContext } from '../context/ConfigContext'
import React from 'react'
import { useLoadUserList } from 'features/threads/ducks/threads/hooks'
import { useLogrocketIdentifyEffect } from '../hooks/useLogrocketIdentifyEffect'
import { usePerceivedNowDate } from '../hooks/usePerceivedNowDate'
import { useReportGroups } from 'features/generic-report/ducks/generic-report/hooks/useReportGroups'

export const AuthentificatedContainer: React.FC = ({ children }) => {
  useLoadConfig()
  useLoadUserList()
  const { error, config, userInfo, properties } = useConfig()
  useReportGroups()

  usePerceivedNowDate()
  useLogrocketIdentifyEffect()

  if (error) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        Could not fetch initial config. Please try again later.
      </Alert>
    )
  }

  return (
    <ConfigContext.Provider
      value={{
        config: config || undefined,
        userInfo: userInfo || {},
        properties,
      }}
    >
      {children}
    </ConfigContext.Provider>
  )
}
