import {
  Box,
  ClickAwayListener,
  Drawer,
  Tooltip,
  Typography,
} from '@mui/material'

import { AnalyticsToolPanel } from './AnalyticsToolPanel'
import { NavigationDrawerItems } from './NavigationDrawerItems'
import { NavigationDrawerTenantLogo } from './NavigationDrawerTenantLogo'
import React from 'react'
import clsx from 'clsx'
import { styled } from '@mui/material/styles'
import { useAuth0WithCypress } from '../../hooks/useAuth0WithCypress'
import { useMixpanel } from '../../utils/mixpanel-utils'

const PREFIX = 'NavigationDrawer'

const classes = {
  drawer: `${PREFIX}-drawer`,
  drawerOpen: `${PREFIX}-drawerOpen`,
  drawerClose: `${PREFIX}-drawerClose`,
  toolbar: `${PREFIX}-toolbar`,
  toolbarLogo: `${PREFIX}-toolbarLogo`,
  toolbarLogoOpen: `${PREFIX}-toolbarLogoOpen`,
  tenantLogo: `${PREFIX}-tenantLogo`,
  toolpanel: `${PREFIX}-toolpanel`,
  toolpanelItem: `${PREFIX}-toolpanelItem`,
}

const wrapperWidth = '7rem'
const drawerWidth = '12rem'
const drawerClosed = '3.5rem'

const NawigationDrawerStylesContainer = styled('div')(({ theme }) => ({
  [`& .${classes.toolpanel}`]: {
    top: '330px',
    left: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    opacity: '0.4',
  },
  [`& .${classes.toolpanelItem}`]: {
    marginBottom: '30px',
    cursor: 'pointer',
  },

  [`& .${classes.drawer}`]: {
    top: 0,
    left: 0,
    width: drawerClosed,
    height: '100%',
    flexShrink: 0,
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    boxShadow: '0 0 36px 0 rgba(230, 224, 217, 0.61)',
    [theme.breakpoints.up('sm')]: {
      top: '1.5rem',
      left: '1.5rem',
      height: 'auto',
      borderRadius: '16px',
    },
  },

  [`& .${classes.drawerOpen}`]: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  [`& .${classes.drawerClose}`]: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  [`& .${classes.toolbar}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1.5rem 0 1rem',
  },

  [`& .${classes.toolbarLogo}`]: {
    width: '1.25rem',
    overflow: 'hidden',
    cursor: 'pointer',
  },

  [`& .${classes.toolbarLogoOpen}`]: {
    width: 'auto',
  },

  [`& .${classes.tenantLogo}`]: {
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}))

const $UserpicWrap = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0 0;
`

const $Userpic = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  color: #ffffff;
  background: #4275e2;
`

const $DraweWrapper = styled('div')`
  width: ${wrapperWidth};
`

export const NavigationDrawer: React.FC = () => {
  const { user, logout } = useAuth0WithCypress()
  const { reset } = useMixpanel()

  const [open, setOpen] = React.useState(false)

  const toggleDrawer = React.useCallback(() => {
    setOpen((prev) => !prev)
  }, [setOpen])

  const closeDrawer = React.useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const wrapTooltip = React.useCallback(
    (
      tooltip: string | React.ReactElement,
      content: React.ReactElement,
    ): React.ReactElement =>
      open ? (
        content
      ) : (
        <Tooltip
          title={<Typography variant="body1">{tooltip}</Typography>}
          placement="right"
          arrow
        >
          {content}
        </Tooltip>
      ),
    [open],
  )

  const handleLogout = React.useCallback(() => {
    logout({
      returnTo: window?.location.origin,
    })
    reset()
  }, [logout, reset])

  return (
    <ClickAwayListener onClickAway={closeDrawer}>
      <NawigationDrawerStylesContainer>
        <$DraweWrapper>
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            {user && (
              <$UserpicWrap>
                {wrapTooltip(
                  (
                    <Typography
                      variant="body1"
                      className="cursor-pointer underline-on-hover"
                      onClick={handleLogout}
                    >
                      Logout
                    </Typography>
                  ) || 'Account',
                  <$Userpic>
                    {`${(user?.given_name || ' ')[0]}${
                      (user?.family_name || ' ')[0]
                    }`.trim() ||
                      (user?.email && user?.email[0]) ||
                      'N/A'}
                  </$Userpic>,
                )}
              </$UserpicWrap>
            )}
            <div className={classes.toolbar}>
              <Box
                className={clsx(classes.toolbarLogo, {
                  [classes.toolbarLogoOpen]: open,
                })}
                onClick={toggleDrawer}
              >
                <img src="/logo.png" alt="Fintastic" />
              </Box>
            </div>
            {open && (
              <Box
                pl={2}
                textAlign="center"
                className={classes.tenantLogo}
                style={{ opacity: open ? 1 : 0 }}
              >
                <NavigationDrawerTenantLogo />
              </Box>
            )}
            <NavigationDrawerItems open={open} />
          </Drawer>
          <AnalyticsToolPanel />
        </$DraweWrapper>
      </NawigationDrawerStylesContainer>
    </ClickAwayListener>
  )
}
