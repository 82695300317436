import { AgGridColumn, AgGridColumnProps } from 'ag-grid-react'

import type { Cohort } from '../../../api/types'
import { ConfigContext } from '../../../context/ConfigContext'
import React from 'react'
import { ValueFormatterParams, ValueGetterParams } from 'ag-grid-community'
import capitalize from 'lodash/capitalize'
import { eligibilityFormatter } from '../../field-formatter/eligibility-formatter'
import invert from 'lodash/invert'
import { regionCellRenderer } from '../../cell-renderer/region-cell-renderer'

// import propertiesLocal from '../../../demo-data/properties_with_names.json'
// import arrData from '../../../demo-data/arrData.json'

const tierToWeightMap = {
  Enterprise: 1,
  Ent: 1,
  Large: 2,
  Medium: 3,
  Mid: 3,
  Small: 4,
  Micro: 5,
} as Record<string, number>

const weightToTierMap = invert(tierToWeightMap)

const defaultColumnDefinitons: Record<string, Partial<AgGridColumnProps>> = {
  account: {
    filter: 'agTextColumnFilter',
    valueFormatter: eligibilityFormatter,
  },
  region: {
    cellRenderer: regionCellRenderer,
  },
  tier: {
    cellRenderer: (params: CellRendererBasicParam) =>
      weightToTierMap[params.value] || 'N/A',
  },
}

type HeaderNameGetter = (field: string, defaultValue?: string) => string
const defaultGetHeaderName: HeaderNameGetter = (field: string) =>
  capitalize(field)

type ValueFormatterFabric = (
  field: string,
) => ((params: ValueFormatterParams) => string) | undefined

/**
 * Builds a function that maps Cohort to <AgGridColumn />
 * Add set of hard-coded properties to adjust behaviour of column according to it's name
 * Like, render a flag for the region etc
 */
export const useGetCohortRenderer = (
  getHeaderName?: HeaderNameGetter,
  makeValueFormatter?: ValueFormatterFabric,
): ((
  cohort: Cohort,
  colDef?: Partial<AgGridColumnProps>,
) => React.ReactElement) => {
  const { properties } = React.useContext(ConfigContext)

  // const usedIds: string[] = []
  // const newArrData: any[] = []
  // const all = arrData.length
  // let current = 0
  // arrData.forEach((arrEntry) => {
  //   let propertiesValue = {} as Record<string, any>
  //   const goodValues = propertiesLocal.filter(
  //     (value) =>
  //       !usedIds.includes(value.id) &&
  //       value.Region === arrEntry.Region &&
  //       value.Segment === arrEntry.Segment,
  //   )
  //   if (!goodValues || !goodValues.length) {
  //     const usedValues = propertiesLocal.filter(
  //       (value) =>
  //         value.Region === arrEntry.Region &&
  //         value.Segment === arrEntry.Segment,
  //     )
  //     if (!usedValues || !usedValues.length) {
  //       console.log('no values for arrEntry', arrEntry)
  //       newArrData.push(arrEntry)
  //     } else {
  //       const randIndex = Math.min(
  //         usedValues.length - 1,
  //         Math.round(Math.random() * usedValues.length),
  //       )
  //       console.log('randIndex', randIndex)
  //       propertiesValue = usedValues[randIndex]
  //     }
  //   } else {
  //     ;[propertiesValue] = goodValues
  //   }
  //   const propertiesKey = propertiesValue.id
  //   current += 1
  //   console.log(`number ${current} of ${all}`)
  //   const newValue = {
  //     ...arrEntry,
  //     // 'id': propertiesKey,
  //     'account': propertiesValue.account,
  //     'Industry': propertiesValue.Industry,
  //     'Currency Iso Code': propertiesValue['Currency Iso Code'],
  //     'Source Type': propertiesValue['Source Type'],
  //     'Owner Name': propertiesValue['Owner Name'],
  //     'Join Month': propertiesValue['Join Month'],
  //     'Join Quarter': propertiesValue['Join Quarter'],
  //     'Last Transaction Type': propertiesValue['Last Transaction Type'],
  //     'Last Transaction Amount': propertiesValue['Last Transaction Amount'],
  //     'Entity ID': propertiesValue['Entity ID'],
  //     'Number Of Employees': propertiesValue['Number Of Employees'],
  //   }
  //   usedIds.push(propertiesKey)
  //   newArrData.push(newValue)
  // })
  // console.log('newArrData', JSON.stringify(newArrData))
  //
  // debugger

  const makeValueGetter = React.useCallback(
    (field: string) => (params: ValueGetterParams) =>
      properties?.[params.data.id]?.[field] || params.data[field],
    [properties],
  )

  const memorizedGetHeaderName = React.useMemo(
    () => getHeaderName || defaultGetHeaderName,
    [getHeaderName],
  )

  return React.useCallback(
    ({ field }: Cohort, colDef) => {
      const colProps = {
        ...(defaultColumnDefinitons[field.toLowerCase()] || {}),
        ...(colDef || {}),
      }
      const valueFormatter =
        colProps.valueFormatter ||
        (makeValueFormatter && makeValueFormatter(field))
      return (
        <AgGridColumn
          key={field}
          field={field}
          filter="agSetColumnFilter"
          headerName={memorizedGetHeaderName(field)}
          valueGetter={makeValueGetter(field)}
          enableRowGroup
          enablePivot
          {...colProps}
          valueFormatter={valueFormatter}
        />
      )
    },
    [makeValueFormatter, memorizedGetHeaderName, makeValueGetter],
  )
}

export type CellRendererBasicParam = { value: string }
