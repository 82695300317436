import {
  AssumptionsStates,
  DashboardStates,
  HeadcountStates,
} from '../../ducks/dashboard/types'
import DashboardComboBarLineChart, {
  DashboardComboBarLineChartData,
} from '../DashboardComboBarLineChart'
import React, { useCallback, useEffect, useState } from 'react'
import {
  selectAssumptionsPageState,
  selectDashboardPageState,
  selectHeadcountPageState,
} from '../../ducks/dashboard/selectors'
import tableData1 from '../../data/headcountPlanningTable_1.json'
import tableData2 from '../../data/headcountPlanningTable_2.json'
import hc1 from '../../data/hc1.json'
import hc2 from '../../data/hc2.json'
import hc3 from '../../data/hc3.json'
import hc4 from '../../data/hc4.json'
import metadata0 from '../../data/hcMetadata0.json'
import metadata1 from '../../data/hcMetadata1.json'
import metadata2 from '../../data/hcMetadata2.json'
import { useSelector } from 'react-redux'
import { Box, IconButton } from '@mui/material'
import DashboardHeadcountTable from '../DashboardHeadcountTable'
import TableViewIcon from '@mui/icons-material/TableView'
import styled from 'styled-components'

type Props = {
  hideInsight?: boolean
}

const IconsContainer = styled.div`
  margin-left: 15px;
  display: flex;
  gap: 5px;
`

export const HeadcountChart: React.FC<Props> = (props) => {
  const currentAssumptionsState = useSelector(selectAssumptionsPageState)
  const currentHeadcountState = useSelector(selectHeadcountPageState)
  const currentDashboardState = useSelector(selectDashboardPageState)
  const [chartData, setChartData] =
    useState<DashboardComboBarLineChartData>(hc1)
  const [headcountView, setHeadcountView] = useState('chart')
  const [chartMetadata, setchartMetadata] = useState(metadata0)
  const [rowData, setRowData] = useState(tableData1)

  useEffect(() => {
    if (currentDashboardState === DashboardStates.finalMarchForecast) {
      setchartMetadata(metadata2)
      setChartData(hc4)
      setRowData(tableData2)
    } else if (currentHeadcountState === HeadcountStates.suggestionClicked) {
      setChartData(hc3)
      setRowData(tableData2)
    } else if (
      currentAssumptionsState === AssumptionsStates.suggestionClicked
    ) {
      setChartData(hc2)
      setRowData(tableData2)
    }

    if (currentDashboardState === DashboardStates.newForecastAdded)
      setchartMetadata(metadata1)
  }, [currentAssumptionsState, currentHeadcountState, currentDashboardState])

  const handleChangeView = useCallback(() => {
    setHeadcountView(headcountView === 'chart' ? 'table' : 'chart')
  }, [headcountView])

  const FooterPanel = () => (
    <IconsContainer>
      <IconButton size="small" onClick={handleChangeView}>
        <TableViewIcon />
      </IconButton>
    </IconsContainer>
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      {headcountView === 'table' ? (
        <DashboardHeadcountTable rowData={rowData} FooterPanel={FooterPanel} />
      ) : (
        <DashboardComboBarLineChart
          data={chartData.data}
          legend={chartMetadata.legend}
          title={chartMetadata.title}
          tooltipNameMapper={chartMetadata.tooltipNameMapper}
          heightRatio={2.3}
          widthRatio={2.4}
          stroke={chartMetadata.stroke}
          hideInsight={props.hideInsight}
          domain={{ barLow: 0, barHigh: 160 }}
          FooterPanel={FooterPanel}
        />
      )}
    </Box>
  )
}
