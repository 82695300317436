// eslint-disable-next-line import/no-extraneous-dependencies
import { CSSProperties } from 'react'

export const widgetContainerStyles = (): CSSProperties =>
  ({
    background: '#fff',
    border: '1px solid #d7d6d5',
    boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.05)',
    borderRadius: '10px',
  } as CSSProperties)
