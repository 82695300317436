/**
 Selector is a function that gets some data from the redux store's RootState
 Each selector's name should start on "selector" word
 */

import { createSelector } from '@reduxjs/toolkit'
import {
  HeadcountDataSource,
  HeadcountDataSourceKey,
  HeadcountDimension,
  HeadcountDimensionConf,
} from '../../types'
import {
  DATA_SOURCE_ASSUMPTIONS,
  DATA_SOURCE_KNOWN_EMPLOYEES,
  DATA_SOURCE_HIRING_PLAN,
} from './const'
import type { RootState, State } from './state'
import type { Maybe } from '../../../../types'
import type { Selector } from 'reselect'

// unified access to all state of current duck
export const selectHeadcountAll: Selector<RootState, State> = (s) => s.headcount

export const selectHeadcountDataSource: Selector<
  RootState,
  HeadcountDataSource<unknown>
> = createSelector(
  [
    selectHeadcountAll,
    (state: RootState, sourceKey: HeadcountDataSourceKey) => sourceKey,
  ],
  (all, sourceKey: HeadcountDataSourceKey) => all[sourceKey],
)

export const selectHeadcountAssumptions: Selector<
  RootState,
  State[typeof DATA_SOURCE_ASSUMPTIONS]
> = createSelector(selectHeadcountAll, (all) => all[DATA_SOURCE_ASSUMPTIONS])

export const selectHeadcountExistingEmployees: Selector<
  RootState,
  State[typeof DATA_SOURCE_KNOWN_EMPLOYEES]
> = createSelector(
  selectHeadcountAll,
  (all) => all[DATA_SOURCE_KNOWN_EMPLOYEES],
)

export const selectHeadcountHiringPlan: Selector<
  RootState,
  State[typeof DATA_SOURCE_HIRING_PLAN]
> = createSelector(selectHeadcountAll, (all) => all[DATA_SOURCE_HIRING_PLAN])

export const selectConfig: Selector<RootState, State['config']> =
  createSelector(selectHeadcountAll, (all) => all.config)

export const selectHeadcountDimensionsConf: Selector<
  RootState,
  Maybe<HeadcountDimensionConf[]>
> = createSelector(selectConfig, (config) =>
  config.data ? config.data.dimensions : null,
)

export const selectHeadcountDimensions: Selector<
  RootState,
  HeadcountDimension[]
> = createSelector(selectHeadcountDimensionsConf, (dimsConf) =>
  dimsConf !== null ? dimsConf.map((dim) => dim.field) : [],
)
