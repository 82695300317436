import React, { FC } from 'react'

import { SecondaryViewHeaderText } from '../../context/SecondaryTableContext'

type HeaderGroupTextFrameworkProps = {
  headerText: SecondaryViewHeaderText
}

export const HeaderGroupTextFramework: FC<HeaderGroupTextFrameworkProps> = ({
  headerText,
}) => (
  <div>
    <div>{headerText.default}</div>
    <div>{headerText.explanation}</div>
  </div>
)
