import type { RowNode } from 'ag-grid-community'

export function getComparatorResultForPeriod(
  nodeA: RowNode,
  nodeB: RowNode,
  isInverted: boolean,
): number {
  if (!nodeA || !nodeB || !nodeA.data || !nodeB.data) return 0

  const periodA = nodeA.data?.periodName
  const periodB = nodeB.data?.periodName
  if (periodA === periodB) return 0
  return (periodA > periodB ? 1 : -1) * (isInverted ? -1 : 1)
}

export function generalComparatorSavePeriodsOrder(
  a: string | number,
  b: string | number,
  nodeA: RowNode,
  nodeB: RowNode,
  isInverted: boolean,
): number {
  if (nodeA && nodeA.group && nodeB && nodeB.group) {
    if (a === b) return 0
    return a > b ? 1 : -1
  }

  return getComparatorResultForPeriod(nodeA, nodeB, isInverted)
}

export const autoColumnName = (name = ''): string =>
  `ag-Grid-AutoColumn-${name}`
