import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'

import Badge from '@mui/material/Badge'
import Fade from '@mui/material/Fade'
import InsightBadge from 'components/atoms/InsightBadge'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import React from 'react'
import UndoIcon from '@mui/icons-material/Undo'

export const dictionaryIsSubset = (
  left: Record<any, any>,
  right: Record<any, any>,
) => Object.keys(left).every((key) => key in right && right[key] === left[key])

export const simpleCellRenderer = (value: any) => (
  <div>
    <Typography
      style={{
        width: '30%',
      }}
    >
      {value}
    </Typography>
  </div>
)

export const getInsightfulCellRenderer =
  (
    insightValue: Record<string, any>,
    insightDialog: Record<string, any>[],
    onSuggestionClick: () => void,
  ) =>
  (params: any) => {
    if (
      'data' in params &&
      dictionaryIsSubset(insightValue.data, params.data) &&
      params.column.colId === 'Oct-22October 22 Forecast'
    ) {
      if (insightValue.replaceData) {
        return (
          <div>
            <Typography
              style={{
                width: '30%',
              }}
            >
              <UndoIcon
                style={{
                  cursor: 'pointer',
                  fontSize: '15px',
                  marginRight: '15px',
                }}
              />
              {params.value}
            </Typography>
          </div>
        )
      }
      if (!insightValue.replaceData) {
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
        const open = Boolean(anchorEl)
        const handleClick = (event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget)
        }
        const handleClose = () => {
          setAnchorEl(null)
        }
        const handleSuggestionClick = () => {
          handleClose()
          onSuggestionClick()
        }
        return (
          <div>
            <Menu
              id="fade-menu"
              MenuListProps={{
                'aria-labelledby': 'fade-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              <Typography
                style={{ fontSize: 12, color: 'grey', marginLeft: '8px' }}
              >
                Suggestions
              </Typography>
              {insightDialog.map((item: Record<string, any>) => (
                <MenuItem key={item.name} onClick={handleSuggestionClick}>
                  {item.component}
                </MenuItem>
              ))}
            </Menu>
            <Typography
              onClick={handleClick}
              variant="caption"
              sx={{
                'position': 'relative',
                'fontWeight': 700,
                'zIndex': '2',
                'cursor': 'pointer',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  marginRight: '4px',
                  top: '-4px',
                  left: '-4px',
                  zIndex: '2',
                  right: '-4px',
                  bottom: '-4px',
                  border: '1px solid #FF7124',
                  borderRadius: '50%',
                  background: '#fff',
                },
              }}
            >
              <span
                style={{ position: 'relative', zIndex: 3, paddingRight: '3px' }}
              >
                {params.value}
              </span>
            </Typography>
          </div>
        )
      }
    }
    return simpleCellRenderer(params.value)
  }

export const InsightGroupCellRenderer =
  (insightValue: any, handleClick: any) => (params: any) => {
    if (
      params.colDef.field === insightValue.badgeField &&
      params.value === insightValue.badgeValue
    )
      return (
        <div>
          <span>{params.value}</span>
          <InsightBadge
            handleClick={handleClick}
            count={1}
            left="-10px"
            inline
            small
          />
        </div>
      )
    return params.value
  }
