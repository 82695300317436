import { Alert, AlertTitle, Box, Button } from '@mui/material'

import React from 'react'
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded'
import { environment } from 'environment'
import { getTenantOverrideFromUrl } from 'environment/tenant-override'
import { useAuth0 } from '@auth0/auth0-react'

const AUTO_LOGIN_URL_TOKEN = 'auto-login'
const ACCES_DENIED_URL_TOKEN = 'access-denied'

const isAccessDenied = (error?: Error): boolean =>
  error?.message === 'Access denied'

export const Login: React.FC = () => {
  const tenantOverriden = getTenantOverrideFromUrl()
  const loginText = tenantOverriden
    ? `Login to ${tenantOverriden}`
    : 'Proceed to Login'

  const url = new URL(window.location.href)
  const params = new URLSearchParams(url.search)
  const organization = params.get('organization')
  const invitation = params.get('invitation')
  const organizationName = params.get('organization_name')

  const { loginWithPopup, error, logout } = useAuth0()
  const [attemptedToLogin, setAttemptedToLogin] = React.useState(false)
  const [loggedOutDueToAccessDenied, setLoggedOutDueToAccessDenied] =
    React.useState(false)

  const handleLogin = React.useCallback(async () => {
    setAttemptedToLogin(true)

    if (isAccessDenied(error)) {
      logout({
        returnTo: `${window?.location.origin}?${AUTO_LOGIN_URL_TOKEN}`,
      })
      return
    }

    if (invitation && organization && organizationName) {
      await loginWithPopup({ invitation, organization })
    } else {
      await loginWithPopup()
    }
  }, [
    error,
    logout,
    loginWithPopup,
    invitation,
    organization,
    organizationName,
  ])

  React.useEffect(() => {
    if (
      organizationName &&
      window.location.host
        .split('.')
        .includes(environment.REACT_APP_TENANT_LOGIN)
    ) {
      window.location.href = window.location.href.replace(
        environment.REACT_APP_TENANT_LOGIN,
        organizationName,
      )
    }
  }, [organizationName])

  React.useEffect(() => {
    if (window.location.search.includes(AUTO_LOGIN_URL_TOKEN)) {
      handleLogin()
      window?.history.replaceState(
        null,
        document.title,
        document.location.origin + document.location.pathname,
      )
    }
  }, [handleLogin])

  React.useEffect(() => {
    if (!attemptedToLogin && isAccessDenied(error)) {
      logout({
        returnTo: `${window?.location.origin}?${ACCES_DENIED_URL_TOKEN}`,
      })
    }
  }, [attemptedToLogin, error, logout])

  React.useEffect(() => {
    if (window.location.search.includes(ACCES_DENIED_URL_TOKEN)) {
      setLoggedOutDueToAccessDenied(true)
    }
  }, [setLoggedOutDueToAccessDenied])

  return (
    <Box mt={3}>
      <Alert severity="info" icon={<SecurityRoundedIcon fontSize="inherit" />}>
        <AlertTitle>Welcome to Fintastic</AlertTitle>
        Please login into your account
      </Alert>
      <Box mt={2}>
        {error && (
          <Box mb={1}>
            <Alert severity="error">
              <b>{error.name}</b> {error.message}
            </Alert>
          </Box>
        )}
        {loggedOutDueToAccessDenied && !error && (
          <Box mb={1}>
            <Alert severity="error">
              <b>Error</b> Access denied. Login with another account.
            </Alert>
          </Box>
        )}
        <Button
          color="primary"
          variant="contained"
          disableElevation
          fullWidth
          onClick={handleLogin}
        >
          {loginText}
        </Button>
      </Box>
    </Box>
  )
}
