import React from 'react'
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  ResponsiveContainer,
  ZAxis,
} from 'recharts'
import { Box, Typography, FormControl, InputLabel, Select } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { grey } from '@mui/material/colors'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'

type Props = {
  graphState: boolean
  setGraphState: React.Dispatch<React.SetStateAction<boolean>>
}

const data = [
  { x: 28, y: 41, z: 301 },
  { x: 28.16, y: 37, z: 299 },
  { x: 28.92, y: 36, z: 299 },
  { x: 29.65, y: 25, z: 299 },
  { x: 31, y: 21, z: 299 },
  { x: 35.12, y: 19, z: 299 },
  { x: 27.6, y: 40, z: 299 },
  { x: 26.4, y: 46, z: 299 },
  { x: 25.85, y: 51, z: 299 },
  { x: 25, y: 60, z: 299 },
]

const data2 = JSON.parse(JSON.stringify(data))
data2[0].z = 299
data2[2].z = 301

const reductions = [
  {
    plan: 'cashplanning',
    title: 'Risk reduction',
  },
  {
    plan: 'vendorsubscription',
    title: 'Cost reduction',
  },
]

const selectReduction = (
  <Box color={grey[700]} gap={0} marginLeft={4}>
    <FormControl size="small" style={{ width: '200px', marginRight: '12px' }}>
      <InputLabel id="select-label">Optimize goal</InputLabel>

      <Select
        labelId="select-label"
        id="simple-select"
        defaultValue="Risk reduction"
        label="Optimize goal"
      >
        {reductions.map((item) => (
          <MenuItem key={item.plan} value={item.title}>
            {item.title}
          </MenuItem>
        ))}{' '}
      </Select>
    </FormControl>
  </Box>
)

const riskHeader = (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      py: 0,
      px: '10px',
      width: '100%',
      height: '70px',
    }}
  >
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <DragIndicatorIcon
        sx={{
          color: 'rgba(0, 0, 0, 0.54)',
        }}
      />
      <Typography
        noWrap={true}
        sx={{ fontWeight: 700, marginRight: '12px', fontSize: '16px' }}
      >
        Risk Vs. Cost
      </Typography>
    </Box>
  </Box>
)

const CustomTooltip = (p: any) => {
  let newPayload
  if (p.payload[0] != null) {
    newPayload = [
      {
        name: 'Name',
        value: p.payload[0].payload.name,
      },
      ...p.payload,
    ]
    if (newPayload?.length > 1) {
      return (
        <Box
          sx={{
            background: 'white',
            padding: 1,
            border: '0.1px solid gray',
            borderRadius: '4px',
          }}
        >
          <div className="label">{`${newPayload[1].name}:  ${newPayload[1].value}${newPayload[1].unit}`}</div>
          <div className="label">{`${newPayload[2].name}:  ${newPayload[2].value}${newPayload[2].unit}`}</div>
        </Box>
      )
    }
  }
  return null
}

export const RiskVsCost: React.FC<Props> = (props) => {
  const handleDotClick = () => {
    props.setGraphState((current) => !current)
  }
  return (
    <Box
      sx={{
        // display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '95%',
      }}
    >
      {riskHeader}
      {selectReduction}
      <ResponsiveContainer>
        <ScatterChart
          margin={{
            top: 20,
            right: 10,
            bottom: -10,
            left: 10,
          }}
        >
          <CartesianGrid />
          <XAxis
            type="number"
            dataKey="x"
            name="Cost"
            unit="M"
            domain={[15, 40]}
          />
          <ZAxis dataKey="z" range={[100, 500]} />
          <YAxis
            type="number"
            dataKey="y"
            name="Risk"
            unit="%"
            domain={[0, 100]}
          />
          <Scatter name="$M" data={props.graphState ? data2 : data}>
            {data.map((entry, index) => (
              <Cell
                key={`cell-${entry}`}
                fill={(() => {
                  if (props.graphState) {
                    return entry.y === 36 ? '#AAAAAA' : '#FF9B25'
                  }
                  return entry.z > 300 ? '#AAAAAA' : '#FF9B25'
                })()}
                onClick={handleDotClick}
              />
            ))}
          </Scatter>
          <Tooltip
            cursor={{ strokeDasharray: '5 5' }}
            content={<CustomTooltip />}
          />
        </ScatterChart>
      </ResponsiveContainer>
    </Box>
  )
}
