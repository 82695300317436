/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import type { Column, IHeaderParams } from 'ag-grid-community'

import { AggregatedTableContext } from '../../context/AggregatedTableContext'
import React from 'react'

// based on https://www.ag-grid.com/react-data-grid/component-header/#simple-header-component
// needs to be simplified / adjusted
export const AggregatedTableCustomHeader: React.FC<IHeaderParams> = (props) => {
  const [ascSort, setAscSort] = React.useState<ActiveInactive>('inactive')
  const [descSort, setDescSort] = React.useState<ActiveInactive>('inactive')
  const [noSort, setNoSort] = React.useState<ActiveInactive>('inactive')
  const refButton: React.LegacyRef<HTMLDivElement> = React.useRef(null)
  const {
    flags: { bridgeWaterfall },
  } = React.useContext(AggregatedTableContext)

  const onMenuClicked = React.useCallback(() => {
    if (refButton.current) {
      props.showColumnMenu(refButton.current)
    }
  }, [props, refButton])

  const onSortChanged = React.useCallback(() => {
    setAscSort(props.column.isSortAscending() ? 'active' : 'inactive')
    setDescSort(props.column.isSortDescending() ? 'active' : 'inactive')
    setNoSort(
      !props.column.isSortAscending() && !props.column.isSortDescending()
        ? 'active'
        : 'inactive',
    )
  }, [props])

  const onSortRequested = React.useCallback(
    (order, event) => {
      props.setSort(order, event.shiftKey)
    },
    [props],
  )

  React.useEffect(() => {
    props.column.addEventListener('sortChanged', onSortChanged)
    onSortChanged()
  }, [props, onSortChanged])

  const menu = props.enableMenu ? (
    <div
      ref={refButton}
      className="customHeaderMenuButton"
      onClick={() => onMenuClicked()}
    >
      <i className="fa" />
    </div>
  ) : null

  const sort = props.enableSorting ? (
    <div style={{ display: 'inline-block' }}>
      <div
        onClick={(event) => onSortRequested('asc', event)}
        onTouchEnd={(event) => onSortRequested('asc', event)}
        className={`customSortDownLabel ${ascSort}`}
      >
        <i className="fa fa-long-arrow-alt-down" />
      </div>
      <div
        onClick={(event) => onSortRequested('desc', event)}
        onTouchEnd={(event) => onSortRequested('desc', event)}
        className={`customSortUpLabel ${descSort}`}
      >
        <i className="fa fa-long-arrow-alt-up" />
      </div>
      <div
        onClick={(event) => onSortRequested('', event)}
        onTouchEnd={(event) => onSortRequested('', event)}
        className={`customSortRemoveLabel ${noSort}`}
      >
        <i className="fa fa-times" />
      </div>
    </div>
  ) : null

  const resetColumnRange = React.useCallback(() => {
    props.api.clearRangeSelection()
    props.api.addCellRange({
      rowStartIndex: 0,
      rowEndIndex: props.api.getDisplayedRowCount() - 1,
      columns: [props.column],
    })
  }, [props.api, props.column])

  const generateColumns = React.useCallback(() => {
    const columns = props.columnApi.getSecondaryColumns()
    const rangeSelection = props.api
      .getCellRanges()
      ?.filter((rangeItem) => rangeItem.columns.length > 0)
    if (!columns || !rangeSelection) return

    const isSameColumn = rangeSelection[0].columns.find(
      (item) => item.getInstanceId() === props.column.getInstanceId(),
    )
    if (isSameColumn) {
      resetColumnRange()
    } else {
      // Reorganize columns according to default order
      const firstId = rangeSelection[0]?.startColumn?.getInstanceId()
      const secondId = props.column.getInstanceId()
      const firstIndex = columns.indexOf(
        columns.find((item) => item.getInstanceId() === firstId) as Column,
      )
      const secondIndex = columns.indexOf(
        columns.find((item) => item.getInstanceId() === secondId) as Column,
      )
      const pickedColumns =
        firstId > secondId
          ? columns.slice(secondIndex, firstIndex + 1)
          : columns.slice(firstIndex, secondIndex + 1)
      const columnsIds = pickedColumns.map((item) => item.getColId())

      props.api.clearRangeSelection()
      props.api.addCellRange({
        rowStartIndex: 0,
        rowEndIndex: props.api.getDisplayedRowCount() - 1,
        columns: columnsIds,
      })
    }
  }, [props.api, props.column, props.columnApi, resetColumnRange])

  const selectColumnRows = React.useCallback(
    (e: React.MouseEvent) => {
      if (
        !bridgeWaterfall ||
        // Check for `Metric` column click, where we should ignore logic
        props.column.isPinned()
      )
        return

      if (!props.column.isPinned() && !e.shiftKey) {
        resetColumnRange()
      } else if (e.shiftKey) {
        generateColumns()
      }
    },
    [bridgeWaterfall, props.column, resetColumnRange, generateColumns],
  )

  return (
    <div
      className="ag-header-cell-label"
      style={{
        height: '48px',
        width: '100%',
      }}
      onClick={selectColumnRows}
    >
      {menu}
      <div>{props.displayName}</div>
      {sort}
    </div>
  )
}

type ActiveInactive = 'active' | 'inactive'
