/* eslint-disable camelcase */
// @see https://docs.cypress.io/guides/testing-strategies/auth0-authentication
import {
  Auth0ContextInterface,
  IdToken,
  User,
  useAuth0,
} from '@auth0/auth0-react'

import type { Maybe } from '../types'
import React from 'react'

interface DecodedToken {
  claims: IdToken
  user: User
}
interface CacheEntry {
  id_token: string
  access_token: string
  expires_in: number
  decodedToken: DecodedToken
  audience: string
  scope: string
  client_id: string
  refresh_token?: string
}

type CypressAuth0LSData = {
  body: Partial<CacheEntry>
  expiresAt: string
}

const getCypressLSAuthData = (): Maybe<CypressAuth0LSData> => {
  const ls = localStorage.getItem('auth0Cypress')

  if (!ls) {
    return null
  }

  return JSON.parse(ls) as CypressAuth0LSData
}

const stub = () => {
  throw new Error('Not allowed in Cypress mode')
}

export const useAuth0WithCypress = (): Auth0ContextInterface => {
  // If Cypress env, use LS data filled by Cypress as source for user info and token
  // NOTE: This is only partial mock
  if (window.Cypress) {
    const data = getCypressLSAuthData()

    return {
      isAuthenticated: !!data,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      getAccessTokenSilently: React.useCallback(
        async () => data?.body.access_token,
        [data?.body.access_token],
      ),
      user: data?.body.decodedToken?.user,
      loginWithPopup: stub,
      loginWithRedirect: stub,
      logout: stub,
      buildAuthorizeUrl: stub,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      buildLogoutUrl: React.useCallback(
        () => 'http://not-working-in-cypress',
        [],
      ),
      isLoading: false,
      getAccessTokenWithPopup: stub,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      getIdTokenClaims: React.useCallback(
        async () => data?.body.decodedToken?.claims,
        [data?.body.decodedToken?.claims],
      ),
      handleRedirectCallback: stub,
    } as Auth0ContextInterface
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useAuth0()
}
