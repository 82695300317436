import { Route, RouteProps, Redirect } from 'react-router-dom'

import { AuthLayout } from '../../layouts/AuthLayout'
import React from 'react'
import { useAuth0WithCypress } from '../../hooks/useAuth0WithCypress'
import { useMixpanel } from '../../utils/mixpanel-utils'

type ProtectedRouteProps = RouteProps & { isEnabled?: boolean }

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  isEnabled = true,
  ...props
}): React.ReactElement => {
  const { isLoading, isAuthenticated } = useAuth0WithCypress()
  const { track, isIdentified } = useMixpanel()

  React.useEffect(() => {
    if (isIdentified) {
      track('PAGE_LOAD', {
        source: props.location?.pathname,
      })
    }
  }, [isIdentified, props.location?.pathname, props.path, track])

  if (!isAuthenticated) {
    return <AuthLayout isLoading={isLoading} />
  }

  if (!isEnabled) return <Redirect to="/" />

  return (
    <>
      <Route {...props} />
    </>
  )
}
