import { Box, Button, ButtonGroup } from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'

import { CommentInput } from './CommentInput'
import React from 'react'
import { SetSelectedThreadPayload } from '../ducks/threads/reducers/types'
import { deserialiseCommentValue } from '../utils/prepare-comment-value'

export type FormInput = {
  comment: string
}

export type CommentFormProps = {
  onSubmit: SubmitHandler<FormInput>
  onAdd: (id: string | number, display: string) => void
  onCancel: (payload: SetSelectedThreadPayload) => void
}

export const CommentForm: React.FC<CommentFormProps> = ({
  onSubmit,
  onAdd,
  onCancel,
}) => {
  const fieldName = 'comment'
  const { setValue, handleSubmit, reset, watch } = useForm<FormInput>()
  const inputValue = watch(fieldName)

  const handleInputValue = React.useCallback(
    (_, newValue) => setValue(fieldName, newValue),
    [setValue],
  )
  const submit: SubmitHandler<FormInput> = React.useCallback(
    (data: FormInput) => {
      if (!data.comment) {
        return
      }

      onSubmit({
        comment: deserialiseCommentValue(data.comment),
      })
      reset()
    },
    [onSubmit, reset],
  )
  const cancel: () => void = React.useCallback(() => {
    reset()
    onCancel(null)
  }, [reset, onCancel])

  return (
    <form onSubmit={handleSubmit(submit)} style={{ maxWidth: '300px' }}>
      <CommentInput
        value={inputValue}
        onChange={handleInputValue}
        onAdd={onAdd}
      />
      <Box sx={{ display: 'flex' }}>
        <ButtonGroup disableElevation color="primary" sx={{ marginTop: 1 }}>
          <Button type="submit" variant="contained">
            Comment
          </Button>
          <Button variant="outlined" onClick={cancel}>
            Cancel
          </Button>
        </ButtonGroup>
      </Box>
    </form>
  )
}
