import { Box, Modal } from '@mui/material'
import { Icon } from '../Icon/Icon'
import React from 'react'
import clsx from 'clsx'
import { styled } from '@mui/material/styles'
import styledComp from 'styled-components'

const PREFIX = 'ModalDialog'

const classes = {
  closeIcon: `${PREFIX}-closeIcon`,
}

const $ModalOuterWrap = styledComp.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 3rem 1rem;
  overflow: auto;
`

const $ModalInnerWrap = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  margin: 'auto',
  padding: '3rem 2rem 1.5rem',
  border: 'solid 1px var(--color-border-modal)',
  borderRadius: '10px',
  boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.15)',
  background: 'var(--color-bg-modal)',

  [`& .${classes.closeIcon}`]: {
    position: 'absolute',
    top: '1.2rem',
    right: '1.2rem',
    color: 'var(--color-icon-nav-inactive)',
    cursor: 'pointer',
  },
}))

export const ModalDialog: React.FC<{
  open: boolean
  maxWidth?: string
  onClose?: () => void
}> = ({ open, maxWidth, onClose, children }) => {
  const handleClose = React.useCallback(() => {
    if (onClose) {
      onClose()
    }
  }, [onClose])

  return (
    <Modal open={open}>
      <$ModalOuterWrap>
        <$ModalInnerWrap style={{ maxWidth: maxWidth || '40rem' }}>
          <Icon
            icon="modal-close"
            color="var(--color-text)"
            stroke={true}
            className={clsx(classes.closeIcon)}
            onClick={handleClose}
          />
          {children}
        </$ModalInnerWrap>
      </$ModalOuterWrap>
    </Modal>
  )
}
