import React from 'react'

export function useVerticalResizeSection(): {
  resized: boolean
  isDragging: boolean
  handleResize: () => void
  handleDragStart: () => void
} {
  const [resized, setIsResized] = React.useState(false)
  const [isDragging, setIsDragging] = React.useState(false)

  const handleResize = React.useCallback(() => {
    setIsResized(true)
    setIsDragging(false)
  }, [setIsResized, setIsDragging])

  const handleDragStart = React.useCallback(() => {
    setIsDragging(true)
  }, [setIsDragging])

  return React.useMemo(
    () => ({
      handleResize,
      handleDragStart,
      resized,
      isDragging,
    }),
    [handleResize, handleDragStart, resized, isDragging],
  )
}
