// prettier-ignore
function usePlans(): {
    getPlans: () => string[][]
} {
    // prettier-ignore
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    // prettier-ignore
    const plansBeforeForecast = ['Board approved plan']
    const currMonth = months[new Date().getMonth()]

    const getFullLabel = (month: string) => `${month} 22 forecast`
    const getPlans = () => {
        plansBeforeForecast.push(getFullLabel(months[new Date().getMonth() - 3]))
        plansBeforeForecast.push(getFullLabel(months[new Date().getMonth() - 2]))
        plansBeforeForecast.push(getFullLabel(months[new Date().getMonth() - 1]))
        const getPlansOfAfter = () => [...plansBeforeForecast, getFullLabel(currMonth)]
        const plansAfterForecast = getPlansOfAfter()
        return [plansBeforeForecast, plansAfterForecast]
    }
    return { getPlans }
}
// prettier-ignore
export default usePlans
