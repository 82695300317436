import { BridgeWaterfallChartView } from '../Chart/BridgeWaterfallChartView'
import { LineChartView } from '../Chart/LineChartView'
import { PieChartView } from '../Chart/PieChartView'
import React from 'react'
import { SecondaryTableContext } from '../../context/SecondaryTableContext'

const SecondaryChartContent: React.FC = () => {
  const { chartData, chartType } = React.useContext(SecondaryTableContext)

  if (chartType === 'basic') {
    if (chartData?.length === 1) {
      return <PieChartView />
    }

    if (chartData?.length && chartData?.length > 1) {
      return <LineChartView />
    }
  }
  if (chartType === 'bridge-waterfall') {
    return <BridgeWaterfallChartView />
  }

  return null
}

export const SecondaryChart: React.FC = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    }}
  >
    <SecondaryChartContent />
  </div>
)
