import type {
  BlueprintReport,
  Report,
  ReportAggregates,
  ReportDetails,
  ReportGroup,
} from 'features/generic-report/types'
import { RequestState, requestInitialState } from 'redux/shared/request/state'

import type { Maybe } from 'types'

export const namespace = 'genericReport'

export type BlueprintState = RequestState & {
  data: Maybe<BlueprintReport>
}

export type ReportGroupsState = RequestState & {
  data: ReportGroup[]
}

export type ReportDetailsState = RequestState & {
  data: Maybe<ReportDetails>
}

type ReportState = RequestState & {
  data: Maybe<ReportAggregates>
}

export type ReportsListState = Record<string, ReportState>
export type BlueprintListState = Record<string, BlueprintState>

export type State = {
  reportGroups: ReportGroupsState
  blueprintList: BlueprintListState
  reportDetails: ReportDetailsState
  selectedVersions: Report[]
  reportsList: ReportsListState
}

export const initialState: State = {
  reportGroups: {
    data: [],
    ...requestInitialState,
  },
  blueprintList: {},
  reportDetails: {
    data: null,
    ...requestInitialState,
  },
  selectedVersions: [],
  reportsList: {},
}
