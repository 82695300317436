import type { ReduxConnectable } from 'redux/types'
import type { State } from './state'
import { dashboardSlice } from './slice'
import { namespace } from './state'

const connectable: ReduxConnectable<State> = {
  namespace,
  reducer: dashboardSlice.reducer,
}

export default connectable
