import LogRocket from 'logrocket'
import React from 'react'
import { environment } from '../environment'
import { useAuth0 } from '@auth0/auth0-react'

export const useLogrocketIdentifyEffect = (): void => {
  const { user, isAuthenticated } = useAuth0()

  React.useEffect(() => {
    if (
      isAuthenticated &&
      environment.REACT_APP_LOGROCKET_APP_ID &&
      user?.email &&
      !window.Cypress
    ) {
      LogRocket.identify(user?.email, user)
    }
  }, [user, isAuthenticated])
}
