export type SerialiseComment = (value: string, mentions: string[]) => string
/**
 * serialise comment format with mailto links based on 'mention' events
 * [INPUT]: hello mike@fintastic.ai WDYT?
 * [OUTPUT]: hello <a href="mailto:mike@fintastic.ai" style="color: #f3622d">mike@fintastic.ai</a> WDYT?
 */
export const serialiseCommentValue: SerialiseComment = (
  value: string,
  mentions: string[],
) =>
  value.replace(/[\w-.]+@([\w-]+\.)+[\w-]{2,4}/g, (email: string) =>
    mentions?.includes(email)
      ? `<a href="mailto:${email}" style="color: #f3622d">${email}</a>`
      : email,
  )

export type DeserialiseComment = (value: string) => string
/**
 * deserialise react-mentions format to
 * [INPUT]: hello world [mike@fintastic.ai] and [volodymyr@fintastic.ai]
 * [OUTPUT]: hello mike@fintastic.ai WDYT?
 */
export const deserialiseCommentValue: DeserialiseComment = (value: string) =>
  value.replace(/[()]/g, '')
