import {
  ApiConfigResponse,
  ApiConfigResponsePropertyCols,
  BridgeRowConf,
  RawApiConfigResponse,
} from './types'

/* eslint-disable camelcase */

const configBridgeDefault: BridgeRowConf[] = []

const configPropertyColumnsDefault: ApiConfigResponsePropertyCols = {
  kpis: {
    asp: [],
    ndr: [],
    nrr: [],
    renewal_rate: [],
    gdrr: [],
    ndrr: [],
  },
  bridge: {},
}

export function mapConfig(serverData: RawApiConfigResponse): ApiConfigResponse {
  const { bridge, property_columns, ...rest } = serverData

  return {
    ...rest,
    bridge: bridge || configBridgeDefault,
    property_columns: property_columns || configPropertyColumnsDefault,
  }
}
