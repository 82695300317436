import { FormControl, InputLabel, Select } from '@mui/material'

import React from 'react'

export interface IStyledSelect {
  label: string
  plan?: string
  style?: Record<string, any>
}

const StyledSelect: React.FC<IStyledSelect> = (props) => (
  <FormControl
    size="small"
    style={props.style || { width: '250px', marginRight: '12px' }}
  >
    <InputLabel id="select-label">{props.label}</InputLabel>

    <Select
      labelId="select-label"
      id="simple-select"
      label={props.label}
      value={props.plan}
      renderValue={(value) => value}
    >
      {props.children}
    </Select>
  </FormControl>
)

export default StyledSelect
