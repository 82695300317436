import type {
  EmitThreadsRequest,
  LoadThreadsFinish,
  MarkThreadResolved,
} from './types'

import type { State } from '../state'
import type { WritableDraft } from '../../../../../redux/types'

export function emitThreadsRequest(
  state: WritableDraft<State>,
  action: EmitThreadsRequest,
): void {
  state.error = null
  state.threadsList = []
  state.selectedPage = action.payload.pageKey
  state.selectedThreadId = null
}

export function markThreadResolved(
  state: WritableDraft<State>,
  action: MarkThreadResolved,
): void {
  const currentThread = state.threadsList.find(
    (thread) => thread.id === action.payload.threadId,
  )

  if (currentThread) {
    currentThread.is_resolved = action.payload.isResolved
  }
}

export function loadThreadsEnd(
  state: WritableDraft<State>,
  action: LoadThreadsFinish,
): void {
  const { error, threadsList } = action.payload
  state.error = error
  if (error !== null) {
    state.status = 'error'
    state.threadsList = []
  } else {
    state.status = 'success'
    state.threadsList = threadsList
  }
}
