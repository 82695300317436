import * as slice from './slice'

import { ReduxConnectable } from '../../../../redux/types'
import { namespace, State } from './state'
import sagas from './sagas'

export * from './selectors'
const { actions, reducer } = slice
export const {
  emitCreateThreadRequest,
  emitPostThreadEventRequest,
  emitMentionEventRequest,

  setSelectedThread,

  emitThreadsRequest,
  loadThreadsEnd,
  markThreadResolved,

  emitThreadEventsRequest,
  loadThreadEventsEnd,
  loadThreadEventsStart,

  emitUserListRequest,
  loadUserListEnd,
} = actions

const connectable: ReduxConnectable<State> = {
  namespace,
  reducer,
  sagas,
}

export default connectable
