import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import type { DatePickerProps, DateRange } from '@mui/lab'
import { PnLActual, PnLConfig } from '../../api/types'
import React, { Dispatch, SetStateAction } from 'react'

import DateAdapter from '@mui/lab/AdapterDayjs'
import dayjs from 'dayjs'
import { periodicities } from '../../utils/periodicity-utils'

export const PnlSettingsDateRange: React.FC<{
  pnlConfig: PnLConfig
  datasets: PnLActual[][]
  currentPeriodicity: string
  setCurrentPeriodicityCallBack: Dispatch<SetStateAction<string>>
  dateRange: DateRange<Date>
  setDateRange: (
    callBack: (oldValue: DateRange<Date>) => DateRange<Date>,
  ) => void
  setRecalculating: React.Dispatch<React.SetStateAction<boolean>>
}> = ({
  pnlConfig,
  datasets,
  currentPeriodicity,
  setCurrentPeriodicityCallBack,
  dateRange,
  setDateRange,
  setRecalculating,
}) => {
  const [dateRangeMin, setDateRangeMin] = React.useState(dayjs(dateRange[0]))
  const [dateRangeMax, setDateRangeMax] = React.useState(dayjs(dateRange[1]))
  const [selectedStartDate, handleStartDateChange] = React.useState(() =>
    dateRange[0] ? dayjs(dateRange[0]) : dayjs().add(-12, 'months'),
  )
  const [selectedEndDate, handleEndDateChange] = React.useState(() =>
    dateRange[1] ? dayjs(dateRange[1]) : dayjs(),
  )

  const [selectedPeriodicity, setSelectedPeriodicity] = React.useState(
    `${currentPeriodicity}`,
  )

  React.useEffect(() => {
    let minDate = new Date(pnlConfig.actuals_min_date)
    let maxDate = new Date(pnlConfig.actuals_max_date)
    datasets
      .filter((i) => Array.isArray(i))
      .map((i) => i.map((j) => j.periodstart))
      .flat()
      .filter((i) => !!i)
      .forEach((i) => {
        const iDate = new Date(i)
        if (iDate < minDate) minDate = iDate
        if (iDate > maxDate) maxDate = iDate
      })
    setDateRangeMin(dayjs(minDate))
    setDateRangeMax(dayjs(maxDate))
  }, [datasets, pnlConfig.actuals_min_date, pnlConfig.actuals_max_date])

  React.useEffect(() => {
    setRecalculating(false)
  }, [setRecalculating])

  return (
    <Stack spacing={3} px={1} pb={2}>
      <Box px={1} pb={2}>
        <FormControl component="fieldset">
          <Typography variant="subtitle2" color="textSecondary" pt={2} pb={1}>
            View by
          </Typography>
          <RadioGroup
            aria-label="periodicity"
            name="radio-buttons-group"
            value={selectedPeriodicity}
          >
            {Object.entries(periodicities).map(([key, value]) => (
              <FormControlLabel
                value={value}
                key={key}
                control={<Radio />}
                label={value}
                onChange={() => setSelectedPeriodicity(value)}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>

      <LocalizationProvider dateAdapter={DateAdapter}>
        <Stack spacing={3}>
          <Box pl={2} pr={2}>
            <DatePicker
              views={['year', 'month']}
              label="Start Date"
              value={selectedStartDate}
              onChange={handleStartDateChange as DatePickerProps['onChange']}
              minDate={dayjs(dateRangeMin)}
              maxDate={dayjs(dateRangeMax)}
              openTo="month"
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
          <Box pl={2} pr={2}>
            <DatePicker
              views={['year', 'month']}
              label="End Date"
              value={selectedEndDate}
              onChange={handleEndDateChange as DatePickerProps['onChange']}
              minDate={dayjs(dateRangeMin)}
              maxDate={dayjs(dateRangeMax)}
              openTo="month"
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
          <Box display="flex" justifyContent="center" pb={2}>
            <Button
              variant="contained"
              onClick={() => {
                setRecalculating(true)
                setTimeout(() => {
                  if (selectedPeriodicity !== currentPeriodicity)
                    setCurrentPeriodicityCallBack(selectedPeriodicity)
                  setDateRange((old) => [
                    selectedStartDate.startOf('month').toDate(),
                    selectedEndDate.endOf('month').toDate(),
                  ])
                }, 500)
              }}
            >
              Apply
            </Button>
          </Box>
        </Stack>
      </LocalizationProvider>
    </Stack>
  )
}
