import { DashboardWrapper } from 'components/DashboardWrapper/DashboardWrapper'
import React, { SyntheticEvent, useCallback, useEffect, useMemo } from 'react'
import { Box, Tab, Tabs, Typography } from '@mui/material'
import { useHistory, useParams } from 'react-router-dom'
import { DimensionsTab } from './DimensionsTab'
import { pageHeadingStyles } from '../styles/page-heading-styles'
import { UsersTab } from './UsersTab'

const tabs = [
  {
    tab: 'dimensions',
    title: 'Dimensions',
    component: () => <DimensionsTab />,
  },
  {
    tab: 'users',
    title: 'Users and permissions',
    component: () => <UsersTab />,
  },
  {
    tab: 'integrations',
    title: 'Integrations',
    component: () => <div>integrations page</div>,
  },
]

const defaultTab = tabs[0].tab

export const SettingsPage: React.FC = () => {
  const params: { tab: string } = useParams()
  const history = useHistory()

  const tab = useMemo(
    () => tabs.find((t) => t.tab === params.tab),
    [params.tab],
  )

  const handleChangeTab = useCallback(
    (e: SyntheticEvent, newTab: string) => {
      history.push(`/settings/${newTab}`)
    },
    [history],
  )

  useEffect(() => {
    if (!tab) {
      history.push(`/settings/${defaultTab}`)
    }
  }, [tab, history])

  return (
    <DashboardWrapper enableThreads>
      <Box
        sx={{ width: '100%', height: '100vh' }}
        display="flex"
        flexDirection="column"
      >
        <Typography
          variant="h1"
          style={{
            marginRight: '20px',
            ...pageHeadingStyles(),
          }}
        >
          Settings and configurations
        </Typography>
        <Tabs onChange={handleChangeTab} value={params.tab}>
          {tabs.map((t) => (
            <Tab
              key={t.tab}
              label={t.title}
              value={t.tab}
              id={`settings-tab-${t.tab}`}
              aria-controls={`settings-tabpanel-${t.tab}`}
            />
          ))}
        </Tabs>
        <Box mt={3} sx={{ height: '100%' }}>
          {tab && (
            <div
              role="tabpanel"
              id={`settings-tabpanel-${tab}`}
              aria-labelledby={`settings-tab-${tab}`}
              style={{ height: '100%' }}
            >
              <tab.component />
            </div>
          )}
        </Box>
      </Box>
    </DashboardWrapper>
  )
}
