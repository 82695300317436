import { DatePicker, DatePickerProps, LocalizationProvider } from '@mui/lab'
import DateAdapter from '@mui/lab/AdapterDayjs'
import { Box, Button, TextField, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { API_RECORD_DATE_FORMAT } from 'utils/const'

type Props = {
  dateFrom: string
  dateTo: string
  onChangeRange: (from: string, to: string) => void
}

export const DateRangeSidebarPanel: React.FC<Props> = (props) => {
  const { dateFrom, dateTo, onChangeRange } = props

  const [selectedStartDate, handleStartDateChange] = React.useState(
    dayjs(dateFrom),
  )
  const [selectedEndDate, handleEndDateChange] = React.useState(dayjs(dateTo))

  const apply = React.useCallback(() => {
    onChangeRange(
      selectedStartDate.startOf('month').format('YYYY-MM'),
      selectedEndDate.endOf('month').format('YYYY-MM'),
    )
  }, [onChangeRange, selectedStartDate, selectedEndDate])

  const selectedDateRangePreview = `${selectedStartDate.format(
    API_RECORD_DATE_FORMAT,
  )} - ${selectedEndDate.format(API_RECORD_DATE_FORMAT)}`

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <Box pt={3} pl={2} pr={2}>
        <DatePicker
          views={['year', 'month']}
          label="Start Date"
          value={selectedStartDate}
          onChange={handleStartDateChange as DatePickerProps['onChange']}
          maxDate={selectedEndDate}
          openTo="month"
          renderInput={(params) => <TextField {...params} />}
        />
      </Box>
      <Box pt={3} pl={2} pb={2} pr={2}>
        <DatePicker
          views={['year', 'month']}
          label="End Date"
          value={selectedEndDate}
          onChange={handleEndDateChange as DatePickerProps['onChange']}
          minDate={selectedStartDate}
          openTo="month"
          renderInput={(params) => <TextField {...params} />}
        />
      </Box>
      <Box display="flex" justifyContent="center">
        <Button variant="contained" onClick={apply}>
          Apply
        </Button>
      </Box>
      <Box pt={4} pl={2} pr={2} pb={2}>
        <Typography variant="caption">
          * Effective applied date range:
          <br />
          <b>{selectedDateRangePreview}</b>
        </Typography>
      </Box>
    </LocalizationProvider>
  )
}
