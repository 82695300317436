import {
  Divider,
  Typography,
  Grid,
  FormControl,
  InputLabel,
} from '@mui/material'
import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { RiskVsCost } from '../components/Charts/RiskVsCost'
import DashboardChartHeader from '../components/DashboardChartHeader'
import { DashboardWrapper } from 'components/DashboardWrapper/DashboardWrapper'
import { GenericContainer } from '../components/GenericContainer'
import { BusinessOptimizationChart } from '../components/Charts/BusinessOptimizationChart'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { grey } from '@mui/material/colors'
import { pageHeadingStyles } from '../styles/page-heading-styles'
import { theme } from 'utils/theme'
import DashboardHeadcountTable from '../components/DashboardHeadcountTable'
import tableData1 from '../data/headcountPlanningTable_1.json'
import styled from 'styled-components'
import { BusinessOptimizationheadcount } from '../components/BusinessOptimizationheadcount'
import { BusinessOptimizationMarketing } from '../components/BusinessOptimizationMarketing'
import { WidgetHeader } from '../components/WidgetHeader'

interface CircularProgressWithLabelProps {
  value: number
  color: string
}

const $Wrapper = styled.div`
  background: #fff;
  border: 1px solid #d7d6d5;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
  border-radius: 10px 10px;
  margin: ${theme.spacing(0.2)};
  margin-bottom: ${theme.spacing(0.8)};
  width: 100%;
  height: 50vh;
`

const availablePlans = [
  {
    plan: 'cashplanning',
    title: 'Budget 2023',
  },
  {
    plan: 'vendorsubscription',
    title: 'Board approval plan 2022',
  },
  {
    plan: 'allocations',
    title: 'Sep 2022 forecast',
  },
  {
    plan: 'allocations',
    title: 'Aug 2022 forecast',
  },
]

const optimizationMetricsList = [
  {
    plan: 'cashplanning',
    title: 'ARR',
  },
  {
    plan: 'vendorsubscription',
    title: 'NDR',
  },
  {
    plan: 'allocations',
    title: 'Churn rate',
  },
]

const showBy = [
  {
    plan: 'cashplanning',
    title: 'All',
  },
  {
    plan: 'vendorsubscription',
    title: 'Region',
  },
  {
    plan: 'allocations',
    title: 'Product',
  },
  {
    plan: 'allocations',
    title: 'Department',
  },
  {
    plan: 'allocations',
    title: 'Segment',
  },
]

const initialKPIs = [
  {
    kpiName: 'Goal',
    actual: 117,
    assumption: 79.3,
    assumptionActualRatio: 97,
    units: { prefix: '$', suffix: 'M' },
    ratioColor: '#4CAF50',
  },
  {
    kpiName: 'Actual',
    actual: 53,
    assumption: 40,
    assumptionActualRatio: 66,
    units: { prefix: '$', suffix: 'M' },
    ratioColor: '#FF8346',
  },
  {
    kpiName: 'Cost',
    actual: 28,
    assumption: 28,
    assumptionActualRatio: 26,
    units: { prefix: '$', suffix: 'M' },
    ratioColor: '#4CAF50',
  },
]

const versionAdjustmentData = [
  {
    kpiName: 'Risk estimation',
    actual: 36,
    assumption: 79.3,
    assumptionActualRatio: 97,
    units: { prefix: '', suffix: '%' },
    ratioColor: '#4CAF50',
  },
  {
    kpiName: 'Delta cost',
    actual: 920,
    assumption: 40,
    assumptionActualRatio: 66,
    units: { prefix: '$', suffix: 'K' },
    ratioColor: '#FF8346',
  },
  {
    kpiName: 'Cost',
    actual: 28.92,
    assumption: 28,
    assumptionActualRatio: 26,
    units: { prefix: '$', suffix: 'M' },
    ratioColor: '#4CAF50',
  },
]

const CircularProgressWithLabel: React.FC<CircularProgressWithLabelProps> = ({
  value,
  color,
}) => (
  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
    <Box
      sx={{
        'position': 'relative',
        'display': 'flex',
        'alignItems': 'center',
        'justifyContent': 'center',
        'width': '200px',
        'height': '200px',
        'borderRadius': '50%',
        'background': '#f2f3f8',
        '@media (max-width: 1700px)': {
          width: '230px',
          height: '230px',
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: '50%',
          overflow: 'hidden',
        }}
      >
        <svg
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            transform: 'rotate(-90deg)',
          }}
          viewBox="0 0 200 200"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            r="90"
            cx="100"
            cy="100"
            fill="transparent"
            strokeDasharray="565.48"
            strokeDashoffset={565.48 * (value < 100 ? (100 - value) / 100 : 0)}
            stroke={color}
            strokeWidth="100"
          />
        </svg>
      </Box>

      <Box
        sx={{
          'position': 'relative',
          'display': 'flex',
          'flexDirection': 'column',
          'alignItems': 'center',
          'justifyContent': 'center',
          'width': '130px',
          'height': '130px',
          'boxShadow': '0px 2px 2px rgba(0, 0, 0, 0.3)',
          'borderRadius': '50%',
          'background': '#fff',
          'fontSize': '26px',
          'lineHeight': '21px',
          color,
          '@media (max-width: 1700px)': {
            width: '85px',
            height: '85px',
            fontSize: '18px',
            lineHeight: '14px',
          },
        }}
      >
        <Box
          sx={{
            fontWeight: 700,
            fontSize: 34,
          }}
        >
          {`${Math.round(value)}`}%
        </Box>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: '50%',
          overflow: 'hidden',
          boxShadow: 'inset 0px 2px 2px rgba(0, 0, 0, 0.3)',
          pointerEvents: 'none',
        }}
      />
    </Box>
  </Box>
)

const topWidgetsLayout = [
  { i: 'ARR', x: 0, y: 0, w: 1.8, h: 2, static: true },
  { i: 'ASP', x: 1.8, y: 0, w: 1.8, h: 2, static: true },
  { i: 'WIN_RATE', x: 3.6, y: 0, w: 1.8, h: 2, static: true },
  { i: 'RENEWAL_RATE', x: 5.4, y: 0, w: 1.8, h: 2, static: true },
  { i: 'NDR', x: 7.2, y: 0, w: 1.8, h: 2, static: true },
  { i: 'COLLECTIONS', x: 9, y: 0, w: 1.8, h: 2, static: true },
]

const versionAdjustmentHeader = (
  <Box
    sx={{
      width: '100%',
      alignItems: 'center',
      fontSize: 23,
    }}
  >
    <WidgetHeader title="Version adjustments" link="/" />
    <Box
      sx={{
        width: '100%',
        alignItems: 'center',
        fontSize: 23,
        paddingLeft: '20px',
      }}
    >
      <Grid container spacing={2}>
        {versionAdjustmentData.map((widgData, i) => (
          <Grid item xs={3} key={topWidgetsLayout[i].i}>
            <Box
              sx={{
                'width': '100%',
                'marginLeft': '0px',
                'display': 'flex',
                'alignItems': 'center',
                'fontSize': 23,
                'padding': '15px 0px 0px 0px',
                'whiteSpace': 'nowrap',
                'marginBottom': '10px',
                '@media (max-width: 1700px)': {
                  fontSize: 15,
                },
              }}
            >
              <Typography style={{ fontSize: '18px', color: '#282828' }}>
                {widgData.kpiName}:
              </Typography>
              <Box
                sx={{
                  'ml': '10px',
                  '@media (max-width: 1700px)': {
                    ml: '50px',
                  },
                }}
              >
                <Typography
                  variant="caption"
                  mr={1}
                  style={{ fontWeight: 700, fontSize: '1em' }}
                >
                  {`${widgData.units.prefix}${widgData.actual}${widgData.units.suffix}`}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  </Box>
)

const headcountMarketing = (
  <Box width="calc((100vw - 112px) / 2)" height="100%">
    {versionAdjustmentHeader}
    <BusinessOptimizationheadcount />
    <BusinessOptimizationMarketing />
  </Box>
)
const headcount = (
  <>
    <WidgetHeader title="Version adjustments" link="/" />
  </>
)

export const BusinessOptimization: React.FC = () => {
  const [graphState, setGraphState] = useState(false)
  const sideGraphsHeadcount = graphState
    ? [
        <RiskVsCost
          key="555"
          graphState={graphState}
          setGraphState={setGraphState}
        />,
        headcountMarketing,
      ]
    : [
        <RiskVsCost
          key="555"
          graphState={graphState}
          setGraphState={setGraphState}
        />,
        // headcount,
      ]

  const versionDetails = (
    <Box
      display="flex"
      alignItems="center"
      color={grey[700]}
      gap={1}
      marginLeft={5}
    >
      <FormControl size="small" style={{ width: '200px', marginRight: '12px' }}>
        <InputLabel id="select-label">Show by</InputLabel>

        <Select
          labelId="select-label"
          id="simple-select"
          defaultValue="All"
          label="Show by"
        >
          {showBy.map((item) => (
            <MenuItem key={item.plan} value={item.title}>
              {item.title}
            </MenuItem>
          ))}{' '}
        </Select>
      </FormControl>
    </Box>
  )

  const HeaderComponent = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: 1,
        mb: '3px',
        width: '100%',
      }}
    >
      <Box
        display="flex"
        gap={theme.spacing(1.25)}
        sx={{
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h1"
          style={{
            marginRight: '20px',
            ...pageHeadingStyles(),
          }}
        >
          Business optimization
        </Typography>
      </Box>
      <Box>
        <FormControl
          size="small"
          style={{ width: '200px', marginRight: '12px' }}
        >
          <InputLabel id="select-label">Optimization Metric</InputLabel>

          <Select
            labelId="select-label"
            id="simple-select"
            defaultValue="ARR"
            label="Optimization Metric"
          >
            {optimizationMetricsList.map((item) => (
              <MenuItem key={item.plan} value={item.title}>
                {item.title}
              </MenuItem>
            ))}{' '}
          </Select>
        </FormControl>
        <FormControl
          size="small"
          style={{ width: '200px', marginRight: '12px' }}
        >
          <InputLabel id="select-label">Versions</InputLabel>

          <Select
            labelId="select-label"
            id="simple-select"
            defaultValue="Budget 2023"
            label="Versions"
          >
            {availablePlans.map((item) => (
              <MenuItem key={item.plan} value={item.title}>
                {item.title}
              </MenuItem>
            ))}{' '}
          </Select>
        </FormControl>
      </Box>
    </Box>
  )

  const riskWidget = (
    <Box
      sx={{
        // 'display': 'flex',
        'justifyContent': 'flex-start',
        'alignItems': 'center',
        'backgroundColor': 'white',
        'width': '100%',
        'padding': '10px 5px 10px 20px',
        'borderRadius': '5px',
        // ...wrapperBoxStyle,
        '@media (max-width: 1700px)': {
          padding: '7px 3px 7px 10px',
        },
      }}
    >
      <Box
        sx={{
          'fontSize': 26,
          'whiteSpace': 'nowrap',
          '@media (max-width: 1700px)': {
            fontSize: 15,
          },
        }}
      >
        <Typography
          variant="caption"
          mr={1}
          style={{ fontWeight: 700, fontSize: '1em' }}
        >
          Risk Estimation
        </Typography>
        <Box
          sx={{
            marginRight: '12px',
            marginTop: '12px',
          }}
        >
          <CircularProgressWithLabel
            value={!graphState ? 41 : 36}
            color="#FF9B25"
          />
        </Box>
      </Box>
    </Box>
  )

  return (
    <DashboardWrapper
      enableThreads
      wrapperStyle={{
        maxWidth: 'calc(100vw - 40px)',
      }}
    >
      <Box display="flex" justifyContent="space-between" width="100%">
        {HeaderComponent}
      </Box>

      <Box>
        <Box sx={{ height: '100%' }}>
          <GenericContainer
            content={
              <>
                <DashboardChartHeader legend={[]} title="Risk estimation" />
                <Grid container spacing={1}>
                  {versionDetails}
                  {initialKPIs.map((widgData, i) => (
                    <Grid item xs={2} key={topWidgetsLayout[i].i}>
                      <Box
                        sx={{
                          'width': '100%',
                          'marginLeft': '50px',
                          'display': 'flex',
                          'alignItems': 'center',
                          'fontSize': 23,
                          'padding': '10px 5px 10px 20px',
                          'whiteSpace': 'nowrap',
                          'marginBottom': '10px',
                          '@media (max-width: 1700px)': {
                            fontSize: 15,
                          },
                        }}
                      >
                        <Typography
                          style={{ fontSize: '18px', color: '#282828' }}
                        >
                          {widgData.kpiName}:
                        </Typography>
                        <Box
                          sx={{
                            'ml': '20px',
                            '@media (max-width: 1700px)': {
                              ml: '50px',
                            },
                          }}
                        >
                          <Typography
                            variant="caption"
                            mr={1}
                            style={{ fontWeight: 700, fontSize: '1em' }}
                          >
                            {`${widgData.units.prefix}${widgData.actual}${widgData.units.suffix}`}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                <Box width="100%" height="100%" display="flex">
                  <BusinessOptimizationChart
                    key="51"
                    graphState={graphState}
                    setGraphState={setGraphState}
                  />
                  <Box width="100%" height="100%">
                    {riskWidget}
                  </Box>
                </Box>
              </>
            }
          />
        </Box>
        <Box display="flex" minHeight="38vh">
          {sideGraphsHeadcount.map((graph, i) => (
            <$Wrapper key={`lala-${graph}`}>
              <Box
                sx={{
                  position: 'relative',
                  height: '100%',
                  maxHeight: graph !== headcountMarketing ? '36vh' : '100%',
                  flex: 1,
                }}
              >
                {graph}
              </Box>
            </$Wrapper>
          ))}
        </Box>
      </Box>
    </DashboardWrapper>
  )
}
