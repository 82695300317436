/* eslint-disable no-param-reassign */
import type { ColDef, ColGroupDef } from 'ag-grid-community'

import type { TimeSegmentationFrame } from '../../../types'
import dayjs from 'dayjs'
import { getDateLabelByTimeSegmentation } from 'utils/date-utils'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'

dayjs.extend(quarterOfYear)

type ColDefProcessor = (colGroupDef: ColGroupDef | ColDef) => void

export const makeFormatColDef =
  (type: TimeSegmentationFrame, minDate?: string): ColDefProcessor =>
  (colDef) => {
    const columnValue = colDef.pivotKeys?.[0] as string

    const dateLabel = getDateLabelByTimeSegmentation(columnValue, type, true)

    colDef.headerName = dateLabel as string
  }
