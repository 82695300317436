import {
  AggregatedTableViewTable,
  AggregatedTableViewTableProps,
} from './AggregatedTableViewTable'
import { Skeleton, Typography } from '@mui/material'

import type { Maybe } from '../../types'
import { NowIsOverridenSnack } from '../snackbars/NowIsOverridenSnack'
import React from 'react'
import ResizePanel from '../../vendor/react-resize-panel-ts/ResizePanel'
import { SecondaryChart } from './SecondaryChart'
import { SecondaryTable } from './SecondaryTable'
import { SecondaryTableContext } from '../../context/SecondaryTableContext'
import styled from 'styled-components'
import { useSecondaryTableContext } from '../../context/useSecondaryTableContext'
import { useVerticalResizeSection } from 'hooks/useVerticalResizeSection'
import { pageHeadingStyles } from '../../features/dashboard/styles/page-heading-styles'

const $Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: nowrap column;
  overflow: hidden;
`

const $DetailsContainer = styled.div`
  flex-grow: 1;
  background: var(--color-bg-chart);
`

type AggregatedTableViewProps = AggregatedTableViewTableProps

export const AggregatedTableView: React.FC<AggregatedTableViewProps> = (
  props,
) => {
  const { propertyColsConfType, ...viewProps } = props

  const height = '60%'
  const { resized, isDragging, handleResize, handleDragStart } =
    useVerticalResizeSection()
  const secondaryTableCtx = useSecondaryTableContext({
    propertyColsConfType,
  })

  const style = resized ? {} : { height }

  let secondaryElement: Maybe<React.ReactElement> = null

  if (secondaryTableCtx.secondaryViewType === 'table') {
    secondaryElement = secondaryTableCtx.rowData?.length ? (
      <SecondaryTable />
    ) : null
  } else if (secondaryTableCtx.secondaryViewType === 'chart') {
    secondaryElement = isDragging ? (
      <Skeleton width="90%" height="90%" style={{ margin: '0 auto' }} />
    ) : (
      <SecondaryChart />
    )
  }

  return (
    <SecondaryTableContext.Provider value={secondaryTableCtx}>
      <$Container>
        {props.title && (
          <Typography
            variant="h1"
            style={{
              marginRight: '20px',
              ...pageHeadingStyles(),
            }}
          >
            {props.title}
          </Typography>
        )}
        <ResizePanel
          direction="s"
          style={style}
          onResized={handleResize}
          onDragStart={handleDragStart}
        >
          <AggregatedTableViewTable
            propertyColsConfType={propertyColsConfType}
            {...viewProps}
          />
        </ResizePanel>
        <$DetailsContainer>{secondaryElement}</$DetailsContainer>
      </$Container>
      <NowIsOverridenSnack />
    </SecondaryTableContext.Provider>
  )
}
