/**
 Reducers functions, that used by @reduxjs/toolkit in createSlice function
 @see https://redux-toolkit.js.org/api/createSlice
 Notice! Each reducer used by @reduxjs/toolkit as a recipe of immer.produce function
 @see https://immerjs.github.io/immer/produce
 */

import type { PayloadAction } from '@reduxjs/toolkit'
import { EmitRequestPayload } from '../../../../redux/shared/request/reducers'
import {
  SvGlobalOperationKey,
  SvGlobalOperations,
  SvVersionOperationKey,
  SvVersionState,
  SvDocumentOperationKey,
  SvDocumentState,
} from '../../types'
import type { State } from './state'
import type { WritableDraft } from 'redux/types'
import { makeDocumentState, makeVersionState } from './utils'

/** ******************* global operations ******************* */
export type EmitGlobalOperationPayload<P = never> = EmitRequestPayload<{
  operation: SvGlobalOperationKey
  params?: P
}>
export type EmitGlobalOperation<P = never> = PayloadAction<
  EmitGlobalOperationPayload<P>
>

export function emitGlobalOperation<P = never>(
  state: WritableDraft<State>,
  action: EmitGlobalOperation<P>,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
): void {}

export type StartGlobalOperationPayload = {
  operation: SvGlobalOperationKey
}
export type StartGlobalOperation = PayloadAction<StartGlobalOperationPayload>

export function startGlobalOperation(
  state: WritableDraft<State>,
  action: StartGlobalOperation,
): void {
  state.globalOperations[action.payload.operation].status = 'loading'
  state.globalOperations[action.payload.operation].datetime =
    new Date().toISOString()
}

export type EndGlobalOperationPayload = {
  operation: SvGlobalOperationKey
  error: SvGlobalOperations[SvGlobalOperationKey]['error']
  result: SvGlobalOperations[SvGlobalOperationKey]['result']
}
export type EndGlobalOperation = PayloadAction<EndGlobalOperationPayload>

export function endGlobalOperation(
  state: WritableDraft<State>,
  action: EndGlobalOperation,
): void {
  const { operation, result, error } = action.payload
  state.globalOperations[operation].status = error ? 'error' : 'success'
  state.globalOperations[operation].error = error
  state.globalOperations[operation].result = result
}

/** ******************* config ******************* */
export type SetVersionsConfigPayload = {
  versionsConfig: State['versionsConfig']
}
export type SetVersionsConfig = PayloadAction<SetVersionsConfigPayload>

export function setVersionsConfig(
  state: WritableDraft<State>,
  action: SetVersionsConfig,
): void {
  state.versionsConfig = action.payload.versionsConfig
}

export type SetDocsConfigPayload = {
  docsConfig: State['docsConfig']
}
export type SetDocsConfig = PayloadAction<SetDocsConfigPayload>

export function setDocsConfig(
  state: WritableDraft<State>,
  action: SetDocsConfig,
): void {
  state.docsConfig = action.payload.docsConfig
}

/** ******************* versions ******************* */
export type SetVersionsListPayload = {
  versionsList: State['versionsList']
}
export type SetVersionsList = PayloadAction<SetVersionsListPayload>

export function setVersionsList(
  state: WritableDraft<State>,
  action: SetVersionsList,
): void {
  state.versionsList = action.payload.versionsList
}

export type EmitVersionOperationPayload<P = never> = EmitRequestPayload<{
  id: SvVersionState['id']
  operation: SvVersionOperationKey
  params?: P
}>
export type EmitVersionOperation<P = never> = PayloadAction<
  EmitVersionOperationPayload<P>
>

export function emitVersionOperation<P = never>(
  state: WritableDraft<State>,
  action: EmitVersionOperation<P>,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
): void {}

export type StartVersionOperationPayload = {
  id: SvVersionState['id']
  operation: SvVersionOperationKey
}
export type StartVersionOperation = PayloadAction<StartVersionOperationPayload>

export function startVersionOperation(
  state: WritableDraft<State>,
  action: StartVersionOperation,
): void {
  const { id, operation } = action.payload
  let versionIndex = state.versions.findIndex((v) => v.id === id)
  if (versionIndex === -1) {
    state.versions.push(makeVersionState(id, null))
    versionIndex = state.versions.length - 1
  }
  state.versions[versionIndex].operations[operation].status = 'loading'
  state.versions[versionIndex].operations[operation].datetime =
    new Date().toISOString()
}

export type EndVersionOperationPayload = {
  id: SvVersionState['id']
  operation: SvVersionOperationKey
  error: SvVersionState['operations'][SvVersionOperationKey]['error']
  result: SvVersionState['operations'][SvVersionOperationKey]['result']
}
export type EndVersionOperation = PayloadAction<EndVersionOperationPayload>

export function endVersionOperation(
  state: WritableDraft<State>,
  action: EndVersionOperation,
): void {
  const { id, operation, error, result } = action.payload
  const versionIndex = state.versions.findIndex((v) => v.id === id)
  if (versionIndex === -1) {
    return
  }
  state.versions[versionIndex].operations[operation].status = error
    ? 'error'
    : 'success'
  state.versions[versionIndex].operations[operation].error = error
  state.versions[versionIndex].operations[operation].result = result
}

export type SetVersionDataPayload = {
  id: SvVersionState['id']
  data: SvVersionState['data']
}
export type SetVersionData = PayloadAction<SetVersionDataPayload>

export function setVersionData(
  state: WritableDraft<State>,
  action: SetVersionData,
): void {
  const { id, data } = action.payload
  const versionIndex = state.versions.findIndex((v) => v.id === id)
  if (versionIndex === -1) {
    return
  }
  state.versions[versionIndex].data = data
}

export type AddVersionPayload = SvVersionState
export type AddVersion = PayloadAction<AddVersionPayload>

export function addVersion(
  state: WritableDraft<State>,
  action: AddVersion,
): void {
  state.versions.push(action.payload)
}

export type RemoveVersionPayload = {
  id: SvVersionState['id']
}
export type RemoveVersion = PayloadAction<RemoveVersionPayload>

export function removeVersion(
  state: WritableDraft<State>,
  action: RemoveVersion,
): void {
  const { id } = action.payload
  const versionIndex = state.versions.findIndex((v) => v.id === id)
  if (versionIndex === -1) {
    return
  }
  state.versions.splice(versionIndex, 1)
}

/** ******************* documents ******************* */
export type EmitDocumentOperationPayload<P = never> = EmitRequestPayload<{
  id: SvDocumentState['id']
  operation: SvDocumentOperationKey
  params?: P
}>
export type EmitDocumentOperation<P = never> = PayloadAction<
  EmitDocumentOperationPayload<P>
>

export function emitDocumentOperation<P = never>(
  state: WritableDraft<State>,
  action: EmitDocumentOperation<P>,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
): void {}

export type StartDocumentOperationPayload = {
  id: SvDocumentState['id']
  operation: SvDocumentOperationKey
}
export type StartDocumentOperation =
  PayloadAction<StartDocumentOperationPayload>

export function startDocumentOperation(
  state: WritableDraft<State>,
  action: StartDocumentOperation,
): void {
  const { id, operation } = action.payload
  let docIndex = state.documents.findIndex((d) => d.id === id)
  if (docIndex === -1) {
    state.documents.push(makeDocumentState(id))
    docIndex = state.documents.length - 1
  }
  state.documents[docIndex].operations[operation].status = 'loading'
  state.documents[docIndex].operations[operation].datetime =
    new Date().toISOString()
}

export type EndDocumentOperationPayload = {
  id: SvDocumentState['id']
  operation: SvDocumentOperationKey
  error: SvDocumentState['operations'][SvDocumentOperationKey]['error']
  result: SvDocumentState['operations'][SvDocumentOperationKey]['result']
}
export type EndDocumentOperation = PayloadAction<EndDocumentOperationPayload>

export function endDocumentOperation(
  state: WritableDraft<State>,
  action: EndDocumentOperation,
): void {
  const { id, operation, error, result } = action.payload
  const docIndex = state.documents.findIndex((d) => d.id === id)
  if (docIndex === -1) {
    return
  }
  state.documents[docIndex].operations[operation].status = error
    ? 'error'
    : 'success'
  state.documents[docIndex].operations[operation].result = result
  state.documents[docIndex].operations[operation].error = error
}

export type SetDocumentDataPayload = {
  id: SvDocumentState['id']
  data: SvDocumentState['data']
}
export type SetDocumentData = PayloadAction<SetDocumentDataPayload>

export function setDocumentData(
  state: WritableDraft<State>,
  action: SetDocumentData,
): void {
  const { id, data } = action.payload
  const docIndex = state.documents.findIndex((d) => d.id === id)
  if (docIndex === -1) {
    return
  }
  state.documents[docIndex].data = data
}

export default {
  emitGlobalOperation,
  startGlobalOperation,
  endGlobalOperation,
  setVersionsConfig,
  setDocsConfig,
  setVersionsList,
  emitVersionOperation,
  startVersionOperation,
  endVersionOperation,
  setVersionData,
  addVersion,
  removeVersion,
  emitDocumentOperation,
  startDocumentOperation,
  endDocumentOperation,
  setDocumentData,
}
