import DashboardComboBarLineChart, {
  DashboardComboBarLineChartData,
} from '../DashboardComboBarLineChart'
import React, { useEffect, useState } from 'react'

import { HeadcountStates } from '../../ducks/dashboard/types'
import chart1 from '../../data/arrCapacity_1.json'
import chart2 from '../../data/arrCapacity_2.json'
import chartMetadata from '../../data/arrCapacityMetadata.json'
import { selectHeadcountPageState } from '../../ducks/dashboard/selectors'
import { useSelector } from 'react-redux'

export const CapacityChart: React.FC = () => {
  const headcountState = useSelector(selectHeadcountPageState)
  const [chartData, setChartData] = useState(chart1)

  useEffect(() => {
    if (headcountState === HeadcountStates.suggestionClicked) {
      setChartData(chart2)
    }
  }, [headcountState])

  return (
    <DashboardComboBarLineChart
      data={chartData}
      legend={chartMetadata.legend}
      title={chartMetadata.title}
      tooltipNameMapper={chartMetadata.tooltipNameMapper}
      heightRatio={2.3}
      widthRatio={2.4}
      stroke={chartMetadata.stroke}
      dollars={[true, true]}
    />
  )
}
