import {
  Box,
  Checkbox,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material'

import { DashboardStates } from '../ducks/dashboard/types'
import NewForecastModal from './NewForecastModal'
import React from 'react'
import StyledSelect from 'components/atoms/StyledSelect'
// import plans from '../data/availablePlans.json'
import { selectDashboardPageState } from '../ducks/dashboard/selectors'
import { useSelector } from 'react-redux'
import { pageHeadingStyles } from '../styles/page-heading-styles'
import { pageContainerPaddings } from '../styles/page-container-paddings'
import dayjs from 'dayjs'
import usePlans from 'hooks/usePlans'

const DashboardHeader: React.FC = () => {
  const currentState = useSelector(selectDashboardPageState)
  const { getPlans } = usePlans()
  const plans = getPlans()
  const availablePlans = plans[currentState === DashboardStates.initial ? 0 : 1]
  const currentPlan = [
    availablePlans[0],
    currentState === DashboardStates.initial
      ? availablePlans[3]
      : availablePlans[4],
  ].join(', ')

  const [updateTime] = React.useState(dayjs().add(-1, 'hour'))

  return (
    <Box
      display="flex"
      pt={3}
      pb={3}
      sx={{
        ...pageContainerPaddings(),
      }}
      justifyContent="space-between"
    >
      <Box display="flex" alignItems="center">
        <Typography
          style={{
            marginRight: '20px',
            ...pageHeadingStyles(),
          }}
        >
          Analytics
        </Typography>
        <Box>
          <Typography
            style={{
              fontSize: 12,
              color: '#a3a3a3',
            }}
          >
            Last Updated
          </Typography>
          <Typography
            style={{
              fontSize: 14,
              color: '#303030',
            }}
          >
            at {updateTime.format('HH:mm MMMM Do, YYYY')}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" pt="3px">
        <StyledSelect
          style={{ width: '340px', marginRight: '12px' }}
          label="Versions"
          plan={currentPlan}
        >
          {availablePlans.map((plan: string) => (
            <MenuItem key={plan} value={plan}>
              <ListItemIcon>
                <Checkbox checked={currentPlan.includes(plan)} />
              </ListItemIcon>
              <ListItemText primary={plan} />
            </MenuItem>
          ))}
        </StyledSelect>
        <NewForecastModal />
      </Box>
    </Box>
  )
}

export default DashboardHeader
