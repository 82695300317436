/**
 * Global override for current date
 * Needed to test application behaviour in `future` dates.
 * `setNow` must be allowed only for @fintastic.ai users
 */

import { API_RECORD_DATE_FORMAT } from './const'
import dayjs from 'dayjs'

let now = new Date()

/** Apply current date override (non-reactive setter) */
export const overrideNowDate = (newNow: Date): void => {
  now = newNow

  // eslint-disable-next-line no-console
  console.log('Now is overriden to', now)
}

/** Get current date (or override, if present) */
export const getNow = (): Date => now

export const isNowOverridden = (): boolean =>
  dayjs().format(API_RECORD_DATE_FORMAT) !==
  dayjs(now).format(API_RECORD_DATE_FORMAT)
