import { AgGridColumn } from 'ag-grid-react'
import React from 'react'
import { datesComparator } from '../utils/dates-comparator'

export const useBridgeDateColumn = (): React.ReactElement => (
  <AgGridColumn
    key="bridge-date"
    field="date"
    filter="agDateColumnFilter"
    hide={false}
    pivot={true}
    filterParams={{
      comparator: datesComparator,
    }}
    suppressMovable
    suppressColumnsToolPanel
    suppressFiltersToolPanel
  />
)
