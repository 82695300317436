import { Link, matchPath, useLocation } from 'react-router-dom'
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material'
import AccountCircle from '@mui/icons-material/AccountCircle'

import { Icon } from '../Icon/Icon'
import React from 'react'
import clsx from 'clsx'
import { routes as dashboardRoutes } from 'features/dashboard/routes'
import { routes } from '../../router/routes'
import { styled } from '@mui/material/styles'

const PREFIX = 'StyledList'

const classes = {
  listItem: `${PREFIX}-listItem`,
  listItemText: `${PREFIX}-listItemText`,
  userpicPlaceholder: `${PREFIX}-userpicPlaceholder`,
  textOverflowEllipsis: `${PREFIX}-textOverflowEllipsis`,
  logoutIcon: `${PREFIX}-logoutIcon`,
}

const StyledList = styled(List)(() => ({
  [`& .${classes.listItem}`]: {
    'marginBottom': '0.5rem',
    'paddingTop': '0.25rem',
    'paddingBottom': '0.25rem',
    '& path': {
      opacity: 0.4,
    },
  },

  [`& .${classes.listItemText}`]: {
    paddingLeft: '2.5rem',
  },

  [`& .${classes.userpicPlaceholder}`]: {
    position: 'absolute',
    fill: 'var(--color-icon-nav-inactive)',
  },

  [`& .${classes.textOverflowEllipsis}`]: {
    paddingRight: '2rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  [`& .${classes.logoutIcon}`]: {
    'position': 'absolute',
    'right': '1rem',
    'cursor': 'pointer',
    '&:hover > path': {
      fill: 'var(--color-icon-nav-selected)',
    },
  },
}))

const $Divider = styled('div')`
  height: 2rem;
`

const $ListItemSelectedMarker = styled('div')`
  position: absolute;
  top: 0;
  left: -4px;
  width: 8px;
  height: 100%;
  border-radius: 80px;
  background-image: linear-gradient(to bottom, #ff5b1e, #ffa727);
`

export const NavigationDrawerItems: React.FC<{ open: boolean }> = ({
  open,
}) => {
  const { pathname } = useLocation()

  const wrapTooltip = React.useCallback(
    (
      tooltip: string | React.ReactElement,
      content: React.ReactElement,
    ): React.ReactElement =>
      open ? (
        content
      ) : (
        <Tooltip
          title={<Typography variant="body1">{tooltip}</Typography>}
          placement="right"
          arrow
        >
          {content}
        </Tooltip>
      ),
    [open],
  )

  const generateSubMenuKey = () => `submenu-${Math.random()}`

  const wrapSubMenu = React.useCallback(
    (
      subMenu: React.ReactElement,
      content: React.ReactElement,
      key: React.Key,
    ): React.ReactElement =>
      open ? (
        content
      ) : (
        <Tooltip
          title={<Typography variant="body1">{subMenu}</Typography>}
          placement="right"
          arrow
          key={key}
        >
          {content}
        </Tooltip>
      ),
    [open],
  )

  return (
    <StyledList>
      {wrapSubMenu(
        <>
          <ListItem
            to={routes.bridge}
            button
            component={Link}
            className={classes.listItem}
          >
            {pathname === routes.bridge && <$ListItemSelectedMarker />}
            <ListItemText
              primary="ARR Bridge"
              className={clsx(classes.listItemText)}
            />
          </ListItem>
          <ListItem
            to={routes.pnl}
            button
            component={Link}
            className={classes.listItem}
          >
            {pathname === routes.pnl && <$ListItemSelectedMarker />}
            <ListItemText
              primary="P&L"
              className={clsx(classes.listItemText)}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary="Cash Flow"
              className={clsx(classes.listItemText)}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary="Headcount"
              className={clsx(classes.listItemText)}
            />
          </ListItem>
          <ListItem
            className={classes.listItem}
            to={routes.newLogosArr}
            button
            component={Link}
          >
            <ListItemText
              primary="Business Health"
              className={clsx(classes.listItemText)}
            />
          </ListItem>
        </>,
        <ListItem
          to={dashboardRoutes.analytics}
          button
          component={Link}
          className={classes.listItem}
        >
          {matchPath(pathname, { path: dashboardRoutes.analytics }) && (
            <$ListItemSelectedMarker />
          )}
          <Icon
            icon="main-menu-analytics"
            color={
              matchPath(pathname, { path: dashboardRoutes.analytics })
                ? 'var(--color-icon-nav-selected)'
                : 'var(--color-icon-nav-inactive)'
            }
            style={{ position: 'absolute' }}
          />
          <ListItemText
            primary="Analytics"
            className={clsx(classes.listItemText)}
          />
        </ListItem>,
        'submenu-1',
      )}
      {wrapSubMenu(
        <>
          <ListItem
            to={routes.modelDrivers}
            button
            component={Link}
            className={classes.listItem}
          >
            {pathname === routes.modelDrivers && <$ListItemSelectedMarker />}
            <ListItemText
              primary="Model Drivers"
              className={clsx(classes.listItemText)}
            />
          </ListItem>
          <ListItem
            to={routes.expenses}
            button
            component={Link}
            className={classes.listItem}
          >
            {pathname === routes.expenses && <$ListItemSelectedMarker />}
            <ListItemText
              primary="Expenses / income input"
              className={clsx(classes.listItemText)}
            />
          </ListItem>
          <ListItem
            to={routes.headcountdata}
            button
            component={Link}
            className={classes.listItem}
          >
            {pathname === routes.headcountdata && <$ListItemSelectedMarker />}
            <ListItemText
              primary="Headcount"
              className={clsx(classes.listItemText)}
            />
          </ListItem>
          <ListItem
            to={routes.capex}
            button
            component={Link}
            className={classes.listItem}
          >
            {pathname === routes.capex && <$ListItemSelectedMarker />}
            <ListItemText
              primary="Capex"
              className={clsx(classes.listItemText)}
            />
          </ListItem>
          <ListItem
            to={routes.cashplanning}
            button
            component={Link}
            className={classes.listItem}
          >
            {pathname === routes.cashplanning && <$ListItemSelectedMarker />}
            <ListItemText
              primary="Cash planning (inc. debt)"
              className={clsx(classes.listItemText)}
            />
          </ListItem>
          <ListItem
            to={routes.vendorsubscription}
            button
            component={Link}
            className={classes.listItem}
          >
            {pathname === routes.vendorsubscription && (
              <$ListItemSelectedMarker />
            )}
            <ListItemText
              primary="Vendor Subscription"
              className={clsx(classes.listItemText)}
            />
          </ListItem>
          <ListItem
            to={routes.allocations}
            button
            component={Link}
            className={classes.listItem}
          >
            {pathname === routes.allocations && <$ListItemSelectedMarker />}
            <ListItemText
              primary="Allocations"
              className={clsx(classes.listItemText)}
            />
          </ListItem>
        </>,
        <ListItem
          to={dashboardRoutes.planning}
          button
          component={Link}
          className={classes.listItem}
        >
          {matchPath(pathname, { path: dashboardRoutes.planning }) && (
            <$ListItemSelectedMarker />
          )}
          <Icon
            icon="main-menu-planning"
            color={
              matchPath(pathname, { path: dashboardRoutes.planning })
                ? 'var(--color-icon-nav-selected)'
                : 'var(--color-icon-nav-inactive)'
            }
            style={{ position: 'absolute' }}
          />
          <ListItemText
            primary="Planning"
            className={clsx(classes.listItemText)}
          />
        </ListItem>,
        'submenu-2',
      )}
      {wrapTooltip(
        'Business Optimization',
        <ListItem
          to={dashboardRoutes.business}
          button
          component={Link}
          className={classes.listItem}
        >
          {matchPath(pathname, { path: dashboardRoutes.business }) && (
            <$ListItemSelectedMarker />
          )}
          <Icon
            icon="main-menu-business-optimization"
            color={
              matchPath(pathname, { path: dashboardRoutes.business })
                ? 'var(--color-icon-nav-selected)'
                : 'var(--color-icon-nav-inactive)'
            }
            style={{ position: 'absolute' }}
          />
          <ListItemText
            primary="Business Optimization"
            className={clsx(classes.listItemText)}
          />
        </ListItem>,
      )}
      <$Divider />
      {wrapTooltip(
        'Settings',
        <ListItem
          to={dashboardRoutes.settings}
          button
          component={Link}
          className={classes.listItem}
        >
          {matchPath(pathname, { path: dashboardRoutes.settings }) && (
            <$ListItemSelectedMarker />
          )}
          <Icon
            icon="main-menu-settings"
            color={
              matchPath(pathname, { path: dashboardRoutes.settings })
                ? 'var(--color-icon-nav-selected)'
                : 'var(--color-icon-nav-inactive)'
            }
            style={{ position: 'absolute' }}
          />
          <ListItemText
            primary="Settings"
            className={clsx(classes.listItemText)}
          />
        </ListItem>,
      )}
    </StyledList>
  )
}
