import { IconButton, IconButtonProps, Tooltip } from '@mui/material'

import React from 'react'

export type ThreadButtonProps = {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  title: string
  icon: IconButtonProps
}

export const ThreadButton: React.FC<ThreadButtonProps> = ({
  onClick,
  title,
  icon,
}) => (
  <Tooltip title={title}>
    <IconButton onClick={onClick}>{icon}</IconButton>
  </Tooltip>
)
