import { routes as dashboardRoutes } from 'features/dashboard/routes'
import { routes as generalReportRoutes } from 'features/generic-report/routes'
import { routes as headcountRoutes } from 'features/headcount/routes'
import { routes as scenarioVersionsRoutes } from 'features/scenarioVersions/routes'

export const routes = {
  auth: {
    login: '/',
  },
  bridge: '/arr',
  kpi: '/kpi',
  accounts: '/accounts',
  reconciliation: '/reconciliation',
  pnl: '/pnl',
  modelDrivers: '/planning/assumptions',
  expenses: '/planning/expenses-income-input',
  headcountdata: '/planning/headcount',
  capex: '/planning/capex',
  cashplanning: '/planning/cash-planning',
  vendorsubscription: '/planning/vendor-subscription',
  allocations: '/planning/allocations',
  newLogosArr: '/new-logos-arr',
  arrClosingBalance: '/arr-closing-balance',
  ...headcountRoutes,
  ...scenarioVersionsRoutes,
  ...generalReportRoutes,
  ...dashboardRoutes,
}
