import React from 'react'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { styled } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'
import { selectSettingsPageState } from '../ducks/dashboard/selectors'
import { dashboardSlice } from '../ducks/dashboard/slice'
import { SettingsStates, StatePages } from '../ducks/dashboard/types'

const regions = ['North America', 'EMEA', 'APAC']
const products0 = ['Core', 'Extended', 'Absolute']
const products1 = ['Core', 'Extended', 'Absolute', 'Enhanced']
const departments = ['Sales', 'Marketing', 'Product', 'R&D', 'CS', 'G&A']

export const DimensionsTab: React.FC = () => {
  const currentState = useSelector(selectSettingsPageState)
  const dispatch = useDispatch()

  const moveToNextState = React.useCallback(() => {
    dispatch(
      dashboardSlice.actions.moveToState({
        page: StatePages.Settings,
        nextState: SettingsStates.productAdded,
      }),
    )
  }, [dispatch])

  const products =
    currentState === SettingsStates.initial ? products0 : products1

  const [showAddProductDialog, setShowAddProductDialog] = React.useState(false)
  const [newProductName, setNewProductName] = React.useState('')
  const [copyRates, setCopyRates] = React.useState(false)
  const [distributeQuantities, setDistributeQuantities] = React.useState(false)

  React.useEffect(() => {
    if (!showAddProductDialog) {
      setNewProductName('')
      setCopyRates(false)
      setDistributeQuantities(false)
    }
  }, [showAddProductDialog])

  const $AutoComplete = styled(Autocomplete)`
    & .MuiInputBase-input {
      height: 2rem;
    }
  `

  return (
    <>
      <Dialog
        open={showAddProductDialog}
        onClose={() => setShowAddProductDialog(false)}
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">Add Product</DialogTitle>
        <DialogContent>
          <Box sx={{ margin: 1 }}>
            <TextField
              label="Product Name"
              value={newProductName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setNewProductName(event.target.value)
              }}
              fullWidth
            />
            <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
              <FormControlLabel
                label={
                  copyRates
                    ? 'Copy assumptions from'
                    : 'Copy assumptions from existing product'
                }
                sx={{ minWidth: '15rem' }}
                control={
                  <Checkbox
                    value={copyRates}
                    onChange={() => setCopyRates((oldValue) => !oldValue)}
                  />
                }
              />
              <$AutoComplete
                options={products0}
                sx={copyRates ? null : { display: 'none' }}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ maxWidth: '15rem' }}
                    label=""
                    variant="standard"
                  />
                )}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAddProductDialog(false)}>Cancel</Button>
          <Button
            onClick={() => {
              moveToNextState()
              setShowAddProductDialog(false)
            }}
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={6}
      >
        <Grid item xs={2}>
          <Stack spacing={2}>
            <Typography>Regions</Typography>
            {regions.map((region) => (
              <TextField
                id={region}
                key={region}
                InputProps={{
                  readOnly: true,
                }}
                defaultValue={region}
              />
            ))}
          </Stack>
          <IconButton aria-label="add-region">
            <AddCircleIcon sx={{ fontSize: 40 }} />
          </IconButton>
        </Grid>
        <Grid item xs={2}>
          <Stack spacing={2}>
            <Typography>Products</Typography>
            {products.map((product) => (
              <TextField
                id={product}
                key={product}
                InputProps={{
                  readOnly: true,
                }}
                defaultValue={product}
              />
            ))}
          </Stack>
          <IconButton
            onClick={() => setShowAddProductDialog(true)}
            aria-label="add-region"
          >
            <AddCircleIcon sx={{ fontSize: 40 }} />
          </IconButton>
        </Grid>
        <Grid item xs={2}>
          <Stack spacing={2}>
            <Typography>Departments</Typography>
            {departments.map((department) => (
              <TextField
                id={department}
                key={department}
                InputProps={{
                  readOnly: true,
                }}
                defaultValue={department}
              />
            ))}
          </Stack>
          <IconButton aria-label="add-region">
            <AddCircleIcon sx={{ fontSize: 40 }} />
          </IconButton>
        </Grid>
      </Grid>
    </>
  )
}
