import React from 'react'
import { StatusPanelWrapper } from './StatusPanelWrapper'
import { getUpdatedAtText } from '../../../utils/date-utils'
import { useLastUpdatedAt } from '../../../api'

const REFRESH_UPDATED_AT_TEXT_TIMER = 60000

export const LastUpdatePanel: React.FC = () => {
  const { data, isLoading } = useLastUpdatedAt()
  const [updatedAt, setUpdatedAt] = React.useState('')

  const updatedAtTick = React.useCallback(() => {
    if (!data) return
    setUpdatedAt(getUpdatedAtText(data.updated_at))
  }, [data])

  React.useEffect(() => {
    const timer = setInterval(
      () => updatedAtTick(),
      REFRESH_UPDATED_AT_TEXT_TIMER,
    )

    updatedAtTick()

    return () => clearInterval(timer)
  }, [updatedAtTick])

  if (isLoading) {
    return <StatusPanelWrapper>Loading...</StatusPanelWrapper>
  }

  if (!data?.updated_at) {
    return null
  }

  return <StatusPanelWrapper>{updatedAt}</StatusPanelWrapper>
}
