import { Mention, MentionsInput, OnChangeHandlerFunc } from 'react-mentions'

import React from 'react'
import { useThreads } from '../ducks/threads/hooks'

const defaultMentionStyle = {
  backgroundColor: '#f3622d',
  fontSize: 14,
  lineHeight: '14px',
}

const defaultStyle = (disabled: boolean) => ({
  control: {
    backgroundColor: '#fff',
    fontWeight: 'normal',
    minHeight: 60,
    lineHeight: '14px',
  },
  highlighter: {
    padding: 8,
    fontSize: 14,
    lineHeight: '14px',
  },
  input: {
    padding: 8,
    border: disabled ? 'none' : '1px solid silver',
    fontSize: 14,
    lineHeight: '14px',
  },
  suggestions: {
    list: {
      border: '1px solid rgba(0,0,0,0.15)',
    },
    item: {
      'padding': '2px 4px',
      '&focused': {
        backgroundColor: '#f3622d',
        color: '#fff',
      },
    },
  },
})

export interface CommentInputProps {
  value?: string
  disabled?: boolean
  onChange?: OnChangeHandlerFunc
  onAdd?: ((id: string | number, display: string) => void) | undefined
}

export const CommentInput: React.FC<CommentInputProps> = ({
  value,
  onChange,
  onAdd,
  disabled = false,
}) => {
  const { userList } = useThreads()
  const data = userList.map((user) => ({
    id: user.id,
    display: user.email,
  }))

  return (
    <MentionsInput
      disabled={disabled}
      value={value}
      onChange={onChange}
      style={defaultStyle(disabled)}
      placeholder="Mention people using '@'"
    >
      <Mention
        markup="(__display__)"
        trigger="@"
        data={data}
        onAdd={onAdd}
        style={defaultMentionStyle}
      />
    </MentionsInput>
  )
}
