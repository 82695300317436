/**
 Sagas attached to current duck.
 @see https://redux-saga.js.org/docs/introduction/GettingStarted
 */

import type {
  AccountProperties,
  ApiAccountsPropertiesResponse,
  ApiConfigResponse,
  ApiUsersInfoResponse,
} from '../../../api/types'
import type { AllEffect, Effect } from 'redux-saga/effects'
import { all, call, put, takeEvery } from 'redux-saga/effects'

import type { EmitRequest } from './reducers'
import type { Saga } from 'redux-saga'
import { actions } from './slice'
import { getAllConfigs } from '../../../api/configAPI'
import keyBy from 'lodash/keyBy'

const { emitRequest, loadEnd, loadStart } = actions

/**
 It's a "worker" generator for emitRequest action
 @see https://redux-saga.js.org/docs/Glossary#watcherworker
 */
function* onEmitRequest(action: EmitRequest) {
  const { token } = action.payload

  yield put(loadStart())

  try {
    const [config, userInfo, propertiesResponse]: [
      ApiConfigResponse,
      ApiUsersInfoResponse,
      ApiAccountsPropertiesResponse,
    ] = yield call(getAllConfigs, token)

    const properties: Record<string, AccountProperties> = keyBy(
      propertiesResponse.result,
      'id',
    )

    yield put(
      loadEnd({
        error: null,
        properties,
        userInfo,
        config,
      }),
    )
  } catch (e) {
    const err: Error = e as Error
    yield put(
      loadEnd({
        error: err.message || 'Something went wrong',
      }),
    )
  }
}

/**
 Keep here all "watchers" generators
 @see https://redux-saga.js.org/docs/Glossary#watcherworker
 */
function* watchersSaga(): Iterator<AllEffect<Effect>> {
  yield all([takeEvery(emitRequest, onEmitRequest)])
}

// here can be any other generators besides watchers
const sagas: Saga[] = [watchersSaga]

// export only array of sagas
export default sagas
