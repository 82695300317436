import { Box, Theme } from '@mui/material'
import React, { useCallback } from 'react'

import { AgGridReact } from 'ag-grid-react'
import ArrInsightModal from './ArrInsightModal'
import type { ColDef } from 'ag-grid-community'
import { WidgetHeader } from './WidgetHeader'
import classNames from 'classnames'
import { currencyFormatter } from '../utils/currencyFormatter'
import { grey } from '@mui/material/colors'
import { makeStyles } from '@mui/styles'
import rowData from '../data/smallArrBridge.json'
import { theme } from 'utils/theme'

const useStyles = makeStyles<Theme>(() => ({
  root: {
    '& .ag-root-wrapper': {
      // border: 'none',
      // backgroundColor: 'white',
    },
  },
  bad: {
    textAlign: 'left!important' as 'left',
    color: theme.palette.error.main,
    fontWeight: 500,
  },
  good: {
    textAlign: 'left!important' as 'left',
    color: theme.palette.success.main,
    borderRightColor: 'red',
    fontWeight: 500,
  },
  basicCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRightColor: `${grey[200]} !important`,
    fontSize: '11px',
  },
  lastCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '11px',
  },
  colorBad: {
    color: theme.palette.error.main,
  },
  colorGood: {
    color: theme.palette.success.main,
  },
  nocolor: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '9px !important',
    borderRightColor: `${grey[200]} !important`,
    fontSize: '11px',
  },
  indent: {
    paddingLeft: '20px !important',
  },
  innerIndent: {
    paddingLeft: '30px !important',
  },
  basicHeader: {
    'borderLeft': `1px solid ${grey[200]} !important`,
    'marginLeft': '-1px',
    '& .ag-header-cell-label': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}))

export const SmallBridgeWidget = () => {
  const classes = useStyles()
  const tableRootClasses = classNames('ag-theme-alpine', classes.root)
  const columnDefs: ColDef[] = [
    {
      field: 'Metrics',
      cellClass: classes.nocolor,
      headerClass: classes.nocolor,
      cellClassRules: {
        // [classes.bad]: (params: any) =>
        //   ['Churn', 'Lost Logos', 'Contraction'].includes(params.value),
        // [classes.good]: (params: any) =>
        //   ['New ARR', 'New Logos', 'Expansion', 'Booking'].includes(
        //     params.value,
        //   ),
        [classes.indent]: (params: any) =>
          ['Churn', 'Booking'].includes(params.value),
        [classes.innerIndent]: (params: any) =>
          ['Lost Logos', 'Contraction', 'New Logos', 'Expansion'].includes(
            params.value,
          ),
      },
    },
    {
      field: 'Q1 Actual',
      headerName: 'Q3 QTD Actual',
      cellClass: classes.basicCell,
      headerClass: classes.basicHeader,
      valueFormatter: currencyFormatter,
    },
    {
      field: 'Q1 Plan',
      headerName: 'Q3 QTD Plan',

      cellClass: classes.basicCell,
      headerClass: classes.basicHeader,
      valueFormatter: currencyFormatter,
    },
    {
      field: 'Difference',
      headerName: 'Diff',

      cellClass: (params) => [
        classes.lastCell,
        params.value < 0 ? classes.colorBad : classes.colorGood,
      ],
      headerClass: classes.basicHeader,
      valueFormatter: currencyFormatter,
    },
  ]

  const onGridReady = useCallback((params) => {
    params.api.sizeColumnsToFit()
    window.addEventListener('resize', function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit()
      })
    })
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <WidgetHeader
        title="ARR Bridge"
        link="/arr"
        insight={<ArrInsightModal />}
      />
      <div
        className={tableRootClasses}
        style={{
          height: '100%',
          padding: theme.spacing(0, 1.25),
          paddingBottom: '10px',
        }}
      >
        <AgGridReact
          processSecondaryColDef={(colGroupDef) => {
            if (!colGroupDef.pivotKeys) return
            colGroupDef.headerName = colGroupDef.pivotKeys.join(' ')
          }}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          rowData={rowData}
          rowHeight={34}
          headerHeight={40}
        />
      </div>
    </Box>
  )
}
