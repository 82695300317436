/**
 Types for describe duck state and extend root state
 */

import type { DefaultRootState } from 'react-redux'
import { FinDocsConfig, ScenarioConfig, ScenarioVersion } from 'api/types'
import { Maybe } from 'types'
import {
  SvDocumentState,
  SvGlobalOperations,
  SvVersionState,
} from '../../types'

/**
 Will be used in @reduxjs/toolkit to generate action types' names
 and to add duck reducer to the root state
 */
export const namespace = 'scenarioVersions'

// extending root state to use it in selectors
export type RootState = DefaultRootState & {
  scenarioVersions: State
}

// duck state
export type State = {
  versions: SvVersionState[]
  documents: SvDocumentState[]
  versionsConfig: Maybe<ScenarioConfig>
  docsConfig: Maybe<FinDocsConfig>
  versionsList: ScenarioVersion[]
  globalOperations: SvGlobalOperations
}

export const initialState: State = {
  versions: [],
  versionsList: [],
  documents: [],
  versionsConfig: null,
  docsConfig: null,
  globalOperations: {
    getVersionsList: {
      status: 'initial',
      error: null,
    },
    getVersionsConfig: {
      status: 'initial',
      error: null,
    },
    getDocsConfig: {
      status: 'initial',
      error: null,
    },
  },
}
