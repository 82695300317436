import React from 'react'
import type { TimeSegmentationFrame } from '../../../types'

const timeSegmentationFrames: Record<TimeSegmentationFrame, string> = {
  month: 'Month',
  quarter: 'Quarter',
  half_year: 'Half year',
  year: 'Year',
}

const minimalMonthsCountPerTimeSegment: Record<TimeSegmentationFrame, number> =
  {
    month: 0,
    quarter: 3,
    half_year: 6,
    year: 12,
  }

export const useTimeSegmentationsList = (
  monthCount: number,
): Array<[key: TimeSegmentationFrame, label: string]> =>
  React.useMemo(() => {
    const ls = Object.entries(timeSegmentationFrames) as Array<
      [key: TimeSegmentationFrame, label: string]
    >

    if (!monthCount) {
      return ls
    }

    return ls.filter(
      ([key]) => minimalMonthsCountPerTimeSegment[key] <= monthCount,
    )
  }, [monthCount])
