import React from 'react'

export type LocalCacheContext = {
  writeLocalCache: (key: string, data: unknown) => void
  getLocalCache: <T>(key: string) => T | undefined
}

const stub = (): never => {
  throw new Error('Context is empty')
}

export const LocalCacheContext = React.createContext<LocalCacheContext>({
  writeLocalCache: stub,
  getLocalCache: stub,
})
