import type { SetSelectedThread } from './types'
import type { State } from '../state'
import type { WritableDraft } from '../../../../../redux/types'

export function setSelectedThread(
  state: WritableDraft<State>,
  action: SetSelectedThread,
): void {
  if (state.selectedThreadId !== action.payload) {
    state.selectedThreadId = action.payload
    state.selectedThreadEvents = null
    state.error = null
  }
}
