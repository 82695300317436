import {
  Redirect,
  Route,
  Router as ReactRouter,
  Switch,
} from 'react-router-dom'

import { AnalyticsPage } from 'features/dashboard/pages/AnalyticsPage'
import { BusinessOptimization } from 'features/dashboard/pages/BusinessOptimization'
import { AuthLayout } from '../layouts/AuthLayout'
import { BridgeLayout } from '../layouts/BridgeLayout'
import { NotFoundLayout } from '../layouts/NotFoundLayout'
import { PlanningPage } from 'features/dashboard/pages/PlanningPage'
import { PnlLayout } from '../layouts/PnlLayout'
import { ProtectedRoute } from '../components/Router/ProtectedRoute'
import React from 'react'
import { SettingsPage } from 'features/dashboard/pages/SettingsPage'
import { history } from './history'
import { routes } from './routes'
import { useAuth0WithCypress } from '../hooks/useAuth0WithCypress'
import { useMixpanel } from '../utils/mixpanel-utils'
import ArrTreemapPage from 'features/dashboard/pages/ArrTreemapPage'
import ExpensionTreemapPage from 'features/dashboard/pages/ExpensionTreemapPage'

export const Router: React.FC = () => {
  const { isLoading, isAuthenticated, user } = useAuth0WithCypress()
  const { identify } = useMixpanel()

  React.useEffect(() => {
    if (user?.sub) identify(user.sub)
  }, [identify, user?.sub])

  if (isLoading && !isAuthenticated) return <AuthLayout isLoading />

  return (
    <ReactRouter history={history}>
      <Route exact path="/">
        <Redirect to="/analytics" />
      </Route>
      <Switch>
        <ProtectedRoute path={routes.bridge} component={BridgeLayout} />
        <ProtectedRoute path={routes.pnl} component={PnlLayout} />
        <ProtectedRoute path={routes.analytics} component={AnalyticsPage} />
        <ProtectedRoute path={routes.settings} component={SettingsPage} />
        <ProtectedRoute path={routes.planning} component={PlanningPage} />
        <ProtectedRoute
          path={routes.business}
          component={BusinessOptimization}
        />
        <ProtectedRoute path={routes.newLogosArr} component={ArrTreemapPage} />
        <ProtectedRoute
          path={routes.arrClosingBalance}
          component={ExpensionTreemapPage}
        />
        <ProtectedRoute component={NotFoundLayout} />
      </Switch>
    </ReactRouter>
  )
}
