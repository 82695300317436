import type { AgFilterModel, Maybe } from '../types'

import type { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact'
import type { ArrItemBase } from '../workers/utils'
import { PropertyColumnsConfType } from '../api/types'
import React from 'react'

export type SecondaryTableContext = {
  secondaryGridRef: Maybe<React.RefObject<AgGridReactType>>
  rowData: readonly ArrItemBase[]
  setRowData: (rowData: readonly ArrItemBase[]) => void
  setFilter: (filter: AgFilterModel) => void
  filter: AgFilterModel
  secondaryViewType: Maybe<SecondaryViewType>
  setSecondaryViewType: (newType: SecondaryViewType) => void
  chartData: Maybe<SecondaryViewChartData>
  setChartData: (chartData: Maybe<SecondaryViewChartData>) => void
  chartType: SecondaryViewChartType
  setChartType: (chartType: SecondaryViewChartType) => void
  aggregationType: SecondaryViewAggregationType
  setAggregationType: (aggregationType: SecondaryViewAggregationType) => void
  headerText: SecondaryViewHeaderText
  setHeaderText: (headerText: SecondaryViewHeaderText) => void
  propertyColsConfType?: PropertyColumnsConfType
  propertyColsConfKey: Maybe<string>
  setPropertyColsConfKey: (newField: Maybe<string>) => void
}

const stub = (): never => {
  throw new Error('Context is empty')
}

export const emptySecondaryTableContext: SecondaryTableContext = {
  secondaryGridRef: null,
  rowData: [],
  setRowData: stub,
  filter: {},
  setFilter: stub,
  secondaryViewType: null,
  setSecondaryViewType: stub,
  chartData: null,
  setChartData: stub,
  chartType: 'basic',
  setChartType: stub,
  aggregationType: 'sum',
  setAggregationType: stub,
  headerText: { default: '', explanation: '' },
  setHeaderText: stub,
  propertyColsConfKey: null,
  setPropertyColsConfKey: stub,
}

export const SecondaryTableContext = React.createContext<SecondaryTableContext>(
  emptySecondaryTableContext,
)

export type SecondaryViewType = 'table' | 'chart'
export type SecondaryViewChartType = 'basic' | 'bridge-waterfall'
export type SecondaryViewChartData = Record<string, string | number | Date>[]
export type SecondaryViewAggregationType = 'sum' | 'count'
export type SecondaryViewHeaderText = { default: string; explanation?: string }
