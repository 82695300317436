import type { Leaves } from '../types'
import get from 'lodash/get'
import { headcountEndpoints } from '../features/headcount/api/endpoints'

const arrsUrlFactory =
  (endpoint: string) =>
  (selectArr = '', from?: string, to?: string): string => {
    let url = `${endpoint}/?select_arr=${selectArr}`

    if (from) {
      url += `&from=${from}`
    }

    if (to) {
      url += `&to=${to}`
    }

    console.log('asd')

    return url
  }

export const endpoints = {
  config: '/reports/config/',
  users: {
    info: '/reports/users/info',
  },
  accounts: {
    arrs: arrsUrlFactory('/reports/accounts/arr'),
    kpis: arrsUrlFactory('/reports/accounts/kpis'),
    properties: '/reports/accounts/properties/',
  },
  customers: {
    list: '/reports/customers/list/v2/',
    updated_at: '/reports/customers/updated_at/',
  },
  fileEndpoint: (folderOrKey: string, fileName?: string): string => {
    let filePath = folderOrKey
    if (fileName) filePath += `/${fileName}`
    return `/reports/s3_resource?key=${encodeURIComponent(filePath)}`
  },
  filesList: (filePath: string): string =>
    `/reports/s3_resource_list?path=${encodeURIComponent(filePath)}`,
  collaboration: {
    reviews: '/collaboration/reviews',
    comments: '/collaboration/comments',
    threads: '/collaboration/threads/',
    threadEvents: (threadId: number): string =>
      `/collaboration/threads/${threadId}/events/`,
    userList: '/collaboration/collaborators/',
  },
  pnl: {
    config: '/reports/pnl/config',
    actuals: '/reports/pnl/actuals',
    actuals_filtered: '/reports/pnl/actuals_filtered',
  },
  ...headcountEndpoints,
  docs: (docType: string, docUUID?: string): string => {
    let res = `/planning/api/v1/doc/${docType}`
    if (docUUID !== undefined) res += `?doc_uuid=${docUUID}`
    return res
  },
  docConfig: '/planning/api/v1/doc/config',
  scenarioVersions: (scenarioType: string, versionUUID?: string): string => {
    let res = `/planning/api/v1/scenario/${scenarioType}`
    if (versionUUID !== undefined) res += `?version_uuid=${versionUUID}`
    return res
  },
  scenarioConfig: '/planning/api/v1/scenario/config',
  report: {
    blueprint: (tenantId: string, blueprintId: string): string =>
      `/api/admin/tenants/${tenantId}/report_blueprints/${blueprintId}/`,
    details: (
      tenantId: string,
      timestamp: string,
      dimensionValue: string,
      reportId: string,
    ): string =>
      `/api/admin/tenants/${tenantId}/reports/${reportId}/details?timestamp=${timestamp}&dimensionValue=${dimensionValue}&reportId=${reportId}`,
    aggregates: (reportId: string): string =>
      `/api/reports/${reportId}/aggregates/`,
  },
}

export const getEndpointByLeaf = (leaf: EndpointLeaf): EndpontDef =>
  get(endpoints, leaf.join('.')) as EndpontDef

export type EndpointLeaf = Leaves<typeof endpoints>
export type ArrsEndpointMakerFn = ReturnType<typeof arrsUrlFactory>

type EndpontDef = ArrsEndpointMakerFn | string
