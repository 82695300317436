import type { GridColumnsChangedEvent } from 'ag-grid-community'
import React from 'react'

export const useKeepRequiredRowGroups = (
  requiredRowGroups: string[],
): ((event: GridColumnsChangedEvent) => void) =>
  // Disallow removal for `requiredRowGroups`
  React.useCallback(
    (event: GridColumnsChangedEvent) => {
      const rowGroups = event.columnApi.getRowGroupColumns()

      const missedRowGroups = requiredRowGroups.filter(
        (requiredColumnId) =>
          !rowGroups.find((row) => row.getId() === requiredColumnId),
      )

      if (!missedRowGroups.length) {
        return
      }

      event.columnApi.setRowGroupColumns([...missedRowGroups, ...rowGroups])
    },
    [requiredRowGroups],
  )
