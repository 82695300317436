import { getNetsuiteTransactionUrl } from '../../utils/external-platform-url'

import { ConfigContext } from '../../context/ConfigContext'
import type { GroupCellRendererParams } from 'ag-grid-community'
import React from 'react'
import netsuiteLogo from '../../assets/netsuite.png'
import styled from 'styled-components'

const $CellFullWidth = styled.div`
  display: inline-block;
  width: 100%;
  padding-right: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  text-align: left;
  padding-left: 1rem;

  .hidden-when-non-hovered {
    opacity: 0;
  }

  &:hover .hidden-when-non-hovered {
    opacity: 1;
  }
`

const $CellIconWrap = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.5rem;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  margin: auto;
  cursor: pointer;

  img {
    display: block;
  }
`

export const NetSuiteCellRenderer: React.FC<GroupCellRendererParams> = (
  props,
) => {
  const { config } = React.useContext(ConfigContext)

  const netsuiteTransactionUrl = getNetsuiteTransactionUrl(
    config?.features_dict.netsuite_customer_id?.value,
    props.node?.data?.trantype,
    props.node?.data?.tranidid,
  )

  return (
    <$CellFullWidth>
      {props.value}
      {netsuiteTransactionUrl && (
        <$CellIconWrap className="hidden-when-non-hovered">
          <a href={netsuiteTransactionUrl} target="_blank" rel="noreferrer">
            <img src={netsuiteLogo} alt="ns link" width="18" height="18" />
          </a>
        </$CellIconWrap>
      )}
    </$CellFullWidth>
  )
}
