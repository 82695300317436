import type {
  AgColumnsPivotKey,
  Maybe,
  TimeSegmentationFrame,
} from '../../../types'
import type {
  Column,
  ICellRendererFunc,
  ICellRendererParams,
  RangeSelectionChangedEvent,
  RowNode,
} from 'ag-grid-community'
import {
  SecondaryTableContext,
  SecondaryViewChartData,
} from '../../../context/SecondaryTableContext'
import { chain, range } from 'lodash'

import { AggregatedTableContext } from '../../../context/AggregatedTableContext'
import React from 'react'
import { getDateLabelByTimeSegmentation } from 'utils/date-utils'

export const normalizePivotKey = (
  column: Column,
  type: TimeSegmentationFrame,
): Maybe<AgColumnsPivotKey> => {
  const colDef = column.getColDef()
  if (!colDef.pivotKeys?.length || !colDef.field) {
    return null
  }

  const date = getDateLabelByTimeSegmentation(
    column.getColDef().pivotKeys?.[0] as string,
    type,
    true,
  ) as Date

  return { date, field: colDef.field }
}

const getTopLevelParentKey = (row: RowNode): Maybe<string> =>
  row.parent?.key !== null
    ? getTopLevelParentKey(row.parent as RowNode)
    : row.key

const getRowKeyNameByField = (column: Maybe<Column>, value: string): string => {
  const fieldMappedToCellRenderer = ['tier']

  if (!column) {
    return value
  }

  const cellRenderer = column?.getColDef()?.cellRenderer

  if (cellRenderer && fieldMappedToCellRenderer.includes(column.getColId())) {
    return (cellRenderer as ICellRendererFunc)({
      value,
    } as ICellRendererParams) as string
  }

  return value
}

export const useHandleRangeSelection = (
  setUrlSelectionCols: (value: Maybe<AgColumnsPivotKey[]>) => void,
  setUrlSelectionRows: (value: Maybe<number[]>) => void,
): RangeSelectionChangedHandler => {
  const { setSecondaryViewType, setChartData, setChartType } = React.useContext(
    SecondaryTableContext,
  )
  const { flags, gridRef, timeSegmentationFrame } = React.useContext(
    AggregatedTableContext,
  )

  return React.useCallback<RangeSelectionChangedHandler>(
    (
      event,
      urlSelectionCols?: AgColumnsPivotKey[],
      urlSelectionRows?: number[],
      doNotSaveIntoUrl?: boolean,
    ) => {
      const cellRanges = gridRef?.current?.api?.getCellRanges()

      if (event.started || !event.finished || !cellRanges?.length) {
        return
      }

      const pivotKeys: AgColumnsPivotKey[] =
        urlSelectionCols ||
        chain(cellRanges)
          .map((cellRange) => cellRange.columns)
          .flatten()
          .uniqBy((col) => col.getColId())
          .map((column) => normalizePivotKey(column, timeSegmentationFrame))
          .compact()
          .value()

      if (!doNotSaveIntoUrl && !urlSelectionCols) {
        const selectionCols = chain(cellRanges)
          .map((cellRange) => cellRange.columns)
          .flatten()
          .uniqBy((col) => col.getColId())
          .value()
          .map((column) => ({
            date: new Date(column.getColDef().pivotKeys?.[0] as string),
            field: column.getColDef().headerName,
          })) as AgColumnsPivotKey[]
        setUrlSelectionCols(selectionCols)
      }

      const selectedRows: RowNode[] = chain(
        cellRanges.map((cellRange) => {
          const start = Math.min(
            cellRange.startRow?.rowIndex || 0,
            cellRange.endRow?.rowIndex || 0,
          )
          const end = Math.max(
            cellRange.startRow?.rowIndex || 0,
            cellRange.endRow?.rowIndex || 0,
          )

          const rangeIndices = urlSelectionRows || range(start, end + 1)
          if (!doNotSaveIntoUrl && !urlSelectionRows) {
            setUrlSelectionRows(rangeIndices)
          }
          return rangeIndices.map((rowIndex) =>
            gridRef?.current?.api?.getModel().getRow(rowIndex),
          )
        }),
      )
        .flatten()
        .compact()
        .uniqBy((row) => row.id)
        .value()

      const chartData: SecondaryViewChartData = chain(pivotKeys)
        .map((pivotKey) => {
          let topLevelKey: Maybe<string> = ''
          const entriesPerRow = chain(selectedRows)
            .map((row): Maybe<[string, string | number | Date]> => {
              const amountObject = row.aggData[pivotKey.field]
              const amount = amountObject?.value || amountObject
              if (!topLevelKey) topLevelKey = getTopLevelParentKey(row)

              return [
                getRowKeyNameByField(row.rowGroupColumn, row.key || ''),
                parseFloat(amount) || 0,
              ]
            })
            .compact()
            .value()

          if (!entriesPerRow.length) {
            return null
          }

          // Add a leading item to the series
          const series: [string, string | number | Date][] = [
            ['aggregation', topLevelKey],
            ['date', pivotKey.date],
            ...entriesPerRow,
          ]

          return Object.fromEntries(series)
        })
        .compact()
        .value()

      if (!chartData.length) {
        return
      }

      const rowIndexDiff =
        (cellRanges[0]?.startRow?.rowIndex || 0) -
        (cellRanges[0]?.endRow?.rowIndex || 0)
      const isSingleCellSelected =
        cellRanges.length === 1 &&
        cellRanges?.[0].columns.length === 1 &&
        rowIndexDiff === 0

      const displayedRowCount =
        gridRef?.current?.api?.getDisplayedRowCount() || 0
      const isWaterfallSelected =
        flags.bridgeWaterfall &&
        cellRanges
          .filter((rangeItem) => rangeItem.columns.length > 0)
          .every(
            (rangeItem) =>
              rangeItem.startRow?.rowIndex === 0 &&
              rangeItem.endRow?.rowIndex === displayedRowCount - 1,
          )

      if (!isSingleCellSelected) {
        setSecondaryViewType('chart')
        setChartType(isWaterfallSelected ? 'bridge-waterfall' : 'basic')
        setChartData(chartData)
      }
    },
    [
      gridRef,
      flags.bridgeWaterfall,
      timeSegmentationFrame,
      setUrlSelectionCols,
      setUrlSelectionRows,
      setSecondaryViewType,
      setChartType,
      setChartData,
    ],
  )
}

type RangeSelectionChangedHandler = (
  event: RangeSelectionChangedEvent,
  urlSelectionCols?: AgColumnsPivotKey[],
  urlSelectionRows?: number[],
  doNotSaveIntoUrl?: boolean,
) => void
