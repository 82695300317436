import React, { ReactNode } from 'react'
import { Box, Typography } from '@mui/material'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import StyledChip from 'components/atoms/StyledChip'
import { WidgetHeader } from './WidgetHeader'

type Legend = {
  id: number
  displayName: string
  color: string
}

interface DashboardChartHeaderProps {
  legend: Legend[]
  title: string
  insight?: ReactNode
}

const DashboardChartHeader: React.FC<DashboardChartHeaderProps> = ({
  legend,
  title,
  insight,
}) => (
  <>
    <WidgetHeader
      title={title}
      insight={insight}
      // legend={legend.map((chip, i) => (
      //   <StyledChip
      //     key={chip.id}
      //     chipBorderColor={chip.color}
      //     label={chip.displayName}
      //   />
      // ))}
    />
  </>
)

export default DashboardChartHeader
