import type { Metric } from '../../../workers/aggregate-bridge.worker'
import { RowNode } from 'ag-grid-community'
import { capitalize } from 'lodash'

const metricFieldsNameMap: Partial<Record<Metric, string>> = {
  'New Logos': 'Booking',
  'Expansion': 'Booking',
  'Lost Logos': 'Total Churn',
  'Contraction': 'Total Churn',
}

export const generateSecondaryTableHeader = (
  row: RowNode,
  aggregationName?: string,
): string => {
  let value

  if (row.field === 'aggregation') {
    value = aggregationName as string
  } else {
    const topMetric = metricFieldsNameMap[row.key as Metric]
      ? `${metricFieldsNameMap[row.key as Metric]} - `
      : ''
    value = `${
      row.field !== 'metric'
        ? `${capitalize(row.field as string)}: `
        : topMetric
    }${row.key}`
  }

  if (row.parent?.key !== null) {
    value = `${generateSecondaryTableHeader(
      row.parent as RowNode,
      aggregationName,
    )}; ${value}`
  }

  return value
}
