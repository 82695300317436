import type { AggregatedDataLoadParams, ArrItemBase } from '../../workers/utils'

import type { Maybe } from '../../types'
import flatten from 'lodash/flatten'
import { getTenantOverrideHeader } from '../../environment/tenant-override'
import groupBy from 'lodash/groupBy'
import { prefixUrl } from '../../utils/prefix-url'

const CACHE_VERSION = 0

// Generic cache loader
export const loadCache = async <T>(
  accessToken: string,
  key: string,
  from: string,
  to: string,
): Promise<Maybe<T[]>> => {
  const cache = (await (
    await fetch(
      prefixUrl(
        `/reports/cache/load?from=${from}&to=${to}&key=${key}_${CACHE_VERSION}`,
      ),
      {
        headers: {
          ...getTenantOverrideHeader(),
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    )
  ).json()) as Record<string, Maybe<string>>

  if (!cache) {
    return null
  }

  const values = Object.values(cache)

  // If any cache record is null, return null
  if (values.findIndex((i) => !i) >= 0) {
    return null
  }

  return flatten(
    values.map((raw) => (raw !== null ? (JSON.parse(raw) as T) : raw)),
  ) as T[]
}

// Generic cache writer
export const writeCache = async <T>(
  accessToken: string,
  date: string,
  key: string,
  data: T,
): Promise<boolean> =>
  (await (
    await fetch(
      prefixUrl(`/reports/cache/save?date=${date}&key=${key}_${CACHE_VERSION}`),
      {
        headers: {
          ...getTenantOverrideHeader(),
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        method: 'POST',
      },
    )
  ).text()) === 'ok'

// Cache loaded for aggregated data
export const loadCachedAggregation = <T extends ArrItemBase>(
  params: AggregatedDataLoadParams,
): Promise<Maybe<T[]>> =>
  loadCache<T>(
    params.accessToken,
    params.key,
    params.range.from,
    params.range.to,
  )

// Cache writer for aggregated data
export const writeCacheAggregation = async <T extends ArrItemBase>(
  params: AggregatedDataLoadParams,
  data: readonly T[],
  skipMonths: string[] = [],
): Promise<boolean> => {
  const grouppedData = groupBy(data, 'date')

  try {
    return (
      await Promise.all(
        Object.entries(grouppedData)
          .filter(([date]) => skipMonths.includes(date) === false)
          .map(([date, result]) =>
            writeCache<T[]>(params.accessToken, date, params.key, result),
          ),
      )
    ).every((saved) => saved === true)
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.error('Could not perform caching request', ex)
    return false
  }
}
