import { ArrBridgeContainer } from '../containers/ArrBridgeContainer'
import { DashboardWrapper } from '../components/DashboardWrapper/DashboardWrapper'
import React from 'react'
import { useLoadThreads } from 'features/threads/ducks/threads/hooks'

export const BridgeLayout: React.FC = () => {
  const threadsPageKey = 'bridge'
  const { loadThreads } = useLoadThreads()

  React.useEffect(() => {
    loadThreads(threadsPageKey, null)
  }, [loadThreads])

  return (
    <DashboardWrapper enableThreads>
      <ArrBridgeContainer />
    </DashboardWrapper>
  )
}
