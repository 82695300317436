import type {
  Mention,
  PageTitle,
  PageTitleKey,
  ThreadEventType,
} from '../../types'
import {
  emitCreateThreadRequest,
  emitPostThreadEventRequest,
  emitThreadEventsRequest,
  emitThreadsRequest,
  emitUserListRequest,
  selectAll,
  selectIsError,
  selectThreadId,
  setSelectedThread,
} from './index'
import { useDispatch, useSelector } from 'react-redux'

import type { Maybe } from '../../../../types'
import React from 'react'
import type { SetSelectedThreadPayload } from './reducers/types'
import { getThreadDescription } from 'features/threads/utils/prepare-thread-id'
import { useAccessToken } from '../../../../api/useAccessToken'
import { useIsFeatureEnabled } from '../../../../redux/ducks/config/hooks'
import { useUrlHashState } from 'hooks/useUrlHashState'

export function useSelectedThreadState(): [
  Maybe<string>,
  (payload?: SetSelectedThreadPayload) => void,
] {
  const dispatch = useDispatch()
  const selectedThreadId = useSelector(selectThreadId)
  const [urlThreadId, setUrlThreadId] =
    useUrlHashState<Maybe<string>>('thread-id')
  const threadIdChanged = React.useRef(false)

  const setHandler = React.useCallback(
    (payload) => {
      threadIdChanged.current = true
      if (payload === null) {
        dispatch(setSelectedThread(payload))
        setUrlThreadId(payload)
        return
      }

      if (payload) {
        setUrlThreadId(payload)
        dispatch(setSelectedThread(payload))
      }
    },
    [dispatch, setUrlThreadId, threadIdChanged],
  )

  const value =
    !threadIdChanged.current && !selectedThreadId
      ? urlThreadId
      : selectedThreadId

  return [value, setHandler]
}

export type UseLoadThreads = {
  loadThreads: (pageKey: PageTitleKey, objectKey: Maybe<string>) => void
}
export function useLoadThreads(): UseLoadThreads {
  const { token } = useAccessToken()
  const dispatch = useDispatch()
  const isError = useSelector(selectIsError)
  const isEnabled = useIsFeatureEnabled('collaboration_threads')

  return {
    loadThreads: React.useCallback(
      (pageKey, objectKey) => {
        if (token !== null && !isError && isEnabled) {
          dispatch(
            emitThreadsRequest({
              token,
              pageKey,
              objectKey,
            }),
          )
        }
      },
      [token, dispatch, isError, isEnabled],
    ),
  }
}

export function useLoadUserList(): void {
  const { token } = useAccessToken()
  const dispatch = useDispatch()
  const isEnabled = useIsFeatureEnabled('collaboration_threads')

  React.useEffect(() => {
    if (token !== null && isEnabled) {
      dispatch(
        emitUserListRequest({
          token,
        }),
      )
    }
  }, [token, isEnabled, dispatch])
}

export type UseThreadEvents = {
  loadThreadEvents: (threadId: number) => void
  postThreadEvent: (
    threadId: number,
    type: ThreadEventType,
    body?: string,
    mentions?: Mention[],
  ) => void
}
export function useThreadEvents(): UseThreadEvents {
  const { token } = useAccessToken()
  const dispatch = useDispatch()

  const loadThreadEvents = React.useCallback(
    (threadId: number) => {
      if (token !== null) {
        dispatch(
          emitThreadEventsRequest({
            token,
            threadId,
          }),
        )
      }
    },
    [token, dispatch],
  )

  const postThreadEvent = React.useCallback(
    (
      threadId: number,
      type: ThreadEventType,
      body?: string,
      mentions?: Mention[],
    ) => {
      if (token !== null) {
        dispatch(
          emitPostThreadEventRequest({
            token,
            threadId,
            type,
            body,
            mentions,
          }),
        )
      }
    },
    [token, dispatch],
  )

  return {
    loadThreadEvents,
    postThreadEvent,
  }
}

export type UseCreateThread = {
  createThread: (
    pageKey: PageTitleKey,
    objectKey: string,
    comment: string,
    pageTitle: PageTitle,
    mentions?: Mention[],
  ) => void
}

export function useCreateThread(): UseCreateThread {
  const { token } = useAccessToken()
  const dispatch = useDispatch()

  return {
    createThread: React.useCallback(
      (pageKey, objectKey, comment, pageTitle, mentions) => {
        const objectTitle = getThreadDescription(objectKey)
        const url = window.location.href

        if (token !== null) {
          dispatch(
            emitCreateThreadRequest({
              token,
              pageKey,
              objectKey,
              comment,
              mentions,
              pageTitle,
              objectTitle,
              url,
            }),
          )
        }
      },
      [token, dispatch],
    ),
  }
}

export function useThreads(): ReturnType<typeof selectAll> {
  return useSelector(selectAll)
}
