/* eslint-disable camelcase */
export type ThreadComment = {
  id: number
  authorName: string
  date: Date
  body: string
}

export type Thread = {
  id: number
  page_key: string
  object_key: string
  is_resolved?: boolean
}

export type ThreadEventType = 'resolve' | 'comment' | 'mention'

export type ThreadEvent = {
  id: number
  event_id: number // event (comment) ID which could be referenced
  timestamp: number
  thread_id: number
  type: ThreadEventType
  body: string // full message body/mention tag
  author_id: number
  user_id: number // for tagging this user
}

export type User = {
  id: number
  name: string
  email: string
}

export type PageTitle = 'ARR Bridge' | 'KPIs' | 'PNL' | 'General report'
export type PageTitleKey = 'bridge' | 'kpis' | 'pnl' | 'report'

export const PAGE_TITLE: Record<PageTitleKey, PageTitle> = {
  bridge: 'ARR Bridge',
  kpis: 'KPIs',
  pnl: 'PNL',
  report: 'General report',
}

export type Mention = {
  id: string
  display: string
}
