import type { Maybe } from '../types'

export const getNetsuiteEntityUrl = (
  netsuiteCustomerId?: Maybe<string>,
  entityId?: string,
): Maybe<string> => {
  if (!netsuiteCustomerId || !entityId) {
    return null
  }

  return `https://${netsuiteCustomerId}.app.netsuite.com/app/common/entity/custjob.nl?id=${entityId}`
}

export const getNetsuiteTransactionUrl = (
  netsuiteCustomerId?: Maybe<string>,
  trantype?: string,
  tranidid?: string,
): Maybe<string> => {
  if (
    !netsuiteCustomerId ||
    typeof trantype !== 'string' ||
    typeof tranidid !== 'string'
  ) {
    return null
  }

  return `https://${netsuiteCustomerId}.app.netsuite.com/app/accounting/transactions/${trantype.toLowerCase()}.nl?id=${tranidid}&whence=`
}

export const getSalesforceAccountUrl = (
  salesforceCustomerId?: Maybe<string>,
  accountId?: string,
): Maybe<string> => {
  if (!salesforceCustomerId || !accountId) {
    return null
  }
  return `https://${salesforceCustomerId}.lightning.force.com/lightning/r/Account/${accountId}/view`
}
