import { Box, Typography } from '@mui/material'
import {
  ColDef,
  ColGroupDef,
  ColumnEverythingChangedEvent,
  FirstDataRenderedEvent,
  GridColumnsChangedEvent,
  GridReadyEvent,
  RowClickedEvent,
} from 'ag-grid-community'
import { HeadcountStates, StatePages } from '../ducks/dashboard/types'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AgGridReact } from 'ag-grid-react'
import { InsightModal } from 'components/atoms/InsightModal'
import { dashboardSlice } from '../ducks/dashboard/slice'
import { pivotTableColumns } from '../pivot'
import pivoted0 from '../data/headcount.json'
import pivoted1 from '../data/headcountNext.json'
import { selectHeadcountPageState } from '../ducks/dashboard/selectors'

const COLUMNS = {
  METRIC: 'Metric',
  SEGMENT: 'Segment',
  DEPARTMENT: 'Department',
  REGION: 'Region',
  ROLE: 'Role',
}

const assumptionInsights = [
  {
    name: '2 New Hires per month',
    component: <div>Accept fintastic suggestion (3 new hires)</div>,
  },
]

export const HeadcountTable = () => {
  const [openInsightDialog, setOpenInsight] = React.useState(false)
  const handleClose = () => setOpenInsight(false)

  const [dimensions, setDimensions] = React.useState([COLUMNS.DEPARTMENT])
  const [isOutsideClicked, setIsOutsideClicked] = React.useState(false)
  const [grid, setGridApi] = React.useState<GridReadyEvent | undefined>(
    undefined,
  )
  const openInsight = useCallback(() => {
    setOpenInsight(true)
  }, [])
  const dispatch = useDispatch()

  const currentState = useSelector(selectHeadcountPageState)

  const handleViewInsight = useCallback(() => {
    setDimensions([
      COLUMNS.DEPARTMENT,
      COLUMNS.ROLE,
      COLUMNS.REGION,
      COLUMNS.SEGMENT,
      COLUMNS.METRIC,
    ])
    grid?.api.setFocusedCell(8, 'Mar-22Mar 22 Forecast')
    setOpenInsight(false)
  }, [grid])
  const pivoted =
    currentState === HeadcountStates.suggestionClicked ? pivoted1 : pivoted0

  const handleSuggestionClick = useCallback(() => {
    dispatch(
      dashboardSlice.actions.moveToState({
        page: StatePages.Headcount,
        nextState: HeadcountStates.suggestionClicked,
      }),
    )
  }, [dispatch])

  const monthForecastMap = useMemo(
    () => ({
      'Jan-22': new Set(['Actual']),
      'Feb-22': new Set(['Actual']),
      'Mar-22': new Set(['Actual']),
      'Apr-22': new Set(['Actual']),
      'May-22': new Set(['Actual']),
      'Jun-22': new Set(['Actual']),
      'Jul-22': new Set(['Actual']),
      'Aug-22': new Set(['Actual']),
      'Sep-22': new Set(['September 22 Forecast', 'Actual', 'Diff']),
      'Oct-22': new Set([
        'September 22 Forecast',
        'October 22 Forecast',
        'Diff',
      ]),
      'Nov-22': new Set([
        'September 22 Forecast',
        'October 22 Forecast',
        'Diff',
      ]),
      'Dec-22': new Set([
        'September 22 Forecast',
        'October 22 Forecast',
        'Diff',
      ]),
    }),
    [],
  )

  const insightValue = useMemo(
    () => ({
      data: {
        [COLUMNS.METRIC]: 'New Employees',
      },
      colId: 'Oct-22September 22 Forecast',
      badgeField: COLUMNS.DEPARTMENT,
      badgeValue:
        currentState === HeadcountStates.initial ? 'Sales' : 'DOESNTEXIST',
      replaceData: currentState !== HeadcountStates.initial,
    }),
    [currentState],
  )

  const handleRowClick = React.useCallback(
    (e: RowClickedEvent) => {
      if (dimensions.length > 1 && e.data.Metric !== 'New Employees') {
        setIsOutsideClicked(true)
        e?.api.redrawRows()
        setTimeout(() => e?.api.redrawRows(), 0)
      }
    },
    [setIsOutsideClicked, dimensions],
  )

  console.log('current state', currentState)

  const columnDefs = useMemo(
    () =>
      pivotTableColumns(
        dimensions,
        monthForecastMap,
        insightValue,
        assumptionInsights,
        openInsight,
        handleSuggestionClick,
      ).map((def: ColDef | ColGroupDef) => {
        if ((def as ColDef).field) {
          const colDef = def as ColDef
          if (colDef.field === 'Role') colDef.width = 48
          if (colDef.field === 'Department') colDef.width = 110
          if (colDef.field === 'Region') colDef.width = 65
          if (colDef.field === 'Segment') colDef.width = 75
          if (colDef.field === 'Metric') {
            colDef.width = 130
            colDef.cellClass = (params) => {
              if (params.value === 'New Employees') {
                return 'c-green'
              }
              if (params.value === 'Employees Attrition') {
                return 'c-red'
              }
              return ''
            }
          }
          return colDef
        }
        return def
      }),
    [
      dimensions,
      handleSuggestionClick,
      insightValue,
      monthForecastMap,
      openInsight,
    ],
  )
  const v = [1, 2, 3].filter(() => true)
  const filtered = (pivoted as Record<string, any>).filter((row: any) =>
    dimensions.some((d) => row[d] !== ''),
  )

  const handleGridReady = useCallback((e: GridReadyEvent) => {
    setGridApi(e)
  }, [])

  return (
    <Box
      sx={{
        'p': '20px',
        'pt': 0,
        '& .ag-root-wrapper .ag-cell': {
          textAlign: 'right',
        },
        '& .ag-root-wrapper .ag-pinned-left-cols-container .ag-cell': {
          textAlign: 'left',
        },
        '& .ag-root-wrapper .c-red': {
          color: (theme) => theme.palette.error.main,
        },
        '& .ag-root-wrapper .c-green': {
          color: (theme) => theme.palette.success.main,
        },
      }}
    >
      <div
        className="ag-theme-alpine ag-theme-demo-short"
        style={{ height: '40vh', width: '100%' }}
      >
        <InsightModal
          open={openInsightDialog}
          title="Insights on Headcount"
          handleClose={handleClose}
          handleView={handleViewInsight}
          height={460}
        >
          <Typography>
            <strong>Attrition over plan YTD</strong>
            <br /> Role: AE
            <br /> Count: 2
            <br /> Segment: Ent
            <br /> Region: Americas
            <br />
            <>Over plan attrition</>: 2
            <br />
            <br />
            <strong>Suggestion</strong>: Increase hiring in April to maintain
            capacity due to staggering rampup by Role: AE, count:{' '}
            <strong>3</strong>
            <br />
            <strong>Impact:</strong>
            <br />
            Increase expenses by <span style={{ color: 'red' }}>$120K</span>
            <br />
            Increase sales capacity in Q4 2022 by{' '}
            <span style={{ color: 'green' }}>$240K</span>
          </Typography>
        </InsightModal>
        <AgGridReact
          suppressAggFuncInHeader={true}
          columnDefs={columnDefs}
          rowData={filtered}
          onRowClicked={handleRowClick}
          rowClassRules={{
            'ag-row-selected': (props) => {
              const isRowIdSelected =
                props.data.Metric === 'New Employees' && !isOutsideClicked
              return isRowIdSelected
            },
          }}
          onGridReady={handleGridReady}
          rowHeight={24}
          headerHeight={30}
        />
      </div>
    </Box>
  )
}
