import type {
  BlueprintListState,
  ReportDetailsState,
  ReportGroupsState,
  ReportsListState,
  State,
} from './state'
import { createSelector, createStructuredSelector } from 'reselect'

import type { AppRootState } from 'redux/store'
import type { Report } from 'features/generic-report/types'
import type { Selector } from 'reselect'

const selectGenericReportAll: Selector<AppRootState, State> = (s) =>
  s.genericReport

// Blueprint

export const selectReportBlueprintList: Selector<
  AppRootState,
  BlueprintListState
> = createSelector(selectGenericReportAll, (state) => state.blueprintList)

// Report Groups

export const selectReportGroups: Selector<
  AppRootState,
  ReportGroupsState['data']
> = createSelector(selectGenericReportAll, (state) => state.reportGroups.data)

export const selectReportGroupsStatus: Selector<
  AppRootState,
  ReportGroupsState['status']
> = createSelector(selectGenericReportAll, (state) => state.reportGroups.status)

export const selectReportGroupsError: Selector<
  AppRootState,
  ReportGroupsState['error']
> = createSelector(selectGenericReportAll, (state) => state.reportGroups.error)

export const selectReportGroupsState = createStructuredSelector<
  AppRootState,
  ReportGroupsState
>({
  data: selectReportGroups,
  status: selectReportGroupsStatus,
  error: selectReportGroupsError,
})

// Report Details

export const selectReportDetails: Selector<
  AppRootState,
  ReportDetailsState['data']
> = createSelector(selectGenericReportAll, (state) => state.reportDetails.data)

export const selectReportDetailsStatus: Selector<
  AppRootState,
  ReportDetailsState['status']
> = createSelector(
  selectGenericReportAll,
  (state) => state.reportDetails.status,
)

export const selectReportDetailsError: Selector<
  AppRootState,
  ReportDetailsState['error']
> = createSelector(selectGenericReportAll, (state) => state.reportDetails.error)

export const selectReportDetailsState = createStructuredSelector<
  AppRootState,
  ReportDetailsState
>({
  data: selectReportDetails,
  status: selectReportDetailsStatus,
  error: selectReportDetailsError,
})

// Select Versions

export const selectSelectedVersions: Selector<AppRootState, Report[]> =
  createSelector(selectGenericReportAll, (state) => state.selectedVersions)

// Reports List

export const selectReportsList: Selector<AppRootState, ReportsListState> =
  createSelector(selectGenericReportAll, (state) => state.reportsList)
