import './utils/init-ag-grid'
import './styles/_colors.css'
import './styles/base.css'
import './styles/recharts-custom.css'

import { CssBaseline } from '@mui/material'
import React from 'react'
import { Router } from './router'

export const App: React.FC = () => (
  <>
    <CssBaseline />
    <Router />
  </>
)
