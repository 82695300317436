import type { ColDef, Column, ModelUpdatedEvent } from 'ag-grid-community'

import { TimeSegmentationFrame } from '../../../types'
import { autoColumnName } from '../../../utils/ag-grid'
import flow from 'lodash/flow'
import { makeFormatColDef } from './format-col-def'

const mergeSecondaryHeaderIntoPrimaryFactory = (
  pivotColumns: Column[],
  timeSegmentationFrame: TimeSegmentationFrame,
  minDate?: string,
): PrepareAgModelFn => {
  const formatColDef = makeFormatColDef(timeSegmentationFrame, minDate)
  return (event: ModelUpdatedEvent) => {
    const result: ColDef[] | null = []
    const staticPivotColumns = pivotColumns?.length
      ? pivotColumns
      : event.columnApi.getSecondaryColumns()
    const existingColumns = event.columnApi.getSecondaryColumns()
    if (staticPivotColumns && existingColumns) {
      staticPivotColumns.forEach((col: Column) => {
        const colDef = col.getColDef()
        const replaceCol = existingColumns.find(
          (exCol) =>
            exCol.getColDef()?.pivotKeys?.[0] === colDef?.pivotKeys?.[0],
        )
        if (replaceCol) {
          formatColDef(replaceCol?.getColDef())
          result.push(replaceCol.getColDef())
          return
        }
        formatColDef(colDef)

        // Clean the pivot column IDs so the existing ones are free
        col.setColDef(
          {
            ...col.getColDef(),
            field: 'pivot_0',
            colId: 'pivot_0',
          },
          null,
        )
        result.push(col.getColDef())
      })
      event.columnApi.setSecondaryColumns(result)
    }

    return event
  }
}

const pinHeaders: PrepareAgModelFn = (event) => {
  const columns = event.columnApi.getColumnState()
  const columnConfiguration = {
    [autoColumnName('metric')]: {
      sort: null,
    },
    [autoColumnName('aggregation')]: {
      sort: null,
    },
    [autoColumnName('Join Quarter')]: {
      sort: 'desc',
    },
  }

  const automaticColumns = columns.filter((column) =>
    column.colId?.includes(autoColumnName()),
  )

  const updateState = automaticColumns.map((column) => ({
    ...column,
    colId: column.colId,
    pinned: 'left',
    ...(columnConfiguration[column.colId || ''] || { sort: 'asc' }),
  }))

  event.columnApi.applyColumnState({ state: updateState })

  return event
}

/**
 * If the first row group is not Metric, we don't want to show any values in this row.
 * Because it would the sum of every metric, which is incorrect.
 */

const hideNonMetricFirstGroupCellValues: PrepareAgModelFn = (event) => {
  const rowGroupColumns = event.columnApi.getRowGroupColumns()
  const rowGroupColumnsNames = rowGroupColumns.map((column) =>
    column.getColId(),
  )
  const metricCol = rowGroupColumns.find(
    (column) => column.getColId() === 'metric',
  )

  if (
    metricCol &&
    rowGroupColumnsNames.length &&
    rowGroupColumnsNames[0] !== 'metric'
  ) {
    const renderedRows = event.api.getRenderedNodes()

    renderedRows.forEach((row) => {
      Object.keys(row.aggData).forEach((pivotColumn) => {
        if (!row.leafGroup) {
          row.setAggData({
            ...row.aggData,
            [pivotColumn]: null,
          })
        }
      })
      event.api.refreshCells()
    })
  }

  return event
}

type PrepareAgModelFn = (event: ModelUpdatedEvent) => ModelUpdatedEvent

export const prepareAgModelFactory = (
  pivotColumns: Column[],
  timeSegmentationFrame: TimeSegmentationFrame,
  minDate?: string,
): PrepareAgModelFn =>
  flow(
    mergeSecondaryHeaderIntoPrimaryFactory(
      pivotColumns,
      timeSegmentationFrame,
      minDate,
    ),
    pinHeaders,
    hideNonMetricFirstGroupCellValues,
  )
