/**
 It's a main file of current duck.
 "Duck" is a way to organize Redux files for each feature or domain.
 @see https://github.com/erikras/ducks-modular-redux
 An exports should follow the rules described below.
 */

import * as slice from './slice'
import type { ReduxConnectable } from '../../types'
import { namespace, State } from './state'
import sagas from './sagas'

// action creators and selectors should be accessible as named exports of redux/ducks/{duckName}
export * from './selectors'
const { reducer, actions } = slice
export const { emitRequest, loadEnd, loadStart } = actions

// connectable is a main entity of duck, so it should be exported as a default export
const connectable: ReduxConnectable<State> = {
  namespace,
  reducer,
  sagas,
}

export default connectable
