import React, { useCallback } from 'react'

import { AgGridReact } from 'ag-grid-react'
import { Box } from '@mui/material'
import type { ColDef } from 'ag-grid-community'
import { WidgetHeader } from './WidgetHeader'
import { currencyFormatter } from '../utils/currencyFormatter'
import { grey } from '@mui/material/colors'
import { makeStyles } from '@mui/styles'
import { theme } from 'utils/theme'

interface DashboardHeadcountTableProps {
  rowData: any[]
  FooterPanel: () => JSX.Element
}

const useStyles = makeStyles(() => ({
  ...(() => {
    console.log(123)
    return {}
  })(),
  actual: {
    color: 'inherit',
  },
  budget: {
    color: 'inherit',
  },
  diffPositive: {
    color: theme.palette.success.main,
  },
  diffNegative: {
    color: theme.palette.error.main,
  },
  nocolor: {},
  basicCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRightColor: `${grey[200]} !important`,
    fontSize: '11px',
  },
  textAlignLeft: {
    'justifyContent': 'flex-start',
    'paddingLeft': '5px !important',
    '& .ag-header-cell-label': {
      justifyContent: 'flex-start !important',
    },
  },
  specialHeader: {
    paddingLeft: '20px !important',
  },
  basicHeader: {
    'borderLeft': `1px solid ${grey[200]} !important`,
    'paddingLeft': '5px !important',
    'marginLeft': '-1px',
    'width': '110px !important',
    '& .ag-header-cell-label': {
      textAlign: 'center',
      justifyContent: 'center',
    },
  },
}))

export default function DashboardHeadcountTable({
  rowData,
  FooterPanel,
}: DashboardHeadcountTableProps) {
  const classes = useStyles()
  const columnDefs: ColDef[] = [
    {
      field: 'Groups',
      headerName: '',
      cellClass: (params) => {
        const headers = ['PS', 'Marketing', 'R&D', 'G&A', 'Sales']
        if (!headers.includes(params.value)) {
          return [classes.basicCell, classes.textAlignLeft]
        }
        return [classes.basicCell, classes.specialHeader]
      },
      headerClass: [classes.basicHeader, classes.textAlignLeft],
      cellStyle: (params) => {
        const headers = ['PS', 'Marketing', 'R&D', 'G&A', 'Sales']
        if (headers.includes(params.value)) {
          return {
            justifyContent: 'flex-start',
            paddingLeft: '20px !important',
          }
        }
        return null
      },
    },
    {
      field: 'Actual',
      headerName: 'Q3 QTD Actual',
      cellClass: [classes.actual, classes.basicCell],
      headerClass: classes.basicHeader,
    },
    {
      field: 'Mar 22 forecast',
      headerName: 'Q3 QTD Plan',
      cellClass: [classes.budget, classes.basicCell],
      headerClass: classes.basicHeader,
    },
    {
      field: 'Plan',
      headerName: 'Plan',
      cellClass: [classes.basicCell, classes.budget],
      headerClass: classes.basicHeader,
    },
  ]

  const onGridReady = useCallback((params) => {
    params.api.sizeColumnsToFit()
    window.addEventListener('resize', function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit()
      })
    })
  }, [])

  // const getRowStyle = () => ({
  //   borderBottom: '1px solid lightgrey',
  //   fontWeight: 'unset',
  // })

  const getRowStyle = (params: any) => {
    const headers = ['PS', 'Marketing', 'R&D', 'G&A', 'Sales']
    if (
      // eslint-disable-next-line
      headers.includes(params.node.data['Groups'])
    ) {
      return {
        color: 'grey !important',
      }
    }
    return {
      color: 'black',
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <WidgetHeader title="Headcount" />
      <Box
        className="ag-theme-alpine"
        sx={{
          height: '100%',
          width: '100%',
          padding: '0 10px 0px',
        }}
      >
        <AgGridReact
          processSecondaryColDef={(colGroupDef) => {
            if (!colGroupDef.pivotKeys) return
            colGroupDef.headerName = colGroupDef.pivotKeys.join(' ')
          }}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          rowData={rowData}
          rowHeight={34}
          headerHeight={40}
          getRowStyle={getRowStyle}
        />
      </Box>
      {FooterPanel && <FooterPanel />}
    </Box>
  )
}
