import { AggregatedTableContext } from '../../context/AggregatedTableContext'
import type { Column } from 'ag-grid-community'
import type { Maybe } from '../../types'
import React from 'react'
import last from 'lodash/last'

export const useScrollToTheEndEffect = (isGridReady: boolean): void => {
  const { gridRef } = React.useContext(AggregatedTableContext)

  // Once grid is ready, scroll to the end
  React.useEffect((): undefined | (() => void) => {
    if (!isGridReady) {
      return undefined
    }

    const timeout = setTimeout(() => {
      // Initially, we want to display the first column from the selection range
      let columnToDisplay: Maybe<Column> =
        gridRef?.current?.api.getCellRanges()?.[0]?.columns?.[0] || null
      const allColumns = gridRef?.current?.columnApi.getAllDisplayedColumns()

      // If no range selected, scroll to the last column
      if (!columnToDisplay) {
        columnToDisplay = last(allColumns) || null
      } else {
        // If range selected to the current month, scroll to the last column anyway
        const currentMonthColumn = allColumns?.[allColumns?.length - 2]
        if (columnToDisplay?.getId() === currentMonthColumn?.getId()) {
          columnToDisplay = last(allColumns) || null
        }
      }

      if (columnToDisplay) {
        gridRef?.current?.api.ensureColumnVisible(columnToDisplay)
      }
      // @todo Remove altogether with timeout in `autoSizeAllColumns`.
      // Need to wait before autosize happens. (500ms + 200ms gap)
    }, 700)

    return () => {
      clearTimeout(timeout)
    }
  }, [gridRef, isGridReady])
}
