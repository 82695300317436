import type { BlueprintReport, ReportAggregates, ReportDetails } from '../types'

import { endpoints } from 'api/endpoints'
import { runFetch } from 'api/useApi'

export function getReportBlueprintAPI(
  token: string,
  tenantId: string,
  blueprintId: string,
): Promise<BlueprintReport> {
  return runFetch(token, endpoints.report.blueprint(tenantId, blueprintId))
}

export function getReportDetailsAPI(
  token: string,
  tenantId: string,
  timestamp: string,
  dimensionValue: string,
  reportId: string,
): Promise<ReportDetails> {
  return runFetch(
    token,
    endpoints.report.details(tenantId, timestamp, dimensionValue, reportId),
  )
}

export function getReportAggregatesAPI(
  token: string,
  reportId: string,
): Promise<{ result: ReportAggregates }> {
  return runFetch(token, endpoints.report.aggregates(reportId))
}
