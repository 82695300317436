import type {
  ApiAccountsArrResponse,
  ApiConfigResponse,
  ArrItem,
  ArrProcessingFormula,
  Cohort,
  KPIDefinintion,
} from '../api/types'
import {
  getKpiDisplayNameByAggregation,
  parseAggregation,
} from '../utils/primitives'

import { ALL_METRICS_ORDERED } from '../components/BridgeTable/utils'
import type { AgBridgeRecord } from 'utils/bridge-aggregations'
import type { AgKPIRecord } from 'utils/kpi-aggregations'
import type { AggregationRange } from 'workers/kpi/types'
import { EndpointLeaf } from '../api/endpoints'

export const getArrData = async <T = unknown>(
  url: string,
  accessToken: string,
): Promise<ApiAccountsArrResponse<T>> =>
  (
    await fetch(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  ).json() as Promise<ApiAccountsArrResponse<T>>

export type ArrItemBase<T = unknown> = ArrItem<T> & {
  amount: number
  // eslint-disable-next-line camelcase
  client__uniqueId: string
}

export type AggregatedDataLoadParams = {
  endpointLeaf: EndpointLeaf
  range: { from: string; to: string; selectedArr?: string }
  accessToken: string
  key: string
}

export type AggregatedDataLoaderResult = readonly [
  readonly ArrItemBase[],
  readonly ArrProcessingFormula[],
  readonly Cohort[],
]

export type AggregationLoadingStatus =
  | 'unknown'
  | 'init'
  | 'cache-validation'
  | 'loading'
  | 'calculating'
  | 'done'

export type AggregatedDataLoaderFN = (
  statusCallback: (status: AggregationLoadingStatus) => void,
  params: AggregatedDataLoadParams,
  config?: ApiConfigResponse,
) => Promise<AggregatedDataLoaderResult>

export const sortByMetric: AggregatedDataComparator<AgBridgeRecord> = (
  valueA,
  valueB,
) => {
  const indexA = ALL_METRICS_ORDERED.indexOf(valueA.metric)
  const indexB = ALL_METRICS_ORDERED.indexOf(valueB.metric)
  if (indexA === indexB) {
    return 0
  }
  return indexA > indexB ? 1 : -1
}

export const sortByAggregationFactory =
  (kpiDefs: KPIDefinintion[]): AggregatedDataComparator<AgKPIRecord> =>
  (valueA, valueB) => {
    const order: Record<AggregationRange, number> = {
      '1_month': 1,
      '1_month_inclusive': 2,
      '3_month': 3,
      '3_month_inclusive': 4,
      'qtd': 5,
      'qtd_inclusive': 6,
      '12_month': 7,
      'ytd': 8,
      '12_month_inclusive': 9,
      'ytd_inclusive': 10,
    }

    const [kpiFormulaA, rangeA] = parseAggregation(valueA.aggregation)
    const [kpiFormulaB, rangeB] = parseAggregation(valueB.aggregation)

    const kpiFormulaAName = getKpiDisplayNameByAggregation(
      valueA.aggregation,
      kpiDefs,
    )
    const kpiFormulaBName = getKpiDisplayNameByAggregation(
      valueB.aggregation,
      kpiDefs,
    )

    // Sort KPIs within group
    if (kpiFormulaA === kpiFormulaB) {
      const indexA = order[rangeA]
      const indexB = order[rangeB]

      if (indexA === indexB) {
        return 0
      }

      return indexA > indexB ? 1 : -1
    }

    // Special case - no KPI labels, sort by formula ID then
    if (kpiFormulaAName === kpiFormulaBName) {
      return kpiFormulaA > kpiFormulaB ? 1 : -1
    }

    // Sort group
    return kpiFormulaAName > kpiFormulaBName ? 1 : -1
  }

export type AggregatedDataComparator<T extends AgKPIRecord | AgBridgeRecord> = (
  valueA: T,
  valueB: T,
) => -1 | 1 | 0
