import { Dashboard } from '@mui/icons-material'
import type { AppRootState } from 'redux/store'
import type { Selector } from 'reselect'
import { createSelector } from 'reselect'
import type { State } from './state'
import {
  AssumptionsStates,
  DashboardStates,
  HeadcountStates,
  SettingsStates,
  StatePages,
} from './types'

const selectDashboardAll: Selector<AppRootState, State> = (s) => s.dashboard

// Select Versions
export const selectSettingsPageState: Selector<AppRootState, SettingsStates> =
  createSelector(
    selectDashboardAll,
    (state) => state.currentState[StatePages.Settings],
  )

export const selectDashboardPageState: Selector<AppRootState, DashboardStates> =
  createSelector(
    selectDashboardAll,
    (state) => state.currentState[StatePages.Dashboard],
  )

export const selectAssumptionsPageState: Selector<
  AppRootState,
  AssumptionsStates
> = createSelector(
  selectDashboardAll,
  (state) => state.currentState[StatePages.Assumptions],
)

export const selectHeadcountPageState: Selector<AppRootState, HeadcountStates> =
  createSelector(
    selectDashboardAll,
    (state) => state.currentState[StatePages.Headcount],
  )

// Select Versions
export const selectFilterState: Selector<
  AppRootState,
  Record<string, string[]>
> = createSelector(selectDashboardAll, (state) => state.currentFilter)
