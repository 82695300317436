import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { Footer } from '../components/Footer/Footer'
import LinearProgress from '@mui/material/LinearProgress'
import { Login } from '../components/Login/Login'
import Paper from '@mui/material/Paper'
import React from 'react'
import { Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import styled from 'styled-components'
import { theme } from '../utils/theme'

const $DarkWrapper = styled.div`
  background: ${grey[200]};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const $FormWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`

const $FooterWrapper = styled.div`
  justify-self: flex-end;
  text-align: center;
  padding: ${theme.spacing(4, 'px')} 0 ${theme.spacing(3, 'px')};
`

export const AuthLayout: React.FC<{ isLoading?: boolean }> = ({
  isLoading,
}) => (
  <$DarkWrapper>
    <$FormWrapper disableGutters>
      <Container maxWidth="xs" fixed disableGutters>
        <Paper>
          {isLoading && <LinearProgress />}
          <Box px={2} py={3}>
            <img src="/logo.png" alt="Fintastic" />
            {isLoading ? (
              <Box mt={2}>
                <Typography>Loading...</Typography>
              </Box>
            ) : (
              <Login />
            )}
          </Box>
        </Paper>
      </Container>
    </$FormWrapper>
    <$FooterWrapper>
      <Footer />
    </$FooterWrapper>
  </$DarkWrapper>
)
