import { AgGridColumn } from 'ag-grid-react'
import { AmountWithThreadCellRenderer } from '../AmountWithThreadCellRenderer'
import React from 'react'
import { currencyFormatter } from '../../field-formatter/currency-formatter'

export const useActualsColumn = (): React.ReactElement => (
  <AgGridColumn
    key="amount"
    field="amount"
    hide={false}
    sortable={false}
    valueFormatter={currencyFormatter}
    cellRendererFramework={AmountWithThreadCellRenderer}
    aggFunc="sum"
    filter="agNumberColumnFilter"
    type="numericColumn"
    suppressMovable
    suppressColumnsToolPanel
  />
)
