import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import '../../../../styles/Table.css'

import { Box, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'

import { AgGridReact } from 'ag-grid-react'
import { DashboardStates } from 'features/dashboard/ducks/dashboard/types'
// import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { WidgetHeader } from '../WidgetHeader'
import assumptionsData1 from '../../data/smallAssumptions1.json'
import assumptionsData2 from '../../data/smallAssumptions2.json'
import { makeStyles } from '@mui/styles'
import { selectDashboardPageState } from 'features/dashboard/ducks/dashboard/selectors'
import { useSelector } from 'react-redux'

const BACKGROUNDS = {
  GREEN: ['#58863B', '#548234', '#B0C5A1', '#D3DFCB', '#F5F8F3'],
  RED: ['#FF0100', '#FF4142', '#FF7F7F', '#FF9696', '#FFE9E9'],
}

const getBackgroundIndex = (value: string) => {
  const formattedValue = +value.replace('$', '').replace('k', '')

  if (formattedValue > 400 || formattedValue < -400) return 0
  if (formattedValue > 300 || formattedValue < -300) return 1
  if (formattedValue > 200 || formattedValue < -200) return 2
  if (formattedValue > 100 || formattedValue < -100) return 3
  return 4
}

const useStyles = makeStyles({
  indent: {
    fontWeight: 'bold',
    borderBottom: '1px solid black',
    backgroundColor: '#f8f8f8',
  },
})

export const AssumptionsHeatmap = () => {
  const classes = useStyles()
  const [rowData, toggleData] = useState(assumptionsData1)
  const dashboardState = useSelector(selectDashboardPageState)

  useEffect(() => {
    if (dashboardState === DashboardStates.finalMarchForecast)
      toggleData(assumptionsData2)
  }, [dashboardState])

  const getBackgroundColor = (params: any) => {
    if (params.data.greenFields.includes(params.colDef.field)) {
      const index = getBackgroundIndex(params.data[params.colDef.field])
      return {
        backgroundColor: BACKGROUNDS.GREEN[index],
      }
    }
    if (params.data.redFields.includes(params.colDef.field)) {
      const index = getBackgroundIndex(params.data[params.colDef.field])
      return {
        backgroundColor: BACKGROUNDS.RED[index],
      }
    }
    return ''
  }

  const [columnDefs] = useState([
    {
      headerName: 'Variance in Q3 QTD',
      children: [
        { field: 'Assumptions' },
        { field: 'Q3 Actual', headerName: 'Q3 QTD Actual' },
        { field: 'Q3 Plan', headerName: 'Q3 QTD Plan' },
      ],
    },
    {
      headerName: 'Impact on End of Year',
      children: [
        {
          field: 'ARR',
          cellStyle: (params: any) => ({
            textAlign: 'center',
            ...getBackgroundColor(params),
          }),
        },
        {
          field: 'EBIDTA',
          cellStyle: (params: any) => ({
            ...getBackgroundColor(params),
          }),
        },
        {
          field: 'Cash',
          cellStyle: (params: any) => ({
            ...getBackgroundColor(params),
          }),
        },
      ],
    },
  ])

  const onGridReady = useCallback((params) => {
    params.api.sizeColumnsToFit()
    window.addEventListener('resize', function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit()
      })
    })
  }, [])

  const getRowStyle = (params: any) => {
    if (
      // eslint-disable-next-line
      ['Marketing', 'Sales', 'Retention', 'HR Costs', 'G&A', 'PS'].includes(
        params.data.Assumptions,
      )
    ) {
      return {
        fontWeight: 'bold',
        borderBottom: '1px solid black',
        backgroundColor: '#f8f8f8',
      }
    }
    return {
      fontWeight: 'unset',
      borderBottom: '1px solid lightgrey',
      backgroundColor: 'unset',
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
    >
      <WidgetHeader title="Model Assumptions" />
      <Box
        className="ag-theme-alpine"
        sx={{
          'height': '100%',
          'width': '100%',
          'padding': '0 10px 10px',
          '& .ag-root-wrapper': {
            background: '#fff !important',
          },
          '& .ag-root-wrapper, & .ag-row': {
            fontSize: '9px !important',
          },
          '& .ag-header-row': {
            fontWeight: '500 !important',
            padding: '0px !important',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '10px !important',
          },
          '& .ag-header-container': {
            background: '#fff',
          },
          '& .ag-header-group-cell-label, .ag-header-cell-label': {
            overflow: 'inherit',
            fontSize: '9px !important',
            fontWeight: '600 !important',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          '& .ag-header-group-cell:not(:last-child)': {
            borderRight: '1px solid var(--ag-border-color, #babfc7)',
          },
          '& .ag-header-cell': {
            padding: '0 0 0 1px !important',
          },
          '& .ag-header-cell:not(:last-child)': {
            borderRight: '1px solid var(--ag-border-color, #babfc7)',
          },
          '& .ag-header-row-column-group': {
            color: '#7a7a7a !important',
          },
          '& .ag-row .ag-cell': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          '& .ag-row .ag-cell:not(:last-child)': {
            borderRight: '1px solid var(--ag-border-color, #babfc7) !important',
          },
          '& .ag-row .ag-cell:first-child': {
            // width: '20px',
            // whiteSpace: 'initial',
            paddingLeft: '3px',
            paddingRight: '2px',
            justifyContent: 'flex-start',
            textAlign: 'left',
            lineHeight: '10px',
            wordBreak: 'inherit',
          },
        }}
      >
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          headerHeight={20}
          getRowStyle={getRowStyle}
          getRowHeight={(params: any) => (!params.data.ARR ? 25 : 45)}
        />
      </Box>
    </Box>
  )
}
