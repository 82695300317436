import {
  generateRowThreadId,
  hasCellThread,
} from 'features/threads/utils/prepare-thread-id'
import {
  getNetsuiteEntityUrl,
  getSalesforceAccountUrl,
} from '../../utils/external-platform-url'

import { AggregatedTableContext } from '../../context/AggregatedTableContext'
import { ConfigContext } from '../../context/ConfigContext'
import type { GroupCellRendererParams } from 'ag-grid-community'
import { Icon } from '../Icon/Icon'
import React from 'react'
import { SecondaryTableContext } from 'context/SecondaryTableContext'
import type { SecondaryTableRow } from '../../types'
import { ThreadRenderer } from 'components/cell-renderer/ThreadRenderer'
import netsuiteLogo from '../../assets/netsuite.png'
import salesforceLogo from '../../assets/salesforce.png'
import styled from 'styled-components'
import { usePageThreads } from 'features/threads/hooks/usePageThreads'

const $CellFullWidth = styled.div`
  display: inline-block;
  width: 100%;
  padding-right: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  text-align: left;
  padding-left: 1rem;

  .hidden-when-non-hovered {
    opacity: 0;
  }

  &:hover .hidden-when-non-hovered {
    opacity: 1;
  }
`

const $CellIconWrap = styled.span`
  display: inline-flex;
  width: 24px;
  height: 24px;
  margin: auto;
  cursor: pointer;
  margin: 0 0 0 3px;
  align-items: center;
  vertical-align: middle;

  img {
    display: block;
  }
`

const $ContentWithIconWrap = styled.span`
  display: inline-block;
  margin-left: 3px;
`

const AccountCellRendererContent: React.FC<
  GroupCellRendererParams & { data: SecondaryTableRow }
> = (props) => {
  const { gridRef } = React.useContext(AggregatedTableContext)
  const data = props.data as SecondaryTableRow

  const [currentAccount, setCurrentAccount] = React.useState('')
  const { config } = React.useContext(ConfigContext)

  const onToggleAccountFocus = React.useCallback(() => {
    const account = currentAccount !== props.value ? props.value : ''
    const accountFilter = gridRef?.current?.api.getFilterInstance('account')

    if (!accountFilter) {
      return
    }
    setCurrentAccount(account)

    accountFilter
      .setModel(
        account
          ? {
              filterType: 'text',
              type: 'equals',
              filter: account,
            }
          : null,
      )
      ?.then(() => {
        try {
          gridRef?.current?.api.onFilterChanged()
        } catch (ex) {
          console.warn(ex)
        }
      })
  }, [gridRef, setCurrentAccount, currentAccount, props.value])

  // Load current selected account once filter changed
  React.useEffect(() => {
    const filterModel = gridRef?.current?.api.getFilterModel()
    setCurrentAccount(
      (filterModel?.account?.type === 'equals' &&
        filterModel?.account?.filter) ||
        '',
    )
  }, [gridRef, setCurrentAccount])

  const saleforceAccountURL = getSalesforceAccountUrl(
    config?.features_dict.salesforce_customer_id?.value,
    data.id,
  )
  const netsuiteAccountURL = getNetsuiteEntityUrl(
    config?.features_dict.netsuite_customer_id?.value,
    data['Entity ID'],
  )

  // For some reason, AG Grid doesn't handle directly attached event via React flow
  // Need to add event listener manually
  const magnifierRef = React.useRef<HTMLSpanElement>(null)

  React.useEffect(() => {
    const node = magnifierRef?.current
    node?.addEventListener('click', onToggleAccountFocus)
    return () => node?.removeEventListener('click', onToggleAccountFocus)
  }, [magnifierRef, onToggleAccountFocus])

  return (
    <$CellFullWidth>
      {props.valueFormatted}
      <$CellIconWrap
        className={
          currentAccount !== props.value ? 'hidden-when-non-hovered' : ''
        }
        ref={magnifierRef}
      >
        <Icon
          icon={
            currentAccount === props.value ? 'magnify-minus' : 'magnify-plus'
          }
          color="var(--color-icon-nav-selected)"
          style={{ position: 'absolute' }}
        />
      </$CellIconWrap>
      {saleforceAccountURL && (
        <$CellIconWrap className="hidden-when-non-hovered">
          <a href={saleforceAccountURL} target="_blank" rel="noreferrer">
            <img src={salesforceLogo} alt="sf link" width="20" height="18" />
          </a>
        </$CellIconWrap>
      )}
      {netsuiteAccountURL && (
        <$CellIconWrap className="hidden-when-non-hovered">
          <a href={netsuiteAccountURL} target="_blank" rel="noreferrer">
            <img src={netsuiteLogo} alt="ns link" width="18" height="18" />
          </a>
        </$CellIconWrap>
      )}
    </$CellFullWidth>
  )
}

export const AccountCellRenderer: React.FC<
  GroupCellRendererParams & { data: SecondaryTableRow }
> = (props) => {
  const { headerText, aggregationType } = React.useContext(
    SecondaryTableContext,
  )

  const threads = usePageThreads()
  const rowId = `${props?.data?.id}/${props.node?.id}/${props?.data?.account}`
  const threadId = generateRowThreadId(
    rowId,
    headerText.default,
    aggregationType,
  )
  const isWithThread = hasCellThread(threads, threadId)

  return isWithThread ? (
    <ThreadRenderer>
      <$ContentWithIconWrap>
        <AccountCellRendererContent {...props} />
      </$ContentWithIconWrap>
    </ThreadRenderer>
  ) : (
    <AccountCellRendererContent {...props} />
  )
}
