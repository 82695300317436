import { Box, Grid } from '@mui/material'
import DashboardComboBarLineChart, {
  DashboardComboBarLineChartData,
} from '../components/DashboardComboBarLineChart'
import DashboardWaterfallChart, {
  DashboardWaterfallChartData,
} from '../components/DashboardWaterfallChart'
import GridLayout, { Responsive, WidthProvider } from 'react-grid-layout'
import KPIWidget, { KPIWidgetProps } from 'components/atoms/KPIWidget'
import React, { useEffect, useState } from 'react'

import { AssumptionsHeatmap } from 'features/dashboard/components/Charts/AssumptionsHeatMap'
import DashboardCashFlow from '../components/DashboardCashFlow'
import DashboardHeader from '../components/DashboardHeader'
import { DashboardStates } from '../ducks/dashboard/types'
import { DashboardWrapper } from 'components/DashboardWrapper/DashboardWrapper'
import { HeadcountChart } from '../components/Charts/HeadcountChart'
import { SmallBridgeWidget } from '../components/SmallBridgeWidget'
import arr from '../data/arrQs1.json'
import arrNext from '../data/arrQs2.json'
import { pageContainerPaddings } from '../styles/page-container-paddings'
import { selectDashboardPageState } from '../ducks/dashboard/selectors'
import toplineKPIs from '../data/topLineKPIS.json'
import { useSelector } from 'react-redux'
import { widgetContainerStyles } from '../styles/widget-container'
import DashboardAdd from '../components/DashboardADD'
import DashboardArrAreaChart from '../components/DashboardArrAreaChart'

interface WindowSize {
  width: number
  height: number
}

const ResponsiveGridLayout = WidthProvider(Responsive)

export const AnalyticsPage: React.FC = () => {
  const currentState = useSelector(selectDashboardPageState)
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
  })

  const [ARRData, setARRData] = useState<DashboardComboBarLineChartData>(arr)

  useEffect(() => {
    if (currentState !== DashboardStates.initial) {
      setARRData(arrNext)
    } else {
      setARRData(arr)
    }
  }, [currentState])

  const initialKPIs: KPIWidgetProps[] = toplineKPIs

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // TODO: move these to some config file
  // TODO: calculate this shit dynamically
  const topWidgetsLayout = [
    { i: 'ARR', x: 0, y: 0, w: 1.8, h: 2, static: true },
    { i: 'ASP', x: 1.8, y: 0, w: 1.8, h: 2, static: true },
    { i: 'WIN_RATE', x: 3.6, y: 0, w: 1.8, h: 2, static: true },
    { i: 'RENEWAL_RATE', x: 5.4, y: 0, w: 1.8, h: 2, static: true },
    { i: 'NDR', x: 7.2, y: 0, w: 1.8, h: 2, static: true },
    { i: 'COLLECTIONS', x: 9, y: 0, w: 1.8, h: 2, static: true },
  ]

  const middleLayout = {
    lg: [
      { i: 'FIG1', x: 0, y: 0, w: 3, h: 2, static: true },
      { i: 'FIG2', x: 3, y: 0, w: 6, h: 2, static: true },
      { i: 'FIG3', x: 9, y: 0, w: 3, h: 2, static: true },
    ],
    md: [
      { i: 'FIG1', x: 0, y: 0, w: 6, h: 2, static: true },
      { i: 'FIG2', x: 0, y: 1, w: 8, h: 2, static: true },
      { i: 'FIG3', x: 6, y: 0, w: 6, h: 2, static: true },
    ],
    sm: [
      { i: 'FIG1', x: 0, y: 0, w: 6, h: 2, static: true },
      { i: 'FIG2', x: 0, y: 1, w: 8, h: 2, static: true },
      { i: 'FIG3', x: 6, y: 0, w: 6, h: 2, static: true },
    ],
  }

  const bottomLayout = {
    lg: [
      { i: 'FIG1', x: 0, y: 0, w: 5, h: 2, static: true },
      { i: 'FIG2', x: 5, y: 0, w: 4, h: 2, static: true },
      { i: 'FIG3', x: 9, y: 0, w: 3, h: 2, static: true },
    ],
    md: [
      { i: 'FIG1', x: 0, y: 0, w: 6, h: 2, static: true },
      { i: 'FIG2', x: 0, y: 1, w: 8, h: 2, static: true },
      { i: 'FIG3', x: 6, y: 0, w: 6, h: 2, static: true },
    ],
    sm: [
      { i: 'FIG1', x: 0, y: 0, w: 6, h: 2, static: true },
      { i: 'FIG2', x: 0, y: 1, w: 8, h: 2, static: true },
      { i: 'FIG3', x: 6, y: 0, w: 6, h: 2, static: true },
    ],
  }

  return (
    <DashboardWrapper enableThreads>
      <DashboardHeader />

      <Box
        sx={{
          ...pageContainerPaddings(),
          pb: 0,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box>
              <Grid container spacing={1}>
                {initialKPIs.map((widgData, i) => (
                  <Grid item xs={2} key={topWidgetsLayout[i].i}>
                    <KPIWidget
                      kpiName={widgData.kpiName}
                      actual={widgData.actual}
                      assumption={widgData.assumption}
                      assumptionActualRatio={widgData.assumptionActualRatio}
                      units={widgData.units}
                      ratioColor={widgData.ratioColor}
                      wrapperBoxStyle={widgetContainerStyles()}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box>
              <Grid container spacing={1} alignItems="stretch">
                <Grid item xs={4} sx={{ height: `${(422 / 1080) * 100}vh` }}>
                  <Box
                    sx={{
                      ...widgetContainerStyles(),
                      position: 'relative',
                      height: '100%',
                    }}
                  >
                    <SmallBridgeWidget />
                  </Box>
                </Grid>
                <Grid item sx={{ height: `${(422 / 1080) * 100}vh` }} xs={4}>
                  <Box
                    sx={{
                      ...widgetContainerStyles(),
                      position: 'relative',
                      height: '100%',
                    }}
                  >
                    <DashboardArrAreaChart />
                    {/* <DashboardComboBarLineChart
                      data={ARRData.data}
                      legend={ARRData.legend}
                      title={ARRData.title}
                      tooltipNameMapper={ARRData.tooltipNameMapper}
                      stroke={ARRData.stroke}
                      area={ARRData.area}
                      heightRatio={2.3}
                      widthRatio={2.1}
                      dollars={[true, true]}
                      domain={{ low: -40000000 }}
                    /> */}
                  </Box>
                </Grid>
                <Grid item sx={{ height: `${(422 / 1080) * 100}vh` }} xs={4}>
                  <Box
                    sx={{
                      ...widgetContainerStyles(),
                      position: 'relative',
                      height: '100%',
                    }}
                  >
                    <AssumptionsHeatmap />
                  </Box>
                </Grid>
                <Grid item sx={{ height: `${(422 / 1080) * 100}vh` }} xs={4}>
                  <Box
                    sx={{
                      ...widgetContainerStyles(),
                      position: 'relative',
                      height: '100%',
                    }}
                  >
                    <HeadcountChart />
                  </Box>
                </Grid>
                <Grid item sx={{ height: `${(422 / 1080) * 100}vh` }} xs={4}>
                  <Box
                    sx={{
                      ...widgetContainerStyles(),
                      position: 'relative',
                      height: '100%',
                    }}
                  >
                    <DashboardCashFlow />
                  </Box>
                </Grid>
                <Grid item sx={{ height: `${(422 / 1080) * 100}vh` }} xs={4}>
                  <Box
                    sx={{
                      ...widgetContainerStyles(),
                      position: 'relative',
                      height: '100%',
                    }}
                  >
                    <DashboardAdd />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </DashboardWrapper>
  )
}
