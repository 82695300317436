import { ConfigContext } from '../../../context/ConfigContext'
import { NO_FIRST_MONTH_FALLBACK } from '../../../utils/const'
import React from 'react'

export const useTableMinDate = (): string => {
  const { config } = React.useContext(ConfigContext)

  return React.useMemo(
    () => config?.limits.first_month || NO_FIRST_MONTH_FALLBACK,
    [config],
  )
}
