/**
 Types for describe duck state and extend root state
 */

import type { DefaultRootState } from 'react-redux'
import { RequestStateStatus } from '../../../../redux/shared/request/types'
import { Maybe } from '../../../../types'
import {
  HeadcountAssumptionsRecord,
  HeadcountConfig,
  HeadcountDataSource,
  HeadcountEmployee,
} from '../../types'
import {
  DATA_SOURCE_HIRING_PLAN,
  DATA_SOURCE_ASSUMPTIONS,
  DATA_SOURCE_KNOWN_EMPLOYEES,
} from './const'

/**
 Will be used in @reduxjs/toolkit to generate action types' names
 and to add duck reducer to the root state
 */
export const namespace = 'headcount'

// extending root state to use it in selectors
export type RootState = DefaultRootState & {
  headcount: State
}

// duck state
export type State = {
  config: {
    status: RequestStateStatus
    error: Maybe<string>
    data?: HeadcountConfig
  }
  [DATA_SOURCE_ASSUMPTIONS]: HeadcountDataSource<HeadcountAssumptionsRecord[]>
  [DATA_SOURCE_KNOWN_EMPLOYEES]: HeadcountDataSource<HeadcountEmployee[]>
  [DATA_SOURCE_HIRING_PLAN]: HeadcountDataSource<HeadcountEmployee[]>
}

export const initialState: State = {
  config: {
    status: 'initial',
    error: null,
  },
  [DATA_SOURCE_ASSUMPTIONS]: {
    status: 'initial',
    version: null,
    error: null,
  },
  [DATA_SOURCE_KNOWN_EMPLOYEES]: {
    status: 'initial',
    version: null,
    error: null,
  },
  [DATA_SOURCE_HIRING_PLAN]: {
    status: 'initial',
    version: null,
    error: null,
  },
}
