import React from 'react'
import type { ReportGroupsState } from '../state'
import { genericReportSlice } from '../slice'
import { isLoadingStatus } from 'redux/utils/isLoadingStatus'
import { selectReportGroupsState } from '../selectors'
import { useAccessToken } from 'api/useAccessToken'
import { useAppSelector } from 'redux/hooks/redux'
import { useDispatch } from 'react-redux'

type UseReportGroups = {
  reportGroups: ReportGroupsState['data']
  error: ReportGroupsState['error']
  isLoading: boolean
  fetchReportGroups: () => void
}

export const useReportGroups = (): UseReportGroups => {
  const dispatch = useDispatch()
  const { token } = useAccessToken()
  const { data, status, error } = useAppSelector(selectReportGroupsState)
  const isLoading = isLoadingStatus(status)

  const fetchReportGroups = React.useCallback(() => {
    dispatch(genericReportSlice.actions.getReportGroupsRequest())
  }, [dispatch])

  React.useEffect(() => {
    if (status === 'initial' && token) fetchReportGroups()
  }, [fetchReportGroups, token, status])

  return {
    reportGroups: data,
    isLoading,
    error,
    fetchReportGroups,
  }
}
