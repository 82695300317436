import React from 'react'
import { useKpiDefNameMap } from '../../hooks/useKpiDefNameMap'

const renderColorlessLegendLabelFactory =
  (mapper: (input: string) => string) => (value: string) =>
    <span className="color-text">{mapper(value)}</span>

export const useRenderChartLabel = (): ReturnType<
  typeof renderColorlessLegendLabelFactory
> => {
  const kpiAggregationToName = useKpiDefNameMap()

  return React.useMemo(
    () => renderColorlessLegendLabelFactory(kpiAggregationToName),
    [kpiAggregationToName],
  )
}
