import { Button, Divider, IconButton, Select, Typography } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  selectDashboardPageState,
  selectSettingsPageState,
} from '../ducks/dashboard/selectors'
import { useHistory, useParams } from 'react-router-dom'

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { AssumptionsTable } from './AssumptionsTable'
import Box from '@mui/material/Box'
import { CapacityChart } from '../components/Charts/CapacityChart'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import TableViewIcon from '@mui/icons-material/TableView'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import DashboardCashFlow from '../components/DashboardCashFlow'
import DashboardChartHeader from '../components/DashboardChartHeader'
import { DashboardStates } from '../ducks/dashboard/types'
import { DashboardWrapper } from 'components/DashboardWrapper/DashboardWrapper'
import DateRangeIcon from '@mui/icons-material/DateRange'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { GenericContainer } from '../components/GenericContainer'
import { HeadcountChart } from '../components/Charts/HeadcountChart'
import { KpiChart } from '../components/Charts/KpiChart'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { PlanningHeadcount } from '../components/PlanningHeadcount'
import { PnlChart } from '../components/Charts/PnlChart'
import VerifyAction from 'components/atoms/VerifyAction'
import { grey } from '@mui/material/colors'
import { pageHeadingStyles } from '../styles/page-heading-styles'
// import plans from '../data/availablePlans.json'
import styled from 'styled-components'
import { theme } from 'utils/theme'
import { useSelector } from 'react-redux'
import usePlans from 'hooks/usePlans'

const $Select = styled(Select)`
  width: 280px;
  margin-left: ${theme.spacing(6)};
  font-size: 20px;
  font-weight: 500;
`

const $Wrapper = styled.div`
  height: 620px;
`

const $PublishButton = styled(Button)`
  color: ${theme.palette.background.paper};
  border: 1px solid #3f51b5;
  text-transform: uppercase;
  background-color: #3f51b5;
  &:hover {
    background-color: #3f51b5;
  }
`

const tabs = [
  {
    tab: 'assumptions',
    title: 'Model Drivers',
    component: () => <AssumptionsTable />,
  },
  {
    tab: 'expensesincomeinput',
    title: 'Expenses / income input',
    component: () => <PlanningHeadcount />,
  },
  {
    tab: 'headcount',
    title: 'Headcount',
    component: () => <PlanningHeadcount />,
  },
  {
    tab: 'capex',
    title: 'Capex',
    component: () => <PlanningHeadcount />,
  },
  {
    tab: 'cashplanning',
    title: 'Cash planning (inc. debt)',
    component: () => <PlanningHeadcount />,
  },
  {
    tab: 'vendorsubscription',
    title: 'Vendor Subscription',
    component: () => <PlanningHeadcount />,
  },
  {
    tab: 'allocations',
    title: 'Allocations',
    component: () => <PlanningHeadcount />,
  },
]

const sideGraphsAssumptions = [
  <PnlChart key="5" />,
  <DashboardCashFlow key="57" planning={true} />,
  <KpiChart key="56" />,
]

const sideGraphsHeadcount = [
  <HeadcountChart key="58" hideInsight={true} />,
  <KpiChart key="59" />,
  <CapacityChart key="50" />,
]
const defaultTab = tabs[0].tab

export const PlanningPage: React.FC = () => {
  const [sideGraphs, setSideGraphs] = useState(sideGraphsHeadcount)
  const currentState = useSelector(selectDashboardPageState)
  const currentSettingState = useSelector(selectSettingsPageState)
  const { getPlans } = usePlans()
  const plans = getPlans()
  const availablePlans = plans[currentState === DashboardStates.initial ? 0 : 1]
  const currentPlan =
    currentState === DashboardStates.initial
      ? availablePlans[0]
      : availablePlans[4]

  const params: { tab: string } = useParams()
  const history = useHistory()

  const handleChangeTab = useCallback(
    (event) => {
      history.push(`/planning/${event.target.value}`)
    },
    [history],
  )

  const currentTab = useMemo(
    () => tabs.find((t) => t.tab === params.tab),
    [params.tab],
  )
  useEffect(() => {
    if (!currentTab) {
      history.push(`/planning/${defaultTab}`)
    }
    if (currentTab?.tab === 'assumptions') {
      setSideGraphs(sideGraphsAssumptions)
    } else {
      setSideGraphs(sideGraphsHeadcount)
    }
  }, [currentTab, history])

  const HeaderComponent = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: 1,
        mb: '3px',
        width: '100%',
      }}
    >
      <Box
        display="flex"
        gap={theme.spacing(1.25)}
        sx={{
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h1"
          style={{
            marginRight: '20px',
            ...pageHeadingStyles(),
          }}
        >
          Planning
        </Typography>
        <Box>
          <Typography
            style={{
              fontWeight: 300,
              fontSize: 18,
              color: 'grey',
              marginTop: '4px',
            }}
          >
            {currentPlan}
          </Typography>
        </Box>
      </Box>
      <Box>
        {/* <$Select
          variant="standard"
          id="planning-page-select"
          value={currentTab?.tab || defaultTab}
          onChange={handleChangeTab}
        >
          {tabs.map((tab) => (
            <MenuItem key={tab.tab} value={tab.tab}>
              {tab.title}
            </MenuItem>
          ))}
        </$Select> */}
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        color={grey[700]}
        gap={1}
      >
        <Divider orientation="vertical" />
        <IconButton>
          <DateRangeIcon />
        </IconButton>
        <Typography whiteSpace="nowrap" fontSize={14}>
          01 Jan, 2022 - 31 Dec, 2022
        </Typography>
        <Divider orientation="vertical" />
        <IconButton>
          <DeleteForeverIcon color="secondary" />
        </IconButton>
        <IconButton>
          <ContentCopyIcon color="secondary" />
        </IconButton>
        <IconButton>
          <MoreVertIcon />
        </IconButton>
        <VerifyAction />
      </Box>
    </Box>
  )

  return (
    <DashboardWrapper
      enableThreads
      wrapperStyle={{
        maxWidth: 'calc(100vw - 40px)',
      }}
    >
      <Box display="flex" justifyContent="space-between" width="100%">
        {HeaderComponent}
      </Box>
      <Box>
        <Box sx={{ height: '100%' }}>
          <GenericContainer
            content={
              <>
                <DashboardChartHeader
                  legend={[]}
                  title={currentTab?.title || ''}
                />
                <Box width="100%" height="100%">
                  {currentTab && <currentTab.component />}
                </Box>
              </>
            }
          />
        </Box>
        <Box display="flex" minHeight="38vh">
          {sideGraphs.map((graph, i) => (
            <GenericContainer
              // eslint-disable-next-line
              key={`lala-${i}`}
              content={
                <Box
                  sx={{
                    position: 'relative',
                    height: '100%',
                    maxHeight: '36vh',
                    flex: 1,
                  }}
                >
                  {graph}
                </Box>
              }
            />
          ))}
        </Box>
      </Box>
    </DashboardWrapper>
  )
}
