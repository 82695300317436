import * as React from 'react'

import { DashboardStates, StatePages } from '../ducks/dashboard/types'
import { useDispatch, useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { Divider, MenuItem } from '@mui/material'
import Modal from '@mui/material/Modal'
import StyledSelect from 'components/atoms/StyledSelect'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { dashboardSlice } from '../ducks/dashboard/slice'
// import plans from '../data/availablePlans.json'
import { selectDashboardPageState } from '../ducks/dashboard/selectors'
import { useHistory } from 'react-router-dom'
import usePlans from '../../../hooks/usePlans'

export default function NewForecastModal() {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const currentState = useSelector(selectDashboardPageState)
  const { getPlans } = usePlans()
  const plans = getPlans()
  const availablePlans = plans[currentState === DashboardStates.initial ? 0 : 1]

  const dispatch = useDispatch()
  const history = useHistory()

  const moveToNextState = React.useCallback(() => {
    dispatch(
      dashboardSlice.actions.moveToState({
        page: StatePages.Dashboard,
        nextState: DashboardStates.newForecastAdded,
      }),
    )
    setOpen(false)
    history.push('/planning/assumptions')
  }, [dispatch, history])

  return (
    <div>
      <Button
        onClick={handleOpen}
        sx={{
          'position': 'relative',
          'height': '40px',
          'pl': '19px',
          'pr': '19px',
          'backgroundColor': '#FFA726',
          'border': '2px solid #ff7b24',
          'backgroundClip': 'padding-box',
          'borderRadius': '4px',
          'background':
            'linear-gradient(87.07deg, #FFA726 -33.99%, #FF5722 127.13%)',
          'boxShadow': '0px 2px 4px rgba(0, 0, 0, 0.15)',
          ':hover': {
            background:
              'linear-gradient(87.07deg, #FF5722 -33.99%, #FFA726 127.13%)',
          },
        }}
      >
        <Typography style={{ color: 'white', fontWeight: 700, fontSize: 18 }}>
          New Forecast
        </Typography>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        BackdropProps={{ invisible: true }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 450,
            bgcolor: 'background.paper',
            border: '0px solid #000',
            boxShadow: 24,
            p: 4,
            borderRadius: 1,
            textAlign: 'center',
            padding: 1,
          }}
        >
          <Box padding="0 10px 0 10px" textAlign="left" height="200px">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h4"
              fontWeight="800"
              style={{ marginBottom: '12px' }}
              sx={{ mb: 0, mt: 2 }}
            >
              Create new forecast
            </Typography>
            <StyledSelect label="Base Version">
              {availablePlans.map((plan: string) => (
                <MenuItem key={plan} value={plan}>
                  {plan}
                </MenuItem>
              ))}
            </StyledSelect>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <TextField
                sx={{ mt: 2 }}
                id="outlined-basic"
                label="Name your forecast"
                variant="outlined"
              />
            </Typography>
          </Box>
          <Divider />
          <div
            style={{
              display: 'flex',
              justifyContent: 'right',
              marginTop: '12px',
              marginBottom: '4px',
            }}
          >
            <Button
              style={{
                border: '1px solid #F4F4F4',
                marginRight: '24px',
                padding: '0px 18px 0px 18px',
              }}
              color="secondary"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: '#FF9800',
                color: 'white',
                padding: '0px 18px 0px 18px',
              }}
              onClick={moveToNextState}
            >
              Create Forecast
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  )
}
