import React from 'react'
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import { ICellRendererFunc, ICellRendererParams } from 'ag-grid-community'

const roles = ['C-suite', 'FP&A', 'Administrator', 'Developer']

const users = [
  { name: ' Aramouni Julia', email: 'julia@fintastic.ai', role: 'C-suite' },
  { name: 'Bartkowiak Dayna', email: 'dayna@fintastic.ai', role: 'FP&A' },
  { name: 'Devoy Lauren', email: 'lauren@fintastic.ai', role: 'FP&A' },
  { name: 'Hatfield Amanda', email: 'amanda@fintastic.ai', role: 'C-suite' },
  { name: 'Moews Oliver', email: 'oliver@fintastic.ai', role: 'FP&A' },
  {
    name: 'Simkhada Prashanna',
    email: 'prashanna@fintastic.ai',
    role: 'Administrator',
  },
  { name: 'Taylor Kendra', email: 'kendra@fintastic.ai', role: 'FP&A' },
  { name: 'Weir Trevor', email: 'trevor@fintastic.ai', role: 'Administrator' },
]

const columns = [
  { title: 'Name', field: 'name', type: 'textColumn', minWidth: 80, width: 80 },
  { title: 'Email', field: 'email', type: 'textColumn', minWidth: 60 },
  { title: 'Role', field: 'role', type: 'textColumn', minWidth: 40 },
]

const defaultColDef = {
  width: 100,
  editable: true,
  filter: true,
  sortable: true,
  resizable: true,
  flex: 1,
  hide: false,
}

const buttonCellRenderer: ICellRendererFunc = (params: ICellRendererParams) => {
  const button = '<Button>Remove</Button>'

  return `${button}`
}

export const UsersTab: React.FC = () => (
  <Stack sx={{ width: '70vw' }} spacing={2}>
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="baseline"
    >
      <Typography variant="h6">People</Typography>
      <Button>Add people</Button>
    </Grid>
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item xs={5}>
        <TextField
          label="Search for names, groups or email addresses"
          variant="outlined"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="role-label">Role</InputLabel>
          <Select
            labelId="role-label"
            id="demo-simple-select"
            defaultValue={null}
            label="Role"
          >
            {roles.map((role) => (
              <MenuItem value={role} key={role}>
                {role}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
    <Box className="ag-theme-alpine" sx={{ height: '50vh', width: '100%' }}>
      <AgGridReact
        defaultColDef={{
          flex: 1,
          filter: true,
          sortable: true,
          resizable: true,
        }}
        rowData={users}
        tooltipShowDelay={500}
      >
        {columns?.map((column) => (
          <AgGridColumn
            key={column.field}
            field={column.field}
            headerName={column.title}
            width={column.width || defaultColDef.width}
            minWidth={column.minWidth}
            editable
            filter="agSetColumnFilter"
            cellStyle={{ textAlign: 'left', leftPadding: 2 }}
          />
        ))}
        <AgGridColumn
          headerName=""
          field="name"
          width={20}
          minWidth={20}
          cellRenderer={buttonCellRenderer}
        />
      </AgGridReact>
    </Box>
  </Stack>
)
