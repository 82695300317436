import { AggregatedTableView } from '../AggregatedTableView/AggregatedTableView'
import React from 'react'
import { useBridgeDateColumn } from '../AggregatedTableView/columns/useBridgeDateColumn'
import { useMetricColumn } from '../AggregatedTableView/columns/useMetricColumn'
import { useActualsColumn } from '../AggregatedTableView/columns/useActualsColumn'

export const BridgeTable: React.FC = () => {
  const columns = [
    useMetricColumn(),
    useActualsColumn(),
    // usePlanColumn(),
    // useDiffColumn(),
    useBridgeDateColumn(),
  ]

  return (
    <AggregatedTableView
      propertyColsConfType="bridge"
      title="ARR Bridge"
      columns={columns}
      requiredRowGroups={['metric']}
      aggregationSwitch
    />
  )
}
