import { allMonthsFromDateRange } from '../../../utils/date-utils'
import dayjs from 'dayjs'
import { random } from 'lodash'

export type PlanningHeadcountComparableRowData = {
  prev: PlanningHeadcountRowData
  current: PlanningHeadcountRowData
}

export type PlanningHeadcountRowData = {
  forecast: string
  metric: string
  dimensions: {
    department: string
    role: string
    region: string
    segment: string
  }
  editable: boolean
  values: PlanningHeadcountRowDataValue[]
}

export type PlanningHeadcountRowDataValue = {
  date: string
  value: number
}

function makeForecastRow(
  forecast: string,
  metric: string,
  editable: boolean,
): PlanningHeadcountRowData {
  return {
    forecast,
    metric,
    dimensions: {
      department: 'Sales',
      role: 'AE',
      region: 'NA',
      segment: 'Mid market',
    },
    editable,
    values: [],
  }
}

function makeForecastRowsSet(
  forecast: string,
  editable: boolean,
): PlanningHeadcountRowData[] {
  const openingBalanceRow = makeForecastRow(
    forecast,
    'Opening balance',
    editable,
  )
  const joinRow = makeForecastRow(forecast, 'New employees', editable)
  const termRow = makeForecastRow(forecast, 'Term employees', editable)
  const closingBalanceRow = makeForecastRow(
    forecast,
    'Closing balance',
    editable,
  )

  const months = allMonthsFromDateRange(dayjs('2022-01'), dayjs('2022-12'))
  let lastIndex = -1

  months.forEach((date, i) => {
    let opening = 0
    const join = random(10, 30)
    const term = random(0, 10)

    if (lastIndex === -1) {
      opening = random(200, 300)
    } else {
      opening = closingBalanceRow.values[lastIndex].value
    }

    const closing = opening + join - term

    openingBalanceRow.values.push({
      date,
      value: opening,
    })
    joinRow.values.push({
      date,
      value: join,
    })
    termRow.values.push({
      date,
      value: term,
    })
    closingBalanceRow.values.push({
      date,
      value: closing,
    })

    lastIndex = i
  })

  return [openingBalanceRow, joinRow, termRow, closingBalanceRow]
}

export function makePlanningHeadcountData(): PlanningHeadcountComparableRowData[] {
  const mar = makeForecastRowsSet('Mar 22 forecast', false)
  const apr = makeForecastRowsSet('Apr 22 forecast', true)
  return mar.map((row, i) => ({
    prev: row,
    current: apr[i],
  }))
}
