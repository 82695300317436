import {
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
} from 'recharts'
import { getLineColor, ignoreLinesMap } from './chart-utils'

import React from 'react'
import { SecondaryTableContext } from '../../context/SecondaryTableContext'
import { compact } from 'lodash'
import { formatNumber } from '../field-formatter/currency-formatter'
import { useRenderChartLabel } from './useRenderChartLabel'

const CustomTooltip: React.FC<TooltipProps<number, string>> = ({
  active,
  payload,
}) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          background: 'white',
          border: '1ox solid #0000CD',
          padding: '12px',
        }}
      >
        <p>
          <b>{payload[0].name}</b>:{' '}
          {`$${formatNumber(payload[0].value as number)}`}
        </p>
      </div>
    )
  }

  return null
}

export const PieChartView: React.FC = () => {
  const { chartData } = React.useContext(SecondaryTableContext)
  const renderColorlessLegendLabel = useRenderChartLabel()

  const pieChartData: {
    label: JSX.Element
    keyName: string
    value: string | number | Date
  }[] = React.useMemo(() => {
    if (!chartData?.length) {
      return []
    }

    return compact(
      Object.keys(chartData[0]).map((key) => {
        if (ignoreLinesMap[key]) {
          return null
        }
        return {
          keyName: key,
          label: renderColorlessLegendLabel(key),
          value: Math.abs(chartData[0][key] as number),
        }
      }),
    )
  }, [chartData, renderColorlessLegendLabel])

  if (!pieChartData?.length || !chartData) {
    return null
  }

  return (
    <ResponsiveContainer>
      <PieChart>
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip content={<CustomTooltip />} isAnimationActive={false} />
        <Legend
          align="right"
          verticalAlign="top"
          iconType="circle"
          formatter={renderColorlessLegendLabel}
        />
        <Pie
          data={pieChartData}
          dataKey="value"
          nameKey="label"
          fill="#8884d8"
          label={(props) => props.label?.props?.children || props.name}
        >
          {pieChartData.map((entry, index) => (
            <Cell
              key={`cell-${entry.label}`}
              fill={getLineColor(entry.keyName, index)}
            />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  )
}
