import type { AgColumnsPivotKey, Maybe } from '../../../types'

import type { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact'
import { AggregatedTableContext } from '../../../context/AggregatedTableContext'
import React from 'react'
import { SecondaryTableContext } from '../../../context/SecondaryTableContext'
import { useUrlHashState } from '../../../hooks/useUrlHashState'

export const useDefaultCellRangeSelection = (
  gridRef: Maybe<React.RefObject<AgGridReactType>>,
  isGridReady: boolean,
): void => {
  const [isDefaultRangeApplied, setDefaultRangeApplied] = React.useState(false)
  const { aggregationType } = React.useContext(SecondaryTableContext)
  const { defaultCellRangeSelection } = React.useContext(AggregatedTableContext)
  const [urlSelectionCols] = useUrlHashState<Maybe<AgColumnsPivotKey[]>>(
    'grid-selection-cols',
  )
  const [urlSelectionRows] = useUrlHashState<Maybe<number[]>>(
    'grid-selection-rows',
  )

  React.useEffect(() => {
    if (
      isDefaultRangeApplied ||
      !gridRef?.current ||
      !defaultCellRangeSelection ||
      !isGridReady ||
      (urlSelectionCols !== null && urlSelectionRows !== null)
    ) {
      return
    }

    const columns = gridRef.current.columnApi.getSecondaryColumns()

    if (!columns) {
      return
    }

    if (defaultCellRangeSelection === 'firstRow') {
      gridRef.current.api?.addCellRange({
        rowStartIndex: 0,
        rowEndIndex: 0,
        columns: columns.map((col) => col.getColId()),
      })
    } else if (defaultCellRangeSelection === 'lastFullMonthColumn') {
      gridRef.current.api.clearRangeSelection()
      gridRef.current.api?.addCellRange({
        rowStartIndex: 0,
        rowEndIndex: gridRef.current.api?.getDisplayedRowCount() - 1,
        columns:
          columns.length > 1 ? [columns[columns.length - 2].getColId()] : [],
      })
    }

    setDefaultRangeApplied(true)
  }, [
    isDefaultRangeApplied,
    setDefaultRangeApplied,
    gridRef,
    isGridReady,
    urlSelectionCols,
    urlSelectionRows,
    defaultCellRangeSelection,
    aggregationType,
  ])
}
