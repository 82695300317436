import { Box, Button, ButtonGroup, Typography } from '@mui/material'

import { ModalDialog } from '../../../components/atoms/ModalDialog'
import React from 'react'

export interface ResolveThreadModalProps {
  open: boolean
  onClose: () => void
  onResolve: () => void
}
export const ResolveThreadModal: React.FC<ResolveThreadModalProps> = ({
  open,
  onClose,
  onResolve,
}) => (
  <ModalDialog open={open} onClose={onClose}>
    <Box
      style={{
        width: '100%',
        maxWidth: '70rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="h6">
        Are you sure want to resolve the thread?
      </Typography>
      <ButtonGroup disableElevation color="primary" sx={{ marginTop: 1 }}>
        <Button variant="contained" onClick={onResolve}>
          Resolve
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </ButtonGroup>
    </Box>
  </ModalDialog>
)
