import type { AllEffect, Effect } from 'redux-saga/effects'
import type {
  EmitCreateThreadRequest,
  EmitMentionEventRequest,
  EmitThreadEventRequest,
  EmitThreadEventsRequest,
  EmitThreadsRequest,
  EmitUserListRequest,
} from './reducers/types'
import type { Thread, ThreadEvent, User } from '../../types'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  getThreadEvents,
  getThreadsList,
  getUserList,
  postThread,
  postThreadEvent,
} from '../../api'

import type { Saga } from 'redux-saga'
import { actions } from './slice'

const {
  emitCreateThreadRequest,
  emitPostThreadEventRequest,
  emitMentionEventRequest,

  emitThreadsRequest,
  loadThreadsEnd,

  emitThreadEventsRequest,
  loadThreadEventsEnd,
  loadThreadEventsStart,

  emitUserListRequest,
  loadUserListEnd,

  setSelectedThread,
  markThreadResolved,
} = actions

function* onEmitThreadEventsRequest(action: EmitThreadEventsRequest) {
  const { token, threadId } = action.payload

  yield put(loadThreadEventsStart())

  try {
    const threadEvents: ThreadEvent[] = yield call(
      getThreadEvents,
      token,
      threadId,
    )

    yield put(
      loadThreadEventsEnd({
        error: null,
        threadEvents,
      }),
    )
  } catch (e) {
    yield put(
      loadThreadEventsEnd({
        error: 'Something went wrong',
        threadEvents: null,
      }),
    )
  }
}

function* onEmitThreadsRequest(action: EmitThreadsRequest) {
  const { token, pageKey, objectKey } = action.payload

  try {
    const threadsList: Thread[] = yield call(getThreadsList, token, {
      page_key: pageKey,
      ...(objectKey ? { object_key: objectKey } : {}),
    })

    yield put(
      loadThreadsEnd({
        error: null,
        threadsList,
      }),
    )
  } catch (e) {
    const err: Error = e as Error
    yield put(
      loadThreadsEnd({
        error: err.message || 'Something went wrong',
        threadsList: [],
      }),
    )
  }
}

function* onEmitCreateThreadRequest(action: EmitCreateThreadRequest) {
  const {
    token,
    pageKey,
    objectKey,
    comment,
    mentions,
    pageTitle,
    objectTitle,
    url,
  } = action.payload

  try {
    const { id: threadId } = yield call(postThread, token, {
      page_key: pageKey,
      object_key: objectKey,
      page_title: pageTitle,
      object_title: objectTitle,
      url,
    })

    yield put(
      emitPostThreadEventRequest({
        token,
        threadId,
        type: 'comment',
        body: comment,
        mentions,
      }),
    )

    yield put(
      emitThreadsRequest({
        token,
        pageKey,
        objectKey: null,
      }),
    )

    yield put(setSelectedThread(objectKey))
  } catch (e) {
    const err: Error = e as Error
    console.log(err.message)
  }
}

function* onEmitMentionEventRequest(action: EmitMentionEventRequest) {
  const { token, threadId, type, mention, eventId } = action.payload
  try {
    const payload = {
      type,
      ...(mention?.display ? { body: mention.display } : {}),
      ...(mention?.id ? { user_id: +mention.id } : {}),
      ...(eventId ? { event_id: eventId } : {}),
    }

    yield call(postThreadEvent, token, threadId, payload)
  } catch (e) {
    const err: Error = e as Error
    console.log(err.message)
  }
}

function* onEmitPostThreadEventRequest(action: EmitThreadEventRequest) {
  const { token, threadId, type, body, mentions } = action.payload
  try {
    const payload = {
      type,
      ...(body ? { body } : {}),
    }
    const { id } = yield call(postThreadEvent, token, threadId, payload)

    if (type === 'comment' && mentions?.length) {
      yield all(
        mentions.map((mention) =>
          put(
            emitMentionEventRequest({
              token,
              threadId,
              type: 'mention',
              mention,
              eventId: id,
            }),
          ),
        ),
      )
    }

    if (type === 'comment') {
      yield put(markThreadResolved({ threadId, isResolved: false }))

      yield put(
        emitThreadEventsRequest({
          token,
          threadId,
        }),
      )
    }

    if (type === 'resolve') {
      yield put(markThreadResolved({ threadId, isResolved: true }))
      yield put(setSelectedThread(null))
    }
  } catch (e) {
    const err: Error = e as Error
    console.log(err.message)
  }
}

function* onEmitUserListRequest(action: EmitUserListRequest) {
  const { token } = action.payload

  try {
    const userList: User[] = yield call(getUserList, token)

    yield put(
      loadUserListEnd({
        error: null,
        userList,
      }),
    )
  } catch (e) {
    const err: Error = e as Error
    yield put(
      loadUserListEnd({
        error: err.message || 'Something went wrong',
        userList: [],
      }),
    )
  }
}

function* watchersSaga(): Iterator<AllEffect<Effect>> {
  yield all([
    takeEvery(emitThreadsRequest, onEmitThreadsRequest),
    takeEvery(emitCreateThreadRequest, onEmitCreateThreadRequest),
    takeEvery(emitPostThreadEventRequest, onEmitPostThreadEventRequest),
    takeEvery(emitThreadEventsRequest, onEmitThreadEventsRequest),
    takeEvery(emitUserListRequest, onEmitUserListRequest),
    takeEvery(emitMentionEventRequest, onEmitMentionEventRequest),
  ])
}

const sagas: Saga[] = [watchersSaga]

export default sagas
