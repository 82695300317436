import { initialState, namespace } from './state'

import type { MoveToState, UpdateFilter } from './types'
import { createSlice } from '@reduxjs/toolkit'

export const dashboardSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    moveToState(state, action: MoveToState) {
      state.currentState[action.payload.page] = action.payload.nextState
    },
    updateFilter(state, action: UpdateFilter) {
      state.currentFilter[action.payload.filterName] =
        action.payload.filterValue
    },
  },
})
