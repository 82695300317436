import type { Maybe } from '../../../types'
import type { SecondaryViewAggregationType } from '../../../context/SecondaryTableContext'
import type { Thread } from '../types'

/**
 *
 * @param headerName column headerName
 * @param pivotKeys column pivot keys in case this is a pivot table
 * @param rowId cell rowId
 * @param cellPath cell title based on metrics
 * @param aggregationType table aggregation type
 * @returns threadId
 *
 * Every cell on table is a calculated not-indexed in DB value, the only way to link thread per cell
 * is creating unique id based on URL search params, table customization (cohorts, aggregation, time segmentation, etc.)
 */

export const generateColumnThreadId = (
  headerName: Maybe<string>,
  pivotKeys: Maybe<string>,
  cellPath: Maybe<string>,
  aggregationType: Maybe<SecondaryViewAggregationType>,
): string => `${headerName}~${pivotKeys}~${cellPath}~${aggregationType}`

export const generateRowThreadId = (
  rowId: Maybe<string>,
  cellPath: Maybe<string>,
  aggregationType: Maybe<SecondaryViewAggregationType>,
): string => `${rowId}~${cellPath}~${aggregationType}`

export const generatePnlThreadId = (
  headerName: Maybe<string>,
  cellPath: Maybe<string>,
): string => `${headerName}~${cellPath}`

/**
 *
 * @param threadIdValue DB-stored thread id
 * @returns string for threads description and object_title
 */

export const getThreadDescription = (threadIdValue: string): string => {
  const [headerName, rawCellPath] = threadIdValue.split('~')
  const description = headerName.split('/')[2] || headerName
  const cellPath = rawCellPath.replace(/; /g, ' - ')
  return `${cellPath} (${description})`
}

export const serializeRowThreadId = (
  threadId: string,
): { rowDataId: string; rowNodeId: string } => {
  const splitedId = threadId?.split('/') ?? ''
  const rowDataId = splitedId[0]
  const rowNodeId = splitedId[1]
  return {
    rowDataId,
    rowNodeId,
  }
}

export const hasCellThread = (threads: Thread[], id: string): boolean =>
  Boolean(
    threads.find((thread) => thread.object_key === id && !thread.is_resolved),
  )
