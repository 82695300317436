import {
  Box,
  FormControlLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'

import { AggregatedTableContext } from 'context/AggregatedTableContext'
import React from 'react'
import type { SelectProps } from '@mui/material/Select'
import type { TimeSegmentationFrame } from '../../../types'
import { useIsFeatureEnabled } from 'redux/ducks/config/hooks'
import { useMonthsCount } from '../hooks/useMonthsCount'
import { useTimeSegmentationsList } from '../hooks/useTimeSegmentationsList'
import { useUrlHashState } from '../../../hooks/useUrlHashState'

export const TimeSegmentationSwitchPanel: React.FC = () => {
  const isFeatureEnabled = useIsFeatureEnabled('time_segmentations')

  // Temporary keep selection in local state instead of context
  const [timeSegmentationFrame, setTimeSegmentationFrame] =
    useUrlHashState<TimeSegmentationFrame>('time-segmentation', {}, 'month')
  const handleChange: SelectProps['onChange'] = React.useCallback(
    (event) => {
      setTimeSegmentationFrame(event.target.value)

      document.location.reload()
    },
    [setTimeSegmentationFrame],
  )

  const { dateRangeFrom, dateRangeTo } = React.useContext(
    AggregatedTableContext,
  )
  const monthsCount = useMonthsCount(dateRangeFrom, dateRangeTo)
  const timeSegments = useTimeSegmentationsList(monthsCount)

  if (!isFeatureEnabled) {
    return null
  }

  return (
    <Box pb={1} pt={1} ml={3}>
      <FormControlLabel
        labelPlacement="start"
        control={
          <Box ml={1}>
            <Select
              labelId="selected-arr"
              value={timeSegmentationFrame}
              onChange={handleChange}
              variant="standard"
            >
              {timeSegments.map(([key, label]) => (
                <MenuItem value={key} key={key}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </Box>
        }
        label={<Typography variant="caption">Period:</Typography>}
      />
    </Box>
  )
}
