import { Box } from '@mui/material'
import { NavigationDrawer } from '../NavigationDrawer/NavigationDrawer'
import React from 'react'
import { ThreadsBar } from 'features/threads/components/ThreadsBar'

export interface DashboardWrapperProps {
  drawer?: React.ReactNode
  enableThreads?: boolean
  padding?: number
  wrapperStyle?: any
}

export const DashboardWrapper: React.FC<DashboardWrapperProps> = ({
  children,
  drawer = <NavigationDrawer />,
  padding,
  enableThreads = false,
  wrapperStyle = {},
}) => (
  <Box
    display="flex"
    sx={{
      width: '100%',
    }}
    style={wrapperStyle}
  >
    {drawer}
    <Box
      flexGrow={1}
      p={padding ?? 0}
      sx={{
        width: '100%',
      }}
    >
      {children}
    </Box>
    {enableThreads && <ThreadsBar />}
  </Box>
)
