/**
 Sagas attached to current duck.
 @see https://redux-saga.js.org/docs/introduction/GettingStarted
 */

import type { AllEffect, Effect } from 'redux-saga/effects'
import { all, put, takeEvery, call } from 'redux-saga/effects'
import type { Saga } from 'redux-saga'
import {
  HeadcountAssumptionsRecord,
  HeadcountConfig,
  HeadcountEmployee,
} from '../../types'
import {
  DATA_SOURCE_ASSUMPTIONS,
  DATA_SOURCE_KNOWN_EMPLOYEES,
  DATA_SOURCE_HIRING_PLAN,
} from './const'
import { EmitLoadConfig } from './reducers'
import type { EmitLoadDataSource } from './reducers'
import { actions } from './slice'
import {
  getHeadcountConfig,
  getHeadcountAssumptions,
  getHeadcountHiringPlan,
  getHeadcountKnownEmployees,
} from '../../api/headcountAPI'

function* onEmitLoadDataSource({
  payload: { version, sourceKey, token },
}: EmitLoadDataSource) {
  yield put(
    actions.loadDataSourceStart({
      sourceKey,
    }),
  )

  try {
    let data
    switch (sourceKey) {
      case DATA_SOURCE_ASSUMPTIONS:
        data = (yield call(
          getHeadcountAssumptions,
          token,
        )) as HeadcountAssumptionsRecord[]
        break
      case DATA_SOURCE_KNOWN_EMPLOYEES:
        data = (yield call(
          getHeadcountKnownEmployees,
          token,
        )) as HeadcountEmployee[]
        break
      case DATA_SOURCE_HIRING_PLAN:
        data = (yield call(
          getHeadcountHiringPlan,
          token,
        )) as HeadcountEmployee[]
        break
      default:
        data = undefined
    }
    yield put(
      actions.loadDataSourceEnd({
        sourceKey,
        version,
        data: data as never,
        error: null,
      }),
    )
  } catch (e) {
    const err = e as Error
    yield put(
      actions.loadDataSourceEnd({
        sourceKey,
        version,
        error: err.message || 'Something went wrong',
      }),
    )
  }
}

function* onEmitLoadConfig(action: EmitLoadConfig) {
  yield put(actions.loadConfigStart({}))
  try {
    const res: HeadcountConfig = yield call(
      getHeadcountConfig,
      action.payload.token,
    )
    yield put(
      actions.loadConfigEnd({
        data: res,
        error: null,
      }),
    )
  } catch (e) {
    const err = e as Error
    yield put(
      actions.loadConfigEnd({
        error: err.message || 'Something went wrong',
      }),
    )
  }
}

/**
 Keep here all "watchers" generators
 @see https://redux-saga.js.org/docs/Glossary#watcherworker
 */
function* watchersSaga(): Iterator<AllEffect<Effect>> {
  yield all([
    takeEvery(actions.emitLoadDataSource, onEmitLoadDataSource),
    takeEvery(actions.emitLoadConfig, onEmitLoadConfig),
  ])
}

// here can be any other generators besides watchers
const sagas: Saga[] = [watchersSaga]

// export only array of sagas
export default sagas
