import React, { ReactNode } from 'react'
import { Box, Typography } from '@mui/material'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { Link } from 'react-router-dom'

type Props = {
  title: string
  link?: string
  insight?: ReactNode
  legend?: ReactNode
}

export const WidgetHeader: React.FC<Props> = (props) => {
  const { title, link, insight = null, legend = null } = props

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
        py: 0,
        px: '10px',
        width: '100%',
        height: '70px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
        }}
      >
        {insight}
        <DragIndicatorIcon
          sx={{
            color: 'rgba(0, 0, 0, 0.54)',
          }}
        />
        <Typography
          noWrap={true}
          sx={{ fontWeight: 700, marginRight: '12px', fontSize: '16px' }}
        >
          {link ? (
            <Link to={link} style={{ textDecoration: 'none', color: '#000' }}>
              {title}
            </Link>
          ) : (
            title
          )}
        </Typography>
      </Box>
      {legend && <Box>{legend}</Box>}
    </Box>
  )
}
