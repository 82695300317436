// Generate date range text based on aggregation
import { Aggregation } from '../../../workers/kpi/types'
import dayjs from 'dayjs'
import { getStartDateFromAggregationRange } from './get-start-date-from-aggregation-range'
import { parseAggregation } from '../../../utils/primitives'

export const generateDateRangeTextFromAggregation = (
  date: string,
  aggregation: Aggregation,
): string => {
  const clickedDateCell = dayjs(date)
  const [, range] = parseAggregation(aggregation)
  const startDate = getStartDateFromAggregationRange(clickedDateCell, range)

  if (!startDate) return ' - '

  return `${dayjs(startDate).format('MMM YYYY')} - ${clickedDateCell.format(
    'MMM YYYY',
  )}`
}
