import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { IconButton } from '@mui/material'
import TableViewIcon from '@mui/icons-material/TableView'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import { SmallPnlWidget } from './SmallPnlWidget'
import SmallExpenseCategories from './SmallExpenseCategories'
import rowData from '../data/smallPnl.json'
import { useHistory } from 'react-router-dom'

const IconContainer = styled.div`
  margin-left: 15px;
  margin-bottom: 5px;
`

export default function DashboardADD() {
  const [currentView, setCurrentView] = useState('p&l')
  const history = useHistory()

  const handleChangeView = useCallback(() => {
    setCurrentView((currentState) =>
      currentState === 'p&l' ? 'categories' : 'p&l',
    )
  }, [])

  const FooterPanel = () => (
    <IconContainer>
      <IconButton onClick={handleChangeView} size="small">
        <TableViewIcon />
      </IconButton>

      <IconButton size="small" onClick={() => history.push('/pnl')}>
        <FullscreenIcon />
      </IconButton>
    </IconContainer>
  )

  return (
    <>
      {currentView === 'p&l' ? (
        <SmallPnlWidget insight FooterPanel={FooterPanel} rowData={rowData} />
      ) : (
        <SmallExpenseCategories FooterPanel={FooterPanel} />
      )}
    </>
  )
}
