import { SettingsStates } from '../ducks/dashboard/types'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectSettingsPageState } from '../ducks/dashboard/selectors'
import { AssumptionsTableNewProduct } from './AssumptionsTableNewProduct'
import { AssumptionsTableMql } from './AssumptionsTableMql'

export const AssumptionsTable: React.FC = () => {
  const newProductState = useSelector(selectSettingsPageState)

  return newProductState === SettingsStates.productAdded ? (
    <AssumptionsTableNewProduct />
  ) : (
    <AssumptionsTableMql />
  )
}
