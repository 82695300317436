/* eslint-disable dot-notation */
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts'
import {
  axisAmountLabelFormatter,
  getClosestGranularNumber,
  getGranularityLength,
  getLineColor,
  xAxisLabelFormatter,
} from './chart-utils'

import type { Maybe } from '../../types'
import type { Metric } from '../../workers/aggregate-bridge.worker'
import React from 'react'
import { SecondaryTableContext } from '../../context/SecondaryTableContext'
import { Typography } from '@mui/material'
import { formatNumber } from '../field-formatter/currency-formatter'
import { uniqBy } from 'lodash'
import { useRenderChartLabel } from './useRenderChartLabel'

const visualValueFormatter = (value: number): string =>
  `$${formatNumber(value)}`

const countAggregationWaterfallMap: Partial<Record<Metric, boolean>> = {
  'Opening Balance': true,
  'New Logos': true,
  'Expansion': false,
  'Lost Logos': true,
  'Contraction': false,
  'Closing Balance': true,
}

type Affix = { affix: '' | '$' }
type CustomTooltipProps = TooltipProps<number, string> & Affix

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
  affix,
}) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          background: 'white',
          border: '1ox solid #0000CD',
          padding: '12px',
        }}
      >
        <Typography variant="h6">{payload[1].payload.date}</Typography>
        <Typography paragraph mb={1}>{`${
          payload[1].payload.label
        }: ${affix}${formatNumber(payload[1].value as number)}`}</Typography>
      </div>
    )
  }

  return null
}

export const BridgeWaterfallChartView: React.FC = () => {
  const { chartData, aggregationType } = React.useContext(SecondaryTableContext)

  const renderColorlessLegendLabel = useRenderChartLabel()

  const waterfall = React.useMemo((): Maybe<WaterfallItem[]> => {
    if (!chartData) {
      return null
    }

    const data: WaterfallItem[] = (chartData as WaterfallChartItem[]).flatMap(
      (source) => {
        const { date } = source

        return [
          {
            label: 'Opening Balance',
            visualValue: source['Opening Balance'],
            transparentValue: 0,
            date,
          },
          {
            label: 'New Logos',
            visualValue: source['New Logos'],
            transparentValue: source['Opening Balance'],
            date,
          },
          {
            label: 'Expansion',
            visualValue: source['Expansion'],
            transparentValue: source['New Logos'] + source['Opening Balance'],
            date,
          },
          {
            label: 'Lost Logos',
            visualValue: source['Lost Logos'],
            transparentValue:
              aggregationType === 'sum'
                ? source['Expansion'] +
                  source['New Logos'] +
                  source['Opening Balance']
                : source['New Logos'] +
                  source['Opening Balance'] -
                  source['Lost Logos'],
            date,
          },
          {
            label: 'Contraction',
            visualValue: source['Contraction'],
            transparentValue:
              source['Lost Logos'] +
              source['Expansion'] +
              source['New Logos'] +
              source['Opening Balance'],
            date,
          },
          {
            label: 'Closing Balance',
            visualValue: source['Closing Balance'],
            transparentValue: 0,
            date,
          },
        ]
      },
    )

    const basicData =
      aggregationType === 'count'
        ? data.filter((item) => countAggregationWaterfallMap[item.label])
        : data

    const finalData =
      data.length > 1
        ? basicData.filter(
            (item, index) => !(index > 0 && item.label === 'Opening Balance'),
          )
        : basicData

    return finalData
  }, [aggregationType, chartData])

  // Pick only needed items from waterfall, based on aggregationType
  const allValues = React.useMemo(() => {
    if (!waterfall) return [0]
    return aggregationType === 'count'
      ? [
          waterfall[0].visualValue,
          waterfall[1].transparentValue,
          waterfall[2].transparentValue,
          waterfall[3].visualValue,
        ]
      : [
          waterfall[0].visualValue,
          waterfall[1].transparentValue,
          waterfall[2].transparentValue,
          waterfall[3].transparentValue,
          waterfall[5].visualValue,
        ]
  }, [aggregationType, waterfall])

  const minValue = Math.min(...allValues)
  const maxValue = Math.max(...allValues)
  const minYAxisValue = React.useMemo(() => {
    let yAxisVal = minValue
    for (let i = getGranularityLength(minValue); i >= 0; i--) {
      const closestGranular = getClosestGranularNumber(minValue, i)
      // max bottom gap of 20% of total chart height
      if ((maxValue - minValue) / (minValue - closestGranular) >= 5) {
        yAxisVal = closestGranular
        break
      }
    }
    return yAxisVal
  }, [minValue, maxValue])

  const legendPayload = React.useMemo(
    () =>
      uniqBy(waterfall, 'label').map((item, index) => ({
        value: item.label,
        color: getLineColor(item.label, index),
      })),
    [waterfall],
  )

  const affix = aggregationType === 'count' ? '' : '$'
  const isSingleMonth = chartData?.length === 1

  if (!waterfall) {
    return null
  }

  return (
    <ResponsiveContainer width="95%">
      <BarChart
        data={waterfall}
        margin={{
          top: 20,
          right: 4,
          left: 0,
          bottom: 16,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          tickMargin={16}
          dataKey={isSingleMonth ? 'label' : 'date'}
          tickLine={isSingleMonth || false}
          interval={0}
          {...(!isSingleMonth && {
            tickFormatter: xAxisLabelFormatter(aggregationType),
          })}
        />
        <YAxis
          type="number"
          yAxisId="y"
          domain={[minYAxisValue >= 0 ? minYAxisValue : 0, 'auto']}
          allowDataOverflow
          tickFormatter={axisAmountLabelFormatter(affix)}
        />
        <Tooltip
          content={<CustomTooltip affix={affix} />}
          isAnimationActive={false}
        />
        <Bar
          dataKey="transparentValue"
          stackId="a"
          yAxisId="y"
          fill="transparent"
          isAnimationActive={false}
        />
        {!isSingleMonth && (
          <Legend
            align="right"
            verticalAlign="top"
            iconType="circle"
            payload={legendPayload}
            formatter={renderColorlessLegendLabel}
          />
        )}
        <Bar
          dataKey="visualValue"
          label="Metric"
          stackId="a"
          yAxisId="y"
          fill="#85ccbe"
          isAnimationActive={false}
        >
          {waterfall.map((item, index) => (
            <Cell key={item.label} fill={getLineColor(item.label, index)} />
          ))}
          {isSingleMonth && (
            <LabelList
              dataKey="visualValue"
              position="top"
              textBreakAll
              {...(aggregationType === 'sum' && {
                formatter: visualValueFormatter,
              })}
              fill="#000000"
            />
          )}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

type WaterfallItem = {
  label: Metric
  visualValue: number
  transparentValue: number
  date?: string | Date
}

type WaterfallChartItem = Record<Metric, number> & { date: Date }
