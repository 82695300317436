export function roundToDecimals(value: number, decimalPlaces: number): number {
  const pow = 10 ** decimalPlaces
  return Math.round((value + Number.EPSILON) * pow) / pow
}

export function numberFormatter(value: number): string {
  return Intl.NumberFormat().format(value)
}

export function finCurrencyFormatter(
  value: number,
  showDecimalPlaces: number,
  forZeroShowEmpty = true,
): string {
  const rounded = roundToDecimals(value, showDecimalPlaces)
  if (forZeroShowEmpty && rounded === 0) return ''
  return rounded < 0
    ? `$(${numberFormatter(-rounded)})`
    : `$${numberFormatter(rounded)}`
}

export function percentFormatter(
  value: number,
  showPercentDecimalPlaces: number,
  forZeroShowEmpty = true,
): string {
  if (
    forZeroShowEmpty &&
    roundToDecimals(value, showPercentDecimalPlaces) === 0
  )
    return ''
  return `${value.toFixed(1)}%`
}
