import type { AllEffect, Effect, SagaReturnType } from 'redux-saga/effects'
import type {
  BlueprintReport,
  ReportAggregates,
  ReportDetails,
  ReportGroup,
} from 'features/generic-report/types'
import type {
  GetReportBlueprintRequestAction,
  GetReportDetailsRequestAction,
  GetReportRequestAction,
} from './types'
import {
  all,
  call,
  delay,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import {
  getReportAggregatesAPI,
  getReportBlueprintAPI,
} from 'features/generic-report/api'
import mockBudgetJson from '../../mock/mock-budget.json'
import mockActualJson from '../../mock/mock-actuals.json'

import ReporDetailsMock from '../../mock/mock-report-details.json'
import type { Saga } from 'redux-saga'
import { genericReportSlice } from './slice'

function* getReportGroups() {
  try {
    yield delay(1000)
    // @todo: actual network request
    const data: ReportGroup[] = [
      {
        id: '92',
        displayName: 'P&L BVA',
        reports: [
          {
            id: 'actual-mock',
            name: 'Actual PnL',
            type: 'actual',
            blueprint: {
              id: '7c195e30-8b4e-11ec-bd10-0a58a9feac02',
            },
          },
          {
            id: 'budget-mock',
            name: 'Budget PnL',
            type: 'budget',
            blueprint: {
              id: '7c195e30-8b4e-11ec-bd10-0a58a9feac02',
            },
          },
        ],
      },
    ]
    yield put(genericReportSlice.actions.getReportGroupsSuccess(data))
  } catch (e) {
    const err = e as Error
    yield put(genericReportSlice.actions.getReportGroupsError(err.message))
  }
}

function* getReportBlueprint(action: GetReportBlueprintRequestAction) {
  try {
    const { token, tenantId, blueprintId } = action.payload

    const data: BlueprintReport = yield call(
      getReportBlueprintAPI,
      token,
      tenantId,
      blueprintId,
    )
    yield put(genericReportSlice.actions.getReportBlueprintSuccess(data))
  } catch (e) {
    yield put(
      genericReportSlice.actions.getReportBlueprintError({
        error: (e as Error)?.message,
        id: action.payload.blueprintId,
      }),
    )
  }
}

function* getReportDetails(action: GetReportDetailsRequestAction) {
  try {
    // const { token, tenantId, timestamp, dimentionValue, reportId } =
    //   action.payload

    // @todo uncomment when api is ready
    // const data: ReportDetails = yield call(
    //   getReportDetailsAPI,
    //   token,
    //   tenantId,
    //   timestamp,
    //   dimensionValue,
    //   reportId,
    // )
    const data: ReportDetails = ReporDetailsMock
    yield put(genericReportSlice.actions.getReportDetailsSuccess(data))
  } catch (e) {
    const err = e as Error
    yield put(genericReportSlice.actions.getReportDetailsError(err.message))
  }
}

function* getReport(action: GetReportRequestAction) {
  const { token, id } = action.payload

  try {
    let data: ReportAggregates

    // @todo(mykola) Remove mock
    if (id === 'budget-mock') {
      yield delay(500)
      data = mockBudgetJson
    } else if (id === 'actual-mock') {
      yield delay(500)
      data = mockActualJson
    } else {
      const result: SagaReturnType<typeof getReportAggregatesAPI> = yield call(
        getReportAggregatesAPI,
        token,
        id,
      )

      data = result.result
    }

    yield put(genericReportSlice.actions.getReportSuccess({ id, data }))
  } catch (e) {
    const err = e as Error
    yield put(
      genericReportSlice.actions.getReportError({ id, error: err.message }),
    )
  }
}

function* watchersSaga(): Iterator<AllEffect<Effect>> {
  yield all([
    takeLatest(
      genericReportSlice.actions.getReportGroupsRequest,
      getReportGroups,
    ),
    takeLatest(
      genericReportSlice.actions.getReportBlueprintRequest,
      getReportBlueprint,
    ),
    takeLatest(
      genericReportSlice.actions.getReportDetailsRequest,
      getReportDetails,
    ),
    takeEvery(genericReportSlice.actions.getReportRequest, getReport),
  ])
}

const sagas: Saga[] = [watchersSaga]

export default sagas
