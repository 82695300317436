import { initialState, namespace } from './state'

import { createSlice } from '@reduxjs/toolkit'
import reducers from './reducers'

export const { actions, reducer } = createSlice({
  name: namespace,
  initialState,
  reducers,
})
