import type {
  ErrorAction,
  GetReportBlueprintRequestAction,
  GetReportBlueprintSuccessAction,
  GetReportDetailsRequestAction,
  GetReportDetailsSuccessAction,
  GetReportError,
  GetReportGroupsSuccessAction,
  GetReportRequestAction,
  GetReportSuccessAction,
  SelectVersion,
  GetBlueprintError,
} from './types'
import { initialState, namespace } from './state'

import type { Report } from 'features/generic-report/types'
import { createSlice } from '@reduxjs/toolkit'

export const genericReportSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    getReportGroupsRequest(state) {
      state.reportGroups.status = 'loading'
    },
    getReportGroupsSuccess(state, action: GetReportGroupsSuccessAction) {
      state.reportGroups.status = 'success'
      state.reportGroups.data = action.payload
      const actual = action.payload[0].reports.find(
        (item: Report) => item.type === 'actual',
      )
      const firstNotActual = action.payload[0].reports.find(
        (item: Report) => item.type !== 'actual',
      )
      const versions = []
      if (actual) versions.push(actual)
      if (firstNotActual) versions.push(firstNotActual)
      state.selectedVersions = versions
    },
    getReportGroupsError(state, action: ErrorAction) {
      state.reportGroups.status = 'error'
      state.reportGroups.error = action.payload
    },

    getReportBlueprintRequest(state, action: GetReportBlueprintRequestAction) {
      const { blueprintId } = action.payload
      state.blueprintList[blueprintId] = {
        status: 'loading',
        error: null,
        data: null,
      }
    },
    getReportBlueprintSuccess(state, action: GetReportBlueprintSuccessAction) {
      const { id } = action.payload

      state.blueprintList[id] = {
        status: 'success',
        error: null,
        data: action.payload,
      }
    },
    getReportBlueprintError(state, action: GetBlueprintError) {
      const { id, error } = action.payload

      state.blueprintList[id] = {
        status: 'error',
        error,
        data: null,
      }
    },

    getReportDetailsRequest(state, action: GetReportDetailsRequestAction) {
      state.reportDetails.status = 'loading'
    },
    getReportDetailsSuccess(state, action: GetReportDetailsSuccessAction) {
      state.reportDetails.status = 'success'
      state.reportDetails.data = action.payload
    },
    getReportDetailsError(state, action: ErrorAction) {
      state.reportDetails.status = 'error'
      state.reportDetails.error = action.payload
    },

    selectVersion(state, action: SelectVersion) {
      const version = action.payload

      if (!state.selectedVersions) {
        state.selectedVersions = [version]
      }

      if (state.selectedVersions?.find((v) => v.id === version.id)) {
        state.selectedVersions = state.selectedVersions.filter(
          (v) => v.id !== version.id,
        )
      } else {
        state.selectedVersions = [...state.selectedVersions, version]
      }
    },

    getReportRequest(state, action: GetReportRequestAction) {
      const { id } = action.payload
      state.reportsList[id] = {
        status: 'loading',
        error: null,
        data: null,
      }
    },
    getReportSuccess(state, action: GetReportSuccessAction) {
      const { id, data } = action.payload

      state.reportsList[id] = {
        status: 'success',
        error: null,
        data,
      }
    },
    getReportError(state, action: GetReportError) {
      const { id, error } = action.payload

      state.reportsList[id] = {
        status: 'error',
        error,
        data: null,
      }
    },
  },
})
