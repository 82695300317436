import { ValueFormatterParams } from 'ag-grid-community'
import React, { useCallback, useMemo } from 'react'
import { compact, uniq, uniqBy } from 'lodash'

import { AccountCellRenderer } from '../AccountCellRenderer'
import { AgGridColumn } from 'ag-grid-react'
import { AggregatedTableContext } from '../../../context/AggregatedTableContext'
import type { Cohort } from '../../../api/types'
import { ConfigContext } from '../../../context/ConfigContext'
import { HeaderGroupTextFramework } from '../HeaderGroupTextFramework'
import { SecondaryTableContext } from '../../../context/SecondaryTableContext'
import capitalize from 'lodash/capitalize'
import { currencyFormatter } from '../../field-formatter/currency-formatter'
import { isArrItemWithMetric } from '../../../utils/type-guard'
import { isDateInTheNextMonth } from '../../../utils/date-utils'
import { useGetCohortRenderer } from '../columns/useGetCohortRenderer'
import { formatPropColVal } from '../utils/format-property-column-value'

export const useSecondaryTableColumns = (): React.ReactElement => {
  const { properties, config } = React.useContext(ConfigContext)

  // Initial cohorts list
  const { cohorts: rawCohorts } = React.useContext(AggregatedTableContext)

  // account cohort handled differently
  const cohorts = React.useMemo(
    () => rawCohorts.filter((cohort) => cohort.field !== 'account'),
    [rawCohorts],
  )

  const { rowData, headerText, propertyColsConfType, propertyColsConfKey } =
    React.useContext(SecondaryTableContext)

  // Select cohorts coming from configuration
  const propertyColumnsConfig = React.useMemo(() => {
    if (!propertyColsConfType || !propertyColsConfKey) {
      return null
    }

    return config?.property_columns[propertyColsConfType][propertyColsConfKey]
  }, [config, propertyColsConfType, propertyColsConfKey])

  // Gather all cohorts coming from properies data
  const cohortsFromProperties: Cohort[] = useMemo(() => {
    if (!properties) {
      return []
    }
    const firstProperty = properties[Object.keys(properties)[0]]
    if (!firstProperty) {
      return []
    }
    return Object.keys(firstProperty).map((fieldName) => ({
      field: fieldName,
    }))
  }, [properties])

  // Reshuffle cohorts in order to show configured first
  const mainCohorts = useMemo(() => {
    const allCohorts = [...cohorts, ...cohortsFromProperties]
    const configuredCohorts = compact(
      propertyColumnsConfig?.map((columnConfig) => {
        // Exclude configured cohorts if they are not part of `allCohorts` list
        let configuredCohort = allCohorts.find(
          (cohort) => cohort.field === columnConfig.field_name,
        )

        // Remove 'account' cohort, because it always inserts to the first column
        if (configuredCohort?.field === 'account') {
          configuredCohort = undefined
        }

        // Assign the "main" category to all configured cohorts
        return configuredCohort
          ? {
              ...configuredCohort,
              category: configuredCohort.category || 'main',
            }
          : undefined
      }),
    )
    return uniqBy([...configuredCohorts, ...cohorts], 'field')
  }, [cohorts, cohortsFromProperties, propertyColumnsConfig])

  // Exclude cohorts that go to "main" from "others"
  const otherCohorts = React.useMemo(
    () =>
      cohortsFromProperties.filter(
        (cohort) =>
          mainCohorts.find((c) => c.field === cohort.field) === undefined,
      ),
    [cohortsFromProperties, mainCohorts],
  )

  // Tries to get header name from the column configuration (`display_name`)
  // Or fallbacks to capitalized field or default value
  const getHeaderName = useCallback(
    (field: string, defaultValue?: string) => {
      const defaultFormatted = defaultValue || capitalize(field)
      if (!propertyColumnsConfig?.length) {
        return defaultFormatted
      }

      const conf = propertyColumnsConfig.find((el) => el.field_name === field)
      if (conf?.display_name) {
        return conf.display_name
      }

      return defaultFormatted
    },
    [propertyColumnsConfig],
  )

  const makeValueFormatter = useCallback(
    (field: string) => {
      if (!propertyColumnsConfig?.length) return undefined
      const conf = propertyColumnsConfig.find((el) => el.field_name === field)
      if (conf && conf.format) {
        return (params: ValueFormatterParams) =>
          formatPropColVal(params.value, conf.format)
      }
      return undefined
    },
    [propertyColumnsConfig],
  )

  const renderCohort = useGetCohortRenderer(getHeaderName, makeValueFormatter)

  const amountCohort = React.useMemo(() => {
    // No need to show `amount` cohort if columns configuration explicitly provided
    if (propertyColumnsConfig) {
      return null
    }

    const isDataPredict =
      rowData.length && isDateInTheNextMonth(rowData[0].date)
    const title = isDataPredict ? 'Probability-Adjusted Amount' : 'Amount'
    const firstRow = rowData?.[0]

    const isNegativeMetric =
      firstRow &&
      isArrItemWithMetric(firstRow) &&
      ['Lost Logos', 'Contraction', 'Total Churn'].includes(firstRow.metric)

    return (
      <AgGridColumn
        key="amount"
        field="amount"
        valueFormatter={currencyFormatter}
        filter="agNumberColumnFilter"
        headerName={getHeaderName('amount', title)}
        type="numericColumn"
        sort={isNegativeMetric ? 'asc' : 'desc'}
      />
    )
  }, [rowData, getHeaderName, propertyColumnsConfig])

  const categorizedCohorts = React.useMemo(() => {
    // First group (i. e. category) must render header using custom component
    const firstGroupHeaderProps = {
      headerGroupComponentFramework: HeaderGroupTextFramework,
      headerGroupComponentParams: { headerText },
    }

    const cohortCategories = uniq([
      'main',
      ...compact(cohorts.map((cohort) => cohort.category)).sort(),
    ])

    const cohortsWithNoCategory = cohorts.filter((cohort) => !cohort.category)
    const hasOtherCohorts = cohortsWithNoCategory.length || otherCohorts.length

    // Account cohort (to be placed at the top of the list)
    const accountCohort = renderCohort(
      { field: 'account', category: 'main' },
      {
        cellRendererFramework: AccountCellRenderer,
      },
    )

    // Helper to render Other cohort (coming from properties)
    const renderOtherCohort = ({ field }: Cohort) => (
      <AgGridColumn
        key={`other_${field}`}
        field={field}
        filter="agSetColumnFilter"
        headerName={getHeaderName(field)}
        enableRowGroup
        enablePivot
        hide
      />
    )

    // Fallback if no cohort categories provided,
    // use legacy categories flow with the hard-coded "Main" catetogy
    if (cohorts.every((cohort) => !cohort.category)) {
      return (
        <>
          <AgGridColumn headerName="Main" {...firstGroupHeaderProps}>
            {accountCohort}
            {amountCohort}
            {cohorts.map((cohort) => renderCohort(cohort))}
          </AgGridColumn>

          {hasOtherCohorts && (
            <AgGridColumn headerName="Other">
              {otherCohorts.map(renderOtherCohort)}
            </AgGridColumn>
          )}
        </>
      )
    }

    const getAgGridColumnPropertiesByIndex = (index: number) => {
      if (index !== 0) {
        return {}
      }

      return firstGroupHeaderProps
    }

    const formatCategoryName = (category: string) => {
      if (category === 'main') {
        return 'Main'
      }

      return category
    }

    return (
      <>
        {cohortCategories.map((category, index) => (
          <AgGridColumn
            headerName={formatCategoryName(category)}
            key={category}
            {...getAgGridColumnPropertiesByIndex(index)}
          >
            {/* Put account and amount to the top */}
            {index === 0 && accountCohort}
            {index === 0 && amountCohort}

            {mainCohorts
              .filter((cohort) => cohort.category === category)
              .map((cohort) => renderCohort(cohort))}
          </AgGridColumn>
        ))}

        {/* Render "Other" cohorts */}
        {hasOtherCohorts && (
          <AgGridColumn headerName="Other">
            {/* First - show cohorts with no category */}
            {cohortsWithNoCategory.map((cohort) => renderCohort(cohort))}
            {/* Second - show cohorts, coming from properties */}
            {otherCohorts.map(renderOtherCohort)}
          </AgGridColumn>
        )}
      </>
    )
  }, [
    headerText,
    cohorts,
    otherCohorts,
    renderCohort,
    getHeaderName,
    amountCohort,
    mainCohorts,
  ])

  return categorizedCohorts
}
