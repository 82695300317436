import type {
  FinDoc,
  FinDocResponse,
  FinDocResponseShort,
  FinDocsConfig,
} from './types'
import { FinDocEditResponse } from './types'
import { endpoints } from './endpoints'
import {
  runFetch,
  RunFetchTokenPolymorphicParams,
  runFetchWithToken,
  useApi,
} from './useApi'
import { AsyncState, useAsync } from 'react-async'
import { useCallback } from 'react'
import type { Maybe } from '../types'
import { useAuth0WithCypress } from '../hooks/useAuth0WithCypress'

export function useDocConfigApi(): AsyncState<Maybe<FinDocsConfig>> {
  return useApi<FinDocsConfig>(endpoints.docConfig)
}

export function useDocApi(
  docType: 'hiring_forecast',
  docUUID: string,
): AsyncState<Maybe<FinDocResponse>> {
  return useApi<FinDocResponse>(endpoints.docs(docType, docUUID))
}

export async function getDocsConfig({
  getAccessTokenSilently,
  token,
}: RunFetchTokenPolymorphicParams): Promise<FinDocsConfig> {
  const url = endpoints.docConfig
  return getAccessTokenSilently
    ? runFetchWithToken({ getAccessTokenSilently }, url)
    : runFetch(token as string, url)
}

export async function getDoc(
  { getAccessTokenSilently, token }: RunFetchTokenPolymorphicParams,
  docTypeName: string,
  docUUID: string,
): Promise<FinDocResponse> {
  const url = endpoints.docs(docTypeName, docUUID)
  return getAccessTokenSilently
    ? runFetchWithToken({ getAccessTokenSilently }, url)
    : runFetch(token as string, url)
}

export function useDocListApi(
  docTypeName: string,
  showDeleted = false,
): AsyncState<Maybe<FinDocResponseShort[]>> {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0WithCypress()

  const runFetchFn = useCallback(async () => {
    if (!isAuthenticated) {
      // eslint-disable-next-line no-console
      console.warn(
        'Attempting to request protected API with non-authentificated user',
      )
      return null
    }

    return runFetchWithToken<FinDocResponseShort[]>(
      { getAccessTokenSilently },
      endpoints.docs(docTypeName),
      {},
      { show_deleted: encodeURIComponent(showDeleted) },
    )
  }, [getAccessTokenSilently, isAuthenticated, docTypeName, showDeleted])

  return useAsync({
    promiseFn: runFetchFn,
  })
}

export async function saveDoc(
  { getAccessTokenSilently, token }: RunFetchTokenPolymorphicParams,
  docTypeName: string,
  document: FinDoc,
): Promise<FinDocEditResponse> {
  const url = endpoints.docs(docTypeName)
  const options = { method: 'PUT', body: JSON.stringify(document) }
  return getAccessTokenSilently
    ? runFetchWithToken({ getAccessTokenSilently }, url, options)
    : runFetch(token as string, url, options)
}
