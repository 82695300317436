// eslint-disable-next-line import/no-extraneous-dependencies
import { CSSProperties } from 'react'
import { textGradientStyles } from './text-gradient'

export const pageHeadingStyles = (): CSSProperties =>
  ({
    fontWeight: 700,
    fontSize: 30,
    color: '#FFA726',
    ...textGradientStyles('#FF5722', '#FFA726'),
  } as CSSProperties)
