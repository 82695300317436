import type { Maybe } from '../types'

export const periodicities = {
  month: 'Month',
  quarter: 'Quarter',
  year: 'Year',
}

export function getPeriodName(
  dateString: string,
  periodicity: string,
): Maybe<string> {
  if (!dateString) return null

  const date = new Date(dateString)
  const year = date.getFullYear()
  const month = date.getMonth() + 1

  switch (periodicity) {
    case periodicities.month:
      return `${year}.${month < 10 ? `0${month}` : month}`
    case periodicities.quarter:
      return `${year}.q${Math.floor(month / 4) + 1}`
    case periodicities.year:
      return `${year}`
    default:
      throw new Error(`Unimplemented periodicity ${periodicity}`)
  }
}

export function getPeriodList(
  values: Record<string, string | number>[],
  dateAttr: string,
  periodicity: string,
): string[] {
  const set = new Set(
    values.map(
      (value) => getPeriodName(value[dateAttr] as string, periodicity) || '',
    ),
  )
  return [...set].sort()
}
