/**
 Sagas attached to current duck.
 @see https://redux-saga.js.org/docs/introduction/GettingStarted
 */

import produce from 'immer'
import type { AllEffect, Effect } from 'redux-saga/effects'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import type { Saga } from 'redux-saga'
import {
  FinDoc,
  FinDocEditResponse,
  FinDocResponse,
  FinDocsConfig,
  ScenarioConfig,
  ScenarioVersion,
  ScenarioVersionResponse,
} from 'api/types'
import {
  copyVersionWithDocs,
  deleteScenarioVersion,
  getScenarioConfig,
  getScenarioVersion,
  getScenarioVersions,
  publishScenarioVersion,
  saveScenarioVersion,
} from 'api/useScenarioApi'
import { DEFAULT_ERROR_MESSAGE } from 'defualts'
import { Maybe } from 'types'
import { getDoc, getDocsConfig, saveDoc } from '../../../../api/useDocApi'
import { routes } from '../../routes'
import {
  SvDocumentCommonOperationParams,
  SvDocumentEditOperationParams,
  SvVersionCopyOperationParams,
  SvVersionSaveOperationParams,
} from '../../types'
import type {
  EmitDocumentOperation,
  EmitGlobalOperation,
  EmitVersionOperation,
} from './reducers'
import { selectVersion, selectVersionsList } from './selectors'
import { actions } from './slice'
import { scenarioTypeName } from 'features/scenarioVersions/utils'
import { history } from 'router/history'

export function* onEmitGlobalOperation(action: EmitGlobalOperation) {
  const { operation, token } = action.payload

  yield put(actions.startGlobalOperation({ operation }))
  let error: Maybe<string> = null

  try {
    if (operation === 'getVersionsConfig') {
      const config: ScenarioConfig = yield call(getScenarioConfig, {
        token,
      })
      yield put(actions.setVersionsConfig({ versionsConfig: config }))
    }

    if (operation === 'getDocsConfig') {
      const config: FinDocsConfig = yield call(getDocsConfig, {
        token,
      })
      yield put(actions.setDocsConfig({ docsConfig: config }))
    }

    if (operation === 'getVersionsList') {
      const versions: Maybe<ScenarioVersion[]> = yield call(
        getScenarioVersions,
        { token },
        scenarioTypeName,
      )
      yield put(
        actions.setVersionsList({
          versionsList: versions || [],
        }),
      )
    }
  } catch (e) {
    error = (e as Error).message || DEFAULT_ERROR_MESSAGE
  }

  yield put(
    actions.endGlobalOperation({
      operation,
      error,
      result: undefined,
    }),
  )
}

export function* onEmitVersionOperation(
  action: EmitVersionOperation<
    SvVersionCopyOperationParams | SvVersionSaveOperationParams
  >,
) {
  const { id, operation, token, params } = action.payload
  yield put(actions.startVersionOperation({ operation, id }))
  let error: Maybe<string> = null

  try {
    if (operation === 'get') {
      const versionResponse: ScenarioVersionResponse = yield call(
        getScenarioVersion,
        { token },
        scenarioTypeName,
        id,
      )
      yield put(actions.setVersionData({ id, data: versionResponse }))
    }

    if (operation === 'copy') {
      const operationParams = params as SvVersionCopyOperationParams
      const versionResponse: ScenarioVersion = yield call(
        copyVersionWithDocs,
        { token },
        operationParams.version.uuid,
        scenarioTypeName,
        operationParams.name,
      )
      yield put(
        actions.emitVersionOperation({
          operation: 'get',
          id: versionResponse.uuid,
          token,
        }),
      )
      const versionsList: ReturnType<typeof selectVersionsList> = yield select(
        selectVersionsList,
      )
      yield put(
        actions.setVersionsList({
          versionsList: [...versionsList, versionResponse],
        }),
      )
      yield call(history.push, routes.version(versionResponse.uuid))
    }

    if (operation === 'publish') {
      const versionResponse: ScenarioVersion = yield call(
        publishScenarioVersion,
        { token },
        scenarioTypeName,
        id,
      )
      yield put(
        actions.emitVersionOperation({
          operation: 'get',
          id: versionResponse.uuid,
          token,
        }),
      )
      const versionsList: ReturnType<typeof selectVersionsList> = yield select(
        selectVersionsList,
      )
      yield put(
        actions.setVersionsList({
          versionsList: produce(versionsList, (draft) => {
            const vListIndex = draft.findIndex(
              (v) => v.uuid === versionResponse.uuid,
            )
            if (vListIndex !== -1) {
              // eslint-disable-next-line no-param-reassign
              draft[vListIndex] = versionResponse
            }
          }),
        }),
      )
    }

    if (operation === 'delete') {
      const versionResponse: ScenarioVersion = yield call(
        deleteScenarioVersion,
        { token },
        scenarioTypeName,
        id,
      )
      yield put(actions.removeVersion({ id: versionResponse.uuid }))
      const versionsList: ReturnType<typeof selectVersionsList> = yield select(
        selectVersionsList,
      )
      yield put(
        actions.setVersionsList({
          versionsList: versionsList.filter(
            (v) => v.uuid !== versionResponse.uuid,
          ),
        }),
      )
    }

    if (operation === 'save') {
      const version: ScenarioVersion = yield call(
        saveScenarioVersion,
        { token },
        scenarioTypeName,
        (params as SvVersionSaveOperationParams).version,
      )
      const oldVersion: ReturnType<typeof selectVersion> = yield select((s) =>
        selectVersion(s, id),
      )

      yield put(
        actions.setVersionData({
          id: version.uuid,
          data: {
            version,
            documents: oldVersion?.data?.documents as Record<string, FinDoc>,
          },
        }),
      )
      const versionsList: ReturnType<typeof selectVersionsList> = yield select(
        selectVersionsList,
      )
      yield put(
        actions.setVersionsList({
          versionsList: produce(versionsList, (draft) => {
            const vListIndex = draft.findIndex((v) => v.uuid === version.uuid)
            if (vListIndex !== -1) {
              // eslint-disable-next-line no-param-reassign
              draft[vListIndex] = version
            }
          }),
        }),
      )
    }
  } catch (e) {
    error = (e as Error).message || DEFAULT_ERROR_MESSAGE
  }

  yield put(
    actions.endVersionOperation({
      id,
      operation,
      error,
      result: undefined,
    }),
  )
}

export function* onEmitDocumentOperation(
  action: EmitDocumentOperation<
    SvDocumentCommonOperationParams | SvDocumentEditOperationParams
  >,
) {
  const { id, operation, token, params } = action.payload
  yield put(actions.startDocumentOperation({ operation, id }))
  let error: Maybe<string> = null
  let result: Maybe<any>

  try {
    if (operation === 'get') {
      const opParams = params as SvDocumentCommonOperationParams
      const documentResponse: FinDocResponse = yield call(
        getDoc,
        { token },
        opParams.docTypeName,
        id,
      )
      yield put(
        actions.setDocumentData({
          id,
          data: documentResponse,
        }),
      )
    }

    if (operation === 'edit') {
      const opParams = params as SvDocumentEditOperationParams
      const documentResponse: FinDocEditResponse = yield call(
        saveDoc,
        { token },
        opParams.docTypeName,
        opParams.document,
      )
      result = {
        ok: documentResponse.ok,
        message: documentResponse.message || null,
      }
      yield put(
        actions.setDocumentData({
          id,
          data: documentResponse.document,
        }),
      )
    }
  } catch (e) {
    error = (e as Error).message || DEFAULT_ERROR_MESSAGE
  }

  yield put(
    actions.endDocumentOperation({
      id,
      operation,
      error,
      result,
    }),
  )
}

/**
 Keep here all "watchers" generators
 @see https://redux-saga.js.org/docs/Glossary#watcherworker
 */
function* watchersSaga(): Iterator<AllEffect<Effect>> {
  yield all([
    takeEvery(actions.emitGlobalOperation, onEmitGlobalOperation),
    takeEvery(actions.emitVersionOperation, onEmitVersionOperation),
    takeEvery(actions.emitDocumentOperation, onEmitDocumentOperation),
  ])
}

// here can be any other generators besides watchers
const sagas: Saga[] = [watchersSaga]

// export only array of sagas
export default sagas
