import numeral from 'numeral'

export const currencyFormatter = (params: any) => {
  if (typeof params.value === 'string') return params.value
  const value = numeral(Math.round(Math.abs(params.value) / 1000)).format('0,0')
  if (params.value < 0) {
    return `$(${value}K)`
  }
  return `$${value}K`
}
