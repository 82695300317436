import { AsyncState, useAsync } from 'react-async'

import { AUTH0_SCOPE } from '../utils/const'
import type { Auth0ContextInterface } from '@auth0/auth0-react'
import React from 'react'
import { environment } from '../environment'
import { getTenantOverrideHeader } from '../environment/tenant-override'
import { prefixUrl } from '../utils/prefix-url'
import { useAuth0WithCypress } from '../hooks/useAuth0WithCypress'

export type RequestProcessingOptions<T> = {
  transformResponse: (jsonData: unknown) => T
}

const methodsWithBody = ['PUT', 'POST', 'PATCH']

export const runFetch = async <T = unknown>(
  accessToken: string,
  url: string,
  options?: RequestInit,
  urlParams?: string[][] | Record<string, string> | string | URLSearchParams,
  processingOpts?: RequestProcessingOptions<T>,
): Promise<T> => {
  const fetchUrl = urlParams
    ? `${prefixUrl(url)}?${new URLSearchParams(urlParams).toString()}`
    : prefixUrl(url)

  const method = options?.method || 'GET'
  const body = !methodsWithBody.includes(method)
    ? undefined
    : options?.body || '{}'

  const response = await fetch(fetchUrl, {
    ...options,
    method,
    body,
    headers: {
      'content-type': 'application/json',
      ...options?.headers,
      ...getTenantOverrideHeader(),
      'Authorization': `Bearer ${accessToken}`,
    },
  })

  const responseText = await response.text()
  if (!response.ok) {
    throw new Error(
      `Response is not ok. ${response.status}: ${response.statusText} \n ${responseText}`,
    )
  } else {
    const contentType = response.headers.get('Content-Type')
    if (contentType?.includes('application/json')) {
      try {
        const data = JSON.parse(responseText)
        if (processingOpts?.transformResponse) {
          return processingOpts.transformResponse(data) as unknown as Promise<T>
        }
        return data as Promise<T>
      } catch (e) {
        throw new Error(
          `${(e as Error)?.message} with parsing text ${responseText}`,
        )
      }
    } else if (options?.method === 'DELETE' && response.status === 204) {
      return undefined as unknown as T
    } else {
      throw new Error(
        `Unimplemented response content type ${contentType} for response text ${responseText}`,
      )
    }
  }
}

export const runFetchWithToken = async <T = unknown>(
  {
    getAccessTokenSilently,
  }: Pick<Auth0ContextInterface, 'getAccessTokenSilently'>,
  url: string,
  options?: RequestInit,
  urlParams?: string[][] | Record<string, string> | string | URLSearchParams,
  processingOpts?: RequestProcessingOptions<T>,
): Promise<T> => {
  const accessToken = await getAccessTokenSilently({
    audience: environment.REACT_APP_AUTH0_AUDIENCE,
    scope: AUTH0_SCOPE.join(','),
  })
  return runFetch(accessToken, url, options, urlParams, processingOpts)
}

export type RunFetchTokenPolymorphicParams = {
  getAccessTokenSilently?: Auth0ContextInterface['getAccessTokenSilently']
  token?: string
}

export const useApi = <T>(
  url: string,
  options?: RequestInit,
  urlParams?: string[][] | Record<string, string> | string | URLSearchParams,
  processingOpts?: RequestProcessingOptions<T>,
): AsyncState<T | null> => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0WithCypress()

  const runFetchFn = React.useCallback(async () => {
    if (!isAuthenticated) {
      // eslint-disable-next-line no-console
      console.warn(
        'Attempting to request protected API with non-authentificated user',
      )
      return null
    }

    return runFetchWithToken<T>(
      { getAccessTokenSilently },
      url,
      options || {},
      urlParams,
      processingOpts,
    )
  }, [
    getAccessTokenSilently,
    isAuthenticated,
    options,
    url,
    urlParams,
    processingOpts,
  ])

  return useAsync({
    promiseFn: runFetchFn,
  })
}
