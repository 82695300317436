/* eslint-disable no-param-reassign */
import {
  formatDateToTimeframe,
  generateDateRange,
  generateIsFinish,
} from './date-utils'

import type { Aggregation } from '../workers/kpi/types'
import type { KPIDefinintion } from 'api/types'
import type { TimeSegmentationFrame } from '../types'
import dayjs from 'dayjs'
import { getNow } from './now'
import { parseAggregation } from './primitives'
import { sortByAggregationFactory } from 'workers/utils'

/* eslint-disable camelcase */

const quarterFilterAggregations = ['1_month', '1_month_inclusive']

const halfYearFilterAggregations = [
  ...quarterFilterAggregations,
  '3_month',
  '3_month_inclusive',
  'qtd',
  'qtd_inclusive',
]

export const aggregateKPIByTimeframe = (
  kpiDefs?: KPIDefinintion[],
  data: AgKPIRecord[] = [],
  type: TimeSegmentationFrame = 'month',
  start = dayjs(getNow())
    .subtract(12, 'month')
    .startOf('month')
    .format('YYYY-MM-DD'),
  finish = dayjs(getNow()).endOf('month').format('YYYY-MM-DD'),
): AgKPIRecord[] => {
  const dateRange = generateDateRange(type, start, finish)

  // iterate over all AgKPIRecords to generate object, so we could instantly change amount property
  // for needed aggregation

  const hashedData = data.reduce((out, item): Out => {
    const isFinish = generateIsFinish(item.date, type)
    if (!isFinish) return out

    const [, range] = parseAggregation(item.aggregation)
    if (type === 'quarter' && quarterFilterAggregations.includes(range))
      return out
    if (
      (type === 'half_year' || type === 'year') &&
      halfYearFilterAggregations.includes(range)
    )
      return out

    const { id, date, amount, aggregation, ARR, JoinArr, num_users } = item
    const dateKey = formatDateToTimeframe(date, type)

    out[id] = out[id] || {}
    out[id][dateKey] = out[id][dateKey] || {}
    if (out[id][dateKey][aggregation]) {
      out[id][dateKey][aggregation].amount += amount
      out[id][dateKey][aggregation].ARR += ARR
      out[id][dateKey][aggregation].JoinArr += JoinArr
      out[id][dateKey][aggregation].num_users += num_users
      out[id][dateKey][
        aggregation
      ].client__uniqueId = `${id}.${dateKey}.${aggregation}`
    } else {
      out[id][dateKey][aggregation] = {
        ...item,
        date: dateKey,
        client__uniqueId: `${id}.${dateKey}.${aggregation}`,
      }
    }
    return out
  }, {} as Out)

  const hashedDataEntries = Object.entries(hashedData)
  const arr: AgKPIRecord[] = []
  hashedDataEntries.forEach(([, value]) => {
    dateRange.forEach((item) => {
      const dataPerTimeFrame: AgKPIRecord[] = Object.values(value[item] || {})
      if (value[item]) arr.push(...dataPerTimeFrame)
    })
  })
  return arr.sort(sortByAggregationFactory(kpiDefs || []))
}

export type AgKPIRecord = {
  ARR: number
  JoinArr: number
  JoinDate: string
  account: string
  aggregation: Aggregation
  amount: number
  client__uniqueId: string
  date: string
  id: string
  new_logo: boolean
  num_users: number
}

type Out = Record<string, Record<string, Record<Aggregation, AgKPIRecord>>>
