import React from 'react'
import styled from 'styled-components'
import { theme } from 'utils/theme'

const $Wrapper = styled.div`
  background: #fff;
  border: 1px solid #d7d6d5;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
  border-radius: 10px 10px;
  margin: ${theme.spacing(0.2)};
  margin-bottom: ${theme.spacing(0.8)};
  width: 100%;
`

type GenericContainer = {
  content: React.ReactNode
}

export const GenericContainer: React.FC<GenericContainer> = ({ content }) => (
  <$Wrapper>{content}</$Wrapper>
)
