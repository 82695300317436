import React from 'react'
import dayjs from 'dayjs'

export const useMonthsCount = (
  dateRangeFrom: string,
  dateRangeTo: string,
): number =>
  React.useMemo(
    () => Math.abs(dayjs(dateRangeFrom).diff(dateRangeTo, 'M')) + 1,
    [dateRangeFrom, dateRangeTo],
  )
