/**
 Grabbing all ducks together
 */

import config from './config'
import headcount from 'features/headcount/ducks/headcount'
import scenarioVersions from 'features/scenarioVersions/ducks/scenarioVersions'
import threads from 'features/threads/ducks/threads'
import genericReport from 'features/generic-report/ducks/generic-report'

const ducks = [config, threads, headcount, scenarioVersions, genericReport]

export default ducks
