import type { LocalCacheContext } from './LocalCacheContext'
import React from 'react'

const MAX_CACHE_ENTRIES = 10

export const useLocalCacheContext = (): LocalCacheContext => {
  const cacheRef = React.useRef<CacheRecord[]>([])

  const writeLocalCache = React.useCallback(
    (key: string, value: unknown) => {
      cacheRef.current = cacheRef.current
        .filter((i) => i.key !== key)
        .concat({
          key,
          value,
        })
        .slice(MAX_CACHE_ENTRIES * -1)
    },
    [cacheRef],
  )

  const getLocalCache = React.useCallback(
    <T = unknown>(key: string): T | undefined =>
      cacheRef.current.find((i) => i.key === key)?.value as T,
    [cacheRef],
  )

  return { writeLocalCache, getLocalCache }
}

type CacheRecord = {
  key: string
  value: unknown
}
