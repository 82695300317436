import type {
  EmitMentionEventRequest,
  EmitThreadEventRequest,
  EmitThreadEventsRequest,
  LoadThreadEventStart,
  LoadThreadEventsFinish,
} from './types'

import type { State } from '../state'
import type { WritableDraft } from '../../../../../redux/types'

export function emitThreadEventsRequest(
  state: WritableDraft<State>,
  action: EmitThreadEventsRequest,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
): void {}

export function loadThreadEventsStart(
  state: WritableDraft<State>,
  action: LoadThreadEventStart,
): void {
  state.status = 'loading'
  state.error = null
  state.selectedThreadEvents = null
}

export function loadThreadEventsEnd(
  state: WritableDraft<State>,
  action: LoadThreadEventsFinish,
): void {
  const { error, threadEvents } = action.payload
  state.error = error
  if (error !== null) {
    state.status = 'error'
    state.selectedThreadEvents = null
  } else {
    state.status = 'success'
    state.selectedThreadEvents = threadEvents
  }
}

export function emitPostThreadEventRequest(
  state: WritableDraft<State>,
  action: EmitThreadEventRequest,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
): void {}

export function emitMentionEventRequest(
  state: WritableDraft<State>,
  action: EmitMentionEventRequest,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
): void {}
