import React, { useCallback } from 'react'

import { AgGridReact } from 'ag-grid-react'
import { Box } from '@mui/material'
import type { ColDef } from 'ag-grid-community'
import InsightBadge from 'components/atoms/InsightBadge'
import { WidgetHeader } from './WidgetHeader'
import { currencyFormatter } from '../utils/currencyFormatter'
import { grey } from '@mui/material/colors'
import { makeStyles } from '@mui/styles'
import { theme } from 'utils/theme'

const useStyles = makeStyles(() => ({
  ...(() => {
    console.log('123', theme)
    return {}
  })(),
  actual: {
    color: 'inherit',
  },
  budget: {
    color: 'inherit',
  },
  diffPositive: {
    color: theme.palette.success.main,
  },
  diffNegative: {
    color: theme.palette.error.main,
  },
  nocolor: {},
  basicCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRightColor: `${grey[200]} !important`,
    fontSize: '11px',
  },
  textAlignLeft: {
    'justifyContent': 'flex-start',
    'paddingLeft': '10px !important',
    'textAlign': 'left',
    '& .ag-header-cell-label': {
      justifyContent: 'flex-start !important',
    },
  },
  basicHeader: {
    'borderLeft': `1px solid ${grey[200]} !important`,
    'marginLeft': '-1px',
    '& .ag-header-cell-label': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}))

interface ISmallPnlWidget {
  insight?: boolean
  FooterPanel?: () => JSX.Element
  rowData: any[]
}

export const SmallPnlWidget = ({
  insight,
  FooterPanel,
  rowData,
}: ISmallPnlWidget) => {
  const classes = useStyles()
  const columnDefs: ColDef[] = [
    {
      field: 'Groups',
      cellClass: [classes.basicCell, classes.textAlignLeft],
      headerClass: [classes.basicHeader, classes.textAlignLeft],
      width: 120,
    },
    {
      field: 'Actual',
      headerName: 'Q3 QTD Actual',
      cellClass: [classes.actual, classes.basicCell],
      headerClass: [classes.basicHeader],
      valueFormatter: currencyFormatter,
    },
    {
      field: 'Budget',
      headerName: 'Q3 QTD Plan',
      cellClass: [classes.budget, classes.basicCell],
      headerClass: [classes.basicHeader],
      valueFormatter: currencyFormatter,
    },

    {
      field: 'Difference',
      headerName: 'Diff',
      cellClass: (params) => {
        const { value } = params
        return [
          classes.basicCell,
          parseFloat(value) < 0 ? classes.diffNegative : classes.diffPositive,
        ]
      },
      headerClass: classes.basicHeader,
      valueFormatter: currencyFormatter,
      width: 100,
    },
  ]

  const onGridReady = useCallback((params) => {
    params.api.sizeColumnsToFit()
    window.addEventListener('resize', function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit()
      })
    })
  }, [])

  const getRowStyle = (params: any) => {
    if (params.node.rowIndex === 2) {
      return { borderBottom: '1px solid black', fontWeight: 'unset' }
    }
    if (params.node.rowIndex === 6) {
      return { borderBottom: '1px solid black', fontWeight: 'unset' }
    }
    if (params.node.rowIndex === 7) {
      return { borderBottom: '1px solid lightgrey', fontWeight: 'bold' }
    }
    return { borderBottom: '1px solid lightgrey', fontWeight: 'unset' }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <WidgetHeader
        title="P&L"
        link="/pnl"
        insight={
          insight && (
            <InsightBadge
              count={1}
              disabled
              sx={{
                left: '0',
                transform: 'translate(-57%, 0)',
              }}
            />
          )
        }
      />
      <Box
        className="ag-theme-alpine"
        sx={{
          height: '100%',
          width: '100%',
          padding: '0 10px 10px',
        }}
      >
        <AgGridReact
          processSecondaryColDef={(colGroupDef) => {
            if (!colGroupDef.pivotKeys) return
            colGroupDef.headerName = colGroupDef.pivotKeys.join(' ')
          }}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          rowData={rowData}
          rowHeight={34}
          headerHeight={40}
          getRowStyle={getRowStyle}
        />
      </Box>
      {FooterPanel && <FooterPanel />}
    </Box>
  )
}

SmallPnlWidget.defaultProps = {
  insight: false,
  FooterPanel: null,
}
