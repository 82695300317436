/**
 Selector is a function that gets some data from the redux store's RootState
 Each selector's name should start on "selector" word
 */

import type { RootState, State } from './state'

import { ApiConfigResponse } from 'api/types'
import type { Selector } from 'reselect'
import { createSelector } from '@reduxjs/toolkit'
import { makeSelectors as makeRequestSelectors } from '../../shared/request/selectors'

// unified access to all state of current duck
export const selectAll: Selector<RootState, State> = (s) => s.config

export const {
  selectStatus,
  selectIsInitial,
  selectIsError,
  selectIsLoading,
  selectError,
} = makeRequestSelectors(selectAll)

export const selectConfig: Selector<RootState, State['config']> = (s) =>
  selectAll(s).config

export const selectUserInfo: Selector<RootState, State['userInfo']> = (s) =>
  selectAll(s).userInfo

export const selectProperties: Selector<RootState, State['properties']> = (s) =>
  selectAll(s).properties

export const selectAllWithStatus: Selector<
  RootState,
  State & {
    isInitial: ReturnType<typeof selectIsInitial>
    isError: ReturnType<typeof selectIsError>
    isLoading: ReturnType<typeof selectIsLoading>
  }
> = createSelector(
  selectAll,
  selectIsLoading,
  selectIsInitial,
  selectIsError,
  (all, isLoading, isInitial, isError) => ({
    ...all,
    isLoading,
    isInitial,
    isError,
  }),
)

export const selectFeaturesDict: Selector<
  RootState,
  ApiConfigResponse['features_dict']
> = (rootState) => selectAll(rootState).config?.features_dict || {}
