import { Alert, AlertTitle } from '@mui/material'

import { DashboardWrapper } from '../components/DashboardWrapper/DashboardWrapper'
import React from 'react'
import WarningIcon from '@mui/icons-material/Warning'

export const NotFoundLayout: React.FC = () => (
  <DashboardWrapper padding={3}>
    <Alert severity="error" icon={<WarningIcon fontSize="inherit" />}>
      <AlertTitle>404 | Page not found</AlertTitle>
    </Alert>
  </DashboardWrapper>
)
