import {
  Avatar,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material'

import AccountCircle from '@mui/icons-material/AccountCircle'
import React from 'react'
import type { ThreadComment } from '../types'
import dayjs from 'dayjs'
import { serialiseCommentValue } from '../utils/prepare-comment-value'
import { useThreads } from '../ducks/threads/hooks'

export type CommentBoxProps = {
  comment: ThreadComment
}

export const CommentBox: React.FC<CommentBoxProps> = ({ comment }) => {
  const { selectedThreadEvents } = useThreads()
  const mentions =
    selectedThreadEvents
      ?.filter((event) => event.event_id === comment.id)
      .map((event) => event.body) ?? []

  return (
    <>
      <ListItem alignItems="flex-start" sx={{ p: 1 }}>
        <ListItemAvatar>
          <Avatar>
            <AccountCircle />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <>
              <Typography
                component="span"
                variant="subtitle2"
                color="textPrimary"
              >
                {comment.authorName}
              </Typography>
              <Typography
                component="span"
                variant="caption"
                color="textSecondary"
              >
                <br />
                {dayjs(comment.date).format('DD MMM YYYY, HH:mm:ss')}
              </Typography>
            </>
          }
          secondary={
            <Typography component="div" variant="body2" color="textPrimary">
              <div
                // eslint-disable-next-line
                dangerouslySetInnerHTML={{
                  __html: serialiseCommentValue(comment.body, mentions),
                }}
              />
            </Typography>
          }
        />
      </ListItem>
      <Divider />
    </>
  )
}
