import { AssumptionsStates, StatePages } from '../ducks/dashboard/types'
import { Box, Typography } from '@mui/material'
import {
  ColDef,
  ColGroupDef,
  ColumnEverythingChangedEvent,
  FirstDataRenderedEvent,
  GridReadyEvent,
  RowClickedEvent,
} from 'ag-grid-community'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AgGridReact } from 'ag-grid-react'
import { InsightModal } from 'components/atoms/InsightModal'
import { dashboardSlice } from '../ducks/dashboard/slice'
import { pivotTableColumns } from '../pivot'
import pivoted0 from '../data/assumptions.json'
import pivoted1 from '../data/assumptionsNext.json'
import { selectAssumptionsPageState } from '../ducks/dashboard/selectors'

const COLUMNS = {
  FORECAST: 'Forecast',
  MONTH: 'Month',
  METRIC: 'Assumption',
  REGION: 'Region',
  PLATFORM: 'Platform',
  VALUE: 'Value',
}

const assumptionInsights = [
  {
    name: 'option 1',
    component: <div>Avg 3M (827)</div>,
  },
  {
    name: 'option 2',
    component: <div>Avg 6M (852)</div>,
  },
  {
    name: 'option 2',
    component: <div>Avg 9M (843)</div>,
  },
]

export const AssumptionsTableMql = () => {
  const [openInsightDialog, setOpenInsight] = React.useState(false)
  const handleClose = () => setOpenInsight(false)

  const [dimensions, setDimensions] = React.useState([COLUMNS.METRIC])
  const [isOutsideClicked, setIsOutsideClicked] = React.useState(false)
  const [grid, setGridApi] = React.useState<GridReadyEvent | undefined>(
    undefined,
  )
  const openInsight = useCallback(() => {
    setOpenInsight(true)
  }, [])

  const handleViewInsight = useCallback(() => {
    setDimensions([COLUMNS.METRIC, COLUMNS.REGION, COLUMNS.PLATFORM])
    setOpenInsight(false)
    grid?.api.setFocusedCell(5, 'Sep-22September 22 Forecast')
  }, [grid?.api])

  const dispatch = useDispatch()

  const currentState = useSelector(selectAssumptionsPageState)

  const pivoted =
    currentState === AssumptionsStates.suggestionClicked ? pivoted1 : pivoted0

  const handleSuggestionClick = useCallback(() => {
    dispatch(
      dashboardSlice.actions.moveToState({
        page: StatePages.Assumptions,
        nextState: AssumptionsStates.suggestionClicked,
      }),
    )
  }, [dispatch])

  const monthForecastMap = useMemo(
    () => ({
      'Jan-22': new Set(['Actual']),
      'Feb-22': new Set(['Actual']),
      'Mar-22': new Set(['Actual']),
      'Apr-22': new Set(['Actual']),
      'May-22': new Set(['Actual']),
      'Jun-22': new Set(['Actual']),
      'Jul-22': new Set(['Actual']),
      'Aug-22': new Set(['Actual']),
      'Sep-22': new Set(['September 22 Forecast', 'Actual', 'Diff']),
      'Oct-22': new Set([
        'September 22 Forecast',
        'October 22 Forecast',
        'Diff',
      ]),
      'Nov-22': new Set([
        'September 22 Forecast',
        'October 22 Forecast',
        'Diff',
      ]),
      'Dec-22': new Set([
        'September 22 Forecast',
        'October 22 Forecast',
        'Diff',
      ]),
    }),
    [],
  )

  const insightValue = useMemo(
    () => ({
      data: {
        [COLUMNS.PLATFORM]: 'Google',
      },
      colId: 'Sep-22September 22 Forecast',
      badgeField: COLUMNS.METRIC,
      badgeValue:
        currentState !== AssumptionsStates.initial
          ? 'DOESNTEXISTS'
          : 'MQL Cost',
      replaceData: currentState !== AssumptionsStates.initial,
    }),
    [currentState],
  )

  const handleRowClick = React.useCallback(
    (e: RowClickedEvent) => {
      if (dimensions.length > 1 && e.data.Platform !== 'Google') {
        setIsOutsideClicked(true)
        e?.api.redrawRows()
        setTimeout(() => e?.api.redrawRows(), 0)
      }
    },
    [setIsOutsideClicked, dimensions],
  )

  const columnDefs = useMemo(
    () =>
      pivotTableColumns(
        dimensions,
        monthForecastMap,
        insightValue,
        assumptionInsights,
        openInsight,
        handleSuggestionClick,
      ).map((def: ColDef | ColGroupDef) => {
        if ((def as ColDef).field) {
          const colDef = def as ColDef
          if (colDef.field === 'Assumption') {
            colDef.minWidth = 200
          }
          if (colDef.field === 'Region') {
            colDef.width = 105
          }
          if (colDef.field === 'Platform') colDef.width = 125
          return colDef
        }
        return def
      }),
    [
      dimensions,
      handleSuggestionClick,
      insightValue,
      monthForecastMap,
      openInsight,
    ],
  )
  const v = [1, 2, 3].filter(() => true)
  const filtered = (pivoted as Record<string, any>).filter((row: any) =>
    dimensions.some((d) => row[d] !== ''),
  )
  const headers = ['Marketing', 'HR Costs', 'Retention', 'G&A', 'Sales', 'PS']
  const getRowStyle = (params: any) => {
    if (
      // eslint-disable-next-line
      headers.includes(params.node.data['Assumption'])
    ) {
      return {
        fontWeight: 'bold',
        borderBottom: '1px solid black',
        backgroundColor: '#f8f8f8',
      }
    }
    return {
      fontWeight: 'unset',
      borderBottom: '1px solid lightgrey',
      backgroundColor: 'unset',
    }
  }

  const handleGridReady = useCallback((e: GridReadyEvent) => {
    setGridApi(e)
  }, [])

  return (
    <Box
      sx={{
        'p': '20px',
        'pt': '0',
        '& .ag-root-wrapper .ag-cell': {
          textAlign: 'right',
        },
        '& .ag-root-wrapper .ag-pinned-left-cols-container .ag-cell': {
          textAlign: 'left',
        },
      }}
    >
      <div
        className="ag-theme-alpine ag-theme-demo-short"
        style={{ height: '40vh', width: '100%' }}
      >
        <InsightModal
          open={openInsightDialog}
          title="Insight on MQL Cost"
          handleClose={handleClose}
          handleView={handleViewInsight}
          height={370}
        >
          <Typography>
            <strong>Actual is 71$ below plan</strong>
            <br /> Plan: <strong>$891</strong> Actual: <strong>$820</strong>
            <br />
            Assumption: MQL Cost <br /> Region: Americas
            <br /> Platform: Google
            <br />
            <br />
            <strong>Suggestion</strong>: Reduce MQL cost for April-December
            <br />
            <strong>Impact</strong>: Expenses{' '}
            <span style={{ color: 'green' }}>-$135K</span>
            <br />{' '}
          </Typography>
        </InsightModal>
        <AgGridReact
          suppressAggFuncInHeader={true}
          columnDefs={columnDefs}
          rowData={filtered}
          onRowClicked={handleRowClick}
          rowClassRules={{
            'ag-row-selected': (props) => {
              const isRowIdSelected =
                props.data.Platform === 'Google' && !isOutsideClicked
              return isRowIdSelected
            },
          }}
          getRowStyle={getRowStyle}
          onGridReady={handleGridReady}
          rowHeight={24}
          headerHeight={30}
        />
      </div>
    </Box>
  )
}
