import './utils/logrocket'

import { App } from './App'
import React from 'react'
import ReactDOM from 'react-dom'
import { RootContainer } from './containers/RootContainer'
import { mixpanelInit } from './utils/mixpanel-utils'
import reportWebVitals from './reportWebVitals'

if (process.env.NODE_ENV !== 'test') {
  mixpanelInit()
}

ReactDOM.render(
  <React.StrictMode>
    <RootContainer>
      <App />
    </RootContainer>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals(console.log)
