import {
  ALL_METRICS_ORDERED,
  COUNT_LOGOS_VIEW_DISABLED_METRICS,
} from '../../BridgeTable/utils'

import { AgGridColumn } from 'ag-grid-react'
import { AggregatedTableContext } from '../../../context/AggregatedTableContext'
import type { ColDef } from 'ag-grid-community'
import { ConfigContext } from '../../../context/ConfigContext'
import React from 'react'
import { makeMetricCellRenderer } from '../../cell-renderer/metric-cell-renderer'

export const useMetricColumn = (): React.ReactElement => {
  const { gridRef } = React.useContext(AggregatedTableContext)
  const { config } = React.useContext(ConfigContext)

  const metricCellRenderer = React.useMemo(
    () => makeMetricCellRenderer(config),
    [config],
  )

  const getValues = React.useCallback(
    ({ success }: FilterParamsValuesGetterParams) => {
      const aggregationType = gridRef?.current?.columnApi
        ?.getColumn('amount')
        ?.getAggFunc() as string

      if (aggregationType === 'count') {
        success(
          ALL_METRICS_ORDERED.filter(
            (i) => !COUNT_LOGOS_VIEW_DISABLED_METRICS.includes(i),
          ),
        )
        return
      }

      success(ALL_METRICS_ORDERED)
    },
    [gridRef],
  )

  return (
    <AgGridColumn
      key="metric"
      field="metric"
      filter="agSetColumnFilter"
      hide={false}
      suppressMovable={false}
      rowGroup={true}
      cellRenderer={metricCellRenderer}
      headerName="Metric"
      pinned="left"
      lockPinned
      enablePivot
      enableRowGroup
      suppressColumnsToolPanel
      filterParams={{
        values: getValues,
        suppressSorting: true,
        defaultToNothingSelected: true,
      }}
    />
  )
}

interface FilterParamsValuesGetterParams {
  success: (items: string[]) => void
  colDef: ColDef
}
