/**
 Keep here the hooks attached to the current duck.
 In perfect case there should be only one way for components to interact with a duck - it's thought the hooks.
 Our hooks it's like a public API for components, and they shouldn't use selectors or action creators directly.
 */

import { ApiConfigFeatureFlag, ApiConfigResponse } from 'api/types'
import {
  emitRequest,
  loadEnd,
  selectAllWithStatus,
  selectError,
  selectFeaturesDict,
  selectIsError,
  selectIsInitial,
  selectIsLoading,
} from './index'
import { useDispatch, useSelector } from 'react-redux'

import React from 'react'
import { useAccessToken } from '../../../api/useAccessToken'

export type UseLoadConfigResult = {
  error: ReturnType<typeof selectError>
  isLoading: ReturnType<typeof selectIsLoading>
}

export function useLoadConfig(): UseLoadConfigResult {
  const { token, error: authError } = useAccessToken()
  const dispatch = useDispatch()
  const error = useSelector(selectError)
  const isError = useSelector(selectIsError)
  const isLoading = useSelector(selectIsLoading)
  const isInitial = useSelector(selectIsInitial)

  React.useEffect(() => {
    if (authError) {
      dispatch(
        loadEnd({
          error: authError,
        }),
      )
      return
    }
    if (token !== null && isInitial && !isLoading && !isError) {
      dispatch(
        emitRequest({
          token,
        }),
      )
    }
  }, [token, dispatch, isError, isLoading, isInitial, authError])

  return {
    error,
    isLoading,
  }
}

export function useConfig(): ReturnType<typeof selectAllWithStatus> {
  return useSelector(selectAllWithStatus)
}

export function useIsFeatureEnabled(
  feature: keyof ApiConfigResponse['features_dict'],
): boolean {
  return useSelector(selectFeaturesDict)[feature]?.enabled ?? false
}

export function useFeatureConfig(
  feature: keyof ApiConfigResponse['features_dict'],
): ApiConfigFeatureFlag {
  return (
    useSelector(selectFeaturesDict)[feature] ?? { enabled: false, value: '' }
  )
}
