// eslint-disable-next-line import/no-extraneous-dependencies
import { CSSProperties } from 'react'

export const textGradientStyles = (start: string, end: string): CSSProperties =>
  ({
    'background': `linear-gradient(${start}, ${end})`,
    'color': `${start}`,
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  } as CSSProperties)
