import { SvDocumentState, SvVersionState } from '../../types'

export function makeVersionState(
  id: SvVersionState['id'],
  data: SvVersionState['data'],
  operations?: {
    get?: Partial<SvVersionState['operations']['get']>
    copy?: Partial<SvVersionState['operations']['copy']>
    publish?: Partial<SvVersionState['operations']['publish']>
    delete?: Partial<SvVersionState['operations']['delete']>
    save?: Partial<SvVersionState['operations']['save']>
  },
): SvVersionState {
  return {
    id,
    data,
    operations: {
      get: {
        status: 'initial',
        error: null,
        ...operations?.get,
      },
      copy: {
        status: 'initial',
        error: null,
        ...operations?.copy,
      },
      publish: {
        status: 'initial',
        error: null,
        ...operations?.publish,
      },
      delete: {
        status: 'initial',
        error: null,
        ...operations?.delete,
      },
      save: {
        status: 'initial',
        error: null,
        ...operations?.save,
      },
    },
  }
}

export function makeDocumentState(id: SvDocumentState['id']): SvDocumentState {
  return {
    id,
    data: null,
    operations: {
      get: {
        status: 'initial',
        error: null,
      },
      edit: {
        status: 'initial',
        error: null,
      },
    },
  }
}
