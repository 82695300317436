import type { AgFilterModel, Maybe } from '../types'
import type {
  SecondaryTableContext,
  SecondaryViewAggregationType,
  SecondaryViewChartData,
  SecondaryViewChartType,
  SecondaryViewHeaderText,
  SecondaryViewType,
} from './SecondaryTableContext'

import type { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact'
import type { AggregationFormula } from '../workers/kpi/types'
import { PropertyColumnsConfType } from '../api/types'
import React from 'react'
import { useUrlHashState } from '../hooks/useUrlHashState'

export type UseSecondaryTableContextOpts = {
  propertyColsConfType?: PropertyColumnsConfType
}

export const useSecondaryTableContext = (
  opts: UseSecondaryTableContextOpts = {},
): SecondaryTableContext => {
  const { propertyColsConfType } = opts
  const secondaryGridRef: React.RefObject<AgGridReactType> = React.useRef(null)

  const [secondaryViewType, setSecondaryViewType] =
    React.useState<Maybe<SecondaryViewType>>(null)
  const [chartData, setChartData] =
    React.useState<Maybe<SecondaryViewChartData>>(null)
  const [chartType, setChartType] =
    React.useState<SecondaryViewChartType>('basic')
  const [aggregationType, setAggregationType] =
    useUrlHashState<SecondaryViewAggregationType>('grid-aggregation', {}, 'sum')
  const [propertyColsConfKey, setPropertyColsConfKey] = React.useState<
    AggregationFormula | string | null
  >(null)

  const [rowData, setRowData] = React.useState<
    SecondaryTableContext['rowData']
  >(() => [])

  const [headerText, setHeaderText] = React.useState<SecondaryViewHeaderText>({
    default: '',
    explanation: '',
  })

  const [filter, setFilter] = React.useState<AgFilterModel>(() => ({}))

  return {
    secondaryGridRef,
    rowData,
    setRowData,
    filter,
    setFilter,
    secondaryViewType,
    setSecondaryViewType,
    chartData,
    setChartData,
    chartType,
    setChartType,
    aggregationType,
    setAggregationType,
    headerText,
    setHeaderText,
    propertyColsConfType,
    propertyColsConfKey,
    setPropertyColsConfKey,
  }
}
