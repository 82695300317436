import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import { useHistory } from 'react-router-dom'
import { Divider } from '@mui/material'

export default function VerifyAction() {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const history = useHistory()
  const handlePublish = () => {
    setOpen(false)
    history.push('/analytics')
  }

  return (
    <>
      <Button
        variant="contained"
        onClick={handleOpen}
        sx={{
          'border': '1px solid #3f51b5',
          'background': '#3f51b5',
          'textTransform': 'uppercase',
          'boxShadow': '0px 2px 4px rgba(0, 0, 0, 0.15)',
          ':hover': {
            background: '#3f51b5',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.15)',
          },
        }}
      >
        <Typography style={{ color: 'white' }}>Publish</Typography>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        BackdropProps={{ invisible: true }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 450,
            bgcolor: 'background.paper',
            border: '0px solid #000',
            boxShadow: 24,
            p: 4,
            borderRadius: 1,
            textAlign: 'center',
            padding: 1,
          }}
        >
          <Box textAlign="left" height="200px">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h4"
              fontWeight="800"
              sx={{ mb: 0, mt: 2 }}
            >
              Publish forecast version?
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              paddingLeft="12px"
              sx={{ mb: 0, mt: 2 }}
            >
              Are you sure you want to publish this forecast?
              <br />
              Published forecasts cannot be edited.
            </Typography>
          </Box>
          <Box>
            <Divider />
            <div
              style={{
                display: 'flex',
                justifyContent: 'right',
                marginTop: '12px',
                marginBottom: '4px',
              }}
            >
              <Button
                style={{
                  border: '1px solid #F4F4F4',
                  marginRight: '24px',
                  padding: '0px 18px 0px 18px',
                }}
                color="secondary"
                onClick={handleClose}
              >
                Go Back
              </Button>
              <Button
                style={{
                  backgroundColor: '#FF9800',
                  color: 'white',
                  padding: '0px 18px 0px 18px',
                }}
                onClick={handlePublish}
              >
                Publish
              </Button>
            </div>
          </Box>
        </Box>
      </Modal>
    </>
  )
}
