import CommentIcon from '@mui/icons-material/Comment'
import React from 'react'

export const ThreadRenderer: React.FC = ({ children }) => (
  <span
    style={{
      position: 'relative',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    }}
  >
    <CommentIcon sx={{ color: '#f3622d' }} />
    {children}
  </span>
)
