import { Box, Button, TextField, Typography } from '@mui/material'
import { DatePicker, DatePickerProps, LocalizationProvider } from '@mui/lab'

import { API_RECORD_DATE_FORMAT } from '../../utils/const'
import { AggregatedTableContext } from '../../context/AggregatedTableContext'
import DateAdapter from '@mui/lab/AdapterDayjs'
import React from 'react'
import { URL_FLAG_FUTURE_MONTH_IS_ALLOWED } from '../../environment/url-flags'
import dayjs from 'dayjs'
import { getNow } from '../../utils/now'
import { isEmailAdmissible } from '../../utils/primitives'
import { useAuth0WithCypress } from '../../hooks/useAuth0WithCypress'
import { useTableMinDate } from './hooks/useTableMinDate'

export const TableMonthRangeSelector: React.FC = () => {
  const { setDateRange, dateRangeFrom, dateRangeTo } = React.useContext(
    AggregatedTableContext,
  )
  const { user } = useAuth0WithCypress()

  const [selectedStartDate, handleStartDateChange] = React.useState(
    dateRangeFrom ? dayjs(dateRangeFrom) : dayjs().add(-12, 'months'),
  )
  const [selectedEndDate, handleEndDateChange] = React.useState(
    dateRangeTo ? dayjs(dateRangeTo) : dayjs(),
  )

  const apply = React.useCallback(() => {
    if (selectedStartDate && selectedEndDate) {
      setDateRange(
        selectedStartDate.startOf('month').format('YYYY-MM-DD'),
        selectedEndDate.endOf('month').format('YYYY-MM-DD'),
      )
    }
  }, [setDateRange, selectedStartDate, selectedEndDate])

  const isFutureAllowed = React.useMemo(
    () =>
      URL_FLAG_FUTURE_MONTH_IS_ALLOWED && isEmailAdmissible(user?.email || ''),
    [user],
  )

  const minDate = useTableMinDate()
  const maxDate = React.useMemo(
    () =>
      isFutureAllowed
        ? dayjs(getNow()).add(100, 'y').endOf('month')
        : dayjs(getNow()).endOf('month'),
    [isFutureAllowed],
  )

  const minDateFirstDay = React.useMemo(
    () => dayjs(minDate).startOf('month'),
    [minDate],
  )

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <Box pt={3} pl={2} pr={2}>
        <DatePicker
          views={['year', 'month']}
          label="Start Date"
          value={selectedStartDate}
          onChange={handleStartDateChange as DatePickerProps['onChange']}
          maxDate={selectedEndDate}
          minDate={minDateFirstDay}
          openTo="month"
          renderInput={(params) => <TextField {...params} />}
        />
      </Box>
      <Box pt={3} pl={2} pb={2} pr={2}>
        <DatePicker
          views={['year', 'month']}
          label="End Date"
          value={selectedEndDate}
          onChange={handleEndDateChange as DatePickerProps['onChange']}
          maxDate={maxDate}
          minDate={selectedStartDate}
          openTo="month"
          renderInput={(params) => <TextField {...params} />}
        />
      </Box>
      <Box display="flex" justifyContent="center">
        <Button variant="contained" onClick={apply}>
          Apply
        </Button>
      </Box>
      <Box pt={4} pl={2} pr={2} pb={2}>
        <Typography variant="caption">
          * Effective applied date range:
          <br />
          <b>
            {selectedStartDate?.format(API_RECORD_DATE_FORMAT)} -{' '}
            {selectedEndDate?.format(API_RECORD_DATE_FORMAT)}
          </b>
        </Typography>
      </Box>
    </LocalizationProvider>
  )
}
