import type { Aggregation } from '../workers/kpi/types'
import { ConfigContext } from '../context/ConfigContext'
import React from 'react'
import { getKpiDisplayNameByAggregation } from '../utils/primitives'

export const useKpiDefNameMap = (): ((
  aggregation: Aggregation | string,
) => string) => {
  const { config } = React.useContext(ConfigContext)

  return React.useCallback(
    (aggregation) => {
      if (typeof aggregation !== 'string') {
        return aggregation
      }

      return getKpiDisplayNameByAggregation(
        aggregation as Aggregation,
        config?.kpis || [],
      )
    },
    [config?.kpis],
  )
}
