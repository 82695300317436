import type { AgColumnsPivotKey, AgFilterModel, Maybe } from '../../types'
import type {
  ApiConfigResponse,
  KPIDefinintion,
  PropertyColumnsConfType,
} from '../../api/types'
import type {
  CellClickedEvent,
  CellRange,
  Column,
  FirstDataRenderedEvent,
  GetContextMenuItemsParams,
  GridColumnsChangedEvent,
  IsGroupOpenByDefaultParams,
  MenuItemDef,
  ModelUpdatedEvent,
  ProcessCellForExportParams,
  RangeSelectionChangedEvent,
  RowNode,
  StatusPanelDef,
} from 'ag-grid-community'
import React, { useCallback } from 'react'
import {
  aggregationNameFromKPIDef,
  getKpiDisplayNameByAggregation,
  parseAggregation,
} from '../../utils/primitives'
import { compact, range } from 'lodash'
import {
  deserializeGridSelectionCols,
  deserializeGridSelectionRows,
  deserializeStringArray,
  serializeGridSelectionCols,
  serializeGridSelectionRows,
  serializeStringArray,
  useUrlHashState,
} from '../../hooks/useUrlHashState'
import {
  deserializeUrlFilter,
  serializeUrlFilter,
} from './utils/prepare-url-filters'
import {
  generateColumnThreadId,
  hasCellThread,
} from 'features/threads/utils/prepare-thread-id'

import { AgGridReact } from 'ag-grid-react'
import { AggregatedTableContext } from '../../context/AggregatedTableContext'
import { Aggregation } from '../../workers/kpi/types'
import { AggregationSwitchPanel } from './status-panels/AggregationSwitchPanel'
import type { ArrItemBase } from '../../workers/utils'
import { ConfigContext } from '../../context/ConfigContext'
import { LastUpdatePanel } from './status-panels/LastUpdatePanel'
import { SecondaryTableContext } from '../../context/SecondaryTableContext'
import { SelectedArrPanel } from './status-panels/SelectedArrPanel'
import { TableMonthRangeSelector } from './TableMonthRangeSelector'
import { TimeSegmentationSwitchPanel } from './status-panels/TimeSegmentationSwitchPanel'
import { generateColumnPath } from './utils/generate-column-path'
import { generateSecondaryTableHeaderParts } from './utils/generate-secondary-table-header-parts'
import { getCohortKey } from './utils/get-cohort-key'
import { getTopLevelAggregation } from './utils/get-top-level-aggregation'
import { makeFormatColDef } from './utils/format-col-def'
import { prepareAgModelFactory } from './utils/prepare-ag-model'
import { useBasicCohortColumns } from './columns/useBasicCohortColumns'
import useDebouncedEffect from 'use-debounced-effect-hook'
import { useDefaultCellRangeSelection } from './hooks/useDefaultRangeSelection'
import { useHandleRangeSelection } from './hooks/useHandleRangeSelection'
import { useIsFeatureEnabled } from 'redux/ducks/config/hooks'
import { useKeepRequiredRowGroups } from './hooks/useKeepRequiredRowGroups'
import { usePageThreads } from 'features/threads/hooks/usePageThreads'
import { useRerenderOnTypeChange } from './hooks/useRerenderOnTypeChange'
import { useScrollToTheEndEffect } from './useScrollToTheEndEffect'
import { useSelectedThreadState } from 'features/threads/ducks/threads/hooks'
import { AggregatedTableCustomHeader } from './AggregatedTableCustomHeader'

export type AggregatedTableViewTableProps = {
  title?: string
  columns?: React.ReactElement[]
  requiredRowGroups: string[]
  aggregationSwitch?: boolean
  propertyColsConfType?: PropertyColumnsConfType
}

const GRID_READY_THRESHOLD = 3000
const SELECTED_ROW_THREASHOLD = 2000

export const AggregatedTableViewTable: React.FC<AggregatedTableViewTableProps> =
  ({
    columns = [],
    aggregationSwitch,
    children,
    requiredRowGroups,
    propertyColsConfType,
    ...props
  }) => {
    const { config } = React.useContext(ConfigContext)
    const isDiscusssionsFeatureEnabled = useIsFeatureEnabled(
      'collaboration_threads',
    )

    const { gridRef, rowData, cohorts, dateRangeFrom, timeSegmentationFrame } =
      React.useContext(AggregatedTableContext)

    const cohortColumns = useBasicCohortColumns(cohorts)
    const isGridLoaded = React.useRef(false)
    const {
      setRowData: setSecondaryRowData,
      setFilter: setSecondaryFilter,
      setSecondaryViewType,
      aggregationType,
      setHeaderText,
      setPropertyColsConfKey,
    } = React.useContext(SecondaryTableContext)

    const [urlFilters, setUrlFilters] =
      useUrlHashState<Maybe<AgFilterModel>>('grid-filters')
    const [urlCohorts, setUrlCohorts] = useUrlHashState<Maybe<string[]>>(
      'grid-cohorts',
      {
        serializer: serializeStringArray,
        deserializer: deserializeStringArray,
      },
    )
    const [urlCohortsExpanded, setUrlCohortsExpanded] = useUrlHashState<
      Maybe<string[]>
    >('grid-cohorts-expanded', {
      serializer: serializeStringArray,
      deserializer: deserializeStringArray,
    })
    const [urlSelectionCols, setUrlSelectionCols] = useUrlHashState<
      Maybe<AgColumnsPivotKey[]>,
      string[]
    >('grid-selection-cols', {
      serializer: serializeGridSelectionCols,
      deserializer: deserializeGridSelectionCols,
    })
    const [urlSelectionRows, setUrlSelectionRows] = useUrlHashState<
      Maybe<number[]>,
      number[]
    >('grid-selection-rows', {
      serializer: serializeGridSelectionRows,
      deserializer: deserializeGridSelectionRows,
    })

    // `staticPivotColumns` are month columns that should be always visible even if no data found
    const [staticPivotColumns, setStaticPivotColumns] =
      React.useState<Maybe<Column[]>>(null)

    const [isGridReady, setGridReady] = React.useState(false)

    // Once data is changed - update `staticPivotColumns`
    React.useEffect(() => {
      const secColumns = gridRef?.current?.columnApi?.getSecondaryColumns()
      setStaticPivotColumns(() => (secColumns ? [...secColumns] : null))
    }, [rowData, setStaticPivotColumns, gridRef])

    // Put `staticPivotColumns` into model update context via argument
    const onModelUpdated = React.useCallback(
      (event: ModelUpdatedEvent) => {
        const expandedCohorts = compact(
          range(1, event.api.getModel().getRowCount() - 1).map((i) => {
            const rowCurr = event.api.getModel().getRow(i)
            const rowPrev = event.api.getModel().getRow(i - 1)
            return rowCurr && rowPrev && rowPrev?.level < rowCurr?.level
              ? getCohortKey(rowPrev)
              : null
          }),
        )
        if (isGridLoaded.current) {
          setUrlCohortsExpanded(expandedCohorts)
        }
        prepareAgModelFactory(
          staticPivotColumns || [],
          timeSegmentationFrame,
          dateRangeFrom,
        )(event)
        setTimeout(() => setGridReady(true), GRID_READY_THRESHOLD)
      },
      [
        staticPivotColumns,
        dateRangeFrom,
        setUrlCohortsExpanded,
        setGridReady,
        timeSegmentationFrame,
      ],
    )

    const keepRequiredRowGroups = useKeepRequiredRowGroups(requiredRowGroups)

    const handleSetPropertyColsConfKey = useCallback(
      (nodeKey: Maybe<string>) => {
        if (propertyColsConfType === 'kpis') {
          const [formula] = parseAggregation(nodeKey as Aggregation)
          setPropertyColsConfKey(formula)
          return
        }
        setPropertyColsConfKey(nodeKey)
      },
      [propertyColsConfType, setPropertyColsConfKey],
    )

    const setSecondaryTable = React.useCallback(
      (secondaryRowData: readonly ArrItemBase<unknown>[]) => {
        setSecondaryViewType('table')
        setSecondaryRowData(secondaryRowData)
      },
      [setSecondaryViewType, setSecondaryRowData],
    )

    const setHeaderInfo = React.useCallback(
      (
        row: RowNode,
        aggregation: Maybe<Aggregation>,
        headerName?: string,
        pivotKeys?: string[],
      ) => {
        const { text, explanation } = generateSecondaryTableHeaderParts(
          row,
          aggregation,
          config as ApiConfigResponse,
          pivotKeys,
          headerName,
        )
        setHeaderText({ default: text, explanation })
      },
      [config, setHeaderText],
    )

    const setUrlSelectionCell = React.useCallback(
      (column: Column, rowIndex: number) => {
        const pivotKey = {
          date: new Date(column.getColDef().pivotKeys?.[0] as string),
          field: column.getColDef().headerName,
        } as AgColumnsPivotKey

        setUrlSelectionCols([pivotKey])
        setUrlSelectionRows([rowIndex])
      },
      [setUrlSelectionCols, setUrlSelectionRows],
    )

    const [selectedThreadId, setThreadId] = useSelectedThreadState()

    const handleCellClick = React.useCallback(
      (event: CellClickedEvent) => {
        if (selectedThreadId) {
          const { column } = event
          const rowNode = event.node

          const aggregation = getTopLevelAggregation(rowNode)
          const displayName = getKpiDisplayNameByAggregation(
            aggregation as string,
            config?.kpis as KPIDefinintion[],
          )
          const cellPath = generateColumnPath(rowNode, displayName)
          const threadId = generateColumnThreadId(
            column.getColDef().headerName ?? '',
            String(column.getColDef().pivotKeys || ''),
            cellPath,
            aggregationType,
          )
          setThreadId(threadId)
        }

        const { pivotKeys } = event.colDef
        const isOneColumnDisplayed =
          event.columnApi
            .getAllGridColumns()
            .filter((column) => !column.getColDef().hide).length === 1

        if ((!pivotKeys || !pivotKeys.length) && !isOneColumnDisplayed) {
          event.api.addCellRange({
            rowStartIndex: event.rowIndex,
            rowEndIndex: event.rowIndex,
            columns: event.columnApi.getAllDisplayedColumns(),
          })
          return
        }

        handleSetPropertyColsConfKey(event.node.key)
        if (typeof event.rowIndex === 'number') {
          setUrlSelectionCell(event.column, event.rowIndex)
        }
      },
      [
        aggregationType,
        config?.kpis,
        handleSetPropertyColsConfKey,
        selectedThreadId,
        setThreadId,
        setUrlSelectionCell,
      ],
    )

    // Handle range selections
    const rawHandleRangeSelection = useHandleRangeSelection(
      setUrlSelectionCols,
      setUrlSelectionRows,
    )
    useDefaultCellRangeSelection(gridRef, isGridReady)

    const threads = usePageThreads()

    const handleThreadsStart = React.useCallback(
      ({ node, column }: GetContextMenuItemsParams) => {
        const rowIndex = node?.rowIndex
        if (!node || !column || typeof rowIndex !== 'number') return

        const aggregation = getTopLevelAggregation(node)
        const displayName = getKpiDisplayNameByAggregation(
          aggregation as string,
          config?.kpis as KPIDefinintion[],
        )
        const cellPath = generateColumnPath(node, displayName)
        const threadId = generateColumnThreadId(
          column.getColDef().headerName ?? '',
          String(column.getColDef().pivotKeys || ''),
          cellPath,
          aggregationType,
        )
        setThreadId(threadId)
      },
      [config?.kpis, aggregationType, setThreadId],
    )

    const handleGridLoaded = React.useCallback(
      (event: FirstDataRenderedEvent) => {
        const singleCellSelected =
          urlSelectionCols?.length === 1 && urlSelectionRows?.length === 1
        // display thread ONLY for ONE selected cell
        if (singleCellSelected) {
          setTimeout(() => {
            let selectedRow: Maybe<RowNode> = null

            try {
              selectedRow =
                event.api.getModel().getRow(urlSelectionRows[0]) || null
            } catch (error) {
              // eslint-disable-next-line no-console
              console.error(error)
            }
            if (!selectedRow) return

            // set property cols conf key
            handleSetPropertyColsConfKey(selectedRow.key)

            // timer for waiting till all groups are open and only after that calculate selectedRow
          }, SELECTED_ROW_THREASHOLD)
        }

        if (urlFilters) {
          const filterModel = deserializeUrlFilter(urlFilters, gridRef)
          gridRef?.current?.api?.setFilterModel(filterModel)
        }

        if (urlCohorts) {
          event.columnApi.setRowGroupColumns(urlCohorts)
        }
        // Put timeout so that URL-saved selection will be re-applied after the default one
        setTimeout(() => {
          if (urlSelectionCols && urlSelectionRows) {
            const selectionCols = event.columnApi
              ?.getAllDisplayedColumns()
              ?.filter((column) => {
                const name = column.getColDef().headerName
                if (name) {
                  return urlSelectionCols.includes(name)
                }
                return false
              })
            gridRef?.current?.api?.addCellRange({
              rowStartIndex: Math.min(...urlSelectionRows),
              rowEndIndex: Math.max(...urlSelectionRows),
              columns: selectionCols,
            })
            rawHandleRangeSelection(
              { finished: true } as RangeSelectionChangedEvent,
              selectionCols?.map((i) => ({
                field: i.getColDef().pivotKeys?.[0] as string,
                date: new Date(),
              })),
              urlSelectionRows,
            )
          }
          setThreadId()
          isGridLoaded.current = true
        }, 1000)
      },
      [
        urlSelectionCols,
        urlSelectionRows,
        urlFilters,
        urlCohorts,
        handleSetPropertyColsConfKey,
        setThreadId,
        gridRef,
        rawHandleRangeSelection,
      ],
    )

    // Handle single cell selection change
    const CELL_SELECTED_DEBOUNCE_TIMEOUT = 100
    useDebouncedEffect(
      () => {
        const singleCellSelected =
          urlSelectionCols?.length === 1 && urlSelectionRows?.length === 1

        if (!singleCellSelected || !gridRef?.current || !isGridReady) {
          return
        }

        let selectedRow: Maybe<RowNode> = null

        const selectedColumn = gridRef.current.columnApi
          .getAllDisplayedColumns()
          .find((col) => col.getColDef().headerName === urlSelectionCols[0])

        try {
          selectedRow =
            gridRef.current.api.getModel().getRow(urlSelectionRows[0]) || null
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error)
        }

        const firstPivotKey = selectedColumn?.getColDef().pivotKeys?.[0]

        const aggregation = selectedRow && getTopLevelAggregation(selectedRow)

        const childNodes = selectedRow?.allLeafChildren.filter(
          (row) => row.data.date === firstPivotKey,
        )

        const secondaryData =
          childNodes?.map((i) => i.data as ArrItemBase) || []

        setSecondaryTable(secondaryData)
        if (selectedRow) {
          setHeaderInfo(
            selectedRow,
            aggregation,
            selectedColumn?.getColDef().headerName,
            selectedColumn?.getColDef().pivotKeys,
          )
        }
      },
      [
        urlSelectionCols,
        urlSelectionRows,
        gridRef,
        isGridReady,
        setSecondaryTable,
        setHeaderInfo,
      ],
      CELL_SELECTED_DEBOUNCE_TIMEOUT,
    )

    const handleRangeSelection = React.useCallback(
      (event: RangeSelectionChangedEvent) => {
        rawHandleRangeSelection(
          event,
          undefined,
          undefined,
          !isGridLoaded.current,
        )
      },
      [rawHandleRangeSelection],
    )

    const handleFiltersChanged = React.useCallback(() => {
      const filterModel = gridRef?.current?.api?.getFilterModel()

      if (filterModel) {
        const newUrlFilters = serializeUrlFilter(filterModel, gridRef)

        if (isGridLoaded.current) {
          setUrlFilters(newUrlFilters)
        }
        setSecondaryFilter(filterModel)
      }
    }, [gridRef, setUrlFilters, setSecondaryFilter])

    const handleColumnsChanged = React.useCallback(
      (evt: GridColumnsChangedEvent) => {
        if (isGridLoaded.current) {
          const cohortsIds = evt.columnApi
            .getRowGroupColumns()
            .map((item) => item.getColId())
          setUrlCohorts(cohortsIds)
        }
        keepRequiredRowGroups(evt)
      },
      [setUrlCohorts, keepRequiredRowGroups],
    )

    const applyAggregation = React.useCallback(
      (agg: string) => {
        if (!gridRef?.current) {
          return
        }
        gridRef.current.columnApi?.getColumn('amount')?.setAggFunc(agg)
        gridRef.current.api?.refreshClientSideRowModel('aggregate')
      },
      [gridRef],
    )

    const onProcessCellForClipboard = React.useCallback(
      (params: ProcessCellForExportParams) => {
        const key = params.value?.toString()
        return params?.columnApi?.getColumn(params?.column)?.getColId() ===
          'ag-Grid-AutoColumn-aggregation'
          ? config?.kpis.find((def) => key === aggregationNameFromKPIDef(def))
              ?.display_name || key
          : key
      },
      [config],
    )

    const isCohortExpanded = React.useCallback(
      (params: IsGroupOpenByDefaultParams) =>
        (Array.isArray(urlCohortsExpanded) ? urlCohortsExpanded : []).includes(
          getCohortKey(params),
        ),
      [urlCohortsExpanded],
    )

    const processSecondaryColGroupDef = React.useMemo(
      () => makeFormatColDef(timeSegmentationFrame, dateRangeFrom),
      [timeSegmentationFrame, dateRangeFrom],
    )

    useDefaultCellRangeSelection(gridRef, isGridReady)
    useRerenderOnTypeChange(aggregationType)
    useScrollToTheEndEffect(isGridReady)

    if (!rowData?.length) {
      return null
    }

    const getContextMenuItems = (params: GetContextMenuItemsParams) => {
      const menuItems: (string | MenuItemDef)[] = [
        'copy',
        'copyWithHeaders',
        'paste',
        'separator',
        'export',
      ]
      const colDef = params.column?.getColDef()
      const aggregation = getTopLevelAggregation(params.node as RowNode)
      const displayName = getKpiDisplayNameByAggregation(
        aggregation as string,
        config?.kpis as KPIDefinintion[],
      )
      const cellPath = generateColumnPath(params.node as RowNode, displayName)
      const threadId = generateColumnThreadId(
        params.column?.getColDef().headerName ?? '',
        String(params.column?.getColDef().pivotKeys || ''),
        cellPath,
        aggregationType,
      )
      const isWithThread = hasCellThread(threads, threadId)
      const cellRange = params.api.getCellRanges() as CellRange[]
      const isRangeSelected =
        cellRange[0] &&
        (cellRange[0].columns.length > 1 ||
          cellRange[0].startRow?.rowIndex !== cellRange[0].endRow?.rowIndex)
      const isNumberValue = colDef?.type === 'numericColumn'

      if (!isRangeSelected && isNumberValue && isDiscusssionsFeatureEnabled) {
        menuItems.unshift(
          {
            name: `${isWithThread ? 'Open' : 'Start'} discussion`,
            action: () => handleThreadsStart(params),
          },
          'separator',
        )
      }

      return menuItems
    }

    // console.log('rowData', rowData)

    return (
      <div
        className="ag-theme-alpine main-grid"
        style={{ height: '100%', width: '100%' }}
      >
        <AgGridReact
          getContextMenuItems={getContextMenuItems}
          ref={gridRef}
          defaultColDef={{
            width: 200,
            sortable: true,
            resizable: true,
            hide: true,
          }}
          rowData={rowData as ArrItemBase[]}
          processSecondaryColGroupDef={processSecondaryColGroupDef}
          onModelUpdated={onModelUpdated}
          autoGroupColumnDef={{
            cellRendererParams: {
              suppressCount: true,
            },
          }}
          isGroupOpenByDefault={isCohortExpanded}
          statusBar={{
            statusPanels: [
              {
                statusPanelFramework: SelectedArrPanel,
                align: 'left',
              },
              aggregationSwitch
                ? {
                    statusPanelFramework: AggregationSwitchPanel,
                    statusPanelParams: { applyAggregation },
                    align: 'left',
                  }
                : undefined,
              {
                statusPanelFramework: TimeSegmentationSwitchPanel,
                align: 'left',
              },
              {
                statusPanelFramework: LastUpdatePanel,
              },
            ].filter((i) => !!i) as StatusPanelDef[],
          }}
          sideBar={{
            defaultToolPanel: undefined,
            toolPanels: [
              {
                id: 'columns',
                labelDefault: 'Cohorts',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                  suppressPivotMode: true,
                  suppressSideButtons: true,
                  suppressValues: true,
                  suppressPivots: true,
                },
              },
              {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
              },
              {
                id: 'dateRange',
                labelDefault: 'Date Range',
                labelKey: 'DateRange',
                iconKey: 'filter',
                toolPanelFramework: TableMonthRangeSelector,
              },
            ],
          }}
          onFirstDataRendered={handleGridLoaded}
          onCellClicked={handleCellClick}
          onFilterChanged={handleFiltersChanged}
          onGridColumnsChanged={handleColumnsChanged}
          onRangeSelectionChanged={handleRangeSelection}
          frameworkComponents={{
            agColumnHeader: AggregatedTableCustomHeader,
          }}
          processCellForClipboard={onProcessCellForClipboard}
          getRowNodeId={(row: ArrItemBase) => row.client__uniqueId}
          enableRangeSelection
          suppressRowClickSelection
          suppressAggFuncInHeader
          groupDisplayType="multipleColumns"
          suppressColumnMoveAnimation
          showOpenedGroup
          pivotMode
          animateRows={false}
          immutableData
          reactUi
          {...props}
        >
          {columns}
          {cohortColumns}
        </AgGridReact>
      </div>
    )
  }
