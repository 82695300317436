import {
  RequestState,
  requestInitialState,
} from '../../../../redux/shared/request/state'
import type { PageTitleKey, Thread, ThreadEvent, User } from '../../types'

import type { DefaultRootState } from 'react-redux'
import type { Maybe } from '../../../../types'

export const namespace = 'threads'

export type RootState = DefaultRootState & {
  threads: State
}

export type State = RequestState & {
  selectedPage: Maybe<PageTitleKey>
  selectedThreadId: Maybe<string>
  selectedThreadEvents: Maybe<ThreadEvent[]>
  threadsList: Thread[]
  userList: User[]
}

export const initialState: State = {
  ...requestInitialState,
  selectedPage: null,
  selectedThreadId: null,
  selectedThreadEvents: null,
  threadsList: [],
  userList: [],
}
