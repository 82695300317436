import { Box, CircularProgress, Typography } from '@mui/material'

import React from 'react'

// type ratioColor =
//   | 'success'
//   | 'inherit'
//   | 'error'
//   | 'primary'
//   | 'secondary'
//   | 'info'
//   | 'warning'
//   | undefined

interface CircularProgressWithLabelProps {
  value: number
  color: string
}

const CircularProgressWithLabel: React.FC<CircularProgressWithLabelProps> = ({
  value,
  color,
}) => (
  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
    <Box
      sx={{
        'position': 'relative',
        'display': 'flex',
        'alignItems': 'center',
        'justifyContent': 'center',
        'width': '74px',
        'height': '74px',
        'borderRadius': '50%',
        'background': '#f2f3f8',
        '@media (max-width: 1700px)': {
          width: '50px',
          height: '50px',
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: '50%',
          overflow: 'hidden',
        }}
      >
        <svg
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            transform: 'rotate(-90deg)',
          }}
          viewBox="0 0 200 200"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            r="90"
            cx="100"
            cy="100"
            fill="transparent"
            strokeDasharray="565.48"
            strokeDashoffset={565.48 * (value < 100 ? (100 - value) / 100 : 0)}
            stroke={color}
            strokeWidth="30"
          />
        </svg>
      </Box>

      {value > 100 && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: '50%',
            overflow: 'hidden',
          }}
        >
          <svg
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
              transform: 'rotate(-90deg)',
            }}
            viewBox="0 0 200 200"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              r="90"
              cx="100"
              cy="100"
              fill="transparent"
              strokeDasharray="565.48"
              strokeDashoffset={
                565.48 * (value - 100 < 100 ? (100 - value - 100) / 100 : 0)
              }
              stroke="#f2f3f8"
              strokeWidth="6"
            />
          </svg>
        </Box>
      )}

      <Box
        sx={{
          'position': 'relative',
          'display': 'flex',
          'flexDirection': 'column',
          'alignItems': 'center',
          'justifyContent': 'center',
          'width': '58px',
          'height': '58px',
          'boxShadow': '0px 2px 2px rgba(0, 0, 0, 0.3)',
          'borderRadius': '50%',
          'background': '#fff',
          'fontSize': '26px',
          'lineHeight': '21px',
          color,
          '@media (max-width: 1700px)': {
            width: '38px',
            height: '38px',
            fontSize: '18px',
            lineHeight: '14px',
          },
        }}
      >
        <Box
          sx={{
            fontWeight: 700,
          }}
        >
          {`${Math.round(value)}`}
        </Box>
        <Box
          sx={{
            'fontSize': '16px',
            'lineHeight': 1,
            '@media (max-width: 1700px)': {
              fontSize: '13px',
            },
          }}
        >
          %
        </Box>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: '50%',
          overflow: 'hidden',
          boxShadow: 'inset 0px 2px 2px rgba(0, 0, 0, 0.3)',
          pointerEvents: 'none',
        }}
      />
    </Box>
  </Box>
)

interface widgetUnitProps {
  prefix: string
  suffix: string
}

export interface KPIWidgetProps {
  kpiName?: string
  actual?: number
  assumption?: number
  assumptionActualRatio?: number
  wrapperBoxStyle?: any
  ratioColor?: string
  ratioSize?: number
  units?: widgetUnitProps
}

// for color options view https://mui.com/api/circular-progress/#main-content
const KPIWidget: React.FC<KPIWidgetProps> = ({
  kpiName = 'ARR',
  actual = 100,
  assumption = 100,
  assumptionActualRatio = 100,
  units = { prefix: '', suffix: '%' },
  ratioColor = '#4CAF50',
  ratioSize = 60,
  wrapperBoxStyle = {},
}) => (
  <Box
    sx={{
      'display': 'flex',
      'justifyContent': 'flex-start',
      'alignItems': 'center',
      'backgroundColor': 'white',
      'width': '100%',
      'padding': '10px 5px 10px 20px',
      'borderRadius': '5px',
      ...wrapperBoxStyle,
      '@media (max-width: 1700px)': {
        padding: '7px 3px 7px 10px',
      },
    }}
  >
    <Box
      sx={{
        marginRight: '12px',
      }}
    >
      <CircularProgressWithLabel
        value={assumptionActualRatio}
        color={ratioColor}
      />
    </Box>
    <Box
      sx={{
        'fontSize': 20,
        'whiteSpace': 'nowrap',
        '@media (max-width: 1700px)': {
          fontSize: 15,
        },
      }}
    >
      <Typography style={{ fontSize: '1em', color: '#282828' }}>
        {kpiName}
      </Typography>
      <Box
        sx={{
          '@media (max-width: 1700px)': {
            mt: '-4px',
          },
        }}
      >
        <Typography
          variant="caption"
          mr={1}
          style={{ fontWeight: 700, fontSize: '1em' }}
        >
          {`${units.prefix}${actual}${units.suffix}`}
        </Typography>
        <Typography
          variant="caption"
          style={{
            fontSize: `${16 / 20}rem`,
            fontWeight: 'bold',
            color: '#aaaaaa',
          }}
        >
          {`(${assumption}${units.suffix})`}
        </Typography>
      </Box>
    </Box>
  </Box>
)

export default KPIWidget
