import * as React from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'

export interface IInsightModal {
  title: string
  open: boolean
  handleClose: () => void
  handleView?: () => void
  height?: number
  width?: number
}

export const InsightModal: React.FC<IInsightModal> = (props) => (
  <>
    <Modal
      open={props.open}
      BackdropProps={{ invisible: true }}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: props.width || 550,
          bgcolor: 'background.paper',
          border: '0px solid #000',
          boxShadow: 24,
          p: 4,
          borderRadius: 1,
          height: props.height || 450,
          padding: 0,
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 4,
            padding: 20,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {props.title}
          </Typography>
          <Button onClick={props.handleClose}>
            <CloseIcon />
          </Button>
        </div>
        <Box
          style={{
            marginLeft: 10,
            marginRight: 10,
            marginBottom: '40px',
            paddingLeft: '20px',
          }}
        >
          {props.children}
        </Box>
        <hr />
        <div
          style={{
            textAlign: 'right',
            width: '100%',
            display: 'flex',
            paddingRight: '10px',
            justifyContent: 'flex-end',
          }}
        >
          <Button onClick={props.handleClose}>Close</Button>
          <Button onClick={props.handleView}>View</Button>
        </div>
      </Box>
    </Modal>
  </>
)
