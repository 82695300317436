import { AgFilterModel, AgSetFilterModel, Maybe } from '../../../types'

import type { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact'
import { SetFilter } from 'ag-grid-enterprise'
import { chain } from 'lodash'

export const serializeUrlFilter = (
  filterModel: AgFilterModel,
  gridRef: Maybe<React.RefObject<AgGridReactType>>,
): AgFilterModel =>
  chain(filterModel)
    .toPairs()
    .map(([filterName, model]): [string, unknown | AgSetFilterModel] => {
      const { filterType } = model as AgSetFilterModel

      if (filterType === 'set') {
        const itemFilter = gridRef?.current?.api?.getFilterInstance(
          filterName,
        ) as SetFilter<unknown>
        const options = itemFilter?.getValueModel()?.getValues()
        const selectedOptions = itemFilter?.getModelFromUi()?.values

        return [
          filterName,
          {
            values: options?.filter(
              (option) => !selectedOptions?.includes(option),
            ),
            filterType,
          },
        ]
      }
      return [filterName, model]
    })
    .fromPairs()
    .value()

export const deserializeUrlFilter = (
  urlFilters: AgFilterModel,
  gridRef: Maybe<React.RefObject<AgGridReactType>>,
): AgFilterModel =>
  chain(urlFilters)
    .toPairs()
    .map(([filterName, model]): [string, unknown | AgSetFilterModel] => {
      const { values: selectedOptions, filterType } = model as AgSetFilterModel

      if (filterType === 'set') {
        const itemFilter = gridRef?.current?.api?.getFilterInstance(
          filterName,
        ) as SetFilter<unknown>
        const options = itemFilter?.getValueModel()?.getValues()

        return [
          filterName,
          {
            values: options?.filter(
              (option) => !selectedOptions.includes(option),
            ),
            filterType,
          },
        ]
      }
      return [filterName, model]
    })
    .fromPairs()
    .value()
