/**
 Types for describe duck state and extend root state
 */

import type {
  AccountProperties,
  ApiConfigResponse,
  ApiUsersInfoResponse,
} from '../../../api/types'
import { RequestState, requestInitialState } from '../../shared/request/state'

import type { DefaultRootState } from 'react-redux'

/**
 Will be used in @reduxjs/toolkit to generate action types' names
 and to add duck reducer to the root state
 */
export const namespace = 'config'

// extending root state to use it in selectors
export type RootState = DefaultRootState & {
  config: State
}

// duck state
export type State = RequestState & {
  config?: ApiConfigResponse
  userInfo?: ApiUsersInfoResponse
  properties?: Record<string, AccountProperties>
}

export const initialState: State = {
  ...requestInitialState,
}
