import type { RowNode } from 'ag-grid-community'

export const generateColumnPath = (
  node: RowNode,
  aggregationName?: string,
): string => {
  let value

  if (node?.field === 'aggregation') {
    value = aggregationName as string
  } else {
    value = node?.key || ''
  }

  value += node?.field || '-'

  if (node?.parent?.key) {
    value = `${generateColumnPath(node.parent, aggregationName)}; ${value}`
  }

  return value
}
