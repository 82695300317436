/**
 * @deprecated
 * @todo: Use lib to format numbers
 */
import type { ValueFormatterParams } from 'ag-grid-community'

export const formatNumber = (number: number): string => {
  let negative = false
  if (number < 0) {
    negative = true
    // eslint-disable-next-line no-param-reassign
    number = Math.abs(number)
  }
  let formatted = Math.round(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  if (negative) {
    formatted = `(${formatted})`
  }
  return formatted
}

// @deprecated, needs refactoring
export const currencyFormatter = (params: ValueFormatterParams): string => {
  const number = params.value
  if (!number) {
    return '-'
  }

  const col =
    params.columnApi?.getColumn('amount') ||
    params.columnApi?.getColumn('values')

  if (col && col.getAggFunc() === 'count') {
    return `${params.value}`
  }

  const formatted = formatNumber(parseFloat(number as string))
  return `$${formatted}`
}

const PercentageCellValueKeys = ['ndr', 'nrr']

export const NDRCurrencyFormatter = (params: ValueFormatterParams): string => {
  let result = '0'
  if (!params.value) {
    return '-'
  }

  let parent = params.node
  while (parent && parent.level !== 0) {
    parent = parent.parent
  }

  if (
    parent?.key &&
    PercentageCellValueKeys.find(
      (i) => parent?.key && parent?.key?.indexOf(i) >= 0,
    )
  ) {
    result = `${params.value}%`
  } else {
    result = `$${formatNumber(params.value)}`
  }
  return result
}
