import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts'
import {
  axisAmountLabelFormatter,
  getClosestGranularNumber,
  getGranularityLength,
  getLineColor,
  hasNonPercentageLines,
  ignoreLinesMap,
} from './chart-utils'

import { Aggregation } from '../../workers/kpi/types'
import { ConfigContext } from '../../context/ConfigContext'
import type { Maybe } from '../../types'
import React from 'react'
import { SecondaryTableContext } from '../../context/SecondaryTableContext'
import { Typography } from '@mui/material'
import dayjs from 'dayjs'
import { formatNumber } from '../field-formatter/currency-formatter'
import { getKpiDisplayNameByAggregation } from '../../utils/primitives'
import { useRenderChartLabel } from './useRenderChartLabel'

const formatDate = (date: Date) =>
  dayjs(date.toString().replace(' to date', '')).format('MMM YY')

interface CustomTooltipProps extends TooltipProps<number, string> {
  affix: string
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
  affix,
}) => {
  const { config } = React.useContext(ConfigContext)
  if (active && payload?.length) {
    return (
      <div
        style={{
          background: 'white',
          border: '1ox solid #0000CD',
          padding: '12px',
        }}
      >
        <Typography variant="h6">{formatDate(label)}</Typography>
        {payload.map((line) => (
          <Typography paragraph mb={1} key={line.dataKey}>
            {/* dataKey is possibly KPI. Try to get it's label. */}
            {getKpiDisplayNameByAggregation(
              (line.dataKey || '') as string,
              config?.kpis || [],
            )}
            :{' '}
            <b>
              {`${affix !== '%' ? affix : ''}${formatNumber(
                line.value as number,
              )}${affix === '%' ? affix : ''}`}
            </b>
          </Typography>
        ))}
      </div>
    )
  }

  return null
}

const getBoundaryValueForSetOfLines = (
  chartData: Record<string, string | number | Date>[],
  lines: string[],
  forMax = false,
): number => {
  let val: Maybe<number> = null
  chartData.forEach((data: Record<string, string | number | Date>): void => {
    lines.forEach((line: string): void => {
      if (
        typeof data[line] === 'number' &&
        (val === null ||
          (!forMax && data[line] < val) ||
          (forMax && data[line] > val))
      ) {
        val = data[line] as number
      }
    })
  })
  return val || 0
}

export const LineChartView: React.FC = () => {
  const { chartData, aggregationType } = React.useContext(SecondaryTableContext)

  const aggregation = React.useMemo(
    () => chartData?.[0].aggregation as Aggregation,
    [chartData],
  )

  const lines = React.useMemo(() => {
    if (!chartData?.length) {
      return null
    }

    return Object.keys(chartData[0]).filter((line) => !ignoreLinesMap[line])
  }, [chartData])

  const renderColorlessLegendLabel = useRenderChartLabel()

  if (!lines?.length || !chartData) {
    return null
  }

  const arePercentageValues = !hasNonPercentageLines(aggregation)
  const minValueForSetOfLines = getBoundaryValueForSetOfLines(chartData, lines)
  const maxValueForSetOfLines = getBoundaryValueForSetOfLines(
    chartData,
    lines,
    true,
  )
  const minYAxisValue = getClosestGranularNumber(
    minValueForSetOfLines,
    arePercentageValues ? 1 : getGranularityLength(minValueForSetOfLines),
  )
  const yPercentagePadding = Math.ceil(
    (maxValueForSetOfLines - minValueForSetOfLines) / 10,
  )

  const defaultAffix = !arePercentageValues ? '$' : '%'
  const affix = aggregationType === 'count' ? '' : defaultAffix

  return (
    <ResponsiveContainer width="95%" height="95%">
      <LineChart data={chartData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" tickFormatter={formatDate} />
        <Tooltip
          content={<CustomTooltip affix={affix} />}
          isAnimationActive={false}
        />
        <YAxis
          type="number"
          yAxisId="y"
          padding={{
            top:
              minValueForSetOfLines > 0 &&
              maxValueForSetOfLines > 0 &&
              maxValueForSetOfLines < 200
                ? 10
                : 0,
          }}
          domain={[
            minYAxisValue - (arePercentageValues ? yPercentagePadding : 0),
            arePercentageValues
              ? Math.ceil(maxValueForSetOfLines) + yPercentagePadding
              : 'auto',
          ]}
          allowDataOverflow={true}
          tickFormatter={axisAmountLabelFormatter(affix, arePercentageValues)}
        />
        <Legend
          align="right"
          verticalAlign="top"
          iconType="circle"
          formatter={renderColorlessLegendLabel}
        />

        {lines.map((line, index) => (
          <Line
            type="linear"
            dataKey={line}
            key={line}
            yAxisId="y"
            stroke={getLineColor(line, index)}
            strokeWidth={2}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  )
}
