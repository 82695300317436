import {
  AssumptionsStates,
  DashboardStates,
  HeadcountStates,
} from '../../ducks/dashboard/types'
import DashboardComboBarLineChart, {
  DashboardComboBarLineChartData,
} from '../DashboardComboBarLineChart'
import React, { useCallback, useEffect, useState } from 'react'
import {
  selectAssumptionsPageState,
  selectHeadcountPageState,
} from '../../ducks/dashboard/selectors'
import styled from 'styled-components'
import kpi1 from '../../data/kpi1.json'
import kpi2 from '../../data/kpi2.json'
import kpi3 from '../../data/kpi3.json'
import kpiMetadata from '../../data/kpiMetadata.json'
import { useSelector } from 'react-redux'
import { Box, IconButton } from '@mui/material'
import TableViewIcon from '@mui/icons-material/TableView'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import DashboardKpiTable from '../DashboardKpiTable'
import kpiData1 from '../../data/kpiPlanningTable_1.json'

const IconsContainer = styled.div`
  margin-left: 15px;
  display: flex;
  gap: 5px;
`

export const KpiChart = () => {
  const currentAssumptionsState = useSelector(selectAssumptionsPageState)
  const headcountState = useSelector(selectHeadcountPageState)

  const [chartData, setChartData] = useState(kpi1)
  const [rowData, setRowData] = useState(kpiData1)
  const [kpiView, setKpiView] = useState('chart')

  useEffect(() => {
    if (currentAssumptionsState === AssumptionsStates.suggestionClicked)
      setChartData(kpi2)
    else if (headcountState === HeadcountStates.suggestionClicked)
      setChartData(kpi3)
    else {
      setChartData(kpi1)
    }
  }, [currentAssumptionsState, headcountState])

  const handleChangeView = useCallback(() => {
    setKpiView(kpiView === 'table' ? 'chart' : 'table')
  }, [kpiView])

  const FooterPanel = () => (
    <IconsContainer>
      <IconButton size="small" onClick={handleChangeView}>
        <TableViewIcon />
      </IconButton>
      <IconButton size="small">
        <FullscreenIcon />
      </IconButton>
    </IconsContainer>
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      {kpiView === 'table' ? (
        <DashboardKpiTable rowData={rowData} FooterPanel={FooterPanel} />
      ) : (
        <DashboardComboBarLineChart
          data={chartData.data}
          legend={kpiMetadata.legend}
          title={kpiMetadata.title}
          tooltipNameMapper={kpiMetadata.tooltipNameMapper}
          heightRatio={2.3}
          widthRatio={2.4}
          stroke={kpiMetadata.stroke}
          dollars={[true, false]}
          FooterPanel={FooterPanel}
        />
      )}
    </Box>
  )
}

KpiChart.defaultProps = {
  FooterPanel: null,
}
