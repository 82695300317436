import { AggregatedTableContext } from '../../../context/AggregatedTableContext'
import React from 'react'
import type { SecondaryViewAggregationType } from '../../../context/SecondaryTableContext'

export const useRerenderOnTypeChange = (
  aggregationType: SecondaryViewAggregationType,
): void => {
  const { gridRef } = React.useContext(AggregatedTableContext)

  React.useEffect(() => {
    if (!gridRef) return
    const secColumns = gridRef.current?.columnApi.getSecondaryColumns() ?? []
    const cellRanges = gridRef.current?.api?.getCellRanges() ?? []
    const colId = cellRanges[0] ? cellRanges[0]?.columns[0]?.getColId() : ''
    if (cellRanges.length > 0) {
      gridRef?.current?.api?.clearRangeSelection()
    }

    gridRef.current?.api?.addCellRange({
      rowStartIndex: 0,
      rowEndIndex: gridRef.current.api?.getDisplayedRowCount() - 1,
      columns: secColumns.length > 1 ? [colId] : [],
    })
  }, [aggregationType, gridRef])
}
