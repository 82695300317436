import dayjs, { Dayjs } from 'dayjs'

import { API_RECORD_DATE_FORMAT } from './const'
import type { AggregationRange } from '../workers/kpi/types'
import type { TimeSegmentationFrame } from '../types'
import type { UseAggregatedTableContextParams } from '../context/useAggregatedTableContext'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { getNow } from './now'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(advancedFormat)
dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)

const DateTimeFormat = 'MMMM Do YYYY, h:mm:ss a'

export const startingDateFromRange: Record<AggregationRange, GetPeriodStartFn> =
  {
    '1_month': (d) => d.startOf('month').subtract(1, 'month'),
    '12_month': (d) => d.subtract(12, 'month'),
    '3_month': (d) => d.subtract(3, 'month'),
    'qtd': (d) => d.startOf('quarter').subtract(1, 'month'),
    'ytd': (d) => d.startOf('year'),

    // For inclusive range - go forward for 1 month
    '1_month_inclusive': (d) => d.startOf('month'),
    '12_month_inclusive': (d) => d.subtract(11, 'month'),
    '3_month_inclusive': (d) => d.subtract(2, 'month'),
    'qtd_inclusive': (d) => d.startOf('quarter'),
    'ytd_inclusive': (d) => d.startOf('year'),
  }

export const formatUTCDateTime = (utcDateTime: string): string =>
  dayjs.utc(utcDateTime).local().format(DateTimeFormat)

export const isDateInTheNextMonth = (date: string): boolean => {
  const parsed = dayjs(date)
  return parsed > dayjs(getNow()).endOf('month')
}

export const minDateLimit = (date: string, limit: string): string => {
  if (!limit || !date) {
    return date
  }

  if (dayjs(date) < dayjs(limit)) {
    return limit
  }

  return date
}

export function diffInMonthsNumbers(
  relativeDate?: string | Date,
  targetDate?: string | Date,
): number {
  if (!targetDate || !relativeDate) return 0

  const target =
    typeof targetDate === 'string' ? new Date(targetDate) : targetDate
  const relative =
    typeof relativeDate === 'string' ? new Date(relativeDate) : relativeDate

  return (
    target.getFullYear() * 12 +
    target.getMonth() +
    1 -
    (relative.getFullYear() * 12 + relative.getMonth() + 1)
  )
}

export function diffInMonths(d1?: Dayjs | string, d2?: Dayjs | string): number {
  if (!d1 || !d2) return 0

  const start = typeof d1 === 'string' ? dayjs(d1) : d1
  const end = typeof d2 === 'string' ? dayjs(d2) : d2

  return start.diff(end.endOf('month'), 'month')
}

export function absDiffInMonths(
  d1?: Dayjs | string,
  d2?: Dayjs | string,
): number {
  if (!d1 || !d2) return 0

  return Math.abs(diffInMonths(d1, d2))
}

export const allMonthsFromDateRange = (
  start: Dayjs,
  end: Dayjs,
  inclusive = true,
): string[] => {
  const months = absDiffInMonths(start, end)

  return new Array(months + (inclusive ? 1 : 0))
    .fill('')
    .map((_, index) =>
      start.add(index, 'month').endOf('month').format(API_RECORD_DATE_FORMAT),
    )
}

export const getDefaultRange = (
  type: 'kpi' | 'bridge',
): UseAggregatedTableContextParams['defaultRange'] => {
  const startDate = dayjs(getNow()).subtract(12, 'month')

  const start = startDate.startOf('month').format(API_RECORD_DATE_FORMAT)
  const end = dayjs(getNow()).endOf('month').format(API_RECORD_DATE_FORMAT)

  return { start, end }
}

export const getUpdatedAtText = (updatedAtDate: string): string => {
  const parsedUpdatedAt = dayjs.utc(updatedAtDate).local()
  const lastUpdateInHours = dayjs().diff(parsedUpdatedAt, 'hour')

  switch (true) {
    case dayjs().isBefore(dayjs(parsedUpdatedAt)):
      return 'Updated in the future'
    case dayjs().isBefore(dayjs(parsedUpdatedAt).add(30, 'minute')):
      return 'Updated less than 30 minutes ago'
    case dayjs().isBefore(dayjs(parsedUpdatedAt).add(1, 'hour')):
      return 'Updated less than 1 hour ago'
    case dayjs().isBefore(dayjs(parsedUpdatedAt).add(2, 'hour')):
      return 'Updated 1 hour ago'
    case dayjs().isBefore(dayjs(parsedUpdatedAt).add(1, 'day')):
      return `Updated ${lastUpdateInHours} hours ago`
    case dayjs().isBefore(dayjs(parsedUpdatedAt).add(2, 'day')):
      return 'Updated 1 day ago'
    case dayjs().isSameOrAfter(dayjs(parsedUpdatedAt).add(2, 'day')):
      return `Updated ${Math.round(lastUpdateInHours / 24)} days ago`
    default:
      return 'Failed to get update time'
  }
}

const endOfType: Record<TimeSegmentationFrame, Record<string, boolean>> = {
  month: {},
  quarter: {
    '03': true,
    '06': true,
    '09': true,
    '12': true,
  },
  half_year: {
    '06': true,
    '12': true,
  },
  year: {
    '12': true,
  },
}

export const startOfType: Record<
  TimeSegmentationFrame,
  Record<string, boolean>
> = {
  month: {},
  quarter: {
    '01': true,
    '04': true,
    '07': true,
    '10': true,
  },
  half_year: {
    '01': true,
    '07': true,
  },
  year: {
    '01': true,
  },
}

const endDateOfMonth = (year: string): Record<string, string> => ({
  '01': '31',
  '02': +year % 4 === 0 ? '29' : '28',
  '03': '31',
  '04': '30',
  '05': '31',
  '06': '30',
  '07': '31',
  '08': '31',
  '09': '30',
  '10': '31',
  '11': '30',
  '12': '31',
})

export const formatDateToTimeframe = (
  date: string,
  type: TimeSegmentationFrame,
): string => {
  const [year, month] = date.split('-')
  const formatedDay = endDateOfMonth(year)[month]

  // 6 is number of months in 1 half year
  const halfOfYear = Math.ceil(+month / 6)
  // 3 is number of months in 1 quarter
  const quarterOfYear = Math.ceil(+month / 3)

  const timeframeToDateMap: Record<TimeSegmentationFrame, string> = {
    year,
    half_year: `${year}-${halfOfYear}`,
    quarter: `${year}-${quarterOfYear}`,
    month: `${year}-${month}-${formatedDay}`,
  }

  return timeframeToDateMap[type]
}

export const generateDateRange = (
  type: TimeSegmentationFrame,
  start: string,
  finish: string,
): string[] => {
  const formatedType = type === 'half_year' ? 'month' : type
  const startDate = dayjs(start)
  const dataRange: string[] = []
  // Because dayjs doesn't support half_year timeframe, we generate diff in months and divide it by 6,
  //  so we will have difference between start and finish date in half of the year timeframe
  const diff = dayjs(finish).diff(startDate, formatedType)
  const diffByType = type === 'half_year' ? Math.ceil(diff / 6) : diff

  dataRange.push(startDate.endOf(type as dayjs.OpUnitType).format('YYYY-MM-DD'))
  for (let i = 0; i < diffByType; i++) {
    const arrItem = dataRange[dataRange.length - 1]
    // add 6 month (half of the year) for half_year timeframe
    const periodsToAdd = type === 'half_year' ? 6 : 1

    const timePeriod = dayjs(arrItem)
      .add(periodsToAdd, formatedType as dayjs.OpUnitType)
      .endOf(formatedType as dayjs.OpUnitType)
      .format('YYYY-MM-DD')

    dataRange.push(timePeriod)
  }

  return dataRange.map((range) => formatDateToTimeframe(range, type))
}

export const generateIsFinish = (
  date: string,
  type: TimeSegmentationFrame,
): boolean => {
  const [, month] = date.split('-')

  if (endOfType[type][month]) {
    return true
  }

  return false
}

// Generate date labels based on time segmentation

export const formatMap: Record<TimeSegmentationFrame, string> = {
  month: 'MMM YYYY',
  quarter: 'YYYY-Q',
  half_year: 'YYYY',
  year: 'YYYY',
}

export const getDateLabelByTimeSegmentation = (
  date: string,
  type: TimeSegmentationFrame,
  isParsed?: boolean,
): Date | string => {
  const format = formatMap[type]
  const parsedDate = dayjs(date)
  const formatedDate =
    !isParsed || type === 'month' ? parsedDate.format(format) : date

  let toDate = ''
  let today = ''
  const thisTimeframe = formatDateToTimeframe(
    dayjs(getNow()).endOf('month').format('YYYY-MM-DD'),
    type,
  )
  const formatedThisTimeframe =
    type === 'month' ? dayjs(thisTimeframe).format(format) : thisTimeframe
  const nextTimeframe = formatDateToTimeframe(
    dayjs(getNow()).add(1, 'month').endOf('month').format('YYYY-MM-DD'),
    type,
  )
  const formatedNextTimeframe =
    type === 'month' ? dayjs(nextTimeframe).format(format) : nextTimeframe

  if (formatedThisTimeframe === formatedDate) {
    toDate = ' to date'
  } else if (formatedNextTimeframe === formatedDate) {
    today = 'date to '
  }

  if (type === 'quarter') {
    const [year, quarterValue] = formatedDate?.split('-')
    return `${today}Q${quarterValue} ${year}${toDate}`
  }

  if (type === 'half_year') {
    const [year, halfYearValue] = formatedDate?.split('-')
    return `${today}H${halfYearValue} ${year}${toDate}`
  }

  if (type === 'year') {
    return `${today}${formatedDate}${toDate}`
  }

  return `${today}${formatedDate}${toDate}`
}

export type GetPeriodStartFn = (d: Dayjs) => Dayjs
