import { namespace } from './state'
import sagas from './sagas'
import type { State } from './state'
import { genericReportSlice } from './slice'
import type { ReduxConnectable } from 'redux/types'

const connectable: ReduxConnectable<State> = {
  namespace,
  reducer: genericReportSlice.reducer,
  sagas,
}

export default connectable
