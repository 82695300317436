import type { Aggregation, AggregationFormula } from '../../workers/kpi/types'

import type { Metric } from '../../workers/aggregate-bridge.worker'
import type { SecondaryViewAggregationType } from '../../context/SecondaryTableContext'
import numeral from 'numeral'
import { parseAggregation } from '../../utils/primitives'

const percentageKPIs: Record<AggregationFormula, boolean> = {
  asp: false,
  ndr: true,
  nrr: true,
  renewal_rate: true,
  gdrr: true,
  ndrr: true,
}

export const axisAmountLabelFormatter =
  (affix = '$', isSuffix = false) =>
  (value: number): string =>
    `${!isSuffix ? affix : ''}${numeral(value)
      .format('0.0a')
      // Regexp below is getting rid of excessive trailing zeroes that may emerge
      // as the result of above formatting using the strict template string,
      // i.e. by default with the '0.0a' formatting template would format e.g. 1000000 as '1.0M',
      // however it's the obvious case when trailing zero is excessive and just a '1M' would be enough for representation.
      // Please pay attention that following regexp perfectly works only for abovementioned format,
      // i.e. once it changes to e.g. '0.00a', some edge cases wtill won't be covered;
      // e.g. in case there're not only zeroes after the comma, trailing zeroes won't get cut off: 1500000 -> 1.50M
      .replace(/^(.+)\.[0]+([a-zA-Z])?$/, '$1$2')
      .toUpperCase()}${isSuffix ? affix : ''}`

export const hasNonPercentageLines = (aggregation: Aggregation): boolean => {
  const [formula] = parseAggregation(aggregation)
  return !percentageKPIs[formula]
}

export const xAxisLabelFormatter =
  (aggregationType: SecondaryViewAggregationType) =>
  (value: string, index: number): string => {
    // For 'sum' aggregation type in waterfall chart Lost Logos indexes are 3, 8, 13 etc
    // For 'count' aggregation type in waterfall chart Lost Logos indexes are 2, 5, 8 etc
    const isVisible =
      aggregationType === 'sum' ? index % 5 === 3 : index % 3 === 2
    return isVisible ? value : ''
  }

export const getGranularityLength = (value: number): number =>
  Math.abs(value).toFixed(0).length

export const getGranularityValue = (value: number, max = 1000000): number =>
  Math.min(parseFloat(`1e${getGranularityLength(value) - 1}`), max)

export const getClosestGranularNumber = (
  value: number,
  granularityLength = 7,
): number => {
  const granularity = parseFloat(`1e${granularityLength - 1}`)
  return (
    value -
    (value >= 0
      ? value % granularity
      : granularity - Math.abs(value % granularity))
  )
}

export const getLineColor = (line: Metric | string, index: number): string => {
  const map: Record<Metric, string> = {
    'Opening Balance': '#8598CC',
    'New Logos': '#85CCBE',
    'Expansion': '#CCBF85',
    'Lost Logos': '#F59916',
    'Contraction': '#F1959B',
    'Total Churn': '#E45050',
    'Closing Balance': '#A56E35',
  } as Record<Metric, string>

  const colors = [
    '#8598CC',
    '#85CCBE',
    '#CCBF85',
    '#D9EFD5',
    '#5391B3',
    '#A56E35',
  ]

  return map[line as Metric] || colors[index % colors.length]
}

export const ignoreLinesMap: Record<string, boolean> = {
  date: true,
  aggregation: true,
}
