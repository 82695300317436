import { useEffect, useRef, useState } from 'react'

import { AUTH0_SCOPE } from '../utils/const'
import { Maybe } from '../types'
import { User } from '@auth0/auth0-react'
import { environment } from '../environment'
import { useAuth0WithCypress } from '../hooks/useAuth0WithCypress'

export type UseAccessTokenResult = {
  token: Maybe<string>
  error: Maybe<string>
  isAuthenticated: boolean
  user: Maybe<User>
}

export function useAccessToken(): UseAccessTokenResult {
  const [token, setToken] = useState<Maybe<string>>(null)
  const [error, setError] = useState<Maybe<string>>(null)
  const setTokenRef = useRef(setToken)
  const setErrorRef = useRef(setError)
  const { getAccessTokenSilently, isAuthenticated, user } =
    useAuth0WithCypress()

  useEffect(() => {
    setTokenRef.current = setToken
    setErrorRef.current = setError
  }, [setToken, setError])

  useEffect(() => {
    if (!isAuthenticated) return

    const lc = { mounted: true }

    getAccessTokenSilently({
      audience: environment.REACT_APP_AUTH0_AUDIENCE,
      scope: AUTH0_SCOPE.join(','),
    })
      .then((accessToken) => {
        if (!lc.mounted) return
        setTokenRef.current(accessToken)
        setErrorRef.current(null)
      })
      .catch((e) => {
        if (!lc.mounted) return
        setTokenRef.current(null)
        setErrorRef.current(e.message || 'Something went wrong.')
      })

    // eslint-disable-next-line consistent-return
    return () => {
      lc.mounted = false
    }
  }, [isAuthenticated, getAccessTokenSilently])

  return {
    token,
    error,
    isAuthenticated,
    user: user || null,
  }
}
