import { Button } from '@mui/material'
import { Icon } from '../Icon/Icon'
import React from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'

export interface IInsightBadge {
  count?: number
  handleClick?: any
  left?: any
  disabled?: boolean
  sx?: SxProps<Theme>
  inline?: boolean
  small?: boolean
}

const InsightBadge: React.FC<IInsightBadge> = (props) => {
  const { count, handleClick, left, disabled, sx, inline, small } = props

  let sxToUse: SxProps<Theme> = inline
    ? {
        display: 'inline-flex',
        minWidth: 0,
        p: 0,
        ml: '5px',
        mb: '4px',
        ...sx,
      }
    : {
        'position': 'absolute',
        'width': 10,
        'top': '-2%',
        'left': left || '-5%',
        '&::hover': {
          background: 'transparent !important',
        },
        ...sx,
      }

  if (small) {
    sxToUse = {
      ...sxToUse,
      '& svg': {
        width: '18px',
        height: '18px',
      },
    }
  }

  return (
    <Button
      onClick={handleClick}
      disabled={disabled}
      disableElevation
      sx={sxToUse}
    >
      <Icon icon="notification" style={{ position: 'absolute' }} />
      <div style={{ position: 'absolute', color: 'white' }}>{count}</div>
    </Button>
  )
}

export default InsightBadge
