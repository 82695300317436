import ComboBarLineChart, {
  ComboBarLineChartData,
} from 'components/Chart/ComboBarLineChart'

import { Box } from '@mui/material'
import DashboardChartHeader from './DashboardChartHeader'
import InsightBadge from 'components/atoms/InsightBadge'
import React from 'react'

export interface DashboardComboBarLineChartData extends ComboBarLineChartData {
  title: string
  hideInsight?: boolean
  FooterPanel?: () => JSX.Element
}

const DashboardComboBarLineChart: React.FC<DashboardComboBarLineChartData> = ({
  data,
  legend,
  title,
  tooltipNameMapper,
  heightRatio = 2.6,
  widthRatio = 3.2,
  stroke = [],
  area = [],
  dollars = [false, false],
  hideInsight,
  domain = { low: 0, high: 0 },
  FooterPanel,
}) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }}
  >
    <DashboardChartHeader
      legend={legend}
      title={title}
      insight={
        title === 'Headcount' &&
        !hideInsight && (
          <InsightBadge
            count={2}
            disabled
            sx={{
              left: '0',
              transform: 'translate(-57%, 0)',
            }}
          />
        )
      }
    />
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '98%',
      }}
    >
      <ComboBarLineChart
        data={data}
        legend={legend}
        tooltipNameMapper={tooltipNameMapper}
        heightRatio={heightRatio}
        widthRatio={widthRatio}
        stroke={stroke}
        area={area}
        dollars={dollars}
        domain={domain}
      />
    </Box>
    {FooterPanel && <FooterPanel />}
  </Box>
)

export default DashboardComboBarLineChart
