import { Icon } from '../Icon/Icon'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material'

import React from 'react'
import FilterSelect from './FilterSelect'

const PREFIX = 'NavigationDrawer'

const classes = {
  toolpanel: `${PREFIX}-toolpanel`,
  toolpanelItem: `${PREFIX}-toolpanelItem`,
}

export const AnalyticsToolPanel: React.FC = () => {
  const [showFilterDialog, setFilterDialogVisibility] = React.useState(false)

  return (
    <>
      <Dialog
        open={showFilterDialog}
        onClose={() => setFilterDialogVisibility(false)}
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">Filter</DialogTitle>
        <DialogContent>
          <FilterSelect />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFilterDialogVisibility(false)}>Done</Button>
        </DialogActions>
      </Dialog>

      <ul className={classes.toolpanel}>
        <Icon
          icon="main-menu-filter"
          onClick={() => setFilterDialogVisibility(true)}
          className={classes.toolpanelItem}
        />
        <Icon icon="main-menu-calendar" className={classes.toolpanelItem} />
        <Icon icon="main-menu-versions" />
      </ul>
    </>
  )
}
