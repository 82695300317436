import type { AggregationFormula } from '../../../workers/kpi/types'

const formulaTextMap: Record<AggregationFormula, string> = {
  asp: "Total new logos' ARR divided by the number of new logos",
  ndr: '(Opening ARR + expansion — contraction — lost logos) divided by the opening ARR',
  nrr: '(Opening ARR + organic expansion — contraction — lost logos) divided by the opening ARR',
  renewal_rate:
    'The number of renewed accounts divided by the number of renewable accounts',
  ndrr: 'Dollar value of renewed ARR divided by the total dollar value of renewable ARR, including expansion',
  gdrr: 'Dollar value of renewed ARR divided by the total dollar value of renewable ARR, excluding expansion',
}

export const generateFormulaText = (formula: AggregationFormula): string =>
  formulaTextMap[formula]
