import type { Aggregation } from 'workers/kpi/types'
import type { Maybe } from 'types'
import type { RowNode } from 'ag-grid-community'
import { autoColumnName } from 'utils/ag-grid'

export const getTopLevelAggregation = (row: RowNode): Maybe<Aggregation> => {
  if (!row) {
    return null
  }

  return row.parent?.key
    ? getTopLevelAggregation(row.parent)
    : row.groupData?.[autoColumnName('aggregation')]
}
