import {
  formatDateToTimeframe,
  generateDateRange,
  generateIsFinish,
  startOfType,
} from './date-utils'

/* eslint-disable no-param-reassign */
import type { Metric } from '../workers/aggregate-bridge.worker'
import type { TimeSegmentationFrame } from '../types'
import dayjs from 'dayjs'
import { getNow } from './now'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import { sortByMetric } from '../workers/utils'

dayjs.extend(quarterOfYear)
/* eslint-disable camelcase */

const metricKeyMap: Record<Metric, string> = {
  'Opening Balance': 'opening_balance',
  'Booking': 'booking',
  'Total Churn': 'total_churn',
  'New Logos': 'new_logos',
  'Lost Logos': 'lost_logos',
  'Contraction': 'contraction',
  'Expansion': 'expansion',
  'Net Churn': 'net_churn',
  'Growth': 'growth',
  'Closing Balance': 'closing_balance',
}

// To get opening balance only from the first month of timeframe, we need to calculate isStart property
const generateIsStart = (
  start: string,
  date: string,
  type: TimeSegmentationFrame,
): boolean => {
  const [dateYear, dateMonth] = date.split('-')
  const [startYear, startMonth] = start.split('-')

  if (
    (dateMonth === startMonth && dateYear === startYear) ||
    startOfType[type][dateMonth]
  ) {
    return true
  }

  return false
}

export const aggregateBridgeByTimeframe = (
  data: AgBridgeRecord[] = [],
  type: TimeSegmentationFrame = 'quarter',
  start = dayjs(getNow())
    .subtract(12, 'month')
    .startOf('month')
    .format('YYYY-MM-DD'),
  finish = dayjs(getNow()).endOf('month').format('YYYY-MM-DD'),
): typeof data => {
  const dateRange = generateDateRange(type, start, finish)

  // iterate over all AgBridgeRecords to generate object, so we could instantly change amount property for needed metric
  const hashedData = data.reduce((out, item): Out => {
    const isStart = generateIsStart(start, item.date, type)
    const isFinish = generateIsFinish(item.date, type)
    if (
      (!isStart && item.metric === 'Opening Balance') ||
      (!isFinish && item.metric === 'Closing Balance')
    )
      return out

    const { id, amount, actuals, plan } = item
    const dateKey = formatDateToTimeframe(item.date, type)
    const metricKey = metricKeyMap[item.metric]

    out[id] = out[id] || {}
    out[id][dateKey] = out[id][dateKey] || {}
    if (out[id][dateKey][metricKey]) {
      out[id][dateKey][metricKey].amount += amount
      out[id][dateKey][metricKey].actuals =
        (out[id][dateKey][metricKey].actuals || 0) + (actuals || 0)
      out[id][dateKey][metricKey].plan =
        (out[id][dateKey][metricKey].plan || 0) + (plan || 0)
    } else {
      out[id][dateKey][metricKey] = {
        id,
        date: dateKey,
        amount,
        actuals: actuals || 0,
        plan: plan || 0,
        metric: item.metric,
        client__uniqueId: `${id}-${dateKey}-${item.metric}`,
      }
    }
    return out
  }, {} as Out)

  const hashedDataEntries = Object.entries(hashedData)
  const arr: AgBridgeRecord[] = []
  hashedDataEntries.forEach(([, value]) => {
    dateRange.forEach((item) => {
      const dataPerTimeFrame: AgBridgeRecord[] = Object.values(
        value[item] || {},
      )
      if (value[item]) arr.push(...dataPerTimeFrame)
    })
  })

  return arr.sort(sortByMetric)
}

export type AgBridgeRecord = {
  id: string
  date: string
  amount: number
  metric: Metric
  client__uniqueId: string
  actuals?: number
  plan?: number
}

type Out = Record<string, Record<string, Record<string, AgBridgeRecord>>>
