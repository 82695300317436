import type { Maybe } from '../types'
import React from 'react'
import { URL_FLAG_PERCEIVED_NOW } from '../environment/url-flags'
import dayjs from 'dayjs'
import { isEmailAdmissible } from '../utils/primitives'
import { overrideNowDate } from '../utils/now'
import { useAuth0WithCypress } from './useAuth0WithCypress'

export const usePerceivedNowDate = (): void => {
  const { user } = useAuth0WithCypress()

  const nowToOverride = React.useMemo((): Maybe<string> => {
    if (!URL_FLAG_PERCEIVED_NOW || !isEmailAdmissible(user?.email || '')) {
      return null
    }

    const params = new URL(window.document.location.href).searchParams
    return params.get('now')
  }, [user])

  React.useEffect(() => {
    if (nowToOverride) {
      overrideNowDate(dayjs(nowToOverride).toDate())
    }
  }, [nowToOverride])
}
