import type { PropertyColumnFormat } from '../../../api/types'
import numeral from 'numeral'

export type FormatPropColValue = string | number

export function formatPropColValString(value: FormatPropColValue): string {
  if (typeof value === 'string') {
    return value
  }
  return value.toString()
}

export function formatPropColValCurrency(value: FormatPropColValue): string {
  return numeral(value).format('$0,0.00')
}

export function formatPropColValDefault(value: FormatPropColValue): string {
  return formatPropColValString(value)
}

export function formatPropColVal(
  value: FormatPropColValue,
  format?: PropertyColumnFormat,
): string {
  switch (format) {
    case 'String':
      return formatPropColValString(value)
    case 'Currency':
      return formatPropColValCurrency(value)
    default:
      return formatPropColValDefault(value)
  }
}
