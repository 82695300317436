/**
 Redux store and sagas configuring
 */

import createSagaMiddleware, { Saga } from 'redux-saga'

import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'
import config from 'redux/ducks/config'
import { configureStore } from '@reduxjs/toolkit'
import dashboard from 'features/dashboard/ducks/dashboard'
import ducks from './ducks'
import genericReport from 'features/generic-report/ducks/generic-report'
import headcount from 'features/headcount/ducks/headcount'
import scenarioVersions from 'features/scenarioVersions/ducks/scenarioVersions'
import threads from 'features/threads/ducks/threads'

const isDev = process.env.NODE_ENV === 'development'

let allSagas: Saga[] = []

ducks.forEach((d) => {
  if (d.sagas?.length) allSagas = [...allSagas, ...d.sagas]
})

// make root reducer
const rootReducer = combineReducers({
  config: config.reducer,
  headcount: headcount.reducer,
  scenarioVersions: scenarioVersions.reducer,
  threads: threads.reducer,
  genericReport: genericReport.reducer,
  dashboard: dashboard.reducer,
})

/**
 * Creates store and runs saga middleware
 */
function createStore() {
  // add middlewares
  const sagaMiddleware = createSagaMiddleware()
  const middleware = [sagaMiddleware]

  // create store
  const store = configureStore({
    reducer: rootReducer,
    middleware,
    // to use dev tools install this Chrome extension: https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd/related?hl=en
    devTools: isDev,
  })

  // HMR support
  if (isDev && module.hot) {
    module.hot.accept('./ducks', () => {
      store.replaceReducer(rootReducer)
    })
  }

  // run sagas
  sagaMiddleware.run(function* runSagas() {
    yield all([...allSagas.map((s) => s())])
  })

  return store
}

export const store = createStore()

/**
 * It's a util types to use them outside of ducks for some custom selectors and dispatchers.
 * ATTENTION! Do not use them inside the ducks to avoid circular dependencies!
 */
export type AppRootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof createStore>
export type AppDispatch = AppStore['dispatch']
