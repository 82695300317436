import '../../node_modules/ag-grid-community/dist/styles/ag-grid.css'
import '../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css'
import '../styles/ag-grid-custom.css'

import { LicenseManager } from 'ag-grid-enterprise'
import { environment } from '../environment'

if (environment.REACT_APP_AGGRID_LICENSE) {
  LicenseManager.setLicenseKey(environment.REACT_APP_AGGRID_LICENSE)
}
