import { ApiConfigResponse, BridgeRowConf } from '../../api/types'
import type { ICellRendererFunc, ICellRendererParams } from 'ag-grid-community'

export const makeMetricCellRenderer =
  (config?: ApiConfigResponse): ICellRendererFunc =>
  (params: ICellRendererParams) => {
    if (
      (params.value !== 0 && !params.value) ||
      (params.value.length !== undefined && !params.value.length)
    ) {
      return ''
    }

    const rowConfig: BridgeRowConf = {
      field_name: params.value,
      display_name: params.value,
      description: params.value,
    }

    if (config?.bridge?.length) {
      const conf = config.bridge.find((el) => el.field_name === params.value)
      if (conf?.display_name) rowConfig.display_name = conf.display_name
      if (conf?.description) rowConfig.description = conf.description
    }

    let paddingClass = ''
    if (
      ['New Logos', 'Expansion', 'Lost Logos', 'Contraction'].includes(
        params.value,
      )
    ) {
      paddingClass = 'padding-left: 12px;'
    }
    if (['Booking', 'New Logos', 'Expansion'].includes(params.value)) {
      return `<span class="color-text-positive" style="${paddingClass}">${rowConfig.display_name}</span>`
    }
    if (['Total Churn', 'Contraction', 'Lost Logos'].includes(params.value)) {
      return `<span class="color-text-negative" style="${paddingClass}">${rowConfig.display_name}</span>`
    }

    return rowConfig.display_name
  }
